<template>
    <div class="workList-container">
        <div class="uploadwork">共上传{{worknum}}组作品</div>
        <div class="work-lists">
            <!-- 最多能显示16组数据 -->
			<!-- <div v-if="currentPage == 1&&userInfo.id == $store.state.userInfo.id" class="work-container addwork" @click="goAddwork">
					<img src="../../assets/img/add.png" alt="" srcset="">
					<p>点击上传作品</p>
			</div> -->
            <div class="work-container" v-for="(item,index) in workList" :key="index" @click="getWorkDetail(item)">
                <div class="work-cover">
					<div class="shadown">
						
					</div>
                    <img :src="item.cover">
                    <!--  :style="`background: url(${item.cover}) no-repeat center;background-size:cover`" -->
                    <div class="remove-icon" v-if="isMe">
                        <img @click.stop="cutCompileBtn(index)" src="@/assets/img/personal/content_icon_chouti2_nor.png">
                            <div class="works-remove-box"  v-if="clickIndex == index">
                                <div class="works-remove-item" @click.stop="removeWorkClick(item, index)">删除</div>
                            </div>
                    </div>
                    <div class="work-status" v-if="isMe && item.status != 1">
                        <span v-if="item.status == 0">{{'审核中'}}</span>
                        <span v-if="item.status == 2" style="color: #C20000;">{{'未通过审核'}}</span>
                    </div>
                </div>
                <div class="work-content">
                    <div class="work-title">
                        {{item.title}}
                    </div>
                    <div class="work-live">
                       <div class="work-look">
                            <img src="@/assets/newImg/37.png" alt="" style="width:20px;height:20px;">
                            <div>{{item.browseNumber}}</div>
                        </div>
                        <div class="work-look">
                            <img src="@/assets/newImg/38.png" alt="" style="width:20px;height:20px;">
                            <div>{{item.commentNum}}</div>
                        </div>
                        <div class="work-look">
                            <img src="@/assets/newImg/39.png" alt="" style="width:20px;height:20px;">
                            <div>{{item.collectNum}}</div>
                        </div>
						<div class="work-look">
							<img src="@/assets/newImg/43.png" alt=""  style="width:20px;height:20px;">
							<div>0</div>
						</div>
                    </div>
                    <div class="work-user">
                        <div class="lasttime">   
                           {{item.createTime | dateformat('YYYY-MM-DD')}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kong" v-if="workList.length == 0">
                暂无数据！
        </div>
        <div class="paginations">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pageSize"
				prev-text="上一页"
				next-text="下一页"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
        
    </div>
</template>

<script>
import {getOtherUserInfo,getuserInfos} from "@/api/message.js";
import {removeWork} from "@/api/work.js";
    export default {
        data(){
            return{
                // 作者Id
                userOtherId: undefined,
                // 进入的主页是否是自己的
                isMe: true,
                workList: [],
                clickIndex: null,
                total: 0,
                pageSize: 18,
                currentPage: 1,
				worknum:0,
                userInfo: {}
            }
        },
        props: {
            classify: {
                type: Number,
                default: undefined
            }
        },
        watch: {
            classify(v) {
                this.getWorkList();
            }
        },
        created(){
            let userId = parseInt(this.$route.query.usersId);
            // setTimeout(() => {
            //     this.userOtherId = userId && userId !== this.$store.state.userInfo.id ? userId : this.$store.state.userInfo.id;
            //     this.workList= [];
            //     this.isMe = this.userOtherId === this.$store.state.userInfo.id
            //     this.getWorkList();
            // })
            this.userOtherId = userId ? userId : this.$cookie.get('userInfo') ? JSON.parse(this.$cookie.get('userInfo')).id : undefined;
            this.workList= [];
            this.isMe = this.$cookie.get('userInfo') ? this.userOtherId ? this.userOtherId === JSON.parse(this.$cookie.get('userInfo')).id : false : false;
            this.getWorkList();
        },
        methods:{
			
			goAddwork(){
				this.$router.push('/release?isShowMsg=0')
			},
            // async getUserInfo(userId){
            //     let {code, data} = await getuserInfos(userId);
            //     if(code == 200){
            //         this.userInfo = data;
            //     }
            // },
            // 删除作品
            async removeWorkClick(item, index){
                const _that = this;
                this.$confirm('是否确认删除该作品，删除后将无法恢复?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
					let timer = item.createTime;
					timer = timer.substring(0,19);
					timer = timer.replace(/-/g,'/');
					let timestamp = new Date(timer).getTime();
					let timestamp2 = new Date(timer).getTime()+3600000;
					let timestamp3 = new Date(timer).getTime()+5184000000;
					let newTimer = new Date().getTime()
					if(timestamp2<newTimer&&newTimer<timestamp3){
						_that.$message.error('上传作品1小时内或60天以后才可以删除')
					}else {
						removeWork(item.id).then(res =>{
						    if(res.code === 200){
						        _that.$message.success("删除成功");
						        _that.clickIndex = null;
						        _that.getWorkList();
						    }else{
						        // _that.$message.error(res.msg);
						        return ;
						    }
						});
					}
                }).catch(function() {
                    _that.clickIndex = null;
                    _that.$message.info("取消删除");
                });
            },
            // 删除小弹框
            cutCompileBtn(index){
                if(this.clickIndex == index){
                    this.clickIndex = -1;
                }else{
                     this.clickIndex = index;
                }
                
            },
            async getWorkList(){
                let queryData = {
                    pageSize: this.pageSize,
                    pageNum: this.currentPage,
                    // type:1 ,
                    userId: this.userOtherId,
                    workType: 1,
                    status: this.isMe ? undefined : 1
                }
                this.isMe && this.$set(queryData, 'classify', this.classify);
                let {code,rows,total} = await getOtherUserInfo(queryData);
                if(code===200){
                    this.workList = rows;
                    this.total = total;
					this.worknum = this.total
                }
            },
            // 作品详情
            getWorkDetail(item){
				const { href } = this.$router.resolve({
				    path: "/workDetail",
				    query: {
						activeIndex:0,
				        workDetailId: item.id,
				      },
				    });
					window.open(href, '_blank');
					return Promise.resolve(false);
                // this.$router.push(`/workDetail?activeIndex=0&workDetailId=${item.id}`);
            },

            // 分页切换
            handleCurrentChange(val) {
				// if(val==1&&this.userInfo.id == this.$store.state.userInfo.id){
				// 	this.pageSize = 17
				// }else {
				// 	this.pageSize = 18
				// }
				document.documentElement.scrollTop = 380
                this.currentPage = val;
                this.getWorkList();
            },
            handleSizeChange(val) {
            },
        }
    }
</script>

<style lang="scss" scoped>
	
    .workList-container{
        overflow: hidden;
        // background: #353849;
        .uploadwork{
            // width: 158px;
            height: 31px;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 31px;
            color: rgba(255,255,255,0.8);
            // text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
            margin-top:50px;
            margin-left:26px;
        }
        .work-lists{
            margin-top: 15px;
            margin-left: 26px;
            display: flex;
            flex-wrap: wrap;
        }
		
        .work-container{
            width: 250px;
            height: 350px;
            background: #0b1525;
            overflow: hidden;
            margin-right: 20px;
			position: relative;
            margin-top: 20px;
            cursor: pointer;
            margin-bottom: 5px;
            &:hover{
                background-color: rgba(255, 255, 255, 0.1);
            }
            .work-cover{
                width: 100%;
                height: 220px;
                overflow: hidden;
                position: relative;
                img{
                    width:100%;
                    height:100%;
                    object-fit: cover;
                }
				.shadown{
					width: 100%;
					height: 220px;
					position: absolute;
					left: 0;
					top: 0;
					background: linear-gradient(to top,rgba(0,14,14,1) 0%,rgba(0,14,41,0.3) 10%,rgba(0,0,0,0));
				}
            }
            .work-status{
                width:100%;
                height: 40px;
                line-height: 40px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                letter-spacing: 2px;
                color: rgba(255,255,255,1);
                background-color: rgba(0, 0, 0, 0.3);
                text-align: center;
                margin-bottom: 10px;
            }
            .remove-icon{
                width: 25px;
                position: absolute;
				top: 20px;
				right: 20px; 
                border-radius: 6px;
                color: rgba(255,255,255,1);;
                display: none;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .work-content{
                width: 100%;
                // background: #252525;
                opacity: 1;
                .work-title{
					margin-top: 12px;
                    width: 100%;
                    height: 38px;
                    font-size: 16px;
                    font-family: SimHei;
                    font-weight: 500;
                    line-height: 38px;
					letter-spacing: 1px;
					color: rgb(0, 225, 225);
                    opacity: 1;
                    padding-left: 10px;
                    white-space:nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .work-tags{
                    height: 18px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 300;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.767);
                    padding-left:12px;
                }
                .work-live{
                    width:251px;
                    display: flex;
                    margin-top:10px;
                    .work-look{
                        height:18px;
                        display: flex;
                        align-items: center;
                        img{
                            width:100%;
                            height:100%;
                            object-fit: cover;
                        }
                        div{
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 300;
                            line-height: 0px;
                            color: rgba(255, 255, 255, 0.822);
                            // color: rgba(154, 227, 103, 0.5);
                            margin-left:6px;
                            display: block;
                        }
                        &:first-child{
                            margin-left:12px;
                        }
                        &:nth-of-type(n+2){
                            margin-left:20px;
                        }
                    }
                }
                .work-user{
                    height: 18px;
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 12px;
                    align-items: center;
                    margin-top: 20px;
                    .lasttime{
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: 500;
                        line-height: 30px;
                        color: rgba(190, 188, 188, 0.65);
                    }
                }
            }
        }
		.addwork{
				border: 2px dashed #35edff;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #2d3043;
				img {
					margin: 80px auto;
				}
			
				p {
					color: #FFFFFF;
					margin-top: 30px;
					font-size: 22px;
				}
		}
        // .work-container:hover .work-status{
        //.work-cover:hover .work-status,
        .work-cover:hover .remove-icon{
            display: inline-block;
        }

        .works-remove-box{
            width: 40px;
            height: 20px;
            border-radius: 4px;
            margin-top: 5px;
            // margin-right: 55px;
            cursor: pointer;
        }
        .works-remove-item{
            text-align: center;
            width: 30px;
            height: 20px;
            font-size: 12px;
            background: #4D4D4D;
            font-family: SourceHanSansCN-Normal;
            line-height: 20px;
            border-radius: 4px;
            padding: 0 5px;
            color: #FFFFFF;
            cursor: pointer;
            &:hover{
                color: #67bbff;
                // color: #9EEA6A;
            }
        
        }
        .kong{
            width: 100%;
            height: 300px;
            text-align: center;
            color: white;
            line-height: 300px;
        }
        .paginations{
        
            margin-top: 80px;
            margin-bottom: 100px;
            .el-pagination {
                text-align: center;
            }
            ::v-deep .el-pagination .btn-prev {
                // background: #353849;
				width: 65px;
        		height: 35px;
                background: #313345;
				color: #FFFFFF;
				padding: 0px;
				
            }
            ::v-deep .el-pager li {
                background: #181A29;
                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #ffffff;
        		background-color: #313345;
        		margin-left: 10px;
                min-width: 35px;
                height: 35px;
                line-height: 35px;
                box-sizing: border-box;
                text-align: center;
            }
            ::v-deep .el-pagination .btn-next {
				width: 65px;
        		margin-left: 10px;
                height: 35px;
                background: #313345;
				color: #FFFFFF;
				padding: 0;
                // background: #353849;
            }
            ::v-deep .el-pager li.active {
                color:white !important;
        		// background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
        		background: #00b4d4;
            }
            ::v-deep .el-pagination__jump {
                    display: inline-block;
                    font-size: 16px;
                    min-width: 35.5px;
                    height: 35px;
					padding: 0 10px;
                    line-height: 35px;
                    vertical-align: top;
                    box-sizing: border-box;
					border: 1px solid #414141;
                    color: #ffffff;
					background-color: #313345;
            }
            ::v-deep .el-input {
                width: 70px;
                height: 33px;
            }
            ::v-deep .el-pagination__editor.el-input .el-input__inner {
                height: 24px;
				top: -2px;
				background: #001220 !important;
                color: white;
                border: none;
            }
            ::v-deep .el-pagination__editor{
                margin:0 10px;
            }
        }
    }
</style>
<style lang="scss">
	.el-message-box{
		background-color: #001220 !important;
		border: none !important;
		border-top: 8px solid #00d0f4 !important;
		border-radius: 8px !important;
		.el-message-box__headerbtn .el-message-box__close{
			color: #00d0f4 !important;
			&:hover{
				color: #00ffff !important;
				transform: scale(1.2) !important;
			}
		}
		.el-message-box__header{
			border: none !important;
			border-radius: 0 !important;
		}
		.el-message-box__title{
			color: #00ffff !important;
		}
		.el-message-box__message{
			color: #c3c3c3 !important;
		}
		// .el-button{
		// 	background-color: #67bbff;
		// 	border-color: #67bbff ;
		// 	color: #4c596d;
		// 	&:hover{
		// 		background-color: #5297cb;
		// 		border-color: #5297cb ;
		// 		color: #FFFFFF;
		// 	}
		// }
		.el-button--primary{
			background-color: #00d8dd !important;
			border-color: #00d8dd !important;
			color: #000000 !important;
			&:hover{
				background-color: #00fafe !important;
				border-color: #00fafe !important;
			}
		}
	}
</style>