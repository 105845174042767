import instance from "./config.js";

// 关于我们
export function getaboutus(){
    return instance.get(`/api/intro/getAboutUs`);
}

// 招聘列表  /api/talentRecruit/list (已经弃用)
export function gettalentRecruit(data){
    return instance.get(`/api/talentRecruit/list`,{params: data});
}

// 招聘详情  /api/talentRecruit/query
export function getRecruitDetail(id){
    return instance.get(`/api/talentRecruit/query/${id}`);
}

// 联系我们
export function getContactUs(){
    return instance.get(`/api/contact/getContactUs`);
}

// 合作伙伴
export function getPartner(){
    return instance.get(`/api/partner/getList`);
}


// 业务范围(已弃用)
export function getBussiness(){
    return instance.get("/api/bussiness/getBussinessScope");
}

// 首页banner图列表
export function getBannerImgList(data){
    return instance.get("/api/banner/list",{params: data});
}

// 业务范围列表
export function getBusinessScopeList(){
    return instance.get("/api/businessScope/list");
}
// 业务范围详情
export function getBussinessDetail(id){
    return instance.get(`/api/businessScope/query/${id}`);
}

// 业务范围详情
export function getFollowUs(){
    return instance.get(`/api/follow/getFollowUs`);
}

// 招聘列表
export function getJobList(data){
    return instance.get(`/api/job/list`, {params: data});
}

// 招聘详情
export function getJobQueryById(data){
    return instance.get(`/api/job/query`, {params: data});
}

// 热招岗位
export function getHotList(data) {
	return instance.get('/api/job/hot',{params: data})
}

// 个人收藏的招聘信息
export function getJobCollectList(data) {
	return instance.get('/api/job/collect',{params: data})
}

// 申请认证
export function setJobApply(data) {
	return instance.post('/api/job/apply',data)
}

// 查看发布招聘资格
export function getJobAdopt(data) {
	return instance.get('/api/job/adopt',{params: data})
}

// 查询个人公司认证信息
export function getJobAuthInfo(data) {
	return instance.get('/api/job/look',{params: data})
}

// 修改个人公司认证信息
export function updateJobAuthInfo(data) {
	return instance.post('/api/job/update',data)
}
