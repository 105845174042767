<template>
  <div class="guide-container">
    <div style="height: 60px"></div>
    <div class="header-nav">
      <div class="header-left">
        <div class="header-left-logo">
          <el-image
            @click="eventToUrl('/')"
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/logo_white.png"
            class="el-image"
            lazy
          ></el-image>
        </div>
        <div class="header-menu">
          <div
            class="header-menu-option"
            :class="activeIndex == 0 && 'active'"
            @click="eventToUrl('/home/newest?activeIndex=0')"
          >
            <p>作品</p>
            <div class="header-menu-illustrate">
              <div class="illustrate-left">
                <p>游戏美术设计师的交流平台</p>
                <p>与全国一线游戏美术设计师交流碰撞，激发灵感。</p>
              </div>
              <div class="illustrate-list">
                <ul>
                  <li>游戏特效</li>
                  <li>原画插画</li>
                  <li>场景、角色模型</li>
                  <li>动作</li>
                  <li>敬请期待</li>
                </ul>
              </div>
              <div class="illustrate-right">
                这里有最全、最专业的游戏美术作品；3A游戏佳作展示；大神经验分享……总之你想要的这里都有。
              </div>
            </div>
          </div>
          <div class="header-menu-option" :class="activeIndex == 1 && 'active'" v-if="isShowCompWork">
            <p>公司简介</p>
            <div class="header-menu-illustrate">
              <div class="illustrate-left">
                <p>怪力视效网络科技</p>
                <p>提供游戏美术全流程专业设计制作的团队</p>
              </div>
              <div class="illustrate-list">
                <ul>
                  <li>关于我们</li>
                  <li>业务范围</li>
                  <li>合作伙伴</li>
                  <li>人才招聘</li>
                  <li>联系我们</li>
                </ul>
              </div>
              <div class="illustrate-right">
                更多精彩作品请见【公司作品】
              </div>
            </div>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 2 && 'active'"
            @click="eventToUrl('/home/materialList?activeIndex=2')"
            v-if="isShowCompWork"
          >
            <p>公司作品</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 4 && 'active'"
            @click="eventToUrl('/home/recruit?activeIndex=4')"
          >
            <p>招聘</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 3 && 'active'"
            @click="eventToUrl('/train?activeIndex=3')"
          >
            <p>教育培训</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 5 && 'active'"
            @click="eventToUrl('/linkage?activeIndex=5')"
          >
            <p>校企联动</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 6 && 'active'"
            @click="eventToUrl('/courses?activeIndex=6')"
          >
            <p>在线课程</p>
          </div>
          <div
            class="header-menu-option"
            @click="eventToUrl('/ectocyst', '_blank')"
            v-if="isShowCompWork"
            >
            <p>外包平台</p>

          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="header-menu">
          <div
            class="header-menu-option"
            @click="eventToUrl('/login')"
            v-if="
              !(
                $cookie.get('userInfo') &&
                $cookie.get('userInfo') != 'undefined'
              )
            "
          >
            <i
              class="el-icon-user-solid"
              style="font-size: 24px; margin-right: 10px"
            ></i>
            <p
              style="
                font-family: 'Microsoft Yahei';
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 2px;
              "
            >
              登录
            </p>
          </div>
          <template v-else>
            <!-- 上传界面 -->
            <div
              class="header-menu-option"
              :class="activeIndex == 7 && 'active'"
            >
              <i
                class="el-icon-upload"
                @click="eventToUrl('/release?activeIndex=7')"
              ></i>
            </div>
            <!-- 通知界面 -->
            <div
              class="header-menu-option"
              :class="activeIndex == 8 && 'active'"
            >
              <i
                class="el-icon-message-solid"
                @click="eventToUrl('/usermessage?activeIndex=8')"
                style="font-size: 26px"
              ></i>
            </div>
            <!-- 头像 -->
            <div class="header-menu-option">
              <el-image
                @click="eventToForum"
                :src="
                  JSON.parse($cookie.get('userInfo')).headImg ||
                  require('@/assets/img/1.png')
                "
                class="el-image"
                lazy
              ></el-image>
              <div class="header-submenu">
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('/personalhome?open=0')"
                >
                  <p>个人中心</p>
                </div>
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('information')"
                >
                  <p>修改资料</p>
                </div>
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('resetpassword')"
                >
                  <p>密码设置</p>
                </div>
                <div class="header-submenu-option" @click="eventToUrl('task')">
                  <p>引导中心</p>
                </div>
                <div
                  class="header-submenu-option"
                  style="border-top: 1px solid #525252"
                  @click="loginout"
                >
                  <p>退出登录</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="header-content">
      <p>致力于打造最专业的<br/>游戏美术平台</p>
      <p class="header-content-line"><span></span></p>
      <p>COMMITTED TO CREATING THE MOST<br/>PROFESSIONAL GAMING<br/>ART PLATFORM</p>
    </div> 
    <div class="header-footer">
      <p><a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备2021083385号</a></p>
      <p>Copyright©2021 广州怪力视效网络科技有限公司 版权所有</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
   return{
    activeIndex:0,
    isShowCompWork:false
   }
  },
  methods: {
    // 跳转界面
    eventToUrl(url, type) {
      if (type === "_blank") {
        let routeUrl = this.$router.resolve({ path: url });
        window.open(routeUrl.href, "_blank");
        return;
      }
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-container {
  width: 100vw;
  height: 100vh;
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/guide/guide.jpg");
  background-size: cover;
  background-position-x: center;

  .header-nav {
    height: 60px;
    // background-color: #060709;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    &:hover {
      background-color: #060709;
    }
    .header-left {
      height: 100%;
      display: flex;
      align-items: center;
      .header-left-logo {
        margin: 0 80px 0 50px;
        width: 160px;
        height: 45px;
        cursor: pointer;
        /deep/.el-image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .header-right {
      position: absolute;
      right: 60px;
      top: 0;
      height: 60px;
      display: flex;
      align-items: center;
      .header-menu-option > i {
        font-size: 32px;
        color: #ffffff;
        // margin-right: 30px;
        cursor: pointer;

        &:hover {
          color: #2376d2;
        }
      }
      .el-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    .header-menu {
      display: flex;
      align-items: center;
      height: 60px;
      .header-menu-option {
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 2px;
        cursor: pointer;
        margin: 0 20px;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        > p {
          transform: scaleY(1.15);
          font-family: biaoti;

          &:hover {
            color: #2376d2;
          }
        }

        &:hover {
          & > .header-submenu {
            display: block;
          }
          & > .header-menu-illustrate {
            display: flex;
          }
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #1752ac;
            width: 100%;
            height: 5px;
          }
        }

        .header-menu-illustrate {
          position: fixed;
          left: 0;
          top: 60px;
          z-index: 1000;
          width: 100vw;
          background-color: #060709;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          padding: 0 10vw;
          min-height: 300px;
          font-size: 18px;
          font-family: neirong;
          box-sizing: border-box;
          display: none;
          .illustrate-left {
            width: 40%;
            p {
              &:first-child {
                font-weight: 800;
                font-size: 40px;
                margin-bottom: 15px;
              }
            }
          }
          .illustrate-list{
            width: 20%;
            font-weight: 600;
            >ul{
              >li{
                line-height: 40px;
              }
            }
          }
          .illustrate-right{
            width: 35%;
            // height: 100%;
            position: relative;
            padding-left: 50px;
            box-sizing: border-box;
            &::before{
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 260px;
              width: 2px;
              content: '';
              background-color: #888888;
            }
          }
        }

        .header-submenu {
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
          z-index: 1001;
          padding-top: 5px;
          display: none;
          .header-submenu-option {
            text-align: center;
            color: #ffffff;
            line-height: 60px;
            font-size: 20px;
            padding: 0 20px;
            cursor: pointer;
            letter-spacing: 2px;
            background-color: #060709;
            > p {
              transform: scaleY(1.15);
              font-family: biaoti;
              &:hover {
                color: #2376d2;
              }
            }
          }
        }
      }
    }
  }

  .header-content{
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    >p:first-child{
      color: #c2d2df;
      font-size: 60px;
      font-family: Simsun;
      font-weight: bold;
    }
    >p:last-child{
      color: #3b8ab3;
      font-weight: 500;
      font-size: 22px;
      line-height: 36px;
      font-family: math;
    }
    .header-content-line{
      width: 20px;
      height: 20px;
      border: 1px solid #657a85;
      border-radius: 50%;
      padding: 4px;
      box-sizing: border-box;
      position: relative;
      >span{
        display: block;
        width: 10px;
        height: 10px;
        background-color: #657a85;
        border-radius: 50%;
      }
      &::before,&::after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 250px;
        height: 2px;
        background-color: #677b85;
      }
      &::before{
        left: -258px;
      }
      &::after{
        right: -258px;
      }
    }
  }

  .header-footer{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px 10px 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #FFFFFF;
    font-size: 14px;
    >p{
      line-height: 26px;
      a{
        color: #FFFFFF;
      }
    }
  }
}
</style>