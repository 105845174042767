import instance from "./config.js";

// 获取消息
export function getMessageDetails(data) {
    return instance.get("/api/message/list", { params: data });
}

//删除消息
export function removeMessage(data) {
    return instance.post("/api/message/update", data)
}
// 获取消息未读数
export function getUnReadNum() {
    return instance.get("/api/message/getUnReadNum");
}

// 获取消息未读数
export function readMessage() {
    return instance.get("/api/message/readMessage");
}

// 获取个人信息 /api/user/getInfo/{id}
export function getuserInfos(id) {
    return instance.get(`/api/user/getInfo/${id}`);
}

// 未登录状态下获取他人信息
export function getuserInfo_loginout(id) {
    return instance.get(`/api/user/userInfo/${id}`);
}

// 修改个人信息 /api/user/getInfo/{id}
export function updateInfo(data) {
    return instance.post(`/api/user/updateInfo`, data);
}
export function updateInfo2(data) {
    return instance.post(`/api/user/bimg`, data);
}

// 获取作品列表
export function getOtherUserInfo(data) {
    return instance.get("/api/work/list", { params: data });
}

// 查询收藏列表  /api/collect/list
export function getCollectList(data) {
    return instance.get("/api/collect/list", { params: data });
}

// 查询用户关注列表   /api/focus/list
export function getFocusList(data) {
    return instance.get("/api/focus/focusList", { params: data });
}

// 查询粉丝列表
export function getanList(data) {
    return instance.get("/api/focus/fanList", { params: data });
}


// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return instance.get('/api/dict/data/type/' + dictType, { params: dictType })
}

//查询经验值
export function getexp(data) {
    return instance.post('/api/user/exp', data)
}


//查询任务状态
export function gettask(data) {
    return instance.post('/api/user/task', data)
}

//查询个人勋章信息
export function getMedalList(userId) {
    return instance.get('/api/badge/query', { params: userId })
}

// 修改密码
export function updatePwd(data) {
    return instance.post('/api/user/updatePwd', data)
}

// 找回密码
export function resettingPwd(data) {
    return instance.post('/api/user/resettingPwd', data)
}

// 查询是否修改密码
export function getIsHasPwd() {
    return instance.get('/api/user/hasPwd')
}

// 查看积分
export function getCourseIntegral(data) {
    return instance.get(`/api/user/integral`, {params:data});
}