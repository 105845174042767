<template>
  <div class="index-page-container index-job-container">
    <div class="index-page-content index-job-content">
      <div class="page-title job-title">实习就业机会ㆍ指导</div>
      <div class="job-box">
        <el-row :gutter="0">
          <el-col :span="8">
            <div class="job-item active">
              <div class="job-item-top">
                <p>广州范围内<br />推荐就业</p>
              </div>
              <div class="job-item-bottom">推荐实习就业</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="job-item">
              <div class="job-item-top">
                <p>深圳范围内<br />推荐就业</p>
              </div>
              <div class="job-item-bottom">推荐实习就业</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="job-item">
              <div class="job-item-top">
                <p>腾讯、畅游、字节等<br />各大厂内推</p>
              </div>
              <div class="job-item-bottom">大厂内推实习就业名额</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="job-item">
              <div class="job-item-top">
                <p>杭州范围内<br />推荐就业</p>
              </div>
              <div class="job-item-bottom">推荐实习就业</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="job-item">
              <div class="job-item-top">
                <p>上海范围内<br />推荐就业</p>
              </div>
              <div class="job-item-bottom">推荐实习就业</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="job-item">
              <div class="job-item-top">
                <p>北京范围内<br />推荐就业</p>
              </div>
              <div class="job-item-bottom">推荐实习就业</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.index-job-container {
  .index-job-content {
    .job-box {
      padding: 110px 0 150px;
      width: 1200px;
      .job-item {
        //color: #9ABFCC;
        color: #cccccc;
        font-weight: bold;
        margin-top: 50px;
        .job-item-top {
          font-size: 40px;
        }
        .job-item-bottom {
          font-size: 24px;
          margin-top: 20px;
        }
      }
      .job-item.active {
        color: #33ecfe;
        .job-item-bottom {
          color: #ffffff;
        }
      }
    }
  }
}
</style>