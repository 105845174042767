<template>
  <div class="release-container">
    <div class="bg"></div>
    <el-form ref="dataForm" :model="dataForm">
      <div class="release-content">
        <div class="release-content-left">
          <template v-if="activeIndex === 43">
            <recruit-form
              v-if="authStatus == 1"
              ref="recruit-form-left"
            ></recruit-form>
            <recruit-auth
              :authStatus="authStatus"
              ref="recruit-auth-left"
              v-else
            ></recruit-auth>
          </template>
          <template v-else>
            <el-form-item label="作品标题">
              <glsx-input
                :maxlength="25"
                :value="dataForm.title"
                placeholderText="简约的作品标题更容易引起关注，最长25个字"
                @getValue="changeInputValue($event, 'title')"
              ></glsx-input>
            </el-form-item>
            <el-form-item label="作品分类">
              <div class="work-type">
                <el-row :gutter="20" style="width: 100%">
                  <el-col :span="8">
                    <glsxSelect
                      :options="classOptionsLevel1"
                      :optionValue="classifyArr[1]"
                      @getValue="getClassifyId($event, 1, 'classOptionsLevel2')"
                    ></glsxSelect>
                  </el-col>
                  <el-col :span="8">
                    <glsxSelect
                      :options="classOptionsLevel2"
                      :optionValue="classifyArr[2]"
                      v-show="classOptionsLevel2.length"
                      ref="OptionsLevel2"
                      @getValue="getClassifyId($event, 2, 'classOptionsLevel3')"
                    ></glsxSelect>
                  </el-col>
                  <el-col :span="8">
                    <glsxSelect
                      :options="classOptionsLevel3"
                      :optionValue="classifyArr[3]"
                      v-show="classOptionsLevel3.length"
                      ref="OptionsLevel3"
                      @getValue="getClassifyId($event, 3)"
                    ></glsxSelect>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
            <el-form-item label="灵感出处" v-show="activeIndex === 42">
              <glsx-input
                :value="dataForm.inspiration"
                placeholderText="如:出自动漫海贼王 原帖地址:http//:abc"
                @getValue="changeInputValue($event, 'inspiration')"
              ></glsx-input>
            </el-form-item>
            <el-form-item label="添加视频" v-show="activeIndex !== 41">
              <el-switch
                v-model="isUploadVideo"
                active-color="#35edff"
                inactive-color="#393e4b"
              >
              </el-switch>
              <div class="uploadvideo" v-show="isUploadVideo">
                <div class="work-video">
                  <template>
                    <div
                      v-for="(item, index) in videoList"
                      :key="item.fileName"
                      class="drag"
                    >
                      <video :src="item.url" controls></video>
                      <div class="vdobtn">
                        <i @click="changeprev(index)" class="el-icon-back"></i>
                        <i
                          @click="removeVideo(index, item.fileName)"
                          class="el-icon-delete"
                        ></i>
                        <i @click="changenext(index)" class="el-icon-right"></i>
                      </div>
                    </div>
                  </template>
                  <div
                    class="drag"
                    v-for="(item, index) in uploadList"
                    :key="index"
                    style="
                      background-color: #4b505e;
                      border: 0.125rem dashed #35edff;
                    "
                    v-loading="true"
                    element-loading-text="拼命上传中"
                    element-loading-background="#4b505e"
                  ></div>
                  <el-upload
                    v-show="!uploadList.length"
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="succesVideo"
                    accept=".mp4,.avi"
                    :limit="5"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUploadVideo"
                    :file-list="videoList"
                  >
                    <div class="uploadvideos">
                      <img src="@/assets/img/add.png" alt="" srcset="" />
                      <p>支持mp4,avi格式</p>
                      <p>视频大小不超过30M</p>
                      <p>最多上传五个视频</p>
                      <p>点击左右箭头可以更换顺序</p>
                    </div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="添加图片" v-show="activeIndex !== 41">
              <el-switch
                v-model="isUploadImg"
                active-color="#35edff"
                inactive-color="#393e4b"
              >
              </el-switch>
              <div class="uploadimg" v-show="isUploadImg">
                <div class="work-img">
                  <template v-if="imageList">
                    <div
                      v-for="(item, index) in imageList"
                      :key="item.fileName"
                      class="drag"
                    >
                      <img :src="item.url" />
                      <div class="imgbtn">
                        <i
                          @click="changeprev_img(index)"
                          class="el-icon-back"
                        ></i>
                        <i
                          @click="handlePictureCardPreview(item)"
                          class="el-icon-zoom-in"
                        ></i>
                        <i
                          @click="handleRemove(index, item.fileName)"
                          class="el-icon-delete"
                        ></i>
                        <i
                          @click="changenext_img(index)"
                          class="el-icon-right"
                        ></i>
                      </div>
                    </div>
                  </template>
                  <el-upload
                    :action="uploadUrl"
                    :on-success="succesPicture"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload"
                    :on-exceed="handleExceed"
                    :on-remove="handleRemove"
                    :file-list="imageList"
                    :limit="20"
                    accept=".jpg,.png,.gif"
                    :show-file-list="false"
                    multiple
                  >
                    <div class="uploadimgs">
                      <img src="../../assets/img/add.png" alt="" srcset="" />
                      <p>支持jpg,gif,png格式</p>
                      <p>图片大小不超过30M</p>
                      <p>最多上传二十张图片</p>
                      <p>点击左右箭头可以更换顺序</p>
                    </div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <template v-if="activeIndex === 41">
              <el-form-item
                label="技巧内容"
                :style="{ marginBottom: 0 }"
              ></el-form-item>
              <div class="el-form-rich">
                <rich-text
                  @getContent="changeWorkState"
                  :content="dataForm.description"
                ></rich-text>
              </div>
            </template>
          </template>
        </div>
        <div class="release-content-center">
          <div class="center-tabbar">
            <ul>
              <li
                :class="activeIndex == 40 ? 'active' : ''"
                @click="changeActiveIndex(40, 'classOptionsLevel1')"
              >
                原创作品
              </li>
              <li
                @click="changeActiveIndex(42, 'classOptionsLevel1')"
                :class="activeIndex == 42 ? 'active' : ''"
              >
                灵感参与
              </li>
              <li
                @click="changeActiveIndex(41, 'classOptionsLevel1')"
                :class="activeIndex == 41 ? 'active' : ''"
              >
                技巧上传
              </li>
              <li
                @click="changeActiveIndex(43, 'classOptionsLevel1')"
                :class="activeIndex == 43 ? 'active' : ''"
              >
                招聘信息
              </li>
            </ul>
            <div class="center-tips">
              <ul>
                <li>温馨提示：</li>
                <li v-for="(item, index) in tipsText[activeIndex]" :key="index">
                  {{ index + 1 + "." + item }}
                </li>
              </ul>
            </div>
            <div class="center-btn-box">
              <el-button @click="onSubmit" class="center-btn" round
                >提交</el-button
              >
              <el-button round class="center-btn" @click="reset"
                >重置</el-button
              >
            </div>
          </div>
        </div>
        <div class="release-content-right">
          <template v-if="activeIndex === 43">
            <recruit-auth-right
              ref="recruit-auth-right"
              v-if="authStatus == 0 || authStatus == 3"
            ></recruit-auth-right>
            <recruit-form-right
              v-if="authStatus == 1"
              ref="recruit-form-right"
            ></recruit-form-right>
          </template>
          <template v-else>
            <el-form-item label="上传封面">
              <div class="uploadcov">
                <div class="work-cov">
                  <div
                    class="drag"
                    v-for="(item, index) in docImgFileList"
                    :key="index"
                  >
                    <img :src="item.url" />
                    <div class="covbtn">
                      <i
                        @click="handleRemoveDoc(item.fileName)"
                        class="el-icon-delete"
                      ></i>
                    </div>
                  </div>
                  <el-upload
                    :action="uploadUrl"
                    :on-success="succesCoverPicture"
                    v-show="!docImgFileList.length"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="
                      (file) => {
                        return beforeAvatarUpload(file, 2);
                      }
                    "
                    :show-file-list="false"
                    :file-list="docImgFileList"
                    :on-exceed="handleCoverExceed"
                    :limit="1"
                    accept=".jpg,.png,.gif"
                    multiple
                  >
                    <div class="uploadcovs">
                      <img src="../../assets/img/add.png" alt="" srcset="" />
                      <p>支持jpg,gif,png格式</p>
                      <p>封面大小不超过2M</p>
                      <p>只能上传一张封面</p>
                    </div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="作者感言" v-if="activeIndex !== 41">
              <glsx-input
                :value="dataForm.description"
                type="textarea"
                :rows="15"
                @getValue="changeInputValue($event, 'description')"
              ></glsx-input>
            </el-form-item>
          </template>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { compressAccurately } from "image-conversion";
import { insertWork, getChildList, deleteUpload, addJobApi } from "@/api/work.js";
import { getJobAdopt, setJobApply, } from "@/api/company.js";
import glsxInput from "@/component/form/glsxInput.vue";
import glsxSelect from "@/component/form/glsxSelect.vue";
import richText from "@/component/richText/index.vue";
import recruitForm from "./components1/recruitForm_Left.vue";
import recruitAuth from "./components1/recruitAuth_Left.vue";
import recruitAuthRight from "./components1/recruitAuth_Right.vue";
import recruitFormRight from "./components1/recruitForm_Right.vue";
export default {
  data() {
    return {
      tipsText: {
        40: [
          "原创作品上传内容必须为真实个人制作",
          "请不要上传跟作品内容无关封面",
          "封面图片尺寸太大会被压缩",
          "精致作品更容易获得关注",
        ],
        41: ["技巧内容可以插入图片视频等", "转载技巧请务必注明出处以及原作者"],
        42: [
          "转载作品必须注明出处或链接",
          "请不要上传跟作品内容无关封面",
          "简约匹配的标题更容易获得关注",
        ],
        43: [
          "先认证，后发布",
          "认证信息进用于内部审核，不予公开，请放心认证",
          "公司照片有助于招揽人才",
        ],
      },
      activeIndex: 40,
      dataForm: {},
      // 作品分类集合
      classOptionsLevel1: [],
      classOptionsLevel2: [],
      classOptionsLevel3: [],
      // 分类数组
      classifyArr: [],
      isUploadVideo: true,
      videoList: [],
      uploadList: [],
      isUploadImg: true,
      docImgFileList: [],
      imageList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      authStatus: 0,
    };
  },
  components: {
    glsxInput,
    glsxSelect,
    richText,
    recruitForm,
    recruitAuth,
    recruitAuthRight,
    recruitFormRight,
  },
  created() {
    this.classifyArr[0] = 40;
    this.getClassify(40, "classOptionsLevel1");
  },
  methods: {
    // 获取
    async getJobAdopt() {
      let { code, data } = await getJobAdopt();
      if (code === 200) {
        this.authStatus = data;
      }
    },
    //tabbar发生变化
    changeActiveIndex(t, optionName) {
      this.activeIndex = t;
      this.resetClassify();
      this.classifyArr[0] = t;
      this.getClassify(t, optionName);
      if (t == 43) {
        this.getJobAdopt();
      }
    },
    // 分类发生变化
    getClassifyId(event, spliceIndex, optionName) {
      this.classifyArr[spliceIndex] = event;
      if (spliceIndex === 1) {
        this.classOptionsLevel2 = [];
        this.classifyArr[2] = undefined;
        // this.$refs.OptionsLevel2.optionValue = undefined;
        this.$refs.OptionsLevel2.optionValueChild = undefined;
        this.classOptionsLevel3 = [];
        this.classifyArr[3] = undefined;
        // this.$refs.OptionsLevel3.optionValue = undefined;
        this.$refs.OptionsLevel3.optionValueChild = undefined;
      } else if (spliceIndex === 2) {
        this.classOptionsLevel3 = [];
        this.classifyArr[3] = undefined;
        // this.$refs.OptionsLevel3.optionValue = undefined;
        this.$refs.OptionsLevel3.optionValueChild = undefined;
      }
      if (event && optionName) {
        this.getClassify(event, optionName);
      }
    },
    // 获取分类数据
    getClassify(type, optionName) {
      let state = {
        queryType: 1,
        parentId: type,
      };
      getChildList(state).then((res) => {
        if (res.code === 200) {
          this[optionName] = [];
          res.data.forEach((item, index) => {
            this[optionName].push({
              label: item.classifyName,
              value: item.id,
            });
          });
        }
      });
    },
    // 重置分类
    resetClassify() {
      this.classifyArr = [];
      // 作品分类集合
      this.classOptionsLevel1 = [];
      this.classOptionsLevel2 = [];
      this.classOptionsLevel3 = [];
    },
    // 输入框组件发生变化
    changeInputValue(event, changeName) {
      this.dataForm[changeName] = event;
    },
    // 关于视频上传的方法 start
    changeprev(index) {
      if (index !== 0) {
        [this.videoList[index], this.videoList[index - 1]] = [
          this.videoList[index - 1],
          this.videoList[index],
        ];
      }
      this.videoList = this.videoList.concat();
    },
    changenext(index) {
      if (index !== this.videoList.length - 1) {
        [this.videoList[index], this.videoList[index + 1]] = [
          this.videoList[index + 1],
          this.videoList[index],
        ];
      }
      this.videoList = this.videoList.concat();
    },
    changeprev_img(index) {
      if (index !== 0) {
        [this.imageList[index], this.imageList[index - 1]] = [
          this.imageList[index - 1],
          this.imageList[index],
        ];
      }
      this.imageList = this.imageList.concat();
    },
    changenext_img(index) {
      if (index !== this.imageList.length - 1) {
        [this.imageList[index], this.imageList[index + 1]] = [
          this.imageList[index + 1],
          this.imageList[index],
        ];
      }
      this.imageList = this.imageList.concat();
    },
    // 上传视频
    succesVideo(res) {
      this.videoUrl += res.data.url + ",";
      this.videoList.push({
        url: res.data.url,
        fileName: res.data.fileName,
      });
      console.log(this.uploadList);
      this.uploadList.length && this.uploadList.splice(0, 1);
    },
    // 删除视频
    async removeVideo(index, fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.videoList.splice(index, 1);
        this.videoUrl = "";
        this.videoList.forEach((i, index) => {
          this.videoUrl += i.url + ",";
        });
      }
    },
    // 上传前的回调
    beforeAvatarUploadVideo(file) {
      //视频大小（MB）
      let fileSize = file.size / 1024 / 1024;
      // "video/wmv",
      //     "video/rmvb",
      //     "video/mov",
      //     "video/ogg",
      //     "video/flv",
      if (["video/mp4", "video/avi"].indexOf(file.type) == -1) {
        this.$message.error("请上传正确的视频格式！");
        return false;
      }
      if (fileSize > 30) {
        this.$message.error("上传的视频大小不可超过 30MB！");
        return false;
      }

      this.uploadList.push(file.uid);

      return true;
    },
    // 关于视频上传的方法 end

    // 调用删除文件接口
    eventRemoveFile(fileName) {
      return new Promise((resolve, reject) => {
        deleteUpload({ fileName }).then((res) => {
          resolve(res);
        });
      });
    },
    // 关于图片上传的方法 start
    // 上传图片
    succesPicture(response, file, fileList) {
      //判断存储的数组长度
      this.imageList.push({
        url: response.data.url,
        fileType: 1,
        fileName: response.data.fileName,
      });
    },
    succesCoverPicture(response, file, fileList) {
      this.docImgFileList.push({
        url: response.data.url,
        fileName: response.data.fileName,
        fileType: 1,
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      //this.dialogVisible = true;
    },
    // 判断是否符合规定
    beforeAvatarUpload(file, maxSize = 30) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      const isGif = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < maxSize;
      if (!isJPG && !isPng && !isGif) {
        this.$message.error("上传图片只能是 JPG、GIF或png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 " + maxSize + "MB!");
        return false;
      }
      if (maxSize == 2 && file.size / 1024 > 30) {
        return new Promise((resolve) => {
          // 压缩到30KB,可自定义
          compressAccurately(file, 30).then((res) => {
            resolve(res);
          });
        });
      }
      return true;
    },
    // 超出图片限制条件的回调函数
    handleExceed() {
      this.$message.warning(`请最多上传20个文件。`);
    },
    // 删除图片
    async handleRemove(index, fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.imageList.splice(index, 1);
      }
    },
    //删除封面图
    async handleRemoveDoc(fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.coverImage = "";
        this.docImgFileList = [];
      }
    },
    // 超出封面图限制条件的回调函数
    handleCoverExceed() {
      this.$message.warning(`请最多上传1张封面图。`);
    },
    // 关于图片上传的方法 end

    escapeStringHTML(str) {
      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");
      return str;
    },
    // 检查作品分类是否选择到了最后一级
    handleClassifylevel() {
      let result = true;
      if (this.classOptionsLevel3.length && !this.classifyArr[3])
        result = false;
      if (this.classOptionsLevel2.length && !this.classifyArr[2])
        result = false;
      if (this.classOptionsLevel1.length && !this.classifyArr[1])
        result = false;
      return result;
    },
    // 点击提交
    async onSubmit() {
      if (this.activeIndex === 43) {
        this.authSubmit();
        return;
      }
      const _that = this;
      if (!this.dataForm.title) {
        this.messageWarn("请输入作品名称");
        return;
      }
      if (
        !this.classifyArr ||
        !this.classifyArr.length ||
        !this.handleClassifylevel()
      ) {
        this.messageWarn("请选择作品分类");
        return;
      }
      if (this.activeIndex === 42 && !this.dataForm.inspiration) {
        this.messageWarn("请输入灵感出处");
        return;
      }
      if (
        (!this.imageList || !this.imageList.length) &&
        this.isUploadImg &&
        (!this.videoList || !this.videoList.length) &&
        this.isUploadVideo &&
        this.activeIndex !== 41
      ) {
        this.messageWarn("视频和图片请至少上传一个哈");
        return;
      }
      // if ( && this.activeIndex !== 41) {
      //     this.messageWarn('请上传图片');
      //     return;
      // }
      if (!this.docImgFileList || !this.docImgFileList.length) {
        this.messageWarn("请上传封面");
        return;
      }
      if (this.activeIndex === 41 && !this.dataForm.description) {
        this.messageWarn("请输入技巧内容");
        return;
      }

      let queryData = {
        title: this.dataForm.title,
        description:
          this.dataForm.description && this.activeIndex === 41
            ? JSON.stringify(this.escapeStringHTML(this.dataForm.description))
            : this.dataForm.description,
        // 灵感出处
        inspiration: this.dataForm.inspiration,
        //...this.dataForm,
        //视频
        videoUrl: this.videoList.length
          ? this.videoList
              .map((v) => {
                return v.url;
              })
              .join(",")
          : undefined,
        // 图片
        files: this.imageList.length ? this.imageList : undefined,
        // 封面图
        cover: this.docImgFileList[0].url,
        workType: 1,
        //分类
        classify: this.classifyArr
          .filter((v) => {
            if (v) return v;
          })
          .join(","),
        classifyId: this.classifyArr
          .filter((v) => {
            if (v) return v;
          })
          .slice(-1)
          .join(""),
      };
      let { code, msg } = await insertWork(queryData);
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "操作成功，作品审核中",
        });
        setTimeout(() => {
          _that.$parent.activeIndex = -1;
          _that.$parent.isShowMsg = 2;
          _that.$router.push("/personalhome?isShowMsg=2");
        }, 1000);
      }
    },
    // 提交验证
    async authSubmit() {
      if (this.authStatus == 1) {
        this.submitJob();
        return;
      }
      let dataParmas = {
        ...this.$refs["recruit-auth-left"].dataForm,
        ...this.$refs["recruit-auth-right"].dataForm,
      };
      let docImgFileList = this.$refs["recruit-auth-left"].docImgFileList;
      let licenseImageList = this.$refs["recruit-auth-right"].licenseImageList;
      console.log(dataParmas);
      if (!dataParmas.company) {
        this.messageWarn("请输入公司名称");
        return;
      }
      if (!dataParmas.address) {
        this.messageWarn("请输入公司地址");
        return;
      }
      if (!dataParmas.email) {
        this.messageWarn("请输入公司邮箱");
        return;
      }
      if (
        !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(
          dataParmas.email
        )
      ) {
        this.messageWarn("公司邮箱格式错误");
        return;
      }
      if (!docImgFileList || !docImgFileList.length) {
        this.messageWarn("请上传公司Logo");
        return;
      }
      if (!licenseImageList || !licenseImageList.length) {
        this.messageWarn("请上传营业执照");
        return;
      }
      if (!dataParmas.hrName) {
        this.messageWarn("请输入HR姓名");
        return;
      }
      if (!dataParmas.phone) {
        this.messageWarn("请输入联系电话");
        return;
      }
      let queryData = {
        ...dataParmas,
        companyLogo: docImgFileList[0].url,
        userId: JSON.parse(this.$cookie.get("userInfo")).id,
        registration: licenseImageList.map((v) => v.url).join(","),
      };
      let { code } = await setJobApply(queryData);
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "操作成功，信息审核中",
        });
        this.getJobAdopt();
      }
    },
    async submitJob() {
      let dataParmas = {
        ...this.$refs["recruit-form-left"].dataForm,
        ...this.$refs["recruit-form-right"].dataForm,
      };
			let docImgFileList = this.$refs["recruit-form-right"].docImgFileList;
			let environmentImageList = this.$refs["recruit-form-right"].environmentImageList;
			if (!dataParmas.position) {
        this.messageWarn("请输入职位名称");
        return;
      }
      if (!dataParmas.company) {
        this.messageWarn("请输入公司名称");
        return;
      }
      if (!dataParmas.email) {
        this.messageWarn("请输入投递邮箱");
        return;
      }
      if (
        !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(
          dataParmas.email
        )
      ) {
        this.messageWarn("投递邮箱格式错误");
        return;
      }
			if (!dataParmas.cid || !dataParmas.classify) {
        this.messageWarn("请选择招聘分类");
        return;
      }
      if (!dataParmas.emolUment) {
        this.messageWarn("请输入薪水报酬");
        return;
      }
      if (!dataParmas.employCity) {
        this.messageWarn("请输入就业城市");
        return;
      }
      if (!dataParmas.eduBg) {
        this.messageWarn("请选择学历要求");
        return;
      }
      if (!dataParmas.workExp.toString()) {
        this.messageWarn("请选择工作经验要求");
        return;
      }
      if (!dataParmas.postWelfare) {
        this.messageWarn("请选择福利关键词");
        return;
      }
      if (!docImgFileList || !docImgFileList.length) {
        this.messageWarn("请上传封面");
        return;
      }
      if (!dataParmas.address) {
        this.messageWarn("请输入公司地址");
        return;
      }
			if (!dataParmas.postWelfare) {
        this.messageWarn("福利关键词不能为空");
        return;
      }
      if (!dataParmas.postIntro) {
        this.messageWarn("请输入岗位要求");
        return;
      }
			let queryData = {
        ...dataParmas,
        environment: environmentImageList.map((v) => v.url).join(","),
        cover: docImgFileList[0].url,
        rid: JSON.parse(this.$cookie.get("userInfo")).id,
      };
      let { code } = await addJobApi(queryData);
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "提交成功，请等待审核！",
        });
      }
    },
    // 警告信息
    messageWarn(text) {
      this.$message({
        message: text,
        offset: 80,
        type: "warning",
      });
    },
    // 重置
    reset() {
      this.dataForm = {};
      // 已上传视频的集合
      this.videoList = [];
      this.videoUrl = undefined;
      // 图片集合
      this.imageList = [];
      // 所展示的图片地址
      this.coverImage = undefined;
      this.dialogImageUrl = undefined;
      // 上传的封面图集合
      this.docImgFileList = [];
      this.resetClassify();
      this.$message({
        type: "success",
        offset: 80,
        message: "重置成功",
      });
    },
    // 重置分类
    resetClassify() {
      this.classifyArr = [];
      // 作品分类集合
      this.classOptionsLevel1 = [];
      this.classOptionsLevel2 = [];
      this.classOptionsLevel3 = [];
      this.classifyArr[0] = this.activeIndex;
      this.getClassify(40, "classOptionsLevel1");
    },
    // 富文本
    changeWorkState(e) {
      this.dataForm.description = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.release-container {
  .bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/message/bg.png");
    z-index: -1;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/955BAE0DE8EC7664A455580C1953D8C3.png");
      background-position: 50% 42%;
    }
  }

  .release-content {
    width: 1800px;
    min-height: 500px;
    border: 2px solid #1752ac;
    border-radius: 30px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    margin: auto;
    margin-top: 200px;
    display: flex;
    color: #ffffff;
    .release-content-left,
    .release-content-right {
      width: 42%;
      padding: 50px 30px;
      box-sizing: border-box;
    }
    .release-content-center {
      width: 16%;
      position: relative;
      min-height: 600px;
      .center-tabbar {
        position: absolute;
        left: 0;
        width: 100%;
        top: -50px;
        height: calc(100% + 100px);
        background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/release/tabbar_bg.png");
        background-size: 100% 100%;
        padding: 0;
        box-sizing: border-box;
        > ul {
          color: #ffffff;
          font-size: 32px;
          font-family: "biaoti";
          padding-top: 30px;
          letter-spacing: 5px;
          > li {
            line-height: 60px;
            text-align: center;
            cursor: pointer;
            &.active {
              background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
            }
          }
        }

        .center-tips {
          margin-top: 30px;
          width: 100%;
          height: 220px;
          padding: 0 20px;
          box-sizing: border-box;
          color: #ffffff;
          font-size: 14px;
          > ul {
            > li {
              margin-top: 15px;
            }
          }
        }

        .center-btn-box {
          width: 100%;
          display: flex;
          justify-content: space-around;
          .center-btn {
            padding: 5px 20px;
            font-size: 18px;
            font-weight: bold;
            background-color: transparent;
            color: #ffffff;
            letter-spacing: 5px;
            // font-family: 'biaoti';
            // transform: scaleY(1.2);
          }
        }
      }
    }

    .uploadvideo {
      box-sizing: border-box;
      margin-top: 20px;
      width: 100%;
      border-radius: 15px;
      background: rgba(195, 195, 195, 0.2);

      .work-video {
        display: flex;
        flex-wrap: wrap;

        .drag {
          // flex: 200px;
          width: 200px !important;
          height: 200px !important;
          border-radius: 15px;
          margin-left: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          overflow: hidden;
          background-color: #23272f;
          position: relative;

          video {
            width: 100%;
            height: 100%;
            margin-top: -20px;
          }

          .vdobtn {
            position: absolute;
            display: flex;
            width: 180px;
            padding: 10px;
            top: 70px;
            justify-content: space-between;
            align-items: center;

            i {
              cursor: pointer;
              font-size: 25px;
              transition: all 0.2s linear;

              &:hover {
                transform: scale(1.2);
              }
            }
          }

          /deep/.el-loading-spinner {
            margin-top: 0;
            transform: translateY(-50%);
            .el-loading-text {
              line-height: 14px;
            }
          }
        }
      }

      .uploadvideos {
        width: 200px;
        height: 200px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        border: 2px dashed #35edff;
        border-radius: 15px;
        background-color: #4b505e;
        img {
          margin: 30px auto;
        }

        p {
          font-size: 12px;
          letter-spacing: 1px;
          line-height: normal;
          color: #ffffff;
        }
      }
    }

    .uploadimg {
      box-sizing: border-box;
      margin-top: 20px;
      width: 100%;
      border-radius: 15px;
      background: rgba(195, 195, 195, 0.2);

      .work-img {
        display: flex;
        flex-wrap: wrap;

        .drag {
          // flex: 200px;
          width: 200px !important;
          height: 200px !important;
          border-radius: 15px;
          margin-left: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          overflow: hidden;
          background-color: #23272f;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .imgbtn {
            position: absolute;
            display: flex;
            width: 180px;
            padding: 10px;
            top: 70px;
            justify-content: space-between;
            align-items: center;

            i {
              cursor: pointer;
              font-size: 25px;
              transition: all 0.2s linear;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }

      .uploadimgs {
        width: 200px;
        height: 200px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        border: 2px dashed #35edff;
        border-radius: 15px;
        background-color: #4b505e;
        img {
          margin: 30px auto;
        }

        p {
          font-size: 12px;
          letter-spacing: 1px;
          line-height: normal;
          color: #ffffff;
        }
      }
    }

    .uploadcov {
      box-sizing: border-box;
      margin-top: 55px;
      width: 100%;
      border-radius: 15px;
      background: rgba(195, 195, 195, 0.2);

      .work-cov {
        display: flex;
        flex-wrap: wrap;

        .drag {
          // flex: 200px;
          width: 200px !important;
          height: 200px !important;
          border-radius: 15px;
          margin-left: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          overflow: hidden;
          background-color: #23272f;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .covbtn {
            position: absolute;
            display: flex;
            width: 180px;
            padding: 10px;
            top: 70px;
            justify-content: center;
            align-items: center;

            i {
              cursor: pointer;
              font-size: 25px;
              transition: all 0.2s linear;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }

      .uploadcovs {
        width: 200px;
        height: 200px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        border: 2px dashed #35edff;
        border-radius: 15px;
        background-color: #4b505e;

        img {
          margin: 30px auto;
        }

        p {
          font-size: 12px;
          letter-spacing: 1px;
          line-height: normal;
          color: #ffffff;
        }
      }
    }

    /deep/ .el-form-item {
      margin-bottom: 30px;
      .work-type {
        display: flex;
      }
    }
    /deep/ .el-form-item__label {
      color: #fff;
      letter-spacing: 3px;
      font-size: 16px;
    }

    /deep/ .el-input {
      width: calc(100% - 90px);
    }

    /deep/ .el-select {
      > .el-input {
        width: 100%;
        letter-spacing: 2px;
      }
    }

    .el-form-rich {
      height: 300px;
      margin-bottom: 50px;
      color: #ffffff;
    }
  }
}
</style>