<template>
  <div class="header-container">
    <div class="header-main" :style="isManage && 'width: calc(100% - 60px);'">
      <img
        src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/logo.png"
        class="header-logo"
        @click="eventToView('/ectocyst')"
      />
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleSelect"
        background-color="#262932"
        text-color="#ffffff"
        active-text-color="#ffffff"
      >
        <el-menu-item index="0"><p>任务</p></el-menu-item>
        <el-menu-item
          index="3"
          v-if="
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-effects' ||
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-painting' ||
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-movement' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-model' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-ui' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
          "
          ><p>技术评论</p></el-menu-item
        >
        <el-menu-item
          index="1"
          v-if="
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-All' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-business'
          "
          ><p>审核</p></el-menu-item
        >
        <el-menu-item
          index="2"
          v-if="
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-finance' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
          "
          ><p>财务结算</p></el-menu-item
        >
      </el-menu>
      <div class="header-btn">
        <div
          class="header-btn-item header-btn-publish"
          @click="eventChangePage('assignmentPublish')"
          v-if="
            JSON.parse(this.$cookie.get('userInfo')).permission ===
              'T-business' ||
            JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
          "
        >
          我要发包
        </div>
        <!-- <div class="header-btn-item header-btn-login" @click="eventChangePage('ectocystLogin')">登录</div> -->
        <!-- <div class="header-btn-item header-btn-sign" @click="eventToView('/ectocyst/register')">注册</div> -->
        <div class="header-btn-user">
          <el-dropdown>
            <div class="header-img">
              <img
                :src="
                  JSON.parse(this.$cookie.get('userInfo')).epibolyInfo
                    .headImage || require('@/assets/img/1.png')
                "
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="
                  JSON.parse(this.$cookie.get('userInfo')).permission ===
                    'T-common' ||
                  JSON.parse(this.$cookie.get('userInfo')).permission ===
                    'T-All'
                "
                @click.native="eventChangePage('ectocystTasks', 'list')"
                >我的任务</el-dropdown-item
              >
              <el-dropdown-item
                v-if="
                  JSON.parse(this.$cookie.get('userInfo')).permission ===
                    'T-business' ||
                  JSON.parse(this.$cookie.get('userInfo')).permission ===
                    'T-All'
                "
                @click.native="eventChangePage('ectocystTasks', 'listBusiness')"
                >我的发包</el-dropdown-item
              >
              <el-dropdown-item @click.native="eventLoginOut()" divided
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginUp } from "@/api/login.js";
export default {
  data() {
    return {
      activeIndex: undefined,
      pageList: ["assignment", "ectocystExamine", "ectocystFinancial", "ectocystComment"],
    };
  },
  props: {
    isManage: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    console.log("路由：", this.$route.query.page);
    this.selectAction();
  },
  methods: {
    // 根据page参数选中activeIndex
    selectAction() {
      let page = this.$route.query.page || "assignment";
      this.activeIndex =
        this.pageList.indexOf(page) !== -1
          ? this.pageList.indexOf(page).toString()
          : undefined;
    },
    handleSelect(t) {
      this.activeIndex = t;
      // switch(Number(t)) {
      //   case 0: {
      //     page = 'assignment';
      //     break;
      //   }
      //   case '1': {
      //     page = 'ectocystExamine';
      //   }
      //   case '2': {
      //     page = '';
      //   }
      //   default: {
      //     page = this.pageList[0];
      //   }
      // }
      let page = this.pageList[Number(t)];
      this.$router.push("/ectocyst?page=" + page);
      this.selectAction();
    },
    // 退出登录
    async eventLoginOut() {
      let data = {
        token: this.$cookie.get("accessToken"),
      };
      let { code } = await loginUp(data);
      if (code == 200) {
        this.$cookie.remove("accessToken");
        this.$cookie.remove("userInfo");
        this.$router.push("/login");
        window.location.reload();
      }
    },
    eventChangePage(page, query) {
      console.log(page);
      this.activeIndex = undefined;
      let routeQuery = "";
      if (query) {
        routeQuery = "&comp=" + query;
      }
      this.$router.push("/ectocyst?page=" + page + routeQuery);
    },
    //跳转到界面
    eventToView(url) {
      this.$router.push(url);
      this.selectAction();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  background-color: #262932;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  color: #ffffff;
  .header-main {
    width: 1260px;
    margin: 0 auto;
    display: flex;
    .header-logo {
      width: 160px;
      height: 50px;
      margin-top: 5px;
      margin-right: 20px;
    }
    .header-btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      .header-btn-item {
        padding: 5px 20px;
        cursor: pointer;
        margin: 0 5px;
        font-weight: bold;
        letter-spacing: 2px;
        &.header-btn-publish {
          //background-color: #5078be;
          background-color: var(--default-ectocyst-color);
          border-radius: 6px;
        }
        &.header-btn-login {
        }
        &.header-btn-sign {
          border: 1px solid var(--default-ectocyst-color);
          border-radius: 6px;
        }
      }

      .header-btn-user {
        margin-left: 50px;
        .header-img {
          width: 40px;
          height: 40px;
          > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
  /deep/.el-menu.el-menu--horizontal {
    border-bottom: 0;
    height: 60px;
  }
  /deep/.el-menu-item {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 0;
    > p {
      display: inline;
      position: relative;
    }
  }

  /deep/.el-menu-item.is-active {
    border-bottom-color: transparent;
    border-bottom: 0;
    > p::after {
      content: "";
      width: 100%;
      background-color: #4a82e5;
      position: absolute;
      height: 2px;
      left: 0;
      bottom: -6px;
    }
  }
}
</style>