<template>
  <div class="train-container">
    <swiper
      class="swiper-container-par swiper-no-swiping"
      :options="swiperOption"
      id="swiper"
    >
      <!-- 首页 -->
      <swiper-slide class="swiper-slide" pageIndex="0">
        <page-home @eventToPage="toSwiperPage"></page-home>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="0">
        <page-home-case @eventToPage="toPageConsulting"></page-home-case>
      </swiper-slide>
      <!-- 课程系列 -->
      <swiper-slide class="swiper-slide" pageIndex="1">
        <page-series @eventToPage="toPageConsulting"></page-series>
      </swiper-slide>
      <swiper-slide
        class="swiper-slide"
        pageIndex="1"
        v-for="(item, index) in curriculumList"
        :key="index"
      >
        <page-series-curriculum
          :content="item"
          @eventToPage="toPageConsulting"
        ></page-series-curriculum>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="1">
        <page-series-guidance></page-series-guidance>
      </swiper-slide>
      <!-- 课程优势 -->
      <swiper-slide class="swiper-slide" pageIndex="2">
        <page-advantage @eventToPage="toSwiperPage"></page-advantage>
      </swiper-slide>
      <!-- 讲师团队 -->
      <swiper-slide class="swiper-slide" pageIndex="3">
        <page-teacher></page-teacher>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="3">
        <page-teacher-adviser></page-teacher-adviser>
      </swiper-slide>
      <!-- 课程案例 -->
      <swiper-slide class="swiper-slide" pageIndex="4">
        <page-case @changeAllowSlide="changeAllowSlideNext"></page-case>
      </swiper-slide>
      <!-- 学员作品 -->
      <swiper-slide class="swiper-slide" pageIndex="5">
        <page-works @changeAllowSlide="changeAllowSlideNext"></page-works>
      </swiper-slide>
      <!-- 就业机会 -->
      <swiper-slide class="swiper-slide" pageIndex="6">
        <page-job></page-job>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="6">
        <page-job-interpolation></page-job-interpolation>
      </swiper-slide>
      <!-- 集训环境 -->
      <swiper-slide class="swiper-slide" pageIndex="7">
        <page-environment></page-environment>
      </swiper-slide>
      <!-- 课程咨询 -->
      <swiper-slide class="swiper-slide" pageIndex="8">
        <page-consulting></page-consulting>
      </swiper-slide>
      <swiper-slide class="swiper-slide" pageIndex="8">
        <page-consulting-question
          @eventToPage="toPageConsulting"
        ></page-consulting-question>
      </swiper-slide>
      <!-- 分页器 -->
      <div class="swiper-pagination" slot="pagination" id="custom"></div>
    </swiper>
    <!-- <span class="index-train-antation"></span>
    <span class="index-train-antation index-train-antation-two"></span>
    <span class="index-train-antation index-train-antation-three"></span> -->
  </div>
</template>

<script>
// window._MICHAT = window._MICHAT || function () { (_MICHAT.a = _MICHAT.a || []).push(arguments) };
//     _MICHAT("accountid", 135165);
//     _MICHAT("domain", "magchat.meinuoka.com");
//     (function (m, d, q, j, s) {
//         j = d.createElement(q),s = d.getElementsByTagName(q)[0];
//         j.async = true;
//         j.charset ="UTF-8";
//         j.src = ("https:" == document.location.protocol ? "https://" : "http://") + "magchat.meinuoka.com/Web/JS/mivisit.js?_=t";
//         s.parentNode.insertBefore(j, s);
//     })(window, document, "script");

// (function (w, d, n, a, j) {
//     w[n] = w[n] || function () {
//       (w[n].a = w[n].a || []).push(arguments);
//     };
//     j = d.createElement("script");
//     j.async = true;
//     j.src = "https://kefu.ywkefu.com/static/js/ywkefu_sdk.js";
//     var s = d.getElementsByTagName("script")[0];
//     s.parentNode.insertBefore(j, s);
//   })(window, document, "ywkf");
//   ywkf("config", {
//     appSceneId: 715
//   })

import pageHome from "./components/home/index.vue";
import pageHomeCase from "./components/home/index_case.vue";
import pageSeries from "./components/series/index.vue";
import pageSeriesCurriculum from "./components/series/index_curriculum.vue";
import pageSeriesGuidance from "./components/series/index_guidance.vue";
import pageAdvantage from "./components/advantage/index.vue";
import pageTeacher from "./components/teacher/index.vue";
import pageTeacherAdviser from "./components/teacher/index_adviser.vue";
import pageCase from "./components/case/index.vue";
import pageWorks from "./components/works/index.vue";
import pageJob from "./components/job/index.vue";
import pageJobInterpolation from "./components/job/index_interpolation.vue";
import pageEnvironment from "./components/environment/index.vue";
import pageConsulting from "./components/consulting/index.vue";
import pageConsultingQuestion from "./components/consulting/index_question.vue";
export default {
  data() {
    const vm = this;
    return {
      swiperOption: {
        direction: "vertical",
        mousewheel: true,
        speed: 800,
        touchStartPreventDefault: false,
        allowSlideNext: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "custom",
          // 这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
          renderCustom: function (swiper, current, total) {
            let _html = "";
            vm.swiper = swiper;
            let swiperChildDom = document
              .getElementById("swiper")
              .getElementsByClassName("swiper-wrapper")[0].childNodes;
            let slideDom = [];
            for (var j in swiperChildDom) {
              if (
                swiperChildDom[j].className &&
                swiperChildDom[j].className.indexOf("swiper-slide") !== -1
              ) {
                slideDom.push(swiperChildDom[j]);
              }
            }
            vm.activePageIndex =
              slideDom[current - 1].getAttribute("pageIndex");
            // 已经渲染过分页器的pageInedx
            let hadCurrentList = [];
            for (let i = 1; i <= total; i++) {
              let pageIndex = slideDom[i - 1].getAttribute("pageIndex");
              if (hadCurrentList.includes(pageIndex)) {
                continue;
              }
              // current == i
              if (parseInt(vm.activePageIndex) === parseInt(pageIndex)) {
                _html +=
                  `<span class="swiper-pagination-custom-item swiper-pagination-custom-item-active" tabIndex="` +
                  i +
                  `" pageIndex="` +
                  pageIndex +
                  `">` +
                  vm.titleList[pageIndex] +
                  `</span>`;
              } else {
                _html +=
                  `<span class="swiper-pagination-custom-item" tabIndex="` +
                  i +
                  `" pageIndex="` +
                  pageIndex +
                  `">` +
                  vm.titleList[pageIndex] +
                  `</span>`;
              }
              hadCurrentList.push(pageIndex);
            }
            return _html;
          },
        },
      },
      sliderBgList: ["red", "green", "yellow", "blue", "purple"],
      customBox: undefined,
      swiper: undefined,
      activePageIndex: 0,
      titleList: [
        "首页",
        "课程系列",
        "课程优势",
        "讲师团队",
        "课程案例",
        "学员作品",
        "就业机会",
        "集训环境",
        "课程咨询",
      ],
      curriculumList: [
        {
          title: "Unity引擎特效课程",
          typeList: [
            {
              title: "基础课程",
              contentList: [
                "PS、3dsMax软件基础",
                "Unity引擎软件基础",
                "粒子与模型在特效中的运用",
                "ASE制作常用特效Shader",
                "动画编辑与TimeLine",
                "插件与脚本的调用",
                "后期光效与滤镜的调节",
              ],
            },
            {
              title: "进阶课程",
              contentList: [
                "不同职业的普攻与重击",
                "法师技能的多种表现技法",
                "防御、治疗等Buff制作",
                "蓄力与施法",
                "元素特征与运动规律",
                "色彩搭配与动态节奏",
                "画面构图与颜色层次",
                "项目规范与职业操守",
              ],
            },
          ],
        },
        {
          title: "3dsMax游戏动作课程",
          typeList: [
            {
              title: "基础课程",
              contentList: [
                "3dsMax软件基础",
                "动画原理基础",
                "蒙皮与绑定",
                "关键Pose设计制作",
                "走跑跳等基础动作方法",
                "不同形式攻击动作分析",
              ],
            },
            {
              title: "进阶课程",
              contentList: [
                "连击动作设计",
                "表演动作设计",
                "四足动物动作制作",
                "受击、死亡等动作表现",
                "动作与情感表现",
                "项目规范与职业操守",
                "毕业作品设计与指导",
              ],
            },
          ],
        },
        // {
        //     title: 'UE4(虚幻)引擎课程',
        //     typeList: [{
        //         contentList: [
        //             'UE4软件基础',
        //             '基础场景搭建与环境设置',
        //             '材质模板、实例、函数',
        //             '虚幻级联粒子基础',
        //             '虚幻4特效实战案例',
        //             '关卡定序器渲染输出'
        //         ]
        //     }]
        // }
      ],
    };
  },
  components: {
    pageHome,
    pageHomeCase,
    pageSeries,
    pageSeriesCurriculum,
    pageSeriesGuidance,
    pageAdvantage,
    pageTeacher,
    pageTeacherAdviser,
    pageCase,
    pageWorks,
    pageJob,
    pageJobInterpolation,
    pageEnvironment,
    pageConsulting,
    pageConsultingQuestion,
  },
  mounted() {
    this.customBox = document.getElementById("custom");
    this.customBox.addEventListener("click", this.handleClick, false);
  },
  methods: {
    handleClick(e) {
      // 获取目标元素，拿到目标元素上的 index 值
      const current = e.target;
      const toCount = current.attributes["tabIndex"].value || "";
      this.activePageIndex = current.attributes["pageIndex"].value || 0;
      // 跳转到指定的 swiper 页面
      if (toCount) {
        this.swiper.slideTo(toCount - 1);
      }
      //this.swiper.allowSlideNext = false;
    },
    toSwiperPage(pageNum) {
      if (pageNum) {
        this.swiper.slideTo(pageNum - 1);
      }
    },
    toPageConsulting() {
      this.swiper.slideTo(14);
    },
    changeAllowSlideNext(e) {
      this.swiper.allowSlideNext = e;
      this.swiper.allowSlidePrev = e;
    },
  },
  destroyed() {
    this.customBox.removeEventListener("click", this.handleClick, false);
  },
};
</script>


<style lang="scss" scoped>
.train-container {
  min-width: 1000px;
  &::before {
    content: "";
    background-image: url("../../assets/img/kj.jpg");
    opacity: 0.3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .swiper-container-par {
    width: 100%;
    height: calc(100vh - 60px);
    .swiper-slide {
      width: 100%;
      height: calc(100vh - 60px);
      color: #ffffff;
      min-width: 1000px;
      overflow: hidden;
    }
  }

  .swiper-pagination {
    position: fixed;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    width: auto;
    height: fit-content;
  }
  ::v-deep .swiper-pagination-custom-item {
    padding: 12px 0;
    color: #9abfcc;
    font-weight: 500;
    width: auto;
    height: 20px;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 18px;
  }
  ::v-deep .swiper-pagination-custom-item.swiper-pagination-custom-item-active {
    color: #ffffff;
  }

  .index-page-container {
    width: 100%;
    height: 100%;
    min-height: 1080px;
    overflow: hidden;
  }

  /deep/ .index-page-container .index-page-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 23%;
    width: fit-content;
    .page-title {
      font-size: 48px;
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        width: 80px;
        height: 5px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        position: absolute;
        bottom: -6px;
        left: 0;
      }
    }
  }

  .index-train-antation {
    display: block;
    width: 1px;
    background: transparent;
    position: absolute;
    top: 60px;
    right: 200px;
    opacity: 0;
    z-index: -1;
    //动画的名称(必须要有)
    animation-name: star-fall;
    //动画的运动曲线(linear是匀速stept()是分步)
    animation-timing-function: linear;
    //动画的运动时间
    animation-duration: 2s;
    //动画的运动次数(infinite是无限循环)
    animation-iteration-count: infinite;
    //动画是否逆序播放
    //animation-direction: alternate;
    &::after {
      content: "";
      display: block;
      border: 0px solid #fff;
      border-width: 0px 90px 2px 90px;
      border-color: transparent transparent transparent rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.1);
      /*变形*/
      transform: rotate(-45deg) translate3d(1px, 3px, 0);
      -webkit-transform: rotate(-45deg) translate3d(1px, 3px, 0);
      -moz-transform: rotate(-45deg) translate3d(1px, 3px, 0);
      transform-origin: 0% 100%;
      -webkit-transform-origin: 0% 100%;
      -moz-transform-origin: 0% 100%;
    }
    &-two {
      right: 250px;
      top: 0;
    }
    &-three {
      right: 300px;
      top: 80px;
    }
  }

  @keyframes star-fall {
    0% {
      opacity: 0;
      transform: scale(0.5) translate3d(0, 0, 0);
      -webkit-transform: scale(0.5) translate3d(0, 0, 0);
      -moz-transform: scale(0.5) translate3d(0, 0, 0);
    }
    50% {
      opacity: 1;
      transform: translate3d(-200px, 200px, 0);
      -webkit-transform: translate3d(-200px, 200px, 0);
      -moz-transform: translate3d(-200px, 200px, 0);
    }
    100% {
      opacity: 0;
      transform: scale(1.2) translate3d(-300px, 300px, 0);
      -webkit-transform: scale(1.2) translate3d(-300px, 300px, 0);
      -moz-transform: scale(1.2) translate3d(-300px, 300px, 0);
    }
  }
}
</style>