<template>
  <div class="index-page-container index-rule-container">
    <div class="index-page-content index-rule-content">
        <div class="page-content">
            <div class="page-content-top">
                <div class="page-header-box">
                    <h2 class="page-header-title"><span class="el-icon-s-claim"></span>赛事规则</h2>
                </div>
                <dl class="page-part">
                    <dd class="page-part-content">
                        <p>1.参赛者需原创参赛作品，作品创意以及主要呈现元素必须为参赛者原创；（例如：参赛者欲把一座“城堡”作为核心表现元素，参赛者可以从网络获取石材、木材等，但不可直接使用网络获取的完整“城堡”模型作为成品提交。）</p>
                        <p>2.制作软件不限，加分参考软件: Unity、Unreal Engine、PS、3dsMax、Maya 。</p>
                        <p>3.按照赛事规定时间内提交作品，逾期无效。</p>
                        <p>4.本次大赛为个人赛，参赛作品必须为个人原创，禁止组队及外援协助制作，一旦发现有抄袭和违反赛事规定，将立即取消参赛资格。</p>
                    </dd>
                </dl>
            </div>
            <div class="page-content-main">
                <div class="page-content-box">
                    <dl class="page-part">
                        <dt class="page-part-title">大赛主题：</dt>
                        <dd class="page-part-content">
                            <p>“我的游戏，我的力量”</p>
                        </dd>
                    </dl>
                    <dl class="page-part">
                        <dt class="page-part-title">比赛时间：</dt>
                        <dd class="page-part-content">
                            <p>2023年11月-12月</p>
                        </dd>
                    </dl>
                    <dl class="page-part">
                        <dt class="page-part-title">赛程安排：</dt>
                        <dd class="page-part-content">
                            <!-- 2022年12月18日 -->
                            <p>1.作品投递时间：2023年11月1日至2023年12月1日。</p>
                            <!-- 2022年11月29日至2022年12月1日 -->
                            <p>2.专家评审时间：2023年12月4日至2023年12月10日。</p> 
                            <!-- 2022年12月2日。（视疫情情况安排现场或线上） -->
                            <p>3.颁奖典礼时间：2023年12月18日至2023年12月22日。（视校企协商结果而定）</p>
                            <!-- 2022年12月3日。 -->
                            <p>4.结果公布时间与方式：2023年12月18日至2023年12月22日，结果与奖项在广州工商学院美术学院官方网站与微信公众号与公司官方网站公布，链接：http://www.glsxvfx.com。</p>
                        </dd>
                    </dl>
                </div>
                <div class="page-content-box" style="margin-left: 80px;">
                    <dl class="page-part">
                        <dt class="page-part-title">参赛对象：</dt>
                        <dd class="page-part-content">
                            <p>广州工商学院美术学院、国际教育学院</p>
                        </dd>
                    </dl>
                    <dl class="page-part">
                        <dt class="page-part-title">投稿方式：</dt>
                        <dd class="page-part-content">
                            <p>1.请赛参者在2023年11月15日前扫码在线填写报名表：</p>
                            <p>
                                <img src="@/assets/img/linkage/qr_code.png" />
                            </p>
                            <!-- 2022年12月18日 -->
                            <p>2.参赛作品请在2023年11月28日前发送到指定的官方邮箱：jyb@glsxvfx.com。邮箱标题与作品格式为：美术大赛+院部+专业班级+姓名+手机号码+作品名称。</p>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <!-- <div class="page-content" style="margin-left: 150px; margin-top: 70px;">
            <dl class="page-part">
                <dt class="page-part-title">参赛方式：</dt>
                <dd class="page-part-content">
                    <p>1.规定时间在本网站（怪力屋）完成账号注册，并完善个人信息。</p>
                    <p>2.请赛参者在2022年11月15日扫码在线填写报名表：</p>
                    <p>
                        <img src="@/assets/img/linkage/qr_code.png" />
                    </p>
                    <p>3.参赛作品请在2022年11月28日前发送到指定的官方邮箱：jyb@glsxvfx.com。邮箱标题与作品格式为：美术大赛+院部+专业班级+姓名+手机号码+作品名称。</p>
                </dd>
            </dl>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.index-rule-container{
    background: url(../../../../assets/img/linkage/page2_bg.jpg) center center
    repeat-x #000518;
    background-size: cover;
    .index-rule-content{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .page-header-box{
            margin-bottom: 10px !important;
        }
        .page-content{
            .page-content-main{
                display: flex;
            }
            .page-content-box{
                // display: grid;
                // display: -ms-grid;
                // display: -moz-grid;
                // grid-template-columns: 1fr 1fr;
                // grid-template-rows: auto auto auto;
                // grid-gap: 60px 40px;
                display: flex;
                flex-direction: column;
                width: 550px;
            }
            .page-part{
                //margin-bottom: 80px !important;
                //width: 550px;
                .page-part-content{
                    padding-left: 24px !important;
                    >p{
                        line-height: 36px;
                        >img{
                            width: 150px;
                            height: 150px;
                            margin-left: 120px;
                        }
                    }
                }
            }
            .page-part.page-part-col2{
                grid-column: span 2;
            }
            .page-part-row2{
                grid-row: span 2;
            }
        }
    }
}
</style>