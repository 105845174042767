<template>
  <div class="attestion-type-container">
    <div class="attestion-type-top">
      <p class="attestion-type-title">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/register/rz-icon-title.png"
        />怪力屋认证注册
      </p>
      <p class="attestion-type-subtitle">请选择属于你的认证注册类型哟</p>
    </div>
    <div class="attestion-type-box">
      <div class="box-item">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/register/rz-img-individual.png"
        />
        <p class="box-item-title">个人身份认证注册</p>
        <p class="box-item-describe">适合独立艺术家申请</p>
        <el-button class="box-item-btn" @click="eventChangePage('person')">点击申请</el-button>
      </div>
      <div class="box-item">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/register/rz-img-company.png"
        />
        <p class="box-item-title">企业身份认证注册</p>
        <p class="box-item-describe">适合企业/工作室申请</p>
        <el-button class="box-item-btn" @click="eventChangePage('company')">点击申请</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    eventChangePage(type) {
      this.$router.push('/ectocyst/register?type='+ type);
    }
  }
};
</script>

<style lang="scss" scoped>
.attestion-type-container {
  width: 1260px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  .attestion-type-top{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .attestion-type-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 42px;
    color: var(--default-ectocyst-color);
    > img {
      width: 36px;
      margin-right: 15px;
    }
  }
  .attestion-type-subtitle{
    font-size: 28px;
    color: #000000;
    margin-top: 20px;
  }
  .attestion-type-box {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .box-item {
      width: 300px;
      background-color: #ffffff;
      border: 1px solid rgba(135, 146, 156, 0.2);
      border-radius: 6px;
      padding: 30px 30px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      &:hover{
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      }
      > img {
        width: 300px;
        height: 200px;
        object-fit: contain;
      }
      .box-item-title {
        color: #000000;
        margin-top: 10px;
      }
      .box-item-describe {
        color: #9aa3bd;
        font-size: 16px;
        margin-top: 8px;
      }
      .box-item-btn {
        margin-top: 15px;
        width: 100%;
        letter-spacing: 5px;
        background-color: var(--default-ectocyst-color);
        color: #ffffff;
      }
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
}
</style>