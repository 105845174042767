<template>
  <div class="login-container-box">
    <div class="box-right-top">
      <div class="box-right-header">
        <el-tabs
          v-model="typeName"
          @tab-click="handleClick"
          v-if="!isShowWxLogin"
        >
          <el-tab-pane label="短信登录" name="message"></el-tab-pane>
          <el-tab-pane label="密码登录" name="password"></el-tab-pane>
        </el-tabs>
        <el-tabs value="wxlogin" v-else>
          <el-tab-pane label="微信扫码登录" name="wxlogin"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="box-right-form" v-if="!isShowWxLogin" style="height: 260px;">
        <el-form ref="form" :model="dataForm" label-width="0" :rules="rules">
          <el-form-item prop="account">
            <el-input
              placeholder="请输入手机号"
              v-model="dataForm.account"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
            >
              <template slot="prepend">
                <img
                  src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-icon-user.png"
                  class="form-item-img"
                />
              </template>
            </el-input>
          </el-form-item>
          <template v-if="typeName === 'password'">
            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="dataForm.password"
                @keydown.enter.native="getLogin"
              >
                <template slot="prepend">
                  <img
                    src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-icon-pwd.png"
                    class="form-item-img"
                  />
                </template>
              </el-input>
            </el-form-item>
            <!-- <el-form-item>
              <div class="form-item-bottom">
                <el-checkbox v-model="checked">记住我</el-checkbox>
                <p>忘记密码?</p>
              </div>
            </el-form-item> -->
          </template>
          <template v-else>
            <el-form-item prop="verifyCode">
              <div class="form-item-message">
                <el-input
                  placeholder="请输入验证码"
                  v-model="dataForm.verifyCode"
                  @keydown.enter.native="getLogin"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  maxlength="6"
                ></el-input>
                <el-button
                  @click="eventGetCodes"
                  :disabled="!downBool"
                  :loading="!downBool"
                  >{{ codeBtnText || "获取验证码" }}</el-button
                >
                <!-- <p>验证码已发送至手机，请注意查收</p> -->
              </div>
            </el-form-item>
            <el-form-item prop="referralCode">
              <el-input
                placeholder="推荐码，若无推荐码或登录账号可不填"
                v-model="dataForm.referralCode"
              >
                <!-- <template slot="prepend">
                  <img
                    src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-icon-user.png"
                    class="form-item-img"
                  />
                </template> -->
              </el-input>
            </el-form-item>
          </template>

          <el-form-item>
            <el-button
              class="form-item-submit"
              @click="eventSubmit"
              :loading="loginLoading"
              :style="{ marginTop: typeName === 'message' ? '10px' : '62px' }"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 微信登录 -->
      <div class="box-wx-content" v-else>
        <wx-login
          appid="wx7d33275501e355ab"
          :scope="'snsapi_login'"
          :theme="'white'"
          state="123"
          :redirect_uri="encodeURIComponent('http://www.glsxvfx.com/login')"
          rel="external nofollow"
          class="wx-iframe-box"
          :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgICAgICBkaXNwbGF5OiBub25lOwogICAgICB9Ci5pbXBvd2VyQm94IC5xcmNvZGV7CndpZHRoOiAyMDBweDsKbWFyZ2luLXRvcDogMTBweDsKfQouaW1wb3dlckJveCAuaW5mb3sKZGlzcGxheTpub25lOwp9Cgog'"
        >
        </wx-login>
        <p>使用微信扫一扫登录 “怪屋”</p>
      </div>
    </div>
    <div class="box-right-bottom">
      <el-divider>其它登录方式</el-divider>
      <div class="bottom-img" @click="eventShowWxQrCode">
        <img
          :src="
            !isShowWxLogin
              ? 'https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/login/dl-icon-wechat-hover.png'
              : 'https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-user.png'
          "
        />
      </div>
    </div>
    <div class="box-tips">
      提示：未注册的用户请选择短信登录，登录成功后将自动为用户注册。
    </div>
  </div>
</template>

<script>
import wxLogin from "vue-wxlogin";
import { getPhoneAuthCode, codeLogin, loginByPwd } from "@/api/login.js";
export default {
  data() {
    return {
      dataForm: {
        account: undefined,
        password: undefined,
        verifyCode: undefined,
        referralCode: undefined
      },
      rules: {
        account: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式错误",
          },
        ],
      },
      typeName: "message",
      codeBtnText: "获取验证码",
      codeTime: 60,
      downBool: true,
      // 是否记住我
      checked: false,
      // 登录按钮loading状态
      loginLoading: false,
      // 微信ID
      phoneWxId: undefined,
      // 是否显示微信登录
      isShowWxLogin: false,
    };
  },
  components: {
    wxLogin,
  },
  methods: {
    // 点击切换tab
    handleClick() {
      this.$refs["form"].clearValidate();
    },
    // 点击登录
    async eventSubmit() {
      this.loginLoading = true;
      try {
        if (this.typeName === "password") {
          let passwordRules = [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 8,
              max: 16,
              message: "密码长度应在 8 到 16 个字符",
              trigger: "blur",
            },
          ];
          this.$set(this.rules, "password", passwordRules);
          this.$delete(this.rules, "verifyCode");
        } else if (this.typeName === "message") {
          let codeRules = [
            { required: true, message: "请输入验证码", trigger: "blur" },
            { len: 6, message: "验证码长度应该是6个字符", trigger: "blur" },
          ];
          this.$set(this.rules, "verifyCode", codeRules);
          this.$delete(this.rules, "password");
        }
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            let res;
            if (this.typeName === "password") {
              res = await loginByPwd({
                account: this.dataForm.account,
                password: this.dataForm.password,
              }).catch((e) => {
                this.$message({
                  type: "error",
                  offset: 80,
                  message: e,
                });
                return;
              });
            } else if (this.typeName === "message") {
              res = await codeLogin({
                account: this.dataForm.account,
                verifyCode: this.dataForm.verifyCode,
              }).catch((e) => {
                this.$message({
                  type: "error",
                  offset: 80,
                  message: e,
                });
                return;
              });
            }
            if (res.code === 200) {
              console.log(res);
              if (res.data.message) {
                this.$message({
                  type: "success",
                  offset: 80,
                  message: "首次登录赠送100经验值!",
                });
              }
              if (res.data.userInfo) {
                if (
                  (Number(res.data.userInfo.roleType) === 4 ||
                    Number(res.data.userInfo.roleType) === 3) &&
                  !("permission" in res.data.userInfo)
                ) {
                  this.$set(
                    res.data.userInfo,
                    "permission",
                    res.data.userInfo.epibolyInfo.permission
                  );
                }
                this.$cookie.set("accessToken", res.data.token, {
                  expires: 30,
                });
                this.$cookie.set("userInfo", res.data.userInfo, {
                  expires: 30,
                });
                this.$store.commit(
                  "setUserInfo",
                  JSON.parse(this.$cookie.get("userInfo"))
                );
                this.$store.commit("setisShowLogin", true);
                this.$message.success("登录成功, 即将跳转");
                if (Number(res.data.userInfo.roleType) === 4) {
                  setTimeout(() => {
                    this.$router.push("/ectocyst");
                    window.location.reload();
                  }, 2000);
                } else {
                  setTimeout(() => {
                    this.$router.push("/home/newest?activeIndex=0&latest=0");
                    window.location.reload();
                  }, 2000);
                }
              }
            } else {
              this.loginLoading = false;
            }
          } else {
            this.loginLoading = false;
          }
        });
      } catch (e) {
        this.loginLoading = false;
      }
    },
    // 获取验证码
    eventGetCodes() {
      this.downBool = false;
      try {
        this.$refs["form"].validateField("account", async (valid) => {
          if (!valid) {
            let { code, msg } = await getPhoneAuthCode({
              account: this.dataForm.account,
              type: 1,
            }).catch((e) => {
              this.$message({
                type: "error",
                offset: 80,
                message: e,
              });
              this.downBool = true;
              return;
            });
            if (code === 200) {
              this.$message({
                type: "success",
                offset: 80,
                message: msg,
              });
              this.intervalDwon();
              //this.$refs.code.focus();
            } else {
              this.downBool = true;
            }
          } else {
            console.log("error submit!!");
            this.downBool = true;
          }
        });
      } catch (e) {
        console.log("error:" + e);
        this.downBool = true;
      }
    },
    // 验证码倒计时
    intervalDwon() {
      this.interval = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        this.codeBtnText = this.codeTime + "s重新发送";
        if (this.codeTime == 0) {
          clearInterval(this.interval);
          this.codeTime = 60;
          this.codeBtnText = "获取验证码";
          this.downBool = true;
        }
      }, 1000);
    },
    // 切换到微信登录二维码
    eventShowWxQrCode() {
      this.isShowWxLogin = !this.isShowWxLogin;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container-box {
  width: 450px;
  height: 520px;
  padding: 30px 50px;
  box-sizing: border-box;
  background-color: #37425d;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  left: calc(50% - 225px);
  top: calc(50% - 250px);
  .box-right-top {
    .box-right-form {
      margin-top: 10px;
      .form-item-img {
        width: 20px;
      }
      .form-item-pwd {
        margin-bottom: 5px;
      }
      .form-item-bottom {
        display: flex;
        justify-content: space-between;
        color: #6e7892;
        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: var(--default-ectocyst-color);
          border-color: var(--default-ectocyst-color);
        }
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: var(--default-ectocyst-color);
        }

        /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #3f4b69;
        }

        > p {
          cursor: pointer;
        }
      }

      .form-item-message {
        display: flex;
        //margin-bottom: 45px;
        position: relative;
        > .el-button {
          margin-left: 10px;
          background-color: #00c0ff;
          color: #ffffff;
          border-color: #00c0ff;
        }
        > p {
          color: #00c0ff;
          position: absolute;
          left: 0;
          top: 32px;
        }
      }

      /deep/.form-item-submit {
        width: 100%;
        color: #ffffff;
        letter-spacing: 10px;
        background: #00c0ff;
        border-color: #00c0ff;
        > i {
          letter-spacing: 0;
        }
      }
    }

    .box-wx-content {
      width: 100%;
      height: 230px;
      text-align: center;
      color: #68748c;
      .wx-iframe-box {
        width: 300px;
        height: 100%;
        margin: auto;
      }
    }
  }

  .box-right-bottom {
    .bottom-img {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    /deep/.el-divider {
      background-color: #3f4b69;
    }

    /deep/.el-divider__text {
      background-color: #37425d;
      color: #959eb8;
    }
  }

  .box-tips {
    margin-top: 20px;
    font-size: 14px;
    color: #d71c25;
  }

  /deep/.el-tabs__item {
    color: #959eb8;
    font-size: 16px;
    &.is-active {
      color: #ffffff;
      font-weight: bold;
    }
  }

  /deep/.el-tabs__active-bar {
    background-color: #00c0ff;
  }

  /deep/.el-tabs__nav-wrap::after {
    background-color: #3f4b69;
  }

  /deep/.el-input-group__prepend {
    padding: 0 15px;
    background-color: #242a3a;
    border-color: #3f4b69;
  }

  /deep/.el-input__inner {
    background-color: #242a3a;
    border-color: #3f4b69;
    color: #ffffff;
    font-size: 16px;
    &::placeholder {
      color: #667087;
    }
  }

  /deep/.el-checkbox__inner {
    background-color: #242a3a;
    border-color: #3f4b69;
  }
}
</style>