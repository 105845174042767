<template>
  <div class="recruit-container">
    <div class="recruit-query-header">
      <div class="recruit-header-sign">
        <el-button class="el-button" icon="el-icon-edit" type="primary" @click="handleSign" v-if="!isSign"
          >点击签到</el-button
        >
        <el-button class="el-button" icon="el-icon-edit" type="primary" v-else disabled plain
          >你已签到</el-button
        >
      </div>
      <div class="recruit-header-menu">
        <ul class="menu-list">
          <li
            class="menu-item"
            :class="!queryForm.classify && 'menu-item-active'"
            @click="eventChangeIndex()"
          >
            首页
          </li>
          <li
            class="menu-item"
            @mouseenter="eventShowChildren"
            @mouseleave="eventHideChildren"
            :class="
              queryForm.classify &&
              queryForm.classify !== 4 &&
              'menu-item-active'
            "
          >
            <p>
              特效基础<span>{{ typeName }}</span>
            </p>
            <i
              :class="typeName ? 'el-icon-close' : 'el-icon-arrow-down'"
              @click="
                () => {
                  typeName && iconClick('classify', 'typeName');
                }
              "
            ></i>
            <div class="menu-children-box">
              <ul class="menu-children-list">
                <li
                  class="menu-children-item"
                  :data="item.id"
                  :active="queryForm.cid === item.id"
                  name="classify"
                  @click.stop="
                    eventItemClick(
                      'classify',
                      item.id,
                      'typeName',
                      item.classifyName
                    )
                  "
                  v-for="item in newTypeOption"
                  :key="item.id"
                >
                  {{ item.classifyName }}
                </li>
              </ul>
            </div>
          </li>
          <li
            class="menu-item"
            :class="queryForm.classify && queryForm.classify === 4 && 'menu-item-active'"
            @click="eventChangeIndex(4)"
          >
            综合类课程
          </li>
        </ul>
        <div class="menu-search">
          <div class="search-center">
            <input
              type="text"
              @keydown.enter="search"
              v-model="searchtext"
              placeholder="搜索视频名称"
              class="search-input"
            />
          </div>
          <div class="search-right">
            <button @click="search" class="search-go"></button>
          </div>
        </div>
      </div>



    </div>
    <div class="recruit-content">
      <div class="recruit-hot-list">
        <p class="hot-title">在线课程</p>
        <div class="hot-list">
          <!-- <p class="recruit-none-data">
            <i class="el-icon-postcard"></i><br />暂无数据
          </p> -->
        </div>
        <!-- <div class="hot-text">
          <p>海量优质课程</p>
          <p>让学习更高效</p>
          <span class="text-flag">怪屋在线</span>
        </div> -->


        <div style="
          margin-top: -226px;
          width: 195px;
          height: 200px;
          margin-left: -27px;
         
          padding: 40px;"> 
          <!-- <p>你要成为一位专业游戏特效师吗？</p>
          <p>为解决大家就业难题，现在提供免费系统学习三个月特效课程</p>
          <p>听说还推荐就业哦名额有限，先到先得！</p> -->

          <img src="@/assets/img/aboutUs/tz.png"/>
          <div style="
            position: absolute;
            left: 105px;
            bottom: -15px;">
          <router-link :to="{name:'guanggao'}" >
            <span style="color: aqua;">详情请点击</span>
          </router-link>
          </div>
        </div>

      </div>



      <div class="recruit-list">
        <router-link
          class="recruit-item"
          v-for="item in seriesList"
          :key="item.id"
          tag="a"
          target="_blank"
          :to="`/coursesDetail?activeIndex=6&id=` + item.id"
        >
          <item :recruitInfo="item"></item>
        </router-link>
      </div>
      <div class="recruit-item-line">
        <p class="line-item" v-for="item in seriesList.length" :key="item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/crile.png"
          />
          <svg v-if="item === 1">
            <line x1="25%" y1="100%" x2="40%" y2="130%"></line>
            <line x1="40%" y1="130%" x2="100%" y2="130%"></line>
          </svg>
          <svg v-else-if="item === 4">
            <line x1="25%" y1="30%" x2="40%" y2="0%"></line>
            <line x1="40%" y1="0%" x2="100%" y2="0%"></line>
          </svg>
          <svg v-else>
            <line x1="40%" y1="70%" x2="100%" y2="70%"></line>
          </svg>
        </p>
      </div>
      <div class="paginations">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import item from "./components/item1.vue";
import { getSeriesList, handleSign, getSignInfo } from "@/api/series.js";
export default {
  data() {
    return {
      activeTypeIndex: 1,
      menuList: [],
      majorList: [],
      classifyName: undefined,
      queryForm: {
        pageNum: 1,
        pageSize: 4,
      },
      search_type_options: [
        {
          label: "岗位",
          value: 1,
        },
        {
          label: "用户",
          value: 2,
        },
      ],
      seriesList: [],
      search_type: 1,
      searchtext: undefined,
      // 普通岗位
      jobList: [],
      total: 0,
      newTypeOption: [
        {
          id: 1,
          classifyName: "入门",
        },
        {
          id: 2,
          classifyName: "进阶",
        },
        {
          id: 3,
          classifyName: "高级",
        },
      ],
      typeName: undefined,
      isSign: false,
    };
  },
  components: {
    item,
  },
  created() {
    this.$store.commit("setShowNav", false);
    this.getList();
    this.getSignInfo();
  },
  methods: {
    // 签到
    async handleSign() {
      let { code, msg } = await handleSign({ type: 2 });
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: msg,
        });
        this.isSign = true;
      }
    },
    // 查看签到情况
    async getSignInfo() {
      let { code, data } = await getSignInfo({ type: 2 });
      if (code === 200) {
        this.isSign = data[data.today];
      }
    },
    // 获取列表数据
    getList() {
      getSeriesList(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.total = res.total;
          this.seriesList = res.rows;
        }
      });
    },
    // 点击筛选栏
    eventChangeIndex(index) {
      this.queryForm.classify = index;
      this.getList();
    },
    eventShowChildren(e) {
      e.currentTarget.setAttribute("type", "show");
    },
    eventHideChildren(e) {
      e.currentTarget.removeAttribute("type");
    },
    // 点击事件
    eventItemClick(attributeName, attributeId, attrName, name) {
      this.queryForm[attributeName] = attributeId;
      this[attrName] = "：" + name;
      this.getList();
    },
    // 点击X 事件
    iconClick(attributeName, attrName) {
      this[attrName] = undefined;
      this.queryForm[attributeName] = undefined;
      this.getList();
    },
    search() {
      this.queryForm.seriesName = this.searchtext;
      this.getList();
    },
    // 页码发生变化
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit-container {
  width: 100vw;
  height: calc(100vh - 60px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
  background-size: 100% 100%;
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    background-position: 30px 50%;
    background-repeat: no-repeat;
  }
  .recruit-query-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    right: 20px;
    border-bottom: 1px solid #929292;
    width: 90%;
    .recruit-header-sign{
      height: 48px;
      .el-button{
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border: none;
        height: 100%;
      }
    }
    .recruit-header-menu {
      display: flex;
      align-items: flex-end;
      margin-right: 40px;
      .menu-list {
        display: flex;
        height: 48px;
        color: #ffffff;
        position: relative;
        // &::after {
        //   content: "";
        //   width: 100%;
        //   height: 1px;
        //   background-color: #929292;
        //   position: absolute;
        //   bottom: 0;
        // }
        .menu-item {
          padding: 0 15px;
          margin: 0 5px;
          cursor: pointer;
          position: relative;
          line-height: 48px;
          > p {
            display: inline-block;
          }
          > .el-icon-close {
            margin-left: 5px;
          }
          .menu-children-box {
            position: absolute;
            top: 46px;
            left: 0;
            //transform: translateX(-50%);
            z-index: 2001;
            .menu-children-list {
              background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
              width: 100px;
              text-align: center;
              padding: 10px 0px;
              display: none;
              margin-right: 2px;
              //display: flex;
              .menu-children-item {
                height: 30px;
                line-height: 30px;
                //padding: 0 10px;
                //width: auto;
                &:hover {
                  background-color: #00a8b9;
                }
                &[active="true"] {
                  background-color: #00a8b9;
                }
              }
              // .menu-children-item.menu-children-item-active{
              //     background-color: #00a8b9;

              // }
              &[type="show"] {
                display: block;
              }
            }
            .menu-children-list-son {
              position: relative;
              left: 100%;
              top: -40px;
              padding-left: 5px;
            }
          }
          &[type="show"] {
            .menu-children-list {
              display: block;
            }
          }
        }
        .menu-item.menu-item-active {
          &:after {
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 2px;
            background-color: #34e8fa;
            z-index: 999;
            left: 15px;
            bottom: 0;
          }
        }
      }
      .menu-search {
        display: flex;
        width: 290px;
        height: 36px;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border-radius: 5px;
        margin-left: 40px;
        .search-center {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 160px;
            height: 2px;
            background-color: transparent;
            z-index: 999;
            left: 15px;
            bottom: 3px;
          }
          .search-input {
            position: relative;
            height: 2.25rem;
            width: 12.1875rem;
            border: none;
            color: #ffffff;
            font-size: 0.875rem;
            letter-spacing: 0.0625rem;
            text-indent: 0.9375rem;
            background-color: transparent;
            caret-color: #ffffff;
          }
        }
        .search-go {
          width: 36px;
          height: 36px;
          background-color: transparent;
          border: none;
          border-radius: 0 1px 1px 0;
          cursor: pointer;
          background-image: url("../../assets/newImg/search.png");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 26px 26px;
        }
      }
    }
  }
  .recruit-content {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    // background-position: 30px 50%;
    // background-repeat: no-repeat;

    .recruit-hot-list{
      position: absolute;
      left: 160px;
      top: 250px;
      color: #FFFFFF;
      width: 300px;
      .hot-title{
        font-family: 'biaoti';
        font-size: 48px;
        letter-spacing: 5px;
        text-align: center;
      }
      .hot-list{
        width: 100%;
        height: 200px;
        .recruit-none-data{
          text-align: center;
          color: #87888d;
          .el-icon-postcard{
            font-size: 32px;
            margin-top: 50px;
          }
        }
      }
      .hot-text{
          >p{
            letter-spacing: 2px;
            line-height: 24px;
          }
          .text-flag{
            background-color: #1A4CA2;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 9px;
            display: inline-block;
            margin-top: 20px;
          }
      }
    }

    .recruit-list {
      position: relative;
      .recruit-item {
        position: absolute;
        &:nth-child(1) {
          left: 550px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 650px;
          top: 180px;
        }
        &:nth-child(3) {
          left: 650px;
          top: 360px;
        }
        &:nth-child(4) {
          left: 550px;
          top: 540px;
        }
      }
    }

    .recruit-item-line {
      position: relative;
      .line-item {
        position: absolute;
        &:nth-child(1) {
          left: 370px;
          top: 70px;
          width: 180px;
          height: 80px;
        }
        &:nth-child(2) {
          left: 525px;
          top: 230px;
          width: 125px;
          height: 80px;
        }
        &:nth-child(3) {
          left: 520px;
          top: 410px;
          width: 130px;
          height: 80px;
        }
        &:nth-child(4) {
          left: 370px;
          top: 550px;
          width: 180px;
          height: 80px;
        }
      }
      .line-item svg {
        width: 100%;
        height: 100%;
        color: #ffffff;
        stroke: currentColor;
        stroke-width: 1;
        overflow: visible;
        transform: scaleY(-1);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .paginations {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<style lang="scss">
.search-left {
  .el-popper {
    position: absolute !important;
    left: 0;
  }
  .el-input__inner {
    width: 60px !important;
    color: #ffffff;
    background-color: transparent;
    padding-right: 0.875rem !important;
    padding-left: 0.375rem !important;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 1px 0 0 1px;
    letter-spacing: 3px;
    line-height: 2rem;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    // &:hover{
    // 	color: #00ceff !important;
    // }
  }

  .el-input__icon {
    width: 12px;
    margin-right: -3px;
    color: #ffffff;
    font-weight: bold;
  }

  .el-select__caret {
    color: #ffffff !important;
  }

  .el-select-dropdown {
    position: absolute;
    background-color: #232538;
    border: none;

    .popper__arrow {
      display: none;
    }

    .el-select-dropdown__item {
      color: #fff !important;
      height: 38px;
      &:hover {
        background-color: #3e4ea6;
      }
    }

    .selected {
      color: #ffffff;
      font-weight: 0;
    }

    .hover {
      font-weight: 600 !important;
      background-color: #3e4ea6;
    }
  }

  .el-input__icon {
    line-height: 2rem;
    color: #3e4ea6;
  }

  .el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
  .Trainingadvertisements{

    border: 2px solid rgb(52, 71, 141);
    width: 195px;
    height: 239px;
    margin-left: -66px;

  }

}
</style>