<template>
  <div class="reset-password-container">
    <div class="reset-password-box">
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="初始化密码"
          name="initPassword"
          v-if="activeName === 'initPassword'"
        >
          <div class="reset-content-box">
            <el-form
              ref="initDataForm"
              :rules="initRules"
              :model="initDataForm"
              label-width="100px"
            >
              <el-form-item label="账号" prop="account" required>
                <glsx-input
                  :value="initDataForm.account"
                  placeholderText="请输入账号"
                  :disabled="true"
                  @getValue="
                    changeInputValue('initDataForm', $event, 'account')
                  "
                ></glsx-input>
              </el-form-item>
              <el-form-item label="验证码" prop="verifyCode" required>
                <div class="form-left">
                  <glsx-input
                    :value="dataForm.verifyCode"
                    placeholderText="请输入验证码"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    :maxlength="6"
                    @getValue="
                      changeInputValue('initDataForm', $event, 'verifyCode')
                    "
                  ></glsx-input>
                  <el-button
                    @click="eventGetCodes"
                    :disabled="!downBool"
                    :loading="!downBool"
                    >{{ codeBtnText || "获取验证码" }}</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item label="密码" prop="password" required>
                <glsx-input
                  type="password"
                  :showPassword="true"
                  :value="initDataForm.password"
                  placeholderText="请输入新密码"
                  @getValue="
                    changeInputValue('initDataForm', $event, 'password')
                  "
                ></glsx-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="footer-btn">
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              round
              @click="eventInitSubmit"
              >提交</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="updatePassword"  v-if="activeName === 'updatePassword'">
          <div class="reset-content-box">
            <el-form
              ref="dataForm"
              :rules="rules"
              :model="dataForm"
              label-width="100px"
            >
              <el-form-item label="旧密码" prop="olderPassword" required>
                <glsx-input
                  type="password"
                  :showPassword="true"
                  :maxlength="25"
                  :value="dataForm.olderPassword"
                  placeholderText="请输入旧密码"
                  @getValue="
                    changeInputValue('dataForm', $event, 'olderPassword')
                  "
                ></glsx-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword" required>
                <glsx-input
                  type="password"
                  :showPassword="true"
                  :maxlength="25"
                  :value="dataForm.newPassword"
                  placeholderText="请输入新密码"
                  @getValue="
                    changeInputValue('dataForm', $event, 'newPassword')
                  "
                ></glsx-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="footer-btn">
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              round
              @click="eventSubmit"
              >提交</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { updatePwd, resettingPwd, getIsHasPwd } from "@/api/message.js";
import { getPhoneAuthCode } from "@/api/login.js";
import glsxInput from "@/component/form/glsxInput.vue";
export default {
  data() {
    return {
      downBool: true,
      codeBtnText: undefined,
      codeTime: 60,
      activeName: undefined,
      initDataForm: {
        account: JSON.parse(this.$cookie.get("userInfo")).account,
        password: undefined,
        verifyCode: undefined,
        type: 2,
      },
      dataForm: {
        olderPassword: undefined,
        newPassword: undefined,
      },
      initRules: {
        account: [
          {
            required: true,
            message: "请输入账户",
            trigger: ["blur", "change"],
          },
        ],
        verifyCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            max: 16,
            message: "密码应为8到16位字符",
            trigger: ["blur"],
          },
        ],
      },
      rules: {
        olderPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            max: 16,
            message: "密码应为8到16位字符",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  components: {
    glsxInput,
  },
  created() {
    this.getIsHasPwd();
  },
  methods: {
    // 查询是否修改了密码
    async getIsHasPwd() {
      let { code, data } = await getIsHasPwd();
      if (code === 200) {
        this.activeName = data ? 'updatePassword' : 'initPassword';
      }
    },
    //  修改密码提交
    async eventSubmit() {
      this.$refs.dataForm.validate(async (valid) => {
        if (valid) {
          let { code } = await updatePwd({
            ...this.dataForm,
            account: JSON.parse(this.$cookie.get("userInfo")).account,
          });
          if (code === 200) {
            this.$message({
              message: "修改成功，即将返回论坛界面",
              type: "success",
            });
            this.$router.push({ path: "/" });
          }
        }
      });
    },
    // 初始化密码提交
    async eventInitSubmit() {
      this.$refs.initDataForm.validate(async (valid) => {
        if (valid) {
          let { code } = await resettingPwd({
            ...this.initDataForm,
          });
          if (code === 200) {
            this.$message({
              message: "设置成功，即将返回论坛界面",
              type: "success",
            });
            this.$router.push({ path: "/" });
          }
        }
      });
    },
    // 获取验证码
    eventGetCodes() {
      this.downBool = false;
      try {
        this.$refs["initDataForm"].validateField("account", async (valid) => {
          if (!valid) {
            let { code, msg } = await getPhoneAuthCode({
              account: this.initDataForm.account,
              type: 2,
            }).catch((e) => {
              this.$message({
                type: "error",
                offset: 80,
                message: e,
              });
              this.downBool = true;
              return;
            });
            if (code === 200) {
              this.$message({
                type: "success",
                offset: 80,
                message: msg,
              });
              this.intervalDwon();
              //this.$refs.code.focus();
            } else {
              this.downBool = true;
            }
          } else {
            console.log("error submit!!");
            this.downBool = true;
          }
        });
      } catch (e) {
        console.log("error:" + e);
        this.downBool = true;
      }
    },
    // 验证码倒计时
    intervalDwon() {
      this.interval = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        this.codeBtnText = this.codeTime + "s重新发送";
        if (this.codeTime == 0) {
          clearInterval(this.interval);
          this.codeTime = 60;
          this.codeBtnText = "获取验证码";
          this.downBool = true;
        }
      }, 1000);
    },
    changeInputValue(objectName, event, attributeName) {
      this.$set(this[objectName], attributeName, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-container {
  background-color: #0f1128;
  color: #fff;
  padding: 100px 0;
  .reset-password-box {
    width: 800px;
    background-color: #16182e;
    padding: 50px;
    box-sizing: border-box;
    margin: auto;
    .reset-content-box {
      .form-left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        /deep/.input-style {
          width: 70% !important;
        }
        > .el-button {
          margin-left: 10px;
          background-color: #00c0ff;
          color: #ffffff;
          border-color: #00c0ff;
        }
      }
    }
    .footer-btn {
      padding: 20px 0;
      // border-top: 1px solid rgba($color: #e4e7ed, $alpha: 0.1);
      display: flex;
      justify-content: center;
      /deep/.el-button--primary {
        background-color: #35edff;
        width: 135px;
        height: 45px;
        font-size: 18px;
        letter-spacing: 2px;
      }
    }
  }
}

/deep/.el-tabs__nav {
  height: 45px;
}
/deep/.el-tabs__nav-wrap::after {
  opacity: 0.2;
}
/deep/.el-tabs__content {
  padding: 20px 20px 0 0;
}
/deep/.el-tabs__item {
  color: #ffffff;
  font-size: 24px;
  &.is-active {
    color: #35edff;
  }
}
/deep/.el-tabs__active-bar {
  height: 4px;
  background-color: #35edff;
}

/deep/.el-form-item__label {
  color: #fff;
  letter-spacing: 3px;
  font-size: 16px;
}

/deep/.el-form-item {
  margin-bottom: 30px;
}

/deep/.el-input {
  width: 100% !important;
}
</style>