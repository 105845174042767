
import axios from "axios";
// import {Toast} from "vant";
import router from "@/router/index.js"
import store from "@/store/index.js";
// import {sleep} from "@/util/pending.js";
import ElementUI from 'element-ui';
import { Loading } from 'element-ui'
import Cookie from 'js-cookie';
// import Router from "@/router/router.js";
let loadingEle; //定义loading变量
function startLoading() { //使用loading-start 方法
loadingEle= Loading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)',
    fullscreen:false,
        // lock: true,
        // text: '请稍候',
        // spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)',
        // fullscreen:false
})
}
function endLoading() { //使用loading-close 方法
    loadingEle.close();
}
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法needLoadingRequestCount+1。
let needLoadingRequestCount = 0
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
}

function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
        needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
         endLoading();
    }
}



// 根据开发环境获取对应接口
// var apiUrl = process.env.NODE_ENV == "development" ? "http://127.0.0.1:3000/api/" : 'http://api.w0824.com/api/';
const instance = axios.create({
    // baseURL: apiUrl,
    //baseURL: "https://0e41-219-137-65-179.jp.ngrok.io/",
	//baseURL:'http://192.168.10.78:8989/',
    //baseURL:'http://www.glsxvfx.com/prod-api',
    baseURL:process.env.VUE_APP_BASE_API,
    withCredent:false,
    // baseURL:process.env.VUE_APP_apiurl,
    // baseURL:"/",
});

// // 添加请求拦截器
instance.interceptors.request.use(async function (config) {
    // 在发送请求之前做些什么
    // var token = localStorage.getItem("token") || "";
    config.headers['Access-Control-Allow-Origin'] = "*"; //设置请求头，告诉服务端不要缓存，获取最新数据
    if(Cookie.get('accessToken')){
        config.headers['Authorization'] = 'Bearer '+ Cookie.get('accessToken') || '';
    }
    if(Cookie.get('userInfo') && Cookie.get('userInfo') != 'undefined'){
        store.commit('setUserInfo',JSON.parse(Cookie.get('userInfo')))
        store.commit('setisShowLogin',true);
    }
    // if(config.url.indexOf('/api/order/info/') == -1 && config.url.indexOf('/api/task/queryClassWorkSubList') == -1){ 
    //     showFullScreenLoading();
    // }
    // 设置请求头
    // token && ( config.headers.token = token );
    // 设置isPending为true 开始加载
    // store.commit("changeIsPending",true)
    // 延迟500豪秒
    // await sleep(500)
   
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
// let  router = Router
instance.interceptors.response.use(function (response) {
    tryHideFullScreenLoading();
    // 关闭加载
    // store.commit("changeIsPending",false)
    // 对响应数据做点什么
    // console.log('response',response);
    if(response.data.code && response.data.code == 401){
        ElementUI.Message.error('请登录你的账号');
        Cookie.remove('userInfo');
        Cookie.remove('accessToken');
        setTimeout(()=>{
            store.commit('setisShowLogin',false);
            router.push('/login')
        },1000);
        return  false;
    }else if(response.data.code && response.data.code == 500){
		ElementUI.Message.error(response.data.msg);
        // ElementUI.Message.error('系统繁忙，请稍后再访问');
        return  false;
    }else if(response.data.code && response.data.code == 256){
        store.commit('setShowLoginDialog', true);
        return false;
    } else if(response.data.code && response.data.code != 200){
        if(!store.state.loginModel){
            ElementUI.Message.error(response.data.msg);
        }
        // return  false;
    }
    return response.data;
    
}, function (error) {
    tryHideFullScreenLoading();
    return Promise.reject(error);
});


export default instance
