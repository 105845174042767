<template>
  <div class="information-container">
    <div class="information-box">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="个人资料" name="first">
          <div class="information-content-box">
            <el-row :gutter="20">
              <el-col :span="16">
                <div class="information-form">
                  <el-form ref="userForm" :model="userForm" label-width="120px">
                    <el-form-item label="账户">
                      <glsx-input
                        :maxlength="25"
                        :value="userForm.account"
                        :disabled="true"
                        placeholderText=""
                        @getValue="
                          changeInputValue('userForm', $event, 'account')
                        "
                      ></glsx-input>
                    </el-form-item>
                    <el-form-item label="昵称">
                      <glsx-input
                        :maxlength="10"
                        :value="userForm.nickName"
                        placeholderText="请输入昵称，最多10个字"
                        @getValue="
                          changeInputValue('userForm', $event, 'nickName')
                        "
                      ></glsx-input>
                    </el-form-item>
                    <el-form-item label="性别">
                      <el-radio-group v-model="userForm.gender">
                        <el-radio
                          v-for="dict in genderOptions"
                          :key="dict.dictValue"
                          :label="parseInt(dict.dictValue)"
                          >{{ dict.dictLabel }}</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="简介">
                      <glsx-input
                        :maxlength="50"
                        type="textarea"
                        :rows="5"
                        show-word-limit
                        :value="userForm.individualSign"
                        placeholderText="请输入简介，最多50个字"
                        @getValue="
                          changeInputValue('userForm', $event, 'individualSign')
                        "
                      ></glsx-input>
                    </el-form-item>
                    <el-form-item label="城市">
                      <v-distpicker
                        class="dist-picker"
                        @selected="onSelected"
                        :province="select.province"
                        :city="select.city"
                        :area="select.area"
                      ></v-distpicker>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="information-header">
                  <img :src="userForm.headImg" />
                  <p @click="dialogVisible = true">点击修改头像</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="公司资料"
          name="second"
          v-if="Number(authStatus) === 1"
        >
          <div class="information-content-box">
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form
                  label-width="120px"
                  ref="companyForm"
                  :model="companyForm"
                >
                  <el-form-item label="公司名称">
                    <glsx-input
                      :maxlength="25"
                      :value="companyForm.company"
                      placeholderText="请输入公司名称,最多25个字"
                      @getValue="changeInputValue($event, 'company')"
                    ></glsx-input>
                  </el-form-item>
                  <el-form-item label="公司地址">
                    <glsx-input
                      :value="companyForm.address"
                      type="text"
                      placeholderText="请输入公司地址"
                      @getValue="changeInputValue($event, 'address')"
                    ></glsx-input>
                  </el-form-item>
                  <el-form-item label="公司邮箱">
                    <glsx-input
                      :value="companyForm.email"
                      type="email"
                      placeholderText="请输入公司邮箱"
                      @getValue="changeInputValue($event, 'email')"
                    ></glsx-input>
                  </el-form-item>
                  <el-form-item label="HR姓名">
                    <glsx-input
                      :value="companyForm.hrName"
                      placeholderText="请输入HR姓名"
                      @getValue="changeInputValue($event, 'hrName')"
                    ></glsx-input>
                  </el-form-item>
                  <el-form-item label="联系电话">
                    <glsx-input
                      :value="companyForm.phone"
                      placeholderText="请输入联系电话，请一定要填正确的哟~"
                      @getValue="changeInputValue($event, 'phone')"
                    ></glsx-input>
                  </el-form-item>
                  <el-form-item label="营业执照">
                    <file-upload
                      :uploadUrl="uploadUrl"
                      :fileList="licenseFileList"
                      fileType="image"
                      accept=".jpg,.png,.gif"
                      :isRemove="false"
                      :maxSize="10"
                      @callbackFile="getLicenseFileList"
                      :tipsList="['支持jpg,gif,png格式', '图片大小不超过10M']"
                    >
                    </file-upload>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="8">
                <div class="information-company-header">
                  <p>公司Logo</p>
                  <file-upload
                    :uploadUrl="uploadUrl"
                    :fileList="logoFileList"
                    fileType="image"
                    accept=".jpg,.png,.gif"
                    :isRemove="false"
                    :maxSize="2"
                    :limit="1"
                    @callbackFile="getLogoFileList"
                    style="width: 244px; background-color: transparent"
                    :tipsList="[
                      '支持jpg,gif,png格式',
                      '图片大小不超过2M',
                      '只能上传一张图片',
                    ]"
                  >
                  </file-upload>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="footer-btn">
        <el-button
          type="primary"
          icon="el-icon-s-promotion"
          round
          @click="eventSubmit"
          >提交</el-button
        >
      </div>
    </div>
    <el-dialog title="修改头像" :visible.sync="dialogVisible" width="700px">
      <div class="img-cutter-box">
        <ImgCutter
          ref="imgCutterModal"
          fileType="jpeg"
          :crossOrigin="true"
          crossOriginHeader="*"
          rate=""
          :tool="true"
          toolBgc="none"
          :isModal="false"
          :showChooseBtn="true"
          :lockScroll="true"
          :boxWidth="306"
          :boxHeight="306"
          :cutWidth="200"
          :cutHeight="200"
          :sizeChange="false"
          :moveAble="true"
          :imgMove="true"
          :originalGraph="true"
          :smallToUpload="false"
          :saveCutPosition="false"
          :scaleAble="true"
          @onPrintImg="selectPoster"
          :previewMode="true"
        >
        </ImgCutter>
        <div class="img-preview">
          <div>头像预览</div>
          <div class="img-file-box">
            <img :src="preImg.dataURL" v-if="preImg" />
          </div>
          <div class="img-tips-text">
            <p>1.请上传JPG或者PNG格式图片</p>
            <p>2.滚轮可以放大缩小哦</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          style="height: 45px; font-size: 18px"
          >取 消</el-button
        >
        <el-button type="primary" @click="eventSubmitHeadImg">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import glsxInput from "@/component/form/glsxInput.vue";
import glsxSelect from "@/component/form/glsxSelect.vue";
import fileUploadVue from "@/component/form/fileUpload.vue";
import VDistpicker from "v-distpicker";
import ImgCutter from "vue-img-cutter";
import { getDicts } from "@/api/message.js";
import {
  getJobAuthInfo,
  updateJobAuthInfo,
  getJobAdopt,
} from "@/api/company.js";
import { getuserInfos, updateInfo } from "@/api/message.js";
import { fileUpload } from "@/api/work.js";
export default {
  data() {
    return {
      userForm: {},
      companyForm: {},
      activeName: "first",
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      genderOptions: [],
      select: {
        province: "",
        city: "",
        area: "",
      },
      licenseFileList: [],
      logoFileList: [],
      dialogVisible: false,
      preImg: undefined,
      authStatus: 0,
    };
  },
  components: {
    fileUpload: fileUploadVue,
    glsxInput,
    glsxSelect,
    VDistpicker,
    ImgCutter,
  },
  created() {
    this.getGenderDicts("sys_user_sex");
    let userId = JSON.parse(this.$cookie.get("userInfo")).id;
    this.getUserInfo(userId);
    this.getJobAdopt();
  },
  methods: {
    // 查询公司认证状态
    async getJobAdopt() {
      let { code, data } = await getJobAdopt();
      if (code === 200) {
        this.authStatus = data;
      }
    },
    handleTabClick() {
      if (this.activeName === "first") {
        let userId = JSON.parse(this.$cookie.get("userInfo")).id;
        this.getUserInfo(userId);
      } else if (this.activeName === "second") {
        this.getCompanyInfo();
      }
    },
    // 获取个人信息
    async getUserInfo(id) {
      let { code, data } = await getuserInfos(id);
      if (code === 200) {
        this.userForm = data;
        let cityArr = data.city ? data.city.split("-") : [];
        this.select.province = cityArr[0] || "";
        this.select.city = cityArr[1] || "";
        this.select.area = cityArr[2] || "";
      }
    },
    // 获取公司信息
    async getCompanyInfo() {
      let { code, data } = await getJobAuthInfo();
      if (code === 200) {
        this.companyForm = data;
        this.licenseFileList = [];
        if (this.companyForm.registration) {
          this.companyForm.registration.split(",").forEach((v) => {
            this.licenseFileList.push({ url: v });
          });
        }
        this.logoFileList = [];
        if (this.companyForm.companyLogo) {
          this.companyForm.companyLogo.split(",").forEach((v) => {
            this.logoFileList.push({ url: v });
          });
        }
      }
    },
    changeInputValue(objectName, event, attributeName) {
      this.$set(this[objectName], attributeName, event);
    },
    selectPoster(res) {
      this.preImg = res;
    },
    // 提交修改头像
    eventSubmitHeadImg() {
      if (!this.preImg) {
        this.$message.error("头像不可上传为空");
        return;
      }
      let formdata = new FormData();
      const _that = this;
      if (this.preImg.file) {
        const isJPG = this.preImg.file.type === "image/jpeg";
        const isPng = this.preImg.file.type === "image/png";
        const isLt10M = this.preImg.file.size / 1024 / 1024 < 10;
        if (!isJPG && !isPng) {
          _that.$message.error("只支持jpg/png格式");
          return;
        }
        if (!isLt10M) {
          _that.$message.error("图片不能超过10M！");
          return;
        }
        formdata.append("file", this.preImg.file);
        fileUpload(formdata).then((res) => {
          _that.userForm.headImg = res.data.url;
        });
      }
      this.dialogVisible = false;
    },
    // 地址选择
    onSelected(data) {
      this.$set(
        this.userForm,
        "city",
        data.province.value + "-" + data.city.value + "-" + data.area.value
      );
    },
    async getGenderDicts(type) {
      let { code, data } = await getDicts(type);
      if (code == 200) {
        this.genderOptions = data;
      }
    },
    getLicenseFileList(fileList) {
      this.licenseFileList = fileList;
    },
    getLogoFileList(fileList) {
      this.logoFleList = fileList;
    },
    async eventSubmit() {
      if (this.activeName === "first") {
        if (!this.userForm.headImg) {
          this.messageWarn("请上传你的头像");
          return;
        }
        if (!this.userForm.nickName) {
          this.messageWarn("请输入昵称");
          return;
        }
        if (typeof this.userForm.gender === "undefined") {
          this.messageWarn("请选择性别");
          return;
        }
        if (!this.userForm.individualSign) {
          this.messageWarn("请输入简介");
          return;
        }
        if (!this.userForm.city) {
          this.messageWarn("请选择城市");
          return;
        }
        let { code, data } = await updateInfo({ ...this.userForm });
        if (code === 200) {
          this.$store.state.userInfo = data;
          this.$store.commit("setworkUserId", data);
          this.$cookie.set("userInfo", data);
          this.$store.commit(
            "setUserInfo",
            JSON.parse(this.$cookie.get("userInfo"))
          );
          this.$message({
            type: "success",
            offset: 80,
            message: "修改成功",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        }
      } else if (this.activeName === "second") {
        if (!this.companyForm.company) {
          this.messageWarn("请输入公司名称");
          return;
        }
        if (!this.companyForm.address) {
          this.messageWarn("请输入公司地址");
          return;
        }
        if (!this.companyForm.email) {
          this.messageWarn("请输入公司邮箱");
          return;
        }
        if (
          !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(
            this.companyForm.email
          )
        ) {
          this.messageWarn("公司邮箱格式错误");
          return;
        }
        if (!this.logoFileList || !this.logoFileList.length) {
          this.messageWarn("请上传公司Logo");
          return;
        }
        if (!this.licenseFileList || !this.licenseFileList.length) {
          this.messageWarn("请上传营业执照");
          return;
        }
        if (!this.companyForm.hrName) {
          this.messageWarn("请输入HR姓名");
          return;
        }
        if (!this.companyForm.phone) {
          this.messageWarn("请输入联系电话");
          return;
        }
        let { company, address, email, hrName, phone } = this.companyForm;
        let queryData = {
          company,
          address,
          email,
          hrName,
          phone,
          companyLogo: this.logoFileList.map((v) => v.url).join(","),
          registration: this.licenseFileList.map((v) => v.url).join(","),
          id: this.companyForm.id,
        };
        let { code } = await updateJobAuthInfo(queryData);
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "修改成功",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        }
      }
    },
    // 警告信息
    messageWarn(text) {
      this.$message({
        message: text,
        offset: 80,
        type: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.information-container {
  background-color: #0f1128;
  color: #fff;
  padding: 100px 10%;
  .information-box {
    background-color: #16182e;
    padding: 50px;
    box-sizing: border-box;
    .information-content-box {
      //display: flex;
      width: 100%;
      padding: 50px 0;
      box-sizing: border-box;
      .information-form {
      }
      .information-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 40px 0;
        img {
          width: 100%;
          max-width: 200px;
          border-radius: 50%;
        }
        p {
          margin-top: 20px;
          cursor: pointer;
          color: #35edff;
        }
      }
      .information-company-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        > p {
          text-align: center;
          font-size: 16px;
          letter-spacing: 3px;
        }
      }
    }
  }

  .img-cutter-box {
    display: flex;
    .img-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      width: calc(100% - 326px);
      color: #ffffff;
      .img-file-box {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        background: #707070;
        margin-top: 20px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .img-tips-text {
      margin-top: 20px;
      line-height: 30px;
      font-size: 12px;
    }
  }

  /deep/.el-tabs__nav {
    height: 45px;
  }
  /deep/.el-tabs__nav-wrap::after {
    opacity: 0.2;
  }
  /deep/.el-tabs__item {
    color: #ffffff;
    font-size: 24px;
    &.is-active {
      color: #35edff;
    }
  }
  /deep/.el-tabs__active-bar {
    height: 4px;
    background-color: #35edff;
  }

  /deep/.el-form-item__label {
    color: #fff;
    letter-spacing: 3px;
    font-size: 16px;
  }

  /deep/.el-input {
    width: 100%;
  }

  /deep/.el-radio__label {
    color: #ffffff;
    font-size: 16px;
  }

  /deep/.el-form-item {
    margin-bottom: 30px;
  }

  /deep/ .distpicker-address-wrapper {
    select {
      background-color: #393d47 !important;
      color: #ffffff;
      margin-left: 12px;
      &:first-child {
        margin-left: 0px;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
      }
    }
  }

  /deep/.el-button--primary {
    background-color: #35edff;
    width: 135px;
    height: 45px;
    font-size: 18px;
    letter-spacing: 2px;
  }

  .upload-tips-text {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
  }

  .footer-btn {
    padding: 20px 0;
    border-top: 1px solid rgba($color: #e4e7ed, $alpha: 0.1);
    display: flex;
    justify-content: center;
  }

  /deep/.el-dialog {
    background: #262736 !important;
    color: #ffffff !important;
    .el-dialog__header {
      color: #ffffff;
      .el-dialog__title {
        color: #ffffff;
      }
    }
  }

  /deep/.i-dialog-footer {
    display: none !important;
  }

  /deep/.copyright {
    display: none !important;
  }
}
</style>