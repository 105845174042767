<template>
    <div class="question-container">
        <div class="question-title">
            {{questionObj.question}}
        </div>
        <div class="question-answer">
            {{questionObj.answer}}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        questionObj: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.question-container{
    font-size: 14px;
    margin-bottom: 20px;
    .question-title{
        color: #ffffff;
        margin-bottom: 5px;
        line-height: 25px;
    }
    .question-answer{
        color: #939395;
        line-height: 25px;
    }
}
</style>