<template>
    <div class="task-container">
        <div class="task-line">
            <p class="task-type task-color">{{taskContent.title}}</p>
            <p class="task-explain">{{taskContent.content}}</p>
            <p class="task-exp"><span class="task-color">获得</span>{{taskContent.exp}}经验值</p>
        </div>
		<div :class="['task-done', !tasktag[taskContent.key] && 'task-color']" @click="handleTask(taskContent.key)">{{tasktag[taskContent.key] ? '已完成' : '未完成'}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        taskContent: {
            type: Object,
            default: () => {}
        },
        tasktag: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        // 点击
        handleTask(key) {
            if (this.tasktag[key]) return;
            this.$emit('handleTask', key);
        }
    }
}
</script>

<style lang="scss" scoped>
.task-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: #313346;
    line-height: 50px;
    margin-bottom: 15px;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 2px;
    padding: 0 30px;
    box-sizing: border-box;
    .task-line{
        display: flex;
        align-items: center;
        .task-type{
            font-size: 16px;
        }
        .task-explain{
            margin-left: 20px;
        }
        .task-exp{
            margin-left: 20px;
            color: #c37a24;
            span{
                margin-right: 10px;
            }
        }
    }
    .task-done{
        font-size: 16px;
        cursor: pointer;
        color: #89898b;
        &:hover{
            transform: scale(1);
        }
    }

    .task-color{
        color: #13d5e1;
    }
}

</style>