<template>
  <div class="auth-container" v-if="!isShowDetail">
    <div class="auth-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="手机号码" prop="phone">
              <el-input
                v-model="queryForm.phone"
                type="text"
                placeholder="根据手机号码搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户姓名" prop="name">
              <el-input
                v-model="queryForm.name"
                type="text"
                placeholder="根据用户姓名搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idCard">
              <el-input
                v-model="queryForm.idCard"
                type="text"
                placeholder="根据身份证号搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="认证时间">
              <el-date-picker
                v-model="authTimeRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
              <!-- <el-date-picker
                v-model="queryForm.time"
                type="date"
                placeholder="选择日期"
                size="small"
              >
              </el-date-picker> -->
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="auth-right-list">
      <el-table :data="tableData" :loading="loading" border style="width: 100%">
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="idCard" label="身份证号"></el-table-column>
        <el-table-column prop="qqNumber" label="QQ"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="bankCard" label="银行卡账号"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-All'"
              @click="eventToDetail(scope.row.aid)"
              >查看详情</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              v-else
              @click="eventToDetail(scope.row.aid)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>
  </div>
  <detail @back="eventBack" type="person" :id="detailId" v-else></detail>
</template>

<script>
import { parseTime } from "@/tools/timeCycle.js";
import { getEpibolyBusinessList } from "@/api/ectocyst.js";
import detail from "./detail.vue";
export default {
  data() {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        phone: undefined,
        name: undefined,
        idCard: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      authTimeRange: undefined,
      tableData: [],
      // 是否显示详情
      isShowDetail: false,
      // 总数
      total: 0,
      loading: false,
      detailId: undefined,
    };
  },
  watch: {
    authTimeRange: {
      handler(v) {
        this.queryForm.startTime = v
          ? parseTime(v[0], "{y}-{m}-{d}")
          : undefined;
        this.queryForm.endTime = v ? parseTime(v[1], "{y}-{m}-{d}") : undefined;
      },
    },
  },
  components: {
    detail,
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let { code, rows, total } = await getEpibolyBusinessList({
          userType: 1,
          ...this.queryForm,
        });
        if (code === 200) {
          this.tableData = rows;
          this.total = total;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    resetQuery() {
      this.authTimeRange = undefined;
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      this.$refs["queryForm"].resetFields();
      this.getList();
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.getList();
    },
    // 修改页码
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    // 审核
    eventToDetail(id) {
      if (!id) return;
      this.detailId = id;
      this.isShowDetail = true;
    },
    // 详情页点击返回上一页
    eventBack() {
      this.isShowDetail = false;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  .auth-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      //margin-top: 10px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .auth-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}
</style>