<template>

  <div class="item-container">
    
    <div class="item-left-image">
      <img :src="recruitInfo.cover" />
    </div>
    <div class="item-right-content">
      <div class="content-header">
        <div class="content-title">
          {{ recruitInfo.seriesName }}
          <span>{{ recruitInfo.emolUment }}</span>
        </div>
        <div class="content-refresh" v-if="recruitInfo.renew">更 新</div>
      </div>
      <div class="content-duty">{{ recruitInfo.introduction }}</div>
      <div class="content-user">
        <div class="content-user-left">
          <img src="@/assets/img/favicon.png" />{{ recruitInfo.teacher }}
        </div>
        <div class="content-user-right">
          <div class="content-time">发布时间：{{ recruitInfo.createTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    recruitInfo: {
      type: Object,
      require: true,
    },
  },
  created() {},
  methods: {
    // 提取标签中的文本，用于岗位描述
    getTagText(tag) {
      let oDiv = document.createElement("div");
      oDiv.innerHTML = this.escapeStringHTML(JSON.parse(tag));
      let text = oDiv.innerText;
      return text;
    },
    // 福利拆分
    getPostWelfareList(text) {
      return text.split(",").filter(Boolean);
    },
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 1000px;
  display: flex;
  border: 2px solid #34478D;
  border-radius: 10px;
  .item-left-image {
    width: 200px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px 0 0 10px;
    }
  }
  .item-right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 220px);
    color: #ffffff;
    margin-left: 20px;
    padding: 10px 5px 10px 0;
    .content-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .content-title {
        font-size: 24px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 300px);
        font-weight: bold;
        span {
          color: #ff5916;
          margin-left: 20px;
        }
      }
      .content-refresh {
        width: 80px;
        line-height: 31px;
        border: none;
        text-align: center;
        border-radius: 5px;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        box-sizing: border-box;
      }
      .content-time {
        width: 250px;
        font-size: 14px;
        //color: #016985;
        text-align: right;
      }
    }
    .content-duty {
      font-size: 14px;
      color: #ffffff;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .content-label {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      span {
        padding: 0 10px;
        line-height: 20px;
        letter-spacing: 2px;
        border: 1px solid #35edff;
        border-radius: 10px;
        display: inline-block;
        margin-right: 15px;
      }
    }
    .content-user {
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      .content-user-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .content-user-auth {
          display: inline-block;
          padding: 2px 5px;
          border: 1px solid #35edff;
          font-size: 12px;
          background-color: #424a61;
          margin-left: 20px;
        }
      }
      .content-user-right {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          margin-right: 10px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>