<template>
  <div class="list-container">
    <div class="list-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="任务名称" prop="title">
              <el-input
                v-model="queryForm.title"
                type="text"
                placeholder="根据任务名称搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="接包方" prop="name">
              <el-input
                v-model="queryForm.name"
                type="text"
                placeholder="根据接包方搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="queryForm.phone"
                type="text"
                placeholder="根据联系电话搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="提交时间">
              <el-date-picker
                v-model="createTimeRange"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="resetQuery">重置</el-button>
            </el-form-item>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button size="small" icon="el-icon-download" type="warning"
                    >导出</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="header-bottom">
        <el-tabs v-model="tabsObject.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in tabsObject.list"
            :key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="list-right-list">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="基本信息" align="center">
          <el-table-column prop="projectName" label="资源名称" width="150px">
          </el-table-column>
          <el-table-column prop="title" label="任务名称" width="150px">
          </el-table-column>
          <el-table-column
            prop="classifyName"
            label="任务类型(环节)"
            width="150px"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="接包方"
            width="200px"
          ></el-table-column>
          <el-table-column
            prop="bankCard"
            label="银行卡账号"
            width="200px"
          ></el-table-column>
          <el-table-column
            prop="openingBank"
            label="开户行"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="bankHolders"
            label="开户人"
            width="120px"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="计划排期" align="center">
          <el-table-column
            prop="expectedStartTime"
            label="计划开始时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.expectedStartTime)"
          ></el-table-column>
          <el-table-column
            prop="firstTime"
            label="首次提交时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.firstTime)"
          ></el-table-column>
          <el-table-column
            prop="expectedEndTime"
            label="计划结束时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.expectedEndTime)"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="结算" align="center">
          <el-table-column
            prop="flnalDay"
            label="制作总人天"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="finalOffer"
            label="人天单价"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="money"
            label="制作总价"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="proportion"
            label="结算比例（%）"
            width="120px"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          prop="remar"
          label="备注"
          align="center"
          width="200px"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="150px"
          v-if="tabsObject.activeName === 'unsettled' "
        >
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="eventToDetail"
              >确认打款</el-button
            > -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="eventToUpload"
              >上传发票并确认打款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>

    <!-- <el-dialog
      title="上传测试录屏"
      :visible.sync="uploadDialog"
      width="400px"
      :before-close="handleClose"
    >
      <el-upload
        class="upload-demo"
        drag
        :show-file-list="false"
        :action="uploadUrl"
        :on-success="uploadVideoSuccess"
        :before-upload="beforeUpload"
        accept=".mp4,.avi,.wmv"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>，最大100M
        </div>
      </el-upload>
    </el-dialog> -->
  </div>
</template>

<script>
import { parseTime } from "@/tools/timeCycle.js";
import { getEpibolyFinanceList } from "@/api/ectocyst.js";
export default {
  data() {
    return {
      createTimeRange: undefined,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        userType: 2,
        status: 3,
        title: undefined,
        phone: undefined,
        startTime: undefined,
        endTime: undefined,
      },
      total: 0,
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      uploadDialog: false,
      tabsObject: {
        activeName: "unsettled",
        list: [
          {
            label: "未结算",
            name: "unsettled",
          },
          {
            label: "已结算",
            name: "settled",
          },
        ],
      },
      tableData: [],
    };
  },
  watch: {
    createTimeRange: {
      handler(v) {
        this.queryForm.startTime = v ? v[0] : undefined;
        this.queryForm.endTime = v ? v[1] : undefined;
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let { code, rows, total } = await getEpibolyFinanceList(this.queryForm);
      if (code === 200) {
        this.tableData = rows;
        this.total = total;
      }
    },
    // 重置
    resetQuery() {
      this.createTimeRange = undefined;
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      this.$refs["queryForm"].resetFields();
      this.getList();
    },
    timeFormatterFun(time) {
      return parseTime(time, "{y}-{m}-{d}");
    },
    // 切换tab
    handleClick() {
      if (this.tabsObject.activeName === "unsettled") {
        this.queryForm.status = 3;
      } else if (this.tabsObject.activeName === "settled") {
        this.queryForm.status = 4;
      }
      this.getList();
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.queryForm.pageNum = 1;
      this.getList();
    },
    // 修改页码
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    // 上传
    eventToUpload() {
      this.uploadDialog = true;
    },
    // 关闭
    handleClose() {

    }
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  .list-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      margin-top: 20px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .list-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  /deep/.el-pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>