import instance from "./config.js";

// 任务列表
export function getEpibolyList(data) {
    return instance.get("/api/epiboly/list", { params: data });
}

// 新用户列表
export function getNewUser(data) {
    return instance.get("/api/epiboly/newPeople", { params: data });
}

// 申请认证-个人
export function epibolyApplyPeople(data) {
    return instance.post("/api/epiboly/applyPeople", data);
}

// 申请认证-公司
export function epibolyApplyCorporation(data) {
    return instance.post("/api/epiboly/applyCorporation", data);
}

// 提交发包
export function epibolyBusinessAdd(data) {
    return instance.post("/api/epiboly/business/add", data);
}

// 查看我通过测试的任务
export function getMyEpibolyList(data) {
    return instance.get("/api/epiboly/myList", { params: data });
}

// 查看我未通过测试的任务
export function getMyEpibolyTestList(data) {
    return instance.get("/api/epiboly/testList", { params: data });
}

// 查看我的发包信息
export function getEpibolyDetail(data) {
    return instance.get("/api/epiboly/query", { params: data });
}

// 技术人员查看待评价列表
export function getTechnologyList(data) {
    return instance.get("/api/epiboly/technology/list", { params: data });
}

// 商务审核测试信息(提交报价和审核是否通过)
export function epibolyTestVerify(data) {
    return instance.post("/api/epiboly/business/testVerify", data);
}

// 认证列表
export function getEpibolyBusinessList(data) {
    return instance.get("/api/epiboly/business/list", { params: data });
}

// 审核-测试中列表
export function getEpibolyBusinessTestList(data) {
    return instance.get("/api/epiboly/business/testList", { params: data });
}

// 审核-制作中列表
export function getEpibolyBusinessCheckList(data) {
    return instance.get("/api/epiboly/business/checkList", { params: data });
}

// 认证详情
export function getEpibolyBusinessDetailById(data) {
    return instance.get("/api/epiboly/business/query", { params: data });
}

// 认证审核
export function getEpibolyBusinessVerify(data) {
    return instance.post("/api/epiboly/business/verify", data);
}

// 我的发包
export function getEpibolyBusinessElist(data) {
    return instance.get("/api/epiboly/business/elist", { params: data });
}

// 上传测试录屏
export function getEpibolyAddTestVideo(data) {
    return instance.post("/api/epiboly/addTest", data);
}

// 评价详情查询
export function getEpibolyAddTechnologyById(data) {
    return instance.get("/api/epiboly/technology/query", { params: data });
}

// 技术人员评价
export function getEpibolyAddTechnologyEvaluate(data) {
    return instance.post("/api/epiboly/technology/evaluate", data);
}

// 商务人员审核查看详情
export function getEpibolyAddBusinessDetailById(data) {
    return instance.get("/api/epiboly/business/queryTest", { params: data });
}

// 填写排期
export function getEpibolyMaketime(data) {
    return instance.get("/api/epiboly/maketime", { params: data });
}

// 上传正式录屏
export function getEpibolyAddCompletedVideo(data) {
    return instance.post("/api/epiboly/addCompleted", data);
}

// 待验收详情
export function getEpibolyAcceptDetailById(data) {
    return instance.get("/api/epiboly/business/details", { params: data });
}

// 商务验收
export function epibolyBusinessCheck(data) {
    return instance.get("/api/epiboly/business/check", { params: data });
}

// 财务列表
export function getEpibolyFinanceList(data) {
    return instance.get("/api/epiboly/finance/verify", { params: data });
}

// 财务确认打款界面
export function getEpibolyFinanceVerify(list, data) {
    return instance.get("/api/epiboly/finance/verifyEpiboly?"+list, { params: data });
}

// 导出
export function getEpibolyFinanceExport(data) {
    return instance.get("/api/epiboly/finance/export", { params: data });
}

// 商务删除
export function deleteEpiboly(data) {
    return instance.get("/api/epiboly/business/del", { params: data });
}

// 商务查看详情
export function getEpibolySpecificsDetail(data) {
    return instance.get("/api/epiboly/business/specifics", { params: data });
}

// 修改
export function updateEpiboly(data) {
    return instance.post("/api/epiboly/business/update", data);
}

// 查询项目列表
export function getProjectNameList(data) {
    return instance.get("/api/epiboly/finance/nameList", { params: data });
}