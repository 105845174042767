<template>
  <div class="rich-text-style">
    <el-upload
      class="avatar-uploader"
      :action="uploadUrl"
      accept=".jpg,.png,.gif"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
    >
    </el-upload>
    <quill-editor
      v-model="richContent"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    >
    </quill-editor>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      richContent: {
        html: ''
      },
      editorOption: {
        placeholder: '',
        theme: 'snow', // 主题 snow/bubble
        modules: {
          toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
                ["blockquote"], //引用，代码块'code-block'
                //[{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                [{ list: "ordered" }, { list: "bullet" }], //列表
                [{ script: "sub" }, { script: "super" }], // 上下标
                [{ indent: "-1" }, { indent: "+1" }], // 缩进
                [{ direction: "rtl" }], // 文本方向
                [{ size: ["small", false, "large", "huge"] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
                [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                [{ font: [] }], //字体
                [{ align: [] }], //对齐方式
                ["clean"], //清除字体样式
                ["image"], //上传图片、上传视频
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用element的图片上传组件
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },  
        }
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload"
    };
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "是时候展示真正的技术了"
    }
  },
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  watch: {
    content(v) {
      this.richContent = v && this.escapeStringHTML(v);
    }
  },
  created() {
    this.$set(this.editorOption, 'placeholder', this.placeholder)
    this.richContent = this.content && this.escapeStringHTML(this.content);
  },
  methods: {
    // 准备编辑器
    onEditorReady(editor) {},
    // 失去焦点事件
    onEditorBlur(event) {},
    // 获得焦点事件
    onEditorFocus() {},
    // 内容改变事件
    onEditorChange(event) {
        this.$emit('getContent', event.html);
    },
    //把实体格式字符串转义成HTML格式的字符串
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    beforeUpload (file) { },
    uploadSuccess (res) {
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code === 200) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败！');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.rich-text-style {
  width: 100%;
  height: 100%;
  .quill-editor {
    width: 100%;
    height: 100%;
  }
  /deep/ .ql-toolbar.ql-snow {
    width: 100%;
    height: 66px;
  }
  /deep/ .ql-container {
    width: 100%;
    height: calc(100% - 66px);
    padding-bottom: 8px;
  }
  /deep/ .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "请输入链接地址:";
  }
  /deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "保存";
    padding-right: 0px;
  }
  /deep/ .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "请输入视频地址:";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-label[data-value="small"]::before {
    content: "10像素";
    color: #fff;
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="small"]::before {
    content: "10像素";
  }
  /deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    content: "14像素";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "14像素";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-label[data-value="large"]::before {
    content: "18像素";
    color: #fff;
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="large"]::before {
    content: "18像素";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-label[data-value="huge"]::before {
    content: "32像素";
    color: #fff;
  }
  /deep/
    .ql-snow
    .ql-picker.ql-size
    .ql-picker-item[data-value="huge"]::before {
    content: "32像素";
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before {
    content: "文本";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "文本";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="1"]::before {
    content: "标题1";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "标题1";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="2"]::before {
    content: "标题2";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "标题2";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="3"]::before {
    content: "标题3";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "标题3";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="4"]::before {
    content: "标题4";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "标题4";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="5"]::before {
    content: "标题5";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "标题5";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-header
    .ql-picker-label[data-value="6"]::before {
    content: "标题6";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "标题6";
  }
  /deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before {
    content: "标准字体";
    color: #fff;
  }
  /deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: "标准字体";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="serif"]::before {
    content: "衬线字体";
    color: #fff;
  }
  /deep/
    .ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="serif"]::before {
    content: "衬线字体";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-font
    .ql-picker-label[data-value="monospace"]::before{
    content: "等宽字体";
  }
  /deep/
    .ql-snow
    .ql-picker.ql-font
    .ql-picker-item[data-value="monospace"]::before {
    content: "等宽字体";
  }
  /deep/ .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: none;
  }

  /deep/.ql-snow .ql-stroke,.ql-snow .ql-stroke.ql-fill{
    stroke: #fff;
  }

  /deep/.ql-snow .ql-fill{
    fill: #fff;
  }

  /deep/.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke:hover{
    stroke: #fff;
  }

  /deep/.ql-editor.ql-blank::before{
    color: #fff;
  }
}
</style>