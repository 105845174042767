<template>
	<div class="task-contains">
		<div class="task-box">
			<div class="task-title">
				<p class="header-title">新手任务</p>
				<p class="sub-title">完成新手任务快速了解怪礼屋规则，获得大量经验值，畅游怪礼屋</p>
			</div>
			<div class="task-content">
				<task v-for="item in taskList" :key="item.id" :taskContent="item" :tasktag="tasktag" @handleTask="handleTask"></task>
			</div>
		</div>
		<div class="task-box" style="margin-top: 50px">
			<div class="task-title">
				<p class="header-title">常见问题说明：</p>
			</div>
			<div class="task-content">
				<question v-for="item in questionList" :key="item.id" :questionObj="item"></question>
			</div>
		</div>
	</div>
</template>

<script>
	import { gettask } from '@/api/message.js';
	import task from './components/task.vue'
	import question from './components/qustion.vue'
	export default {
		data() {
			return {
				activeName: 'first',
				tasktag:{},
				taskList:[{
					id: 1,
					title: '完善个人资料',
					content: '首次完成个人资料修改与填写',
					exp: 100,
					key: 'one_task'
				},{
					id: 2,
					title: '发布一个作品',
					content: '首次完成1个作品的上传',
					exp: 100,
					key: 'three_task'
				},{
					id: 3,
					title: '收藏一个作品',
					content: '首次收藏1个作品',
					exp: 100,
					key: 'two_task'
				},{
					id: 4,
					title: '点赞一个作品',
					content: '首次点赞1个作品',
					exp: 50,
					key: 'five_task'
				},{
					id: 5,
					title: '评论一个作品',
					content: '首次评论1个作品',
					exp: 30,
					key: 'four_task'
				}],
				questionList: [{
					id: 1,
					question: '1.如何获得获得经验值？',
					answer: '答：用户可以通过上传原创作品、灵感、技巧等帖子的方式获得大量经验值；另外点赞、收藏、关注、评论也可以获得对应经验值。'
				},{
					id: 2,
					question: '2.为什么我第一次上传作品和第二次上传作品经验不一样？',
					answer: '答：用户首次上传、首次分享、首次评论等各种操作都会获得一定的额外奖励，具体奖励经验值会有差异。'
				},{
					id: 3,
					question: '3.如何获得星标推荐？',
					answer: '答：星标根据怪力屋美术委员会评定结果，由怪力屋管理员后台发放并自动显示，无须用户操作。'
				},{
					id: 4,
					question: '4.为什么我的经验值变少了？',
					answer: '答：删除已上传成功的帖子都会扣除对应经验值；如果你的帖子下已经有人评论、点赞、收藏等，删除帖子后，对应的评论点赞等经验值也会扣。'
				}]
			};
		},
		components: {
			task,
			question
		},
		created() {
			this.getUserInfo(this.$store.state.userInfo.id)
		},
		methods: {
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// 点击未完成按钮触发
			handleTask(key) {
				// 完善资料
				switch(key) {
					case 'one_task':
						this.$router.push('/personalhome?isShowMsg=2&open=1');
						break;
					case 'two_task':
					case 'four_task':
					case 'five_task':
						this.$router.push('/home/newest?activeIndex=0&latest=1&classify=40');
						break;
					case 'three_task':
						this.$router.push('/release?isShowMsg=0');
						break;
				}
			},
			async getUserInfo(id) {
				let queryData={
					userId : id
				}
				let {
					code,
					data
				} = await gettask(queryData);
				if (code == 200) {
					this.tasktag = data
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	.tabbar {
		/deep/ .el-tabs__content {
			overflow: visible;
		}

		/deep/ .el-tabs__item {
			color: white;
		}

		/deep/ .el-tabs__item.is-active {
			color: #15cbf3;
		}
		/deep/ .el-tabs__nav-scroll{
			display: flex;
			justify-content: center;
		}
		/deep/ .el-icon-arrow-left {
			color: white;
		}

		/deep/ .el-icon-arrow-right {
			color: white;
		}

		/deep/ .el-tabs__nav-wrap::after {
			height: 0;
		}

		/deep/ .el-tabs__active-bar {
			background-color: #15cbf3;
		}
	}

	.task-contains {
		//width: 80%;
		//margin: 20px auto;
		//min-height: 800px;
		background-color: #0f1128;
		color: #fff;
		padding: 100px 10%;

		.task-box{
			background-color: #16182e;
			padding: 50px;
			box-sizing: border-box;
		}

		.task-title {
			width: 100%;
			text-align: left;
			display: flex;
			.header-title{
				font-size: 24px;
			}
			.sub-title{
				font-size: 14px;
				color: #aeaeae;
				line-height: 44px;
				margin-left: 40px;
			}
			
		}

		.task-content {
			padding: 20px 25px 0;
			box-sizing: border-box;
		}

	}
</style>
