<template>
  <div class="register-container">
    <Transition name="fade" mode="out-in">
      <component :is="activeComponent"></component>
    </Transition>
  </div>
</template>

<script>
// 认证类型
import attestationType from "./components/attestation_type.vue";
// 个人认证表单
import attestationPerson from "./components/attestation_person.vue";
export default {
  data() {
    return {
      activeComponent: "attestationType",
    };
  },
  watch: {
    '$route.query.type': {
      handler() {
        this.showComponent();
      }
    }
  },
  components: {
    attestationType,
    attestationPerson
  },
  created() {
    this.showComponent();
  },
  methods: {
    showComponent() {
      if (!this.$route.query.type) {
        this.activeComponent = 'attestationType';
        return;
      }
      this.activeComponent = 'attestationPerson'
    }
  }
};
</script>

<style lang="scss" scoped>
.register-container {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ebf5ff;
  background-image: url(https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/content_bg.png);
  background-repeat: no-repeat;
  background-size: 1585px 1658px;
  background-position: bottom right;
  .register-content {
    width: 1260px;
  }
}
</style>