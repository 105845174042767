<template>
  <div class="index-page-container index-question-container">
    <div class="index-page-content index-question-content">
      <div class="question-title">
        <div class="question-title-left">常见问题</div>
        <el-button class="question-title-right" @click="eventToPage(12)"
          >留言咨询</el-button
        >
      </div>
      <div class="question-box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- <el-tab-pane label="学多久？学费多少？" name="first">
            <div class="question-item">
              <ul>
                <li><span class="item-disc"></span>学期 6 个月</li>
                <li>
                  <span class="item-disc"></span>原价：<span
                    style="text-decoration: line-through; margin-top: 3px"
                    >24800</span
                  >元 / 人 / 学期
                </li>
                <li>
                  <span class="item-disc"></span>早鸟价：<span
                    class="item-price"
                    >23800</span
                  >
                  元 / 人 / 学期
                </li>
              </ul>
            </div>
          </el-tab-pane> -->
          <el-tab-pane label="我想学，但怕学不会怎么办？" name="second">
            <div class="question-item">
              <ul>
                <li>
                  <span class="item-disc"></span
                  >入学面试：专业面试测试评定是否达到入学标准
                </li>
                <li>
                  <span class="item-disc"></span
                  >免费试学体验课：行不行试了就知道
                </li>
                <li>
                  <span class="item-disc"></span
                  >专业团队：专业讲师团队，十年特效从业经验
                </li>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane label="学成后，好找工作吗？" name="third">
            <div class="question-item">
              <ul>
                <li>
                  <span class="item-disc"></span>行业缺口大，项目多，急需人才
                </li>
                <li>
                  <span class="item-disc"></span
                  >拒绝华丽胡哨，项目实用技巧训练无缝对接
                </li>
                <li>
                  <span class="item-disc"></span
                  >总部、分部+多家合作企业等广阔推荐平台
                </li>
              </ul>
            </div>
          </el-tab-pane>
          <el-tab-pane label="学成后，待遇如何？" name="fourth">
            <div class="question-item">
              <ul>
                <li>
                  <span class="item-disc"></span
                  >能者多得，根据个人实力谈待遇没有上限
                </li>
                <li>
                  <span class="item-disc"></span
                  >怪力初级4K-6K，怪力中级7K-9K，怪力高级10K-20K+
                </li>
                <li>
                  <span class="item-disc"></span>
                  <p>
                    怪力双休、绩效奖金、年终奖、团建、带薪年假、<br />
                    网易、腾讯、字节等大厂内推、节假日福利、旅游、<br />
                    下午茶、升职加薪等诸多福利
                  </p>
                </li>
              </ul>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "second",
    };
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    eventToPage(n) {
      this.$emit("eventToPage", n);
    },
  },
};
</script>

<style lang="scss" scoped>
.index-question-container {
  .index-question-content {
    .question-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 1158px;
      .question-title-left {
        font-size: 48px;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          width: 80px;
          height: 5px;
          background-image: linear-gradient(to right, #d73860, #4472e8);
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
      .question-title-right {
        height: 48px;
        border-radius: 10px;
        background-image: linear-gradient(to right, #d73860, #4472e8);
        color: #ffffff;
        border: none;
        font-size: 18px;
      }
    }
    .question-box {
      margin-top: 20px;
      min-height: 400px;
      /deep/ .el-tabs__item {
        color: #9abfcc;
        font-size: 18px;
      }
      /deep/ .el-tabs__active-bar {
        background-image: linear-gradient(to right, #d73860, #4472e8);
        height: 4px;
      }

      /deep/.el-tabs__item.is-active {
        color: #ffffff;
      }

      /deep/ .el-tabs__nav-wrap::after {
        background-color: #728e97;
        height: 4px;
      }

      .question-item {
        margin-top: 30px;
        min-width: 450px;
        border: 1px solid #647a84;
        background-color: rgba(40, 45, 60, 0.9);
        padding: 50px 20px;
        display: inline-block;
        > ul {
          > li {
            //line-height: 40px;
            font-size: 18px;
            color: #9abfcc;
            border-top: 1px solid rgba($color: #647a84, $alpha: 0.4);
            display: flex;
            padding: 10px 0;
            &:nth-child(1) {
              border-top: none;
            }
            > .item-disc {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #9abfcc;
              margin-right: 10px;
              margin-left: 5px;
              margin-top: 8px;
            }
            > .item-price {
              color: #c5000f;
              font-size: 30px;
              font-weight: bold;
              line-height: 20px;
              margin-right: 10px;
            }
            > p {
              display: inline;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>