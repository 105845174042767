<template>
  <div class="footer-container">
    <div class="footer-bottom">
        <p>Copyright©2021 广州怪力视效网络科技有限公司版权所有</p>
        <p><a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备2021083385号</a></p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer-container{
    width: 100%;
    background-color: #1f2229;
    color: #9aa3bd;
    position: relative;
    z-index: 1;
    .footer-bottom{
        //padding: 20px 0;
        line-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        >p{
            &:not(:last-child) {
                margin-right: 50px;
            }
            >a{
                color: #9aa3bd;
            }
        }
    }
}
</style>