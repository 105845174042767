<template>
  <div class="detail-container">
    <div class="bg"></div>
    <div class="detail-left-menu">
      <div style="font-size: 20px; line-height: 50px; margin-bottom: 10px">
        课程目录
      </div>
      <ul>
        <li
          v-for="(item, index) in cursorList"
          :key="index"
          :class="[
            cursorActiveIndex === index && 'active',
            !detailInfo.isBuy && detailInfo.charge
              ? item.isFree
                ? 'is-try'
                : 'is-lock'
              : '',
          ]"
          @click="changeIndex(index, item.isFree,item.id)"
        >
          <p class="content-text-index">
            {{ index + 1 >= 10 ? index + 1 : "0" + (index + 1) }}
          </p>
          <p class="content-text-title">{{ item.title }}</p>
        </li>
      </ul>
    </div>

 

    <!-- <div style="
            border: 4px solid rgb(52, 71, 141);
            width: 344px;
            height: 130px;
            position: absolute;
            bottom: 108px;
            left: 30px;
            color: white;
            border-radius: 15px;
          "> 
          <p class="ps">你要成为一位专业游戏特效师吗？</p>
          <p class="ps">为解决大家就业难题，现在提供免费系统学习三</p>
          <p class="ps">个月特效课程</p>
          <p class="ps">听说还推荐就业哦名额有限，先到先得！</p>
          <p class="ps">
          <router-link :to="{name:'guanggao'}" >
            <span style="color: aqua;">详情请点击</span>
          </router-link>
          </p>
    </div> -->

    <div class="detail-right-content">
      <div class="detail-right-header">
        <div class="header-title">{{ detailInfo.seriesName }}</div>
        <div class="header-info">
          <div class="header-company">
            发布用户：<img src="@/assets/img/favicon.png" />怪教授
          </div>
          <div class="header-setting">
            <div class="function-like" @click="eventLike">
              <img
                src="@/assets/newImg/like1.png"
                alt=""
                title="点赞"
                v-show="!detailInfo.likeFlag"
              />
              <img
                src="@/assets/newImg/like2.png"
                alt=""
                v-show="detailInfo.likeFlag"
                title="取消点赞"
              />
              {{ detailInfo.likeFlag ? "取消点赞" : "点赞" }}
            </div>
            <div class="function-like" @click="eventCollection">
              <i class="el-icon-star-off" v-show="!detailInfo.collectFlag"></i>
              <i class="el-icon-star-on" v-show="detailInfo.collectFlag"></i>
              {{ detailInfo.collectFlag ? "取消收藏" : "收藏" }}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-right-video">
        <video
          id="player-container-id"
          preload="auto"
          playsinline
          webkit-playsinline
        ></video>
      </div>
      <div class="detail-right-introduction">
        <p class="introduction-title">课程简介：</p>
        <p class="introduction-content">
          {{ detailInfo.introduction || "暂无简介" }}
        </p>
      </div>
      <div class="detail-right-introduction">
        <p class="introduction-title">课程笔记:</p>
      </div>
     <div style="margin-top: 5px;">
      <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="textarea"
          @input="changeVlaue()"
          >
        </el-input>
        <div v-if="showFlag">
          <el-button @click="addOrUpdateNote()" style="background-color:#21a4fa; color: white;">提交</el-button>
        </div>
        
     </div>


      <div class="detail-right-work">
        <p class="work-title">
          课程作业：
          <span style="float: right; margin-top: 10px"
            ><el-button
              type="text"
              icon="el-icon-upload"
              @click="eventUploadWork"
              >上传作业</el-button
            ></span
          >
        </p>
        <div class="work-content">
          <ul class="work-type">
            <li
              :class="workType === 1 && 'active'"
              @click="eventChangeWorkType(1)"
            >
              全部作业
            </li>
            <li
              :class="workType === 2 && 'active'"
              @click="eventChangeWorkType(2)"
            >
              我的作业
            </li>
          </ul>
          <div class="work-list">
            <template v-if="workList.length">
              <item
                v-for="item in workList"
                :key="item.id"
                :info="item"
                :isOperate="workType === 2"
                @edit="eventEditWork"
                @clickTo="eventSetDetailWorkInfo"
                @list="getWorkList"
              ></item>
            </template>
            <div class="work-no-data" v-else>
              <div class="work-upload">
                <i class="el-icon-document-remove"></i>
                <p>暂无作业</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="showToast"
      width="300px"
      top="20vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="eventCancel"
      center
    >
      <p>你还未购买该课程！</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="eventCancel">再想想</el-button>
        <el-button type="primary" @click="eventToBuy">去购买</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="购买课程"
      :visible.sync="showCursorToast"
      :close-on-click-modal="false"
    >
      <toast
        :info="detailInfo"
        @refreshPage="refreshPage"
        v-if="showCursorToast"
      ></toast>
    </el-dialog>

    <el-dialog
      :title="editWorkId ? '修改课程作业' : '上传课程作业'"
      :visible.sync="showWorkUpload"
      :close-on-click-modal="false"
    >
      <upload-work
        :vid="detailInfo.pid"
        @close="eventCloseUploadWork"
        :workId="editWorkId"
        ref="uploadWork"
        v-if="showWorkUpload"
      ></upload-work>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog
      title="作业详情"
      :visible.sync="showWorkDetail"
      :close-on-click-modal="false"
    >
      <work-detail
        :detailInfo="detailWorkInfo"
        v-if="showWorkDetail"
      ></work-detail>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from "@/tools/timeCycle.js";
import item from "./components/studentWork/item.vue";
import {
  getSeriesDetailById,
  setIntegralByTime,
  getCourseWorkList,
  getCourseWorkDetailById,
  addNote,
  updateNote,
  queryNote
} from "@/api/series.js";
import {
  insertCollection,
  deleteCollection,
  getLiveLike,
  calcelLiveLike,
} from "@/api/work.js";
import toast from "./components/toast.vue";
import uploadWork from "./components/studentWork/uploadWork.vue";
import workDetail from "./components/studentWork/detail.vue";
export default {
  data() {
    return {
      player: undefined,
      detailInfo: {},
      cursorList: [],
      cursorActiveIndex: 0,
      detailInfo: {},
      showToast: false,
      isBuy: false,
      // 课程作业列表
      workList: [],
      workType: 1,
      // 是否首次观看
      isFirstLook: true,
      // 是否看过了1/3
      isOneInThree: false,
      // 是否看过了2/3
      isTwoInThree: false,
      // 是否看完过
      isFinsh: false,
      playCountTime: 0,
      showCursorToast: false,
      editWorkId: undefined,
      showWorkUpload: false,
      showWorkDetail: false,
      textarea:'',
      showFlag:false,
      note:{
        id:null,
        vid:null,
        content:null
      }
        

      
    };
  },
  computed: {
    fileId() {
      return (
        this.cursorList[this.cursorActiveIndex] &&
        this.cursorList[this.cursorActiveIndex].videoSourceId
      );
    },
  },
  watch: {
    fileId: {
      handler(v) {
        // console.log(v);
        //this.init()
        this.changeVideoFile();
      },
    },
  },
  components: {
    item,
    toast,
    uploadWork,
    workDetail,
  },
  mounted() {
    this.init();
    this.refreshPage();
  },
  methods: {
    // 回调刷新界面
    refreshPage() {
      let cid = this.$route.query.id;
      cid && this.getDetail(cid, this.cursorActiveIndex);
    },
    // 上传作品
    eventUploadWork() {
      this.editWorkId = undefined;
      this.showWorkUpload = true;
    },
    // 关闭上传作品弹窗
    eventCloseUploadWork() {
      this.showWorkUpload = false;
      this.editWorkId = undefined;
      this.getWorkList();
    },
    // 获取详情信息
    async getDetail(id, cursorActiveIndex) {

      let { code, data } = await getSeriesDetailById({ parentId: id });
      if (code === 200) {
        this.$set(this.detailInfo, "seriesName", data.seriesName);
        this.$set(this.detailInfo, "price", data.price / 100);
        this.$set(this.detailInfo, "pid", id);
        this.$set(this.detailInfo, "introduction", data.introduction);
        this.$set(this.detailInfo, "charge", data.charge);
        this.$set(this.detailInfo, "isBuy", data.isBuy);
        this.$set(this.detailInfo, "time", parseTime(new Date()));
        this.$set(this.detailInfo, "likeFlag", data.likeFlag);
        this.$set(this.detailInfo, "collectFlag", data.collectFlag);
        this.cursorList = data.List;
        this.isBuy = data.isBuy;
        this.cursorActiveIndex = cursorActiveIndex || 0;
        this.changeIndex(
          this.cursorActiveIndex,
          this.cursorList[this.cursorActiveIndex].isFree
        );
        this.getWorkList();
    
         let one=data.List[0];
         console.log(one)
        
         this.note.vid=one.id
 

        if(data.note==null){
          this.note.id=null       
        }else{
          this.note.id=data.note.id
          let content=data.note.content
          this.note.content=content
          this.textarea=content
        }
        // console.log("biyangde ",this.note)
      }
    },
    // 初始化播放器
    init() {
      this.player = new this.TcPlayer("player-container-id", {
        fileID: this.fileId, // 请传入需要播放的视频fileID 必须
        appID: "1314030635", // 请传入点播账号的子应用appID 必须
        psign: "", // 请传入播放器签名psign 必须
        autoplay: true, // 是否自动播放
      });
      let timeTest = undefined;
      this.playCountTime = 0;
      this.player.on("play", (event) => {
        timeTest = setInterval(() => {
          this.playCountTime += 0.25;
          // console.log("this.playCountTime:", this.playCountTime);
          this.time(this.playCountTime);
        }, 250);
      });

      this.player.on("pause", (event) => {
        this.playCountTime += 0.25;
        clearInterval(timeTest);
        timeTest = undefined;
      });
    },
    time() {
      let countTime = this.player.duration();
      // console.log(countTime);
      let one = Math.floor(countTime / 3);
      // console.log(one);
      let lookprogress = document
        .getElementsByClassName("vjs-progress-holder")[0]
        .getAttribute("aria-valuenow");
      // console.log(lookprogress);
      let type = undefined;
      if (this.isFirstLook) {
        this.isFirstLook = false;
        type = 1;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
      }
      if (
        this.playCountTime >= one &&
        lookprogress > 33 &&
        !this.isOneInThree
      ) {
        console.log("看到1/3了");
        this.isOneInThree = true;
        type = 2;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
      }
      if (
        this.playCountTime >= one * 2 &&
        lookprogress > 66 &&
        !this.isTwoInThree
      ) {
        console.log("看到2/3了");
        this.isTwoInThree = true;
        type = 3;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
      }
      if (this.playCountTime >= one * 3 && lookprogress > 95 && !this.isFinsh) {
        console.log("看完了");
        this.isFinsh = true;
        type = 4;
        setIntegralByTime({
          vid: this.cursorList[this.cursorActiveIndex].id,
          type,
        }).then((res) => {
          if (res.code === 200 && res.msg) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
      }
    },
    // 切换视频
    changeVideoFile() {
      // console.log(this.player);
      this.playCountTime = 0;
      this.isFirstLook = true;
      this.isOneInThree = false;
      this.isTwoInThree = false;
      this.isFinsh = false;
      this.player.loadVideoByID({ fileID: this.fileId, appID: "1314030635" });
    },
    // 修改下标
    changeIndex(index, isFree,id) {

       
      if (!this.$cookie.get("userInfo") && !isFree) {
        this.$store.commit("setShowLoginDialog", true);
        return false;
      }
      if (!isFree && !this.isBuy) {
        this.showToast = true;
        return;
      }
      this.cursorActiveIndex = index;

       if(id!=undefined){
        // console.log("id 是 ",id)
           this.queryNote(id);

       }

    },



    eventCancel() {
      this.showToast = false;
    },
    eventToBuy() {
      this.showToast = false;
      this.showCursorToast = true;
    },
    // 点赞
    async eventLike() {
      if (!this.detailInfo.likeFlag) {
        let { code, msg } = await getLiveLike({
          // userId: JSON.parse(this.$cookie.get('userInfo')).id,
          modelId: this.$route.query.id,
          modelType: 3,
        });
        if (code === 200) {
          this.$set(this.detailInfo, "likeFlag", true);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      } else {
        let { code, msg } = await calcelLiveLike({
          userId: JSON.parse(this.$cookie.get("userInfo")).id,
          modelId: this.$route.query.id,
          modelType: 3,
        });
        if (code === 200) {
          this.$set(this.detailInfo, "likeFlag", false);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      }
    },
    // 收藏
    async eventCollection() {
      if (!this.detailInfo.collectFlag) {
        let data = {
          workId: Number(this.$route.query.id),
          userId: this.$store.state.userInfo.id,
          headImg: this.$store.state.userInfo.headImg,
          flag: 3,
        };
        let { code, msg } = await insertCollection(data);
        if (code === 200) {
          this.$set(this.detailInfo, "collectFlag", true);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      } else {
        let data = { id: Number(this.$route.query.id), flag: 3 };
        let { code, msg } = await deleteCollection(data);
        if (code === 200) {
          this.$set(this.detailInfo, "collectFlag", false);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      }
    },
    // 修改作品弹窗
    eventEditWork(id) {
      this.editWorkId = id;
      this.showWorkUpload = true;
    },
    eventSetDetailWorkInfo(id) {
      this.getCourseWorkDetailById(id);
    },
    // 切换课程作业栏
    eventChangeWorkType(t) {
      console.log(t);
      this.workType = t;
      this.getWorkList();
    },
    // 获取作业列表
    async getWorkList() {
      let { code, rows } = await getCourseWorkList({
        type: Number(this.workType),
        vid: this.detailInfo.pid,
      });
      if (code === 200) {
        this.workList = rows;
      }
    },
    // 查看详情
    async getCourseWorkDetailById(id) {
      let { code, data } = await getCourseWorkDetailById({ id });
      if (code === 200) {
        this.detailWorkInfo = data;
        this.showWorkDetail = true;
      }
    },


    async addNote(vid,content){
      let {code,data}= await addNote({vid:vid,content:content});
       if(code===200){
        this.$message({
            type: "success",
            offset: 80,
            message: "提交成功",
          });
       }
       
    },
    async updateNote(vid,content){
      let {code,data}= await updateNote({vid:vid,content:content});
       
       if(code===200){
        this.$message({
            type: "success",
            offset: 80,
            message: "修改成功",
          });
       }
       
    },

    async queryNote(vid){
      let {code,data}= await queryNote({vid});
       if(code===200){
        
         if(data===undefined){
          this.note.content=null
          this.note.vid=vid
           this.textarea=null
           return;
           
         }

         this.note.content=data.content 
         this.note.id=data.id
         this.note.vid=vid
         this.textarea=data.content 
       console.log(this.note)
       }      
    },
    changeVlaue(){
      if(this.note.content===this.textarea){
         this.showFlag=false
         return;
      }
      if(this.textarea!=this.note.content){
        this.showFlag=true;
      }
      
    },
    addOrUpdateNote(){
         if(this.note.content==null ){
          // console.log("添加",this.note.vid)
         this.addNote(this.note.vid,this.textarea)

       
         }else{
          this.updateNote(this.note.id,this.textarea)
          
         }
         this.note.content=this.textarea
          this.showFlag=false
    }
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  width: 100vw;
  // min-width: 1920px;
  height: calc(100vh - 60px);
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  &::before {
    content: "";
    width: 861px;
    height: 1117px;
    background: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    position: absolute;
    left: -182px;
    top: 60%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .bg {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 60px);
    background-size: 100% 100%;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
    z-index: -2;
  }
  .detail-left-menu {
    position: absolute;
    left: 30px;
    top: 20px;
    padding: 50px 10px;
    box-sizing: border-box;
    min-height: 600px;
    max-height: 80vh;
    overflow-y: overlay;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    width: 350px;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/border.png");
    background-size: 100% 100%;
    color: #ffffff;
    > ul {
      > li {
        font-size: 22px;
        line-height: 50px;
        //border: 1px solid #0f1128;
        display: flex;
        cursor: pointer;
        .content-text-title {
          width: calc(100% - 50px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &.active {
          background-color: #0f1128;
        }
        &.is-try {
          position: relative;
          padding-left: 45px;
          &::before {
            content: "试学";
            position: absolute;
            left: 5px;
            top: 12px;
            //width: 42px;
            height: 24px;
            line-height: 24px;
            font-size: 14px;
            padding: 0 6px;
            border: 1px solid rgb(175, 26, 26);
            border-radius: 3px;
            color: #ff3271;
            .content-text-title {
              width: calc(100% - 100px);
            }
          }
        }
        &.is-lock {
          position: relative;
          padding-left: 45px;
          &::before {
            content: "";
            position: absolute;
            left: 5px;
            top: 13px;
            width: 42px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50%;
            background-image: url("../../assets/img/cursor/lock.svg");
          }
        }
        .content-text-index {
          width: 50px;
          height: 50px;
          text-align: center;
          //background-color: #0f1128;
          margin-right: 10px;
          //border-right: 4px solid #0f1128;
          position: relative;
          &::after {
            content: "";
            width: 4px;
            height: 22px;
            background-color: #35edff;
            position: absolute;
            right: 0;
            top: 14px;
          }
        }
      }
    }
  }
  .detail-right-content {
    color: #ffffff;
    width: calc(100vw - 450px);
    height: calc(100vh - 80px);
    margin-top: 20px;
    float: right;
    padding: 0 10% 20px;
    box-sizing: border-box;
    overflow-y: overlay;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .detail-right-header {
      .header-title {
        font-size: 36px;
        letter-spacing: 2px;
      }
      .header-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .header-company {
          display: flex;
          align-items: flex-end;
          > img {
            width: 30px;
            height: auto;
            margin-right: 5px;
          }
        }
        .header-setting {
          letter-spacing: 2px;
          display: flex;
          .function-like {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 15px;
            background-color: #2b2b2d;
            font-size: 14px;
            cursor: pointer;
            > img {
              width: 30px;
              margin-bottom: 2px;
            }
            > i {
              font-size: 30px;
              margin-bottom: 2px;
              color: #35edff;
            }
            &:not(:last-child) {
              border-right: 1px solid #35edff;
            }
          }
        }
      }
    }
    .detail-right-video {
      margin-top: 10px;
      width: 100%;
      height: 600px;
      #player-container-id {
        width: 100%;
        height: 100%;
      }
    }
    .detail-right-introduction {
      margin-top: 10px;
      .introduction-title {
        font-size: 28px;
      }
      .introduction-content {
        margin-top: 20px;
        text-indent: 32px;
      }
    }
    .detail-right-work {
      margin-top: 30px;
      .work-title {
        font-size: 28px;
      }
      .work-content {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .work-type {
          > li {
            width: 120px;
            line-height: 30px;
            border-radius: 5px;
            background-color: #2b2b2d;
            text-align: center;
            letter-spacing: 2px;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &.active {
              background-color: #21a4fa;
            }
          }
        }
        .work-list {
          width: calc(100% - 150px);
          background-color: #282d35;
          min-height: 300px;

          .work-no-data {
            width: 100%;
            height: 100%;
            position: relative;
            .work-upload {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              > i {
                color: #21a4fa;
                font-size: 64px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/.el-dialog {
  background-color: #234767;
}
/deep/.el-dialog__title {
  font-family: "biaoti";
  font-size: 26px;
  color: #ffffff;
  letter-spacing: 5px;
}
/deep/.el-dialog__close {
  color: #ffffff;
}
/deep/.el-dialog--center .el-dialog__body {
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2px;
}
/deep/.el-button {
  width: 85px;
  letter-spacing: 2px;
}
/deep/.el-button--primary {
  background-color: rgb(0, 212, 255);
  margin-left: 20px;
}
.ps{
  line-height: 10px;
  margin-top: 12px;
}

/deep/ .el-textarea__inner{
    background: #393d47;
    border: 0.0625rem solid #6f7693;
    color: #fff;
    &:focus{
      outline: none;
      border-color: #6f7693;
    }
  }

</style>