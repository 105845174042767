<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="图层_1"
    x="0px"
    y="0px"
    viewBox="0 0 338 228"
    enable-background="new 0 0 612 792"
    xml:space="preserve"
>
    <path
    class="yy aa1"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M174,0c31.6,32.5-4.5,33-16,54c0.7,1.7,1.3,3.3,2,5c1,0.3,2,0.7,3,1  
                c2.3-1.7,4.7-3.3,7-5c-12.3,33.4-51.1,34.6-70,60c-4.4,5.8-13.5,28.2-8,36c1.4-9.3,6.2-20.6,12-26c0.3,0,0.7,0,1,0  c-4.1,25.5-3.8,50.9,8,70c4.3,6.9,20.5,18.9,20,23c0.3,0.7,0.7,1.3,1,2c-19.7,2.3-39.3,4.7-59,7c0-0.3,0-0.7,0-1  c3.1-4.1,8.7-6.2,14-8c0-0.3,0-0.7,0-1c-16.3-28.7-23.7-42.5-19-86c-0.3,0-0.7,0-1,0c-3.7,3.3-7.3,6.7-11,10c-2.7,6-5.3,12-8,18  c-22.4-13.4-36.3-30-50-52c1,0.3,2,0.7,3,1c-0.7-1-1.3-2-2-3c12,3.8,28,13.6,44,9c24.9-7.1,41.2-31.9,60-46  c17.2-12.8,51.9-22.6,63-41C170,18,172,9,174,0z"
    />
    <path
    class="yy aa2"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M124,30c1,0,2,0,3,0c-0.3,1-0.7,2-1,3c-12.7,11.7-25.3,23.3-38,35  c0-2.3,0-4.7,0-7c4.4-12.1,10-20.6,20-27c1.1,3.4-0.3,2.7,3,2C115.3,34,119.7,32,124,30z"
    />
    <path
    class="yy aa3"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M196,50c17.7,10.7,35.3,21.3,53,32c14,10.5,27.1,27.9,46,33  c15.2,4.1,31.5-6.8,42-10c-7.4,13.7-18.1,30.5-30,40c-6,4-12,8-18,12c-5.5,8.5-3.8,24-8,32c-0.7-0.3-1.3-0.7-2-1  C276.9,114,190.2,98.8,196,50z"
    />
    <path
    class="yy aa4"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M166,113c13.2-0.4,22.7,1.1,31,5c0.3-1.3,0.7-2.7,1-4  c12.1,3.8,19.1,19.7,23,32c-0.7,1.3-1.3,2.7-2,4c-1-0.7-2-1.3-3-2c-11.6-27.3-56.7-17-63,8c-8.8,35,41.4,60,52,29  c2.4-5.4,0.3-13.4-1-18c-2.3-2-4.7-4-7-6c-2.7,0.3-5.3,0.7-8,1c-7.1,3.3-5.5,9.8-6,17c-1.3-0.7-2.7-1.3-4-2c-6.1-2.7-8.3-6.9-8-16  c8.5-18.3,35.3-16.3,47-2c17.1,21-2.3,48.3-21,55c1.7,3.8-0.1,1.9,1,5c-15,0-30,0-45,0c1.2-6.8-17.9-19.4-23-28  C105.1,149.1,138.9,128.3,166,113z"
    />
    <path
    class="yy aa5"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M244,221c0.3-14.2-3.3-31.9-2-44c1.2-11.2,10-19.4,7-35  c-1.5-8-6.4-15-6-22c0.3,0,0.7,0,1,0c24.4,18.1,37.3,63.4,17,92c1,2,2,4,3,6c3,1,6,2,9,3c-2.2,2.2-1.9,0-1,3  C262.7,223,253.3,222,244,221z"
    />
    <path
    class="yy aa6"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="none"
    d="M236,195c4.4,5.8,3.3,19.5,6,24c-2.2,0.5-1-0.2-1,2  c-7-0.7-14-1.3-21-2c0.3-1,0.7-2,1-3C227.8,211.6,231.8,201.9,236,195z"
    />
</svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
svg{
    width: 100%;
}
.yy {
    stroke-width: 1;
    stroke: #b71444;
  }

  .aa1 {
    animation: lineMove 3s ease-out infinite;
  }

  .aa2 {
    animation: lineMove2 3s ease-out infinite;
  }

  .aa3 {
    animation: lineMove3 3s ease-out infinite;
    stroke: #8b2970;
  }

  .aa4 {
    animation: lineMove4 3s ease-out infinite;
  }

  .aa5 {
    animation: lineMove5 3s ease-out infinite;
    stroke: #8b2970;
  }

  .aa6{
    animation: lineMove6 3s ease-out infinite;
    stroke: #8b2970;
  }

  @keyframes lineMove {
    0% {
      stroke-dasharray: 0, 864;
    }
    50% {
      stroke-dasharray: 864, 864;
      fill: rgba(183, 20, 68, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 864, 864;
      fill: rgba(183, 20, 68, 1);
      opacity: 0;
    }
  }

  @keyframes lineMove2 {
    0% {
      stroke-dasharray: 0, 119;
    }
    50% {
      stroke-dasharray: 119, 119;
      fill: rgba(183, 20, 68, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 119, 119;
      fill: rgba(183, 20, 68, 1);
      opacity: 0;
    }
  }

  @keyframes lineMove3 {
    0% {
      stroke-dasharray: 0, 439;
    }
    50% {
      stroke-dasharray: 439, 439;
      fill: rgba(139, 41, 112, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 439, 439;
      fill: rgba(139, 41, 112, 1);
      opacity: 0;
    }
  }

  @keyframes lineMove4 {
    0% {
      stroke-dasharray: 0, 642;
    }
    50% {
      stroke-dasharray: 642, 642;
      fill: rgba(183, 20, 68, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 642, 642;
      fill: rgba(183, 20, 68, 1);
      opacity: 0;
    }
  }

  @keyframes lineMove5 {
    0% {
      stroke-dasharray: 0, 255;
    }
    50% {
      stroke-dasharray: 255, 255;
      fill: rgba(139, 41, 112, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 255, 255;
      fill: rgba(139, 41, 112, 1);
      opacity: 0;
    }
  }

  @keyframes lineMove6 {
    0% {
      stroke-dasharray: 0, 79;
    }
    50% {
      stroke-dasharray: 79, 79;
      fill: rgba(139, 41, 112, 0);
      opacity: 1;
    }
    100% {
      stroke-dasharray: 79, 79;
      fill: rgba(139, 41, 112, 1);
      opacity: 0;
    }
  }
</style>