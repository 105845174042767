<template>
  <div class="recruit-auth-left-container">
    <div class="auth-status-success" v-if="authStatus == 2">
      <i class="el-icon-document-checked"></i><br />
      认证资料提交成功，预计1-3个工作日审核完成，请耐心等待！
    </div>
    <div class="auth-status-error" v-if="authStatus == 3">
      <i class="el-icon-document-delete"></i>
      审核不通过，请重新提交!
    </div>
    <template v-if="authStatus == 0 || authStatus == 3">
      <div class="auth-header">
        <i class="el-icon-warning-outline"></i
        >请先完成一下公司信息认证，审核通过后方可发布招聘信息。
      </div>
      <el-form-item label="公司名称" label-width="100px">
        <glsx-input
          :maxlength="25"
          :value="dataForm.company"
          placeholderText="请输入公司名称,最多25个字"
          @getValue="changeInputValue($event, 'company')"
        ></glsx-input>
      </el-form-item>
      <el-form-item label="公司地址" label-width="100px">
        <glsx-input
          :value="dataForm.address"
          type="text"
          placeholderText="请输入公司地址"
          @getValue="changeInputValue($event, 'address')"
        ></glsx-input>
      </el-form-item>
      <el-form-item label="公司邮箱" label-width="100px">
        <glsx-input
          :value="dataForm.email"
          type="email"
          placeholderText="请输入公司邮箱"
          @getValue="changeInputValue($event, 'email')"
        ></glsx-input>
      </el-form-item>
      <el-form-item label="公司Logo" label-width="100px">
        <file-upload
          :uploadUrl="uploadUrl"
          :fileList="docImgFileList"
          fileType="image"
          accept=".jpg,.png,.gif"
          :maxSize="2"
          :limit="1"
          @callbackFile="getLogoFileList"
          :tipsList="[
            '支持jpg,gif,png格式',
            '图片大小不超过2M',
            '只能上传一张图片',
          ]"
        >
        </file-upload>
      </el-form-item>
    </template>
  </div>
</template>

<script>
import glsxInput from "@/component/form/glsxInput.vue";
import fileUpload from "@/component/form/fileUpload.vue";
export default {
  data() {
    return {
      dataForm: {},
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      docImgFileList: [],
    };
  },
  props: {
    authStatus: {
      require: true,
    },
  },
  components: {
    glsxInput,
    fileUpload,
  },
  methods: {
    getLogoFileList() {},
    changeInputValue(event, attributeName) {
      this.$set(this.dataForm, attributeName, event);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input {
  width: 100% !important;
}
/deep/ .el-form-item__label {
  color: #fff;
  letter-spacing: 3px;
  font-size: 16px;
}
.recruit-auth-left-container {
  .auth-status-success {
    padding: 50px 0;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    .el-icon-document-checked {
      color: #35edff;
      font-size: 64px;
    }
  }
  .auth-status-error {
    font-size: 24px;
    color: #c03737;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    .el-icon-document-delete {
      font-size: 32px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
}
</style>