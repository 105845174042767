import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '../store';
import ElementUI from 'element-ui';
import Cookie from "js-cookie";  //引入
// 登录
import login from '../views/login/index.vue'
import guide from '../component/public/guide.vue'
// 论坛
import Home from '../views/home/home.vue';
import newest from '../views/home/newest.vue';
import materialList from '../views/home/materialList.vue';
// 公司简介
import profile from '../views/companyProfile/aboutUs.vue';
// import profile from '../views/companyProfile/index.vue';
import business from '../views/companyProfile/business.vue';
import businessDetail from '../views/companyProfile/businessDetail.vue';
import partners from '../views/companyProfile/partners.vue';
import recruitment from '../views/companyProfile/recruitment.vue';
import contnctus from '../views/companyProfile/contactus.vue';
// 人才招聘详情
import recruitDetail from '@/views/companyProfile/recruitDetail';
import recruitDetail_ from '@/views/recruit/detail1';

// 新招聘界面
import recruit from '../views/recruit/index1.vue'

// 消息中心
import usermessage from '../views/message/index1.vue';

import task from '../views/task/index.vue'

// 个人主页
import personalhome from "../views/personalHome/index.vue";

// 发布中心
import release from "../views/releaseCenter/index1.vue";

// 作品详情
import workDetail from '@/views/home/workDetail';

// 培训界面
import train from '@/views/train/index';

// 校企联动
import linkage from '@/views/linkage/detail';

//修改个人资料
import information from '@/views/personalHome/information/index'

// 修改密码
import resetpassword from '@/views/resetpassword/index'

//在线课程
import courses from '@/views/courses/index1'

//在线课程详情界面
import coursesDetail from '@/views/courses/detail1'

//广告页面
import guanggao from '@/views/courses/guanggao'

// 外包平台
import ectocyst from '@/views/ectocyst/index'
// 外包平台注册界面
import ectocystRegister from '@/views/ectocyst/register/index'


// 以下是新界面UI
import login1 from '@/views/login/login'

// 首页
import guide1 from '@/views/guide/index'

// 论坛
import forum from '@/views/home/home'
// import forum from '@/views/forum/index'
import forumDetail from '@/views/forum/detail'


Vue.use(VueRouter)

const routes = [
  // {path: '/community',name: 'community',component: community, children:[
  //     {path: '/',name: 'Home', component: Home},
  //     {path: '/home',name: 'Home',component: Home}
  //   ]},
  // 首页
  // 只有/home的才有轮播图和中部文字

  { path: '/login', name: 'login', component: login1 },
  { path: '/', name: 'Guide', redirect: '/guide', component: guide1 },
  {
    path: '/home',
    name: 'Home',
    redirect: '/home/newest',
    component: forum,
    children: [
      { path: 'newest', name: 'newest', component: forum },
    ]
  },

  // 素材库
  { path: '/home/materialList', name: 'materialList', component: materialList },
  // 关于我们
  { path: '/home/profile', name: 'profile', component: profile },
  // 业务范围
  { path: '/home/business', name: 'business', component: business },
  { path: '/home/businessDetail', name: 'businessDetail', component: businessDetail },
  // 合作伙伴
  { path: '/home/partners', name: 'partners', component: partners },
  // 人才招聘
  { path: '/home/recruitment', name: 'recruitment', component: recruitment },
  // 新人才招聘
  { path: '/home/recruit', name: 'recruit', component: recruit },
  // 人才招聘详情页
  { path: '/home/recruitDetail', name: 'recruitDetail', component: recruitDetail },
  // 新人才招聘详情页
  { path: '/recruitDetail_', name: 'recruitDetail_', component: recruitDetail_ },
  // 联系我们
  { path: '/home/contnctus', name: 'contnctus', component: contnctus },

  // 消息中心
  { path: '/usermessage', name: 'usermessage', component: usermessage },
  // 个人主页
  { path: '/personalhome', name: 'personalhome', component: personalhome },
  // 发布中心
  { path: '/release', name: 'release', component: release },
  { path: '/workDetail', name: 'workDetail', component: workDetail },
  { path: '/task', name: 'task', component: task },

  // 培训train
  { path: '/train', name: 'train', component: train },

  // 校企联动
  { path: '/linkage', name: 'linkage', component: linkage },

  // 修改个人资料
  { path: '/information', name: 'information', component: information },

  // 修改密码
  { path: '/resetpassword', name: 'resetpassword', component: resetpassword },

  // 在线课程
  { path: '/courses', name: 'courses', component: courses },


  //培训广告

  { path: '/courses', name: 'guanggao', component: guanggao },

  // 在线课程详情
  { path: '/coursesDetail', name: 'coursesDetail', component: coursesDetail },

  // 外包平台
  { path: '/ectocyst', name: 'ectocyst', component: ectocyst },
  // 外包平台注册
  { path: '/ectocyst/register', name: 'ectocystRegister', component: ectocystRegister },

  // 以下是新UI界面router地址

  // 登录
  { path: '/login_1', name: 'login_1', component: login1 },
  // 首页
  { path: '/index', name: 'guide1', component: guide1 },
  // // 论坛
  { path: '/forum', name: 'forum', component: forum },
  { path: '/forumDetail', name: 'forumDetail', component: forumDetail }

    
    // { path: '/home', name: 'forum', component: home },
    // { path: '/forumDetail', name: 'forumDetail', component: forumDetail }
]


// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push

// 修改原型对象中的push方法，捕捉错误
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
let _this = this;
// to.path.indexOf('/login') == -1
// 如果要检索的字符串值没有出现，则该方法返回 -1。有出现不等于-1.
router.beforeEach(function (to, from, next) {
  if (to.path.indexOf('/guide') == -1) {
    store.commit("setShowGuide", true);
  } else {
    store.commit("setShowGuide", false);
  }

  // 如果是外包平台下的界面
  if (to.path.indexOf('/ectocyst') !== -1 || to.path.indexOf('/index') !== -1) {
    store.commit("setShowHeader", false);
  } else {
    store.commit("setShowHeader", true);
  }

  if (to.path.indexOf('/home') == -1) {
    store.commit("setShowNav", false);
  } else {
    store.commit("setShowNav", true);
  }
  if (to.path.indexOf('/courses') !== -1) {
    store.commit("setShowNav", true);
  }
  if (to.path.indexOf('/home/partners') !== -1 || to.path.indexOf('/home/recruitment') !== -1 || to.path.indexOf('/coursesDetail') !== -1 || to.path.indexOf('/home/newest') !== -1 ) {
    store.commit("setShowNav", false);
  }
  if (to.path.indexOf('/workDetail') !== -1 || to.path.indexOf('/release') !== -1 || to.path.indexOf('/train') !== -1 || to.path.indexOf('/linkage') !== -1 || to.path.indexOf('/ectocyst') !== -1 || to.path.indexOf('/index') !== -1 || to.path.indexOf('/forumDetail') !== -1) {
    store.commit("setShowFooter", false);
  } else {
    store.commit("setShowFooter", true);
  }


  if (to.path == '/login') {
    store.commit("setShowFooter", false);
    if (to.query.code && to.query.state) {
      store.dispatch('wechatLogin', { code: to.query.code, state: to.query.state }).then(res => {
        if (!res.data.hasOwnProperty('flag')) {
          ElementUI.Message.success("登录成功");
          if (res.data.userInfo) {
            Cookie.set('accessToken', res.data.token, {
              expires: 30
            });
            Cookie.set('userInfo', res.data.userInfo, {
              expires: 30
            });
            store.commit('setUserInfo', JSON.parse(Cookie.get('userInfo')))
            store.commit('setisShowLogin', true);
            setTimeout(() => {
              router.push({ path: '/home/newest', query: { activeIndex: 0, latest: 1 } });
            }, 2000)
          }
        } else {
          router.push({ path: '/login', query: { wxId: res.data.wxId } });
        }
      })
    } else {
      next();
    }
  }
  // console.log(to.path);
  if (to.path == '/usermessage' || to.path == '/release') {
    if (!Cookie.get('accessToken')) {
      ElementUI.Message({
        type: 'error',
        offset: 80,
        message: '请先登录再查看!'
      });
      // router.push('/login');
      next({ path: '/login' })
    }
  }
  if (to.path.search('/home/profile') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/home/business') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/home/partners') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/home/recruitment') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/home/contnctus') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/usermessage') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/personalhome') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/release') != -1) {
    document.documentElement.scrollTop = 0;
  }
  if (to.path.search('/workDetail') != -1) {
    document.documentElement.scrollTop = 0;
  }
  // 如果用户未能验证身份，则 `next` 会被调用两次
  if (to.path !== '/login') {
    next()
  }
})


export default router
