import Vue from 'vue'
import Vuex from 'vuex'
import router from 'vue-router';
import Cookie from 'js-cookie';
import { wechatLogin } from '@/api/login';
import { Message } from 'element-ui';
Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		showNav: false,
		showFooter: true,
		userInfo: {},
		isShowLogin: false,
		workUserInfo: '',
		unReadNum: 0,
		showGuide: false,
		// 提示登录弹窗
		showLoginDialog: false,
		// 是否显示头部
		showHeader: true,
	},
	getters: {
		unReadNum(state) {
			return state.unReadNum;
		},
		userInfo(state) {
			return state.userInfo;
		}
	},
	mutations: {
		setunReadNum: (state, int) => {
			state.unReadNum = int
		},
		setShowNav: (state, bool) => {
			state.showNav = bool;
		},
		setShowGuide: (state, bool) => {
			state.showGuide = bool;
		},
		setShowFooter: (state, bool) => {
			state.showFooter = bool;
		},
		setUserInfo: (state, data) => {
			state.userInfo = data;
		},
		setisShowLogin: (state, bool) => {
			state.isShowLogin = bool;
		},
		setworkUserId: (state, data) => {
			state.workUserInfo = data;
		},
		setShowLoginDialog: (state, bool) => {
			state.showLoginDialog = bool;
		},
		setShowHeader: (state, bool) => {
			state.showHeader = bool;
		}
	},
	actions: {
		wechatLogin({commit}, wxInfo) {
			return new Promise((resolve, reject) => {
				wechatLogin(wxInfo).then(res => {
					if (res.code === 200) {
						resolve(res);
					} else {
						reject();
					}
				})
			})
		}
	},
	modules: {}
})
export default store;
