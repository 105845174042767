<template>
  <div class="index-linkage-detail-container">
    <swiper class="swiper-container-par swiper-no-swiping" :options="swiperOption" id="swiper">
        <swiper-slide class="swiper-slide" pageIndex="0"> 
            <page-home></page-home>
        </swiper-slide>
        <swiper-slide class="swiper-slide" pageIndex="1"> 
            <page-about></page-about>
        </swiper-slide>
        <swiper-slide class="swiper-slide" pageIndex="2"> 
            <page-rule></page-rule>
        </swiper-slide>
        <swiper-slide class="swiper-slide" pageIndex="3"> 
            <page-prize></page-prize>
        </swiper-slide>
        <swiper-slide class="swiper-slide" pageIndex="4"> 
            <page-work></page-work>
        </swiper-slide>
        <swiper-slide class="swiper-slide" pageIndex="5"> 
            <page-periphery></page-periphery>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" id="custom"></div>
    </swiper>
  </div>
</template>

<script>
import pageHome from './components/home/index.vue'
import pageAbout from './components/about/index.vue'
import pageRule from './components/rule/index.vue'
import pagePrize from './components/prize/index.vue'
import pageWork from './components/works/index.vue'
import pagePeriphery from './components/periphery/index.vue'
export default {
    data() {
        const vm = this;
        return {
            swiperOption: {
                direction: 'vertical',
                mousewheel: true,
                speed: 800,
                touchStartPreventDefault:false,
                allowSlideNext: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    type : 'custom',
                    // 这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
                    renderCustom: function (swiper, current, total) {
                        let _html = '';
                        vm.swiper = swiper;
                        let swiperChildDom = document.getElementById('swiper').getElementsByClassName('swiper-wrapper')[0].childNodes;
                        let slideDom = []
                        for(var j in swiperChildDom) {
                            if (swiperChildDom[j].className && swiperChildDom[j].className.indexOf('swiper-slide') !== -1) {
                                slideDom.push(swiperChildDom[j]);
                            }
                        }
                        vm.activePageIndex = slideDom[current - 1].getAttribute('pageIndex');
                        // 已经渲染过分页器的pageInedx
                        let hadCurrentList = [];
                        for (let i = 1; i <= total; i++) {
                            let pageIndex = slideDom[i - 1].getAttribute('pageIndex');
                            if (hadCurrentList.includes(pageIndex)) {
                                continue;
                            }
                            if (parseInt(vm.activePageIndex) === parseInt(pageIndex)) {
                                _html += `<span class="swiper-pagination-custom-item swiper-pagination-custom-item-active" tabIndex="`+ i +`" pageIndex="`+ pageIndex +`">` + vm.titleList[pageIndex] + `</span>`
                            } else {
                                _html += `<span class="swiper-pagination-custom-item" tabIndex="` + i + `" pageIndex="`+ pageIndex +`">` + vm.titleList[pageIndex] + `</span>`
                            }
                            hadCurrentList.push(pageIndex);
                        }
                        return _html
                    }
                },
            },
            swiper: undefined,
            customBox: undefined,
            titleList: ['首页','简介','规则','奖项','作品','周边'],
        }
    },
    components: {
        pageHome,
        pageAbout,
        pageRule,
        pagePrize,
        pageWork,
        pagePeriphery
    },
    mounted() {
        this.customBox = document.getElementById('custom');
        this.customBox.addEventListener('click', this.handleClick, false);
    },
    methods: {
        handleClick(e) {
            // 获取目标元素，拿到目标元素上的 index 值
            const current = e.target;
            const toCount = current.attributes["tabIndex"].value || '';
            this.activePageIndex = current.attributes["pageIndex"].value || 0;
            // 跳转到指定的 swiper 页面
            if (toCount) {
                this.swiper.slideTo(toCount - 1);
            }
            //this.swiper.allowSlideNext = false;
        },
    },
    destroyed() {
        this.customBox.removeEventListener('click', this.handleClick, false);
    }
}
</script>

<style lang="scss" scoped>
.index-linkage-detail-container{
    min-width: 1000px;
    &::before{
        content: '';
        background-image: url('../../assets/img/kj.jpg');
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .swiper-container-par{
        width: 100%;
        height: calc(100vh - 60px);
        .swiper-slide{
            width: 100%;
            height: calc(100vh - 60px);
            color: #FFFFFF;
            min-width: 1000px;
            overflow: hidden;
        }
    }
    .swiper-pagination{
        position: fixed;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        width: auto;
        height: fit-content;
    }

    ::v-deep .swiper-pagination-custom-item{
        padding: 12px 0;
        color: #9ABFCC;
        font-weight: 500;
        width: auto;
        height: 20px;
        background-color: transparent;
        cursor: pointer;
        text-align: left;
        font-size: 18px;
    }
    ::v-deep .swiper-pagination-custom-item.swiper-pagination-custom-item-active{
        color: #FFFFFF;
        font-size: 24px;
    }

    .index-page-container{
        width: 100%;
        height: 100%;
        //min-height: 1080px;
        overflow: hidden;
    }

    /deep/ .index-page-container .index-page-content{
        width: 1180px;
        margin: 0 auto;
        height: 100%;
        padding-top: 50px;
        box-sizing: border-box;
        .page-content{
            float: left;
            .page-header-box {
                margin-bottom: 25px;
                .page-header-title {
                    font-size: 36px;
                    font-weight: 700;
                    color: #f6443f;
                    line-height: 60px;
                    letter-spacing: 2px;
                    >span {
                        margin-right: 10px;
                    }
                }
                .page-header-time {
                    color: #e2e2e2;
                    font-size: 20px;
                }
            }

            .page-part{
                margin-bottom: 20px;
                .page-part-title{
                    font-size: 20px;
                    font-weight: 700;
                    color: #f6443f;
                    margin-bottom: 10px;
                }
                .page-part-content{
                    line-height: 26px;
                }
            }
        }
    }
}
</style>