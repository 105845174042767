<template>
  <div class="detail-work-container">
    <div class="detail-video">
      <video
        controlslist="nodownload"
        autoplay="autoplay"
        controls="controls"
        class="video-item"
      >
        <source :src="detailInfo.link" />
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div class="detail-title">{{ detailInfo.title }}</div>
    <div class="detail-evaluate" v-if="detailInfo.remark">
      老师评语：【{{ detailInfo.remark }}】
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    detailInfo: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-work-container{
  width: 100%;
  min-width: 0 !important;
  .detail-video{
    width: 100%;
    .video-item{
      width: 100%;
    }
  }
  .detail-title{
    color: white;
    font-size: 24px;
    margin-top: 10px;
  }
  .detail-evaluate{
    color: white;
    margin-top: 10px;
  }
}
</style>