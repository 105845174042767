<template>
  <div class="index-page-container index-advantage-container">
    <div class="index-page-content index-advantage-content">
      <div class="page-title advantage-title">课程优势</div>
      <div class="advantage-box">
        <el-row>
          <el-col :span="12">
            <div class="advantage-item" @click="eventToPage(8)">
              <p>专业</p>
              <span>资深技术专家团队结合项目实例开发最专业的课程</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage-item" @click="eventToPage(10)">
              <p>时效</p>
              <span>实时更新最新最好的软件技术与美术技法</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage-item" @click="eventToPage(11)">
              <p>应用</p>
              <span>紧跟一线项目实际应用，拒绝不实用案例</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage-item" @click="eventToPage(13)">
              <p>就业</p>
              <span>面试指导、应聘测试题指导、职业规划、岗位推荐</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    eventToPage(pageNum) {
      this.$emit("eventToPage", pageNum);
    },
  },
};
</script>

<style lang="scss" scoped>
.index-advantage-container {
  .index-advantage-content {
    .advantage-box {
      display: flex;
      flex-wrap: wrap;
      padding: 100px 200px;
      //width: 1000px;
      .advantage-item {
        width: 224px;
        margin-left: 200px;
        margin-bottom: 50px;
        cursor: pointer;
        > p {
          font-size: 80px;
          font-weight: bold;
          color: #ffffff;
          text-align: justify;
          text-align-last: justify;
          width: 200px;
        }
        > span {
          font-size: 18px;
          color: #9abfcc;
          display: block;
          margin-top: 20px;
          line-height: 30px;
        }
        &:nth-child(odd) {
          margin-left: 0px;
        }
      }
    }
  }
}
</style>