<template>
    <div class='business-container'>
        <!-- 业务范围 -->
        <div class="business-content" v-for="(item, index) in businessScopeList" :key="index">
            <div class="business-img">
                <!-- <img src="@/assets/img/map.png" alt=""> -->
                <el-image class="business-image" v-if="item.descFile.indexOf('mp4') == -1" :src="item.descFile" :preview-src-list="item.fileList"></el-image>
                <video v-else class="video-player" controlslist="nodownload" :autoplay="false" controls="controls">
                    <source :src="item.descFile" type="video/mp4" /> 您的浏览器不支持 video 标签。
                </video>
            </div>
            <router-link tag="a" target="_blank" :to="`/home/businessDetail?id=${item.id}&activeIndex=1&companyIndex=1`" class="link-hover">
                <div class="business-title">
                    <div class="business-titles">
                        {{item.title}}
                    </div>
                    <div class="business-title-tag" v-for="(tag, index1) in item.tagList" :key="index1">
                        {{tag}}
                    </div>
                </div>
                <div class="business-title-intro">
                    {{item.descripe}}
                </div>
            </router-link>
        </div>
       <!-- <div class="business-content" v-html="data"> -->
       <!-- </div> -->
    </div>
</template>

<script>
    import {getBussiness,getBusinessScopeList} from '@/api/company.js';
    export default {
        data(){
            return{
                data: '',
                businessScopeList: '',
            }
        },
        methods:{
            async getBussiness(){
                let {code,data} = await getBussiness();
                if(code==200){
                    this.data = data;
                }
            },
            async getBusinessScopeList(){
                let {code,rows} = await getBusinessScopeList();
                if(code==200){
                    this.businessScopeList = rows;
                    this.businessScopeList.forEach(item => {
                        if(item.descFile.indexOf('.mp4') == -1){
                            item.fileList = [];
                            item.fileList.push(item.descFile);
                        }
                    });
                }
            }
        },
        created(){
            // this.getBussiness();
            this.getBusinessScopeList();
        }
    }
</script>

<style lang="scss" scoped>
.business-container{
    width: 1300px;
    margin: 40px auto;
    .business-content{
        width: 100%;
        margin: 0 auto;
        &:nth-of-type(n+2){
            margin-top: 90px;
        }
        /deep/ img{
            width: 1100px;
            object-fit: cover !important;
        }
        .business-img{
            margin: 0 auto;
            width: 100%;
            height: 100%;
            background: #2D2E2C;
            border: 1px solid #489bfab7;
            box-shadow: 0px 3px 8px rgba(106, 166, 234, 0.2);
            opacity: 1;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            // img{
            //     width: 100%;
            //     background: #2D2E2C;
            //     opacity: 1;
            //     border-radius: 10px;
            //     object-fit: cover;
            // }
            .business-image{
                width: 100%;
                height: auto;
                background: #2D2E2C;
                border-radius: 10px;
                img{
                    width: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }
           .video-player{
               width: 100%;
               height: 100%;
               border-radius: 10px;
            }
        }
        .link-hover{
            .business-title{
                margin-left: 30px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top:33px;
                // &:hover{
                //     color: rgba(103, 235, 16, 0.8);    
                // }
                .business-titles{
                    height: 40px;
                    font-size: 30px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 40px;
                    color: #FFFFFF;
                    // color: #9EEA6A;
                    text-shadow: 0px 2px 2px rgba(50, 50, 50, 0.25);
                    opacity: 1;
                    margin-left:30px;
                    &:hover{
                        color: rgba(200, 209, 194, 0.8);    
                    }
                }
                .business-title-tag{
                    height: 35px;
                    line-height: 35px;
                    text-align: center;
                    padding:0px 20px;
                    // background: rgba(158, 234, 106, 0.33);
                    // border-radius: 20px 0px 20px 0px;
                    background-image: url('../../assets/newImg/32.png');
                    background-size:  100% 100%;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-shadow: 0px 2px 2px rgba(50, 50, 50, 0.25);
                    opacity: 1;
                    margin-left:15px;
                    &:hover{
                        color: rgba(255, 255, 255, 0.8);    
                    }
                }
            }
            .business-title-intro{
                width: 100%px;
                min-height: 23px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 27px;
                color: rgb(255, 255, 255);
                margin-left: 55px;
                margin-top: 30px;
                &:hover{
                    color: rgba(255, 255, 255, 0.8);    
                }
            }
        }

    }
    
    /deep/ .el-image{
                width: 100%;
                height: 551px;
                background: #2D2E2C;
                border-radius: 10px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

}
</style>