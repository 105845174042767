<template>
    <div class="custom-container">
        <component
            :is="menuItem.childList ? 'el-submenu' : 'el-menu-item'"
            v-for="menuItem in list"
            :key="menuItem.id"
            :index="menuItem.id.toString()"
            :popper-class="menuItem.childList && menuItem.childList.length && 'children-menu'" :popper-append-to-body="!(menuItem.childList && menuItem.childList.length)"
        >
            <template v-if="menuItem.childList && menuItem.childList.length">
                <template slot="title"><div :class="['sub-title-box',activeIndex == menuItem.id && 'is-active']">{{ menuItem.name }}</div></template>
                <customElMenu :list="menuItem.childList"></customElMenu>
            </template>
            <template v-else>
                <span slot="title" @click.stop="handleSelect(1,2)" @mouseenter="getChildList(menuItem.id)">{{ menuItem.name }}</span>
            </template>
        </component>
    </div>
</template>

<script>
import customElMenu from './customElMenu.vue'
export default {
    name: "customElMenu",
    props: {
        list: {
            type: Array,
            required: true,
            default: () => []
        },
        activeIndex: {
            type: String,
            default: ''
        }
    },
    components: {
        customElMenu
    },
    methods: {
        handleSelect(id, name) {
            this.$emit('handleSelect', {id, name});
        },
        getChildList(id) {
            console.log(id);
            this.$emit('getChildList', id)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>