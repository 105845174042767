var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-main",style:(_vm.isManage && 'width: calc(100% - 60px);')},[_c('img',{staticClass:"header-logo",attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/logo.png"},on:{"click":function($event){return _vm.eventToView('/ectocyst')}}}),_c('el-menu',{attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":"#262932","text-color":"#ffffff","active-text-color":"#ffffff"},on:{"select":_vm.handleSelect}},[_c('el-menu-item',{attrs:{"index":"0"}},[_c('p',[_vm._v("任务")])]),(
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-effects' ||
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-painting' ||
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-movement' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-model' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-ui' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
        )?_c('el-menu-item',{attrs:{"index":"3"}},[_c('p',[_vm._v("技术评论")])]):_vm._e(),(
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-All' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-business'
        )?_c('el-menu-item',{attrs:{"index":"1"}},[_c('p',[_vm._v("审核")])]):_vm._e(),(
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-finance' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
        )?_c('el-menu-item',{attrs:{"index":"2"}},[_c('p',[_vm._v("财务结算")])]):_vm._e()],1),_c('div',{staticClass:"header-btn"},[(
          JSON.parse(this.$cookie.get('userInfo')).permission ===
            'T-business' ||
          JSON.parse(this.$cookie.get('userInfo')).permission === 'T-All'
        )?_c('div',{staticClass:"header-btn-item header-btn-publish",on:{"click":function($event){return _vm.eventChangePage('assignmentPublish')}}},[_vm._v(" 我要发包 ")]):_vm._e(),_c('div',{staticClass:"header-btn-user"},[_c('el-dropdown',[_c('div',{staticClass:"header-img"},[_c('img',{attrs:{"src":JSON.parse(this.$cookie.get('userInfo')).epibolyInfo
                  .headImage || require('@/assets/img/1.png')}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                JSON.parse(this.$cookie.get('userInfo')).permission ===
                  'T-common' ||
                JSON.parse(this.$cookie.get('userInfo')).permission ===
                  'T-All'
              )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.eventChangePage('ectocystTasks', 'list')}}},[_vm._v("我的任务")]):_vm._e(),(
                JSON.parse(this.$cookie.get('userInfo')).permission ===
                  'T-business' ||
                JSON.parse(this.$cookie.get('userInfo')).permission ===
                  'T-All'
              )?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.eventChangePage('ectocystTasks', 'listBusiness')}}},[_vm._v("我的发包")]):_vm._e(),_c('el-dropdown-item',{attrs:{"divided":""},nativeOn:{"click":function($event){return _vm.eventLoginOut()}}},[_vm._v("退出登录")])],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }