<template>
  <div class="index-page-container index-home-container">
    <div class="home-main">
        <!-- <div class="home-sponsor">
            <p>主办方：</p>
            <img src="@/assets/newImg/GLlogo.png" />
        </div> -->
        <div class="home-action-content">
            <p class="content-title">“超·能·力”怪力视效校园美术大赛</p>
            <p class="content-time">作品提交：2023年11月1日至2023年12月1日</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.index-home-container{
    width: 100%;
    height: 100%;
    background: url(../../../../assets/img/linkage/page1_bg.jpg) center center repeat-x #000518; 
    background-size: cover;
    overflow: hidden;
    .home-main{
        position: relative;
        width: 1180px;
        height: 100%;
        margin: 0 auto;
        .home-sponsor{
            position: absolute;
            top: 25px;
            >p{
                font-size: 16px;
                margin-bottom: 10px;
            }
            >img{
                width: 160px;
                height: auto;
            }
        }
        .home-action-content{
            margin: 180px auto -300px;
            position: absolute;
            top: 50%;
            text-align: center;
            width: 100%;
            .content-title{
                font-size: 60px;
                letter-spacing: 5px;
            }
            .content-time{
                font-size: 24px;
                margin-top: 20px;
            }
        }
    }
}
</style>