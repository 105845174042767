<template>
  <menu-iframe :menuObject="menuObject" @changeSelect="changeSelect">
    <component :is="activeCompany" slot="rightContent"></component>
  </menu-iframe>
</template>

<script>
import menuIframe from "../components/menu.vue";
// 认证审核界面
import authPerson from "./auth/index_person.vue";
import authCompany from "./auth/index_company.vue";
// 认证审核详情界面
import detail from "./auth/detail.vue";
// 任务审核界面
import task from "./tasks/index.vue";
export default {
  data() {
    return {
      activeCompany: "task",
      menuObject: {
        defaultActive: "task",
        list: [
          // {
          //   title: "认证审核",
          //   icon: "el-icon-s-unfold",
          //   index: "0",
          //   list: [
          //     {
          //       title: "个人认证审核列表",
          //       icon: "el-icon-s-unfold",
          //       index: "authPerson",
          //       components: authPerson
          //     },
          //     {
          //       title: "企业认证审核列表",
          //       icon: "el-icon-s-unfold",
          //       index: "authCompany",
          //       components: authCompany
          //     },
          //   ],
          // },
          {
            title: "任务审核",
            icon: "el-icon-s-unfold",
            index: "task",
            components: task,
          },
        ],
      },
      tableData: [],
      activeIndex: "0",
    };
  },
  components: {
    menuIframe,
    authPerson,
    authCompany,
    detail,
    task,
  },
  created() {
    let permission = JSON.parse(this.$cookie.get("userInfo")).permission;
    if (permission === "T-business" || permission === "T-All") {
      let menu = {
        title: "认证审核",
        icon: "el-icon-s-unfold",
        index: "0",
        list: [
          {
            title: "个人认证审核列表",
            icon: "el-icon-s-unfold",
            index: "authPerson",
            components: authPerson,
          },
          {
            title: "企业认证审核列表",
            icon: "el-icon-s-unfold",
            index: "authCompany",
            components: authCompany,
          },
        ],
      };
      this.menuObject.list.unshift(menu);
      this.$set(this.menuObject, 'defaultActive', "authPerson");
      this.activeCompany = "authPerson";
    }
  },
  methods: {
    // 菜单选择
    changeSelect(index) {
      console.log(index);
      this.activeCompany = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-list {
  padding: 30px;
  background-color: #ffffff;
}

/deep/.el-pagination {
  margin-top: 30px;
  text-align: right;
}
</style>