<template>
  <menu-iframe :menuObject="menuObject">
    <component :is="activeCompany" slot="rightContent"></component>
  </menu-iframe>
</template>

<script>
import menuIframe from "../components/menu.vue";
import list from "./list/list.vue"
import listBusiness from "./list/list-business.vue";
import item from "../components/item.vue";
export default {
  data() {
    return {
      queryForm: {
        state: undefined,
      },
      activeCompany: undefined,
      menuObject: {
        defaultActive: "0",
        list: [
          {
            title: "任务列表",
            icon: "el-icon-s-unfold",
            index: "0",
          },
        ],
      },
      tabsObject: {
        activeName: "all",
        list: [
          {
            label: "所有任务",
            name: "all",
          },
          {
            label: "报名中",
            name: "registering",
          },
          {
            label: "测试中",
            name: "testing",
          },
          {
            label: "制作中",
            name: "production",
          },
          {
            label: "已完成",
            name: "completed",
          },
        ],
      },
      testContent: {
        flag: true,
        classifyName: "特效",
        title: "这是个测试的数据呀",
        eid: 101,
        status: 0,
        money: "￥1000 - 2000",
      },
    };
  },
  components: {
    menuIframe,
    item,
    list,
    listBusiness
  },
  watch: {
    '$route.query.comp': {
      handler(v) {
        if (JSON.parse(this.$cookie.get("userInfo")).permission === 'T-All') {
          this.activeCompany = v;
        }
      }
    }
  },
  created() {
    let permission = JSON.parse(this.$cookie.get("userInfo")).permission;
    if (permission === 'T-common') {
      this.activeCompany = 'list';
    } else if (permission === 'T-business') {
      this.activeCompany = 'listBusiness';
    } else if (permission === 'T-All') {
      let query = this.$route.query.comp;
      this.activeCompany = query;
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-right-header {
  width: 100%;
  //height: 120px;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  background: #ffffff;
  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    .header-title {
      font-weight: bold;
    }
    .header-form {
      //margin-top: 15px;
      /deep/.el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .header-bottom {
    margin-top: 20px;
    /deep/.el-tabs__header {
      margin-bottom: 0;
    }
    /deep/.el-tabs__nav-wrap::after {
      background-color: #ffffff;
      bottom: -2px;
    }
    /deep/.el-tabs__item {
      font-size: 16px;
    }
    /deep/.el-tabs__item:hover {
      color: var(--default-ectocyst-color);
    }
    /deep/.el-tabs__item.is-active {
      color: var(--default-ectocyst-color);
      font-weight: bold;
    }
    /deep/.el-tabs__active-bar {
      background-color: var(--default-ectocyst-color);
    }
  }
}

.auth-right-list {
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.list-btn {
  margin-top: 20px;
  width: 100%;
  background-color: var(--default-ectocyst-color);
  color: #ffffff;
}

.data-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0 80px 30px;
}

/deep/.el-pagination {
  margin-top: 30px;
  text-align: right;
}

/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  background-color: #ffffff;
}

/deep/.el-pager li.active {
  background-color: #409eff !important;
}
</style>