<template>
  <div class="login-container">
    <div class="login-logo">
      <el-image
        @click="eventToIndex"
        src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/logo.png"
        class="el-image"
        lazy
      ></el-image>
    </div>
    <div class="login-form-box">
      <div class="login-form-content">
        <ul class="login-form-header">
          <template v-if="loginType !== 3">
            <li
              class="header-item"
              :class="loginType === 1 && 'header-item-active'"
              @click="eventChangeType(1)"
            >
              短信登录
            </li>
            <li
              class="header-item"
              :class="loginType === 2 && 'header-item-active'"
              @click="eventChangeType(2)"
            >
              密码登录
            </li>
          </template>
          <li
            v-else
            class="header-item"
            :class="loginType === 3 && 'header-item-active'"
          >
            微信登录
          </li>
        </ul>
        <div class="login-form-info" v-if="loginType !== 3">
          <el-form>
            <el-form-item>
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.account"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
              >
                <template slot="prepend">
                  <el-image
                    src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-icon-user.png"
                    class="input-left-icon"
                    lazy
                  ></el-image>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="loginType === 1">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.verifyCode"
                @keydown.enter.native="getLogin"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="6"
              >
                <template slot="append">
                  <div class="input-right-btn">
                    <el-button
                      @click="eventGetCode"
                      :disabled="!downBool"
                      :loading="downLoading"
                      >{{ codeBtnText || "获取验证码" }}</el-button
                    >
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item v-else>
              <el-input
                placeholder="请输入密码"
                type="password"
                v-model="loginForm.password"
                @keydown.enter.native="getLogin"
              >
                <template slot="prepend">
                  <el-image
                    src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-icon-pwd.png"
                    class="input-left-icon"
                    lazy
                  ></el-image>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="success" class="login-form-submit" @click="getLogin" :loading="loginLoading"
            >登录</el-button
          >
        </div>
        <div class="login-form-wxcode" v-else>
          <wx-login
            appid="wx7d33275501e355ab"
            :scope="'snsapi_login'"
            :theme="'white'"
            state="123"
            :redirect_uri="encodeURIComponent('http://www.glsxvfx.com/login')"
            rel="external nofollow"
            class="wx-iframe-box"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgICAgICBkaXNwbGF5OiBub25lOwogICAgICB9Ci5pbXBvd2VyQm94IC5xcmNvZGV7CndpZHRoOiAxODBweDsKbWFyZ2luLXRvcDogMTBweDsKfQouaW1wb3dlckJveCAuaW5mb3sKZGlzcGxheTpub25lOwp9'"
          >
          </wx-login>
          <p class="wx-login-tips">使用微信扫一扫登录 “怪屋”</p>
        </div>
        <div class="login-form-bottom">
          <p class="login-form-divider">其它登录方式</p>
          <el-image
            v-if="loginType !== 3"
            @click="eventChangeType(3)"
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/login/dl-icon-wechat-hover.png"
          ></el-image>
          <el-image
            @click="eventChangeType(1)"
            v-else
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-user.png"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wxLogin from "vue-wxlogin";
import { getPhoneAuthCode, codeLogin, loginByPwd } from "@/api/login.js";
export default {
  data() {
    return {
      // 登录的参数
      loginForm: {
        account: undefined,
        verifyCode: undefined,
        password: undefined,
      },
      // 登录方式
      loginType: 1,
      // 手机号正则
      phoneReg: /^(13|14|15|16|17|18|19)[0-9]{9}$/,
      downBool: true,
      downLoading: false,
      interval: undefined,
      codeBtnText: "获取验证码",
      codeTime: 60,
      loginLoading: false,
    };
  },
  components: {
    wxLogin,
  },
  created() {
    this.$store.state.showHeader = false;
    this.$store.state.showFooter = false;
  },
  methods: {
    // 跳转到首页界面
    eventToIndex() {
      this.$router.push("/");
    },
    // 点击登录
    async getLogin() {
      if (!this.loginForm.account) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (!this.phoneReg.test(this.loginForm.account)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      if (this.loginType === 1 && !this.loginForm.verifyCode) {
        this.$message({
          message: "请输入验证码",
          type: "warning",
        });
        return;
      }
      if (this.loginType === 1 && this.loginForm.verifyCode.length !== 6) {
        this.$message({
          message: "验证码格式错误",
          type: "warning",
        });
        return;
      }
      if (this.loginType === 2 && !this.loginForm.password) {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return;
      }
      this.loginLoading = true;
      let res;
      if (this.loginType === 2) {
        res = await loginByPwd({
          account: this.loginForm.account,
          password: this.loginForm.password,
        }).catch((e) => {
          this.$message({
            type: "error",
            offset: 80,
            message: e,
          });
          this.loginLoading = false;
          return;
        });
      } else if (this.loginType === 1) {
        res = await codeLogin({
          account: this.loginForm.account,
          verifyCode: this.loginForm.verifyCode,
        }).catch((e) => {
          this.$message({
            type: "error",
            offset: 80,
            message: e,
          });
          this.loginLoading = false;
          return;
        });
      }
      if (res.code === 200) {
        this.$message.success("登录成功, 即将跳转");
        if (res.data.message) {
          this.$message({
            type: "success",
            offset: 80,
            message: "首次登录赠送100经验值!",
          });
        }
        if (res.data.userInfo) {
          if (
            (Number(res.data.userInfo.roleType) === 4 ||
              Number(res.data.userInfo.roleType) === 3) &&
            !("permission" in res.data.userInfo)
          ) {
            this.$set(
              res.data.userInfo,
              "permission",
              res.data.userInfo.epibolyInfo.permission
            );
          }
          this.$cookie.set("accessToken", res.data.token, {
            expires: 30,
          });
          this.$cookie.set("userInfo", res.data.userInfo, {
            expires: 30,
          });
          this.$store.commit(
            "setUserInfo",
            JSON.parse(this.$cookie.get("userInfo"))
          );
          this.$store.commit("setisShowLogin", true);
          if (Number(res.data.userInfo.roleType) === 4) {
            setTimeout(() => {
              this.$router.push("/ectocyst");
              window.location.reload();
            }, 2000);
          } else {
            setTimeout(() => {
              this.$router.push("/home/newest?activeIndex=0&latest=0");
              window.location.reload();
            }, 2000);
          }
        }
      } else {
        this.loginLoading = false;
      }
    },
    // 获取验证码
    async eventGetCode() {
      if (!this.loginForm.account) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (!this.phoneReg.test(this.loginForm.account)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      this.downLoading = true;
      let { code, msg } = await getPhoneAuthCode({
        account: this.loginForm.account,
        type: 1,
      }).catch((e) => {
        this.$message({
          type: "error",
          offset: 80,
          message: e,
        });
        this.downLoading = false;
        return;
      });
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: msg,
        });
        this.intervalDwon();
      }
      this.downLoading = false;
    },
    // 验证码倒计时
    intervalDwon() {
      this.downBool = false;
      this.interval = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        this.codeBtnText = this.codeTime + "s重新发送";
        if (this.codeTime == 0) {
          clearInterval(this.interval);
          this.codeTime = 60;
          this.codeBtnText = "获取验证码";
          this.downBool = true;
        }
      }, 1000);
    },
    // 切换type
    eventChangeType(t) {
      this.loginType = t;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100vw;
  height: 100vh;
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/login-bg.jpg");
  background-size: 100% 100%;

  .login-logo {
    position: absolute;
    top: 5px;
    left: 50px;
    width: 160px;
    height: 45px;
    .el-image {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .login-form-box {
    width: 320px;
    height: 400px;
    position: absolute;
    top: calc(50% - 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/border.png");
    background-size: 100% 100%;

    .login-form-content {
      width: 100%;
      height: 100%;
      position: relative;
      &::after {
        content: "";
        width: 240px;
        height: 240px;
        background-image: url(https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/login-icon.png);
        background-size: 100% 100%;
        position: absolute;
        bottom: -120px;
        right: -120px;
        z-index: -1;
      }

      .login-form-header {
        display: flex;
        width: 100%;
        height: 60px;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border-radius: 10px 10px 0 0;
        color: #ffffff;
        font-size: 18px;
        letter-spacing: 2px;

        .header-item {
          flex: 1;
          text-align: center;
          line-height: 60px;
          cursor: pointer;

          &.header-item-active {
            color: #ababab;
          }
        }
      }

      .login-form-info {
        padding: 40px 10px 5px;

        /deep/.el-form-item__content {
          border-bottom: 1px solid #626780;
        }

        /deep/.el-input-group__prepend {
          background-color: transparent;
          border: none;
          padding: 0 10px;
        }

        /deep/.el-input__inner {
          background-color: rgba($color: #35415b, $alpha: 1);
          border: none;
          height: 36px;
          border-radius: 0;
          color: #ffffff;
        }

        .input-left-icon {
          width: 28px;
        }

        /deep/.el-input-group__append {
          background-color: transparent;
          border: none;
          padding-left: 5px;
          padding-right: 0;
        }

        .input-right-btn {
          background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
          border: none;
          border-radius: 5px;
          height: 36px;
          line-height: 36px;
          color: #ffffff;
          padding: 0 10px;
          cursor: pointer;
          overflow: hidden;

          /deep/.el-button {
            border: none;
          }

          /deep/.el-button.is-disabled:hover {
            cursor: not-allowed;
            background-image: none;
            background-color: transparent;
            border: none;
          }
        }
      }

      .wx-iframe-box {
        width: 300px;
        height: 200px;
        margin: 10px auto;
      }

      .wx-login-tips {
        width: 100%;
        text-align: center;
        color: #68748c;
        font-size: 14px;
      }

      .login-form-submit {
        width: 100%;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border: none;
        letter-spacing: 5px;
      }

      .login-form-bottom {
        width: 100%;
        padding: 0 10px;
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        .login-form-divider {
          width: 100%;
          position: relative;
          text-align: center;
          font-size: 14px;
          color: #626780;
          letter-spacing: 1px;
          margin-top: 20px;
          &::before,
          &::after {
            position: absolute;
            top: 50%;
            content: "";
            width: 90px;
            height: 1px;
            background-color: #626780;
            transform: translateY(-50%);
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }

        /deep/.el-image {
          width: 40px;
          height: 40px;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>