<template>
    <div class="replay-container">
        <div class="review-content-user" v-if="hotComment.nickname">
            <div class="review-content-img"><img :src="hotComment.avatar" title="点击可查看用户个人主页"
                    @click="getUserPersonal(hotComment.user_id)"></div>
            <div class="review-content-title">{{hotComment.nickname}}</div>
            <div v-if="workDetailList.userInfo" class="author"
                v-show='hotComment.user_id==workDetailList.userInfo.id'>作者</div>
            <div class="review-content-time">{{ hotComment.create_time | dateformat('YYYY-MM-DD HH:mm') }}</div>
            <div class="review-content-icon">
                <div style="margin-right:-27px;"
                    @click="getCommentNums(hotComment)">
                    <img :src="hotComment.likeFlag==1 ? likeImg : unlikeImg" alt="">
                </div>
                <span style="margin-right: 5px;">{{hotComment.likenum}}</span>
            </div>
        </div>
        <div class="review-content-intro" @click="moreintro($event)" :title="hotComment.content">
            {{hotComment.content}}
            <div class="addComment"
                @click="addComment(hotComment.nickname,hotComment.id,hotComment.user_id,hotComment.content,$event)">
                回复
            </div>
        </div>
        <div class="replyCommentall" v-if="hotComment.replyComments">
            <div  class="replyComments" v-for="ket3 in !isShowAll ? hotComment.replyComments.slice(0,2) : hotComment.replyComments" :key="ket3.id">
                <div class="review-content-user" v-if="ket3.nickname">
                    <div class="review-content-title">{{ket3.nickname}}</div>
                    <div v-if="workDetailList.userInfo" class="author"
                        v-show='ket3.user_id==workDetailList.userInfo.id'>作者</div>
                    <div class="review-content-title2"><span>@</span>{{ket3.parentNickname}}</div>
                </div>
                <div class="review-content-intro" :title="ket3.content">
                    {{ket3.content}}
                    <div class="addComment"
                        @click="addComment(ket3.nickname,ket3.id,ket3.user_id,ket3.content,$event);">
                        回复
                    </div>
                </div>
            </div>
            <div @click="isShowAll = !isShowAll" class="morecomment" v-if="hotComment.replyComments.length>2">
                <span v-show="!isShowAll">查看所有{{hotComment.replyComments.length}}条回复</span>
                <span v-show="isShowAll">收起</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 是否显示全部回复信息
            isShowAll: false,
            // 回复框提示
            replayPlaceholder: undefined,
            // 是否显示回复框
            isShowinp: false,
            // 回复的内容
            comments: undefined,
            replayData: {
                content: undefined,
                toContent: undefined,
                tuUserId: undefined,
                parentNickname: undefined
            },
            likeImg: require('@/assets/img/layericon/like1.png'),
            unlikeImg: require('@/assets/img/layericon/like2.png')
        }
    },
    props: {
        hotComment: {
            type: Object,
            default: () => {}
        },
        workDetailList: {
            type: Object,
            default: () => {}
        } 
    },
    methods: {
        // 点击回复
        addComment(nickname, id, tuUserId, toContent, e) {
            this.$emit('handleAddComment', {nickname, id, tuUserId, toContent, e})
        },
        // 点赞
        getCommentNums(Obj) {
            this.$emit('getCommentNums', Obj);
        },
        // 点击查看个人主页
        getUserPersonal(userId) {
            this.$emit('getUserPersonal', userId);
        }
    }
}
</script>

<style lang="scss" scoped>
.replay-container{
    margin-left: 0;
    .review-content-user{
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 0px;
        margin-left: 10px;

        .review-content-time {
            position: absolute;
            left: 56px;
            top: 35px;
            height: 21px;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            line-height: 21px;
			color: rgba(255, 255, 255, 0.3);
        }

        .review-content-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            margin-top: 10px;
            border: 1px solid rgba(0, 0, 0, 0);

            &:hover {
                border: 1px solid #06b0ff;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .author {
            font-size: 12px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            border-radius: 8px;
            background-color: #00d8ff;
            color: #000000;
            margin-left: 5px;
        }

        .review-content-title {
            height: 21px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 21px;
            color: #FFFFFF;
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
            opacity: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 15px;
            margin-right: 15px;
        }

        .review-content-icon {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            position: absolute;
            right: 41px;
            top: 15px;

            div {
                width: 19px;
                height: 18px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            span {
                color: #d6d6d6;
                font-size: 10px;
                margin-top: 8px;
            }
        }
    }

    .review-content-intro {
        min-height: 30px;
        font-size: 14px;
        //width: 285px;
        margin-left: 20px;
        margin-right: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        //color: rgba(190, 190, 190, 0.7);
        color: #ffffff;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        word-break: break-all;
        //提示打包的时候这里不做处理
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow-wrap: break-word;
        margin-top: 10px;
        cursor: default;
        

        .addComment {
            cursor: pointer;
            float: right;
            color: #0375f8;
            margin-right: 6px;
        }
    }

    .review-content-title2 {
        height: 21px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        //color: #9f9f9f;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 5px;
        color: #9ffff7;

        span {
            color: #ff9cff;
            margin-right: 8px;
        }
    }

    .replyCommentall {
        margin-left: 20px;
        //width: 285px;
        border-radius: 12px;
        background-color: #242b3d;
        margin-bottom: 20px;
        position: relative;
        margin-top: 10px;
        box-sizing: border-box;
        margin-right: 8px;

        .replyComments {
            //width: 250px;
            padding: 8px 5px;

            .review-content-user{
                margin-left: 0;
            }

            .review-content-intro{
                margin-right: 0;
            }

            .review-content-title {
                max-width: 120px;
                height: 21px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 21px;
                color: #FFFFFF;
                text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
                opacity: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 5px;
                margin-right: 0px;
            }

            

            .review-content-title {
                color: #9ffff7;
            }

            .review-content-intro {
                //width: 250px;
            }

            .addComment {
                cursor: pointer;
                float: right;
                color: #0375f8;
                margin-right: 0px;
            }
        }

        .morecomment {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            cursor: pointer;
            font-size: 12px;
            color: #09adce;
        }
    }
    
}
</style>