<template>
  <div class="task-container" v-if="!isShowDetail">
    <div class="auth-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="任务名称" prop="title">
              <el-input
                type="text"
                placeholder="根据任务名称搜索"
                clearable
                size="small"
                v-model="queryForm.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="任务类型" prop="cid">
              <el-select
                v-model="queryForm.cid"
                filterable
                clearable
                placeholder="根据任务类型搜索"
                size="small"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接包方" prop="name">
              <el-input
                type="text"
                placeholder="根据接包方搜索"
                clearable
                size="small"
                v-model="queryForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                type="text"
                placeholder="根据联系电话搜索"
                clearable
                size="small"
                v-model="queryForm.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="queryForm.status"
                filterable
                clearable
                placeholder="根据审核状态来搜素"
                size="small"
              >
                <el-option
                  v-for="item in examineOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="提交时间">
              <el-date-picker
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" size="small" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div
        class="header-bottom"
        v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business' || JSON.parse($cookie.get('userInfo')).permission === 'T-All'"
      >
        <el-tabs v-model="tabsObject.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in tabsObject.list"
            :key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="auth-right-list">
      <el-table :data="tableData" border style="width: 100%">
        <!-- <el-table-column prop="aid" label="任务ID"></el-table-column> -->
        <el-table-column prop="cover" label="封面">
          <template slot-scope="scope">
            <img :src="scope.row.cover" style="width: 100%" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="任务标题"></el-table-column>
        <el-table-column prop="name" label="接包方"></el-table-column>
        <el-table-column
          prop="classifyName"
          label="任务类型（环节）"
        ></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.status === 0">待审核</p>
            <p v-if="scope.row.status === 1">已通过</p>
            <p v-if="scope.row.status === 2">已拒绝</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="eventToDetail(scope.row.mid, scope.row.cid)"
              v-if="
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-effects' ||
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-painting' ||
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-movement' ||
                JSON.parse($cookie.get('userInfo')).permission === 'T-model' ||
                JSON.parse($cookie.get('userInfo')).permission === 'T-ui'
              "
            >
              评论
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-business' && tabsObject.activeName === 'testing'
              "
              @click="eventToBusinessDetail(scope.row.mid)"
            >
              审核并填写价格
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-business' && tabsObject.activeName === 'production'
              "
              @click="eventToBusinessAcceptDetail(scope.row.eid)"
            >
              验收
            </el-button>
            <!-- 管理员 -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-All' && tabsObject.activeName === 'testing'
              "
              @click="eventToBusinessDetail(scope.row.mid)"
            >
              查看详情
            </el-button>
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="
                JSON.parse($cookie.get('userInfo')).permission ===
                  'T-All' && tabsObject.activeName === 'production'
              "
              @click="eventToBusinessAcceptDetail(scope.row.eid)"
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>
  </div>
  <div v-else>
    <detail @back="eventBack" :content="detailContent" v-if="tabsObject.activeName === 'testing'"></detail>
    <detail-accept @back="eventBack" :content="detailContent" v-if="tabsObject.activeName === 'production'"></detail-accept>
  </div>
</template>

<script>
import {
  epibolyTestVerify,
  getTechnologyList,
  getEpibolyBusinessTestList,
  getEpibolyBusinessCheckList,
  getEpibolyAddTechnologyById,
  getEpibolyAddBusinessDetailById,
  getEpibolyAcceptDetailById
} from "@/api/ectocyst.js";
import { getClassifyList } from "@/api/work.js";
import detail from "./detail.vue";
import detailAccept from './detail_accept.vue';
export default {
  data() {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        cid: undefined,
        title: undefined,
        phone: undefined,
        name: undefined,
        status: undefined,
      },
      total: 0,
      tableData: [],
      isShowDetail: false,
      tabsObject: {
        activeName: "testing",
        list: [
          // {
          //   label: "所有任务",
          //   name: "all",
          // },
          {
            label: "测试中",
            name: "testing",
          },
          {
            label: "制作中",
            name: "production",
          },
          // {
          //   label: "已审核",
          //   name: "completed",
          // },
        ],
      },
      typeOptions: [],
      examineOptions: [
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "审核通过",
          value: 1,
        },
        {
          label: "审核未通过",
          value: 2,
        },
      ],
      headManList: [
        { flag: "T-effects", cid: 139 },
        { flag: "T-painting", cid: 141 },
        { flag: "T-movement", cid: 140 },
        { flag: "T-model", cid: 142 },
        { flag: "T-ui", cid: 144 },
      ],
      detailContent: undefined,
    };
  },
  components: {
    detail,
    detailAccept
  },
  created() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    // 获取类型列表
    async getTypeList() {
      let state = {
        queryType: 6,
      };
      let { code, data } = await getClassifyList(state);
      if (code === 200) {
        this.typeOptions = data || [];
      }
    },
    // 列表信息
    async getList() {
      let permission = JSON.parse(this.$cookie.get("userInfo")).permission;
      if (permission === "T-business" || permission === "T-All") {
        if (this.tabsObject.activeName === "testing") {
          let { code, rows, total } = await getEpibolyBusinessTestList(
            this.queryForm
          );
          if (code === 200) {
            this.tableData = rows || [];
            this.total = total;
          }
        } else if (this.tabsObject.activeName === "production") {
          let { code, rows, total } = await getEpibolyBusinessCheckList(
            this.queryForm
          );
          if (code === 200) {
            this.tableData = rows || [];
            this.tableData.forEach((el) => {
              el.cover = el.image;
            });
            this.total = total;
          }
        }
      } else if (
        permission === "T-effects" ||
        permission === "T-painting" ||
        permission === "T-movement" ||
        permission === "T-model" ||
        permission === "T-ui"
      ) {
        let cid = this.headManList.find((el) => el.flag === permission).cid;
        let { code, rows, total } = await getTechnologyList({
          cid: cid,
          ...this.queryForm,
        });
        if (code === 200) {
          this.tableData = rows || [];
          this.total = total;
        }
      }
    },
    // 重置
    resetQuery() {
      this.$refs['queryForm'].resetFields();
      this.getList();
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.getList();
    },
    // 换页
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    // 评论
    async eventToDetail(mid, cid) {
      let { code, data } = await getEpibolyAddTechnologyById({
        mid: mid,
        cid: cid,
      });
      if (code === 200) {
        this.detailContent = data;
        this.isShowDetail = true;
      }
    },
    // 审核并填写报价
    async eventToBusinessDetail(mid) {
      let { code, data } = await getEpibolyAddBusinessDetailById({ mid });
      if (code === 200) {
        this.detailContent = data;
        this.isShowDetail = true;
      }
    },
    async eventToBusinessAcceptDetail(eid) {
      let {code, data} = await getEpibolyAcceptDetailById({eid});
      if (code === 200) {
        this.detailContent = data;
        this.isShowDetail = true;
      }
    },
    // 详情页点击返回上一页
    eventBack() {
      this.isShowDetail = false;
      this.getList()
    },
    handleClick() {
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.task-container {
  .auth-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      margin-top: 20px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .auth-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}
</style>