<template>
  <div class="index-page-container index-prize-container">
    <div class="index-page-content index-prize-content">
        <div class="page-content">
            <div class="page-header-box">
                <h2 class="page-header-title"><span class="el-icon-s-management"></span>奖项</h2>
            </div>
            <div class="page-part">
                <img src="@/assets/img/linkage/prize1.jpg" class="page-part-img" />
                <img src="@/assets/img/linkage/prize2.jpg" class="page-part-img" />
                <img src="@/assets/img/linkage/prize3.jpg" class="page-part-img" />
            </div>
            <dl class="page-part" style="margin-top: 50px">
                <dd class="page-part-content">
                    <span>一等奖：</span>1名 获得大赛奖杯并颁发荣誉证书，享有精英班一期免费学习机会（1期时长为5-6个月）；奖金3000元/人
                </dd>
                <dd class="page-part-content">
                    <span>二等奖：</span>2名 获得大赛奖杯并颁发荣誉证书，享有精英班一期学习5折券；奖金1500元/人
                </dd>
                <dd class="page-part-content">
                    <span>三等奖：</span>3名 获得大赛奖杯并颁发荣誉证书；享有精英班一期学习7.5折券；奖金800元/人
                </dd>
                <dd class="page-part-content">
                    <span>优秀奖：</span>10名  颁发荣誉证书；享有精英班一期学习8.5折券；纪念奖品1份/人
                </dd>
                <dd class="page-part-content">
                    <span>优秀指导教师奖：</span>指导获奖同学的对应教师可获得《优秀指导教师》荣誉证书。
                </dd>
            </dl>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.index-prize-container{
    background: url(../../../../assets/img/linkage/page2_bg.jpg) center center
    repeat-x #000518;
    background-size: cover;
    .index-prize-content{
        .page-part{
            >.page-part-img{
                margin-right: 50px;
                width: 304px;
                height: 351px;
                object-fit: cover;
            }
            >.page-part-content{
                font-size: 16px;
                letter-spacing: 2px;
                line-height: 40px !important;
                >span{
                    color: #f6443f;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>