<template>
  <div
    class="barrage-container"
    :style="{ animationDuration: duration + 'ms' }"
  >
    <div class="barrage-text">
      <i class="el-icon-chat-line-round"></i>{{ prefix }}{{ text || ""
      }}{{ suffix }}
    </div>
    <i class="el-icon-circle-close" @click="eventShowBarrage(false)"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: undefined,
      textFun: undefined,
    };
  },
  props: {
    textList: {
      type: Array,
      require: true,
    },
    duration: {
      type: Number,
      default: 20000,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
  },
  watch: {
    textList: {
      handler() {
        //this.init();
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    eventShowBarrage(bool) {
      //this.isShowBarrage = false;
      this.$emit("emitClear", bool);
    },
    init() {
      let index = 0;
      this.setText(index);
      this.textFun = setInterval(() => {
        index += 1;
        this.setText(index);
      }, this.duration);
    },
    // 注入文字
    setText(index) {
      this.text = this.textList[index];
      if (index >= this.textList.length) {
        this.$emit("emitClear", false);
        clearInterval(this.textFun);
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.barrage-container {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  box-sizing: border-box;
  border-radius: 18px;
  position: fixed;
  bottom: 150px;
  //transform: translateX(-100%);
  z-index: 9;
  background-color: rgba($color: #000000, $alpha: 0.6);
  color: #ffffff;
  animation: toRightAnimation linear 20s;
  animation-iteration-count: infinite;
  animation-play-state: running;
  &:hover {
    animation-play-state: paused;
  }
  @keyframes toRightAnimation {
    0% {
      //transform: translateX(-100%);
      //margin-left: 0;
      left: 0;
    }
    100% {
      left: 100%;
      transform: translateX(calc(100% + 30px));
      //margin-left: 100%;
      //transform: translateX(calc(100% + 40px));
      //transform: translateX(-100%);
    }
  }
  .barrage-text {
    display: flex;
    align-items: center;
    .el-icon-chat-line-round {
      margin-right: 5px;
    }
  }
  .el-icon-circle-close {
    margin-left: 5px;
    cursor: pointer;
    background-color: #999999;
    border-radius: 50%;
  }
}
</style>