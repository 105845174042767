<template>
  <menu-iframe :menuObject="menuObject" @changeSelect="changeSelect">
    <component :is="activeCompany" slot="rightContent"></component>
  </menu-iframe>
</template>

<script>
import menuIframe from "../components/menu.vue";
import listPerson from "./list/list_person.vue";
import listCompany from "./list/list_company.vue"
export default {
  data() {
    return {
      activeCompany: 'listPerson',
      menuObject: {
        defaultActive: "listPerson",
        list: [
          {
            title: "结算列表",
            icon: "el-icon-s-unfold",
            index: "listPerson",
            components: listPerson
            // list: [
            //   {
            //     title: "个人接包方结算",
            //     icon: "el-icon-s-unfold",
            //     index: "listPerson",
            //     components: listPerson
            //   },
            //   {
            //     title: "企业接包方结算",
            //     icon: "el-icon-s-unfold",
            //     index: "listCompany",
            //     components: listCompany
            //   },
            // ],
          }
        ]
      }
    }
  },
  components: {
    menuIframe,
    listPerson,
    listCompany
  },
  methods: {
    changeSelect(index) {
      this.activeCompany = index;
    }
  }
}
</script>

<style>

</style>