<template>
  <div class="item-container">
    <div class="item-left-image">
      <img :src="info.cover" />
      <!-- <p>已更新</p> -->
    </div>
    <div class="item-right-content">
      <div class="content-header">
        <div
          class="content-title"
          :style="{ width: info.renew ? 'calc(100% - 85px)' : '100%' }"
        >
          {{ info.seriesName }}
        </div>
        <div class="content-refresh" v-if="info.renew">更 新</div>
        <!-- <div class="content-time">发布于：2022年12月27日</div> -->
      </div>
      <div class="content-duty">{{ info.introduction }}</div>
      <div class="content-user">
        <div class="content-user-left">
          <img src="@/assets/img/favicon.png" />{{ info.teacher }}
        </div>
        <div class="content-user-right">发布时间：{{ info.createTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    info: {
      type: Object,
      default: {},
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #323547, #181a29) 1;
  position: relative;
  &::after {
    content: "";
    width: 0px;
    height: 2px;
    background-color: #016985;
    position: absolute;
    left: 0;
    bottom: -1px;
    transition: all 0.8s;
  }
  &:hover {
    &::after {
      width: 100%;
    }
    .item-left-image {
      > img {
        transform: scale(1.2);
      }
    }
  }
  .item-left-image {
    width: 200px;
    height: 150px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      transition: all 0.8s;
    }
  }
  .item-right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 220px);
    color: #ffffff;
    margin-left: 20px;
    .content-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .content-title {
        font-size: 24px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        //width: calc(100% - 220px);
        width: 100%;
      }
      .content-refresh {
        width: 80px;
        line-height: 31px;
        border: 1px solid #892525;
        text-align: center;
        border-radius: 5px;
        background-color: #d20d0d;
        box-sizing: border-box;
      }
      .content-time {
        width: 200px;
        font-size: 14px;
        text-align: right;
        line-height: 40px;
      }
    }
    .content-duty {
      font-size: 14px;
      color: #016985;
      width: 100%;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /*重点，不能用block等其他*/
      -webkit-line-clamp: 2; /*重点IE和火狐不支持*/
      -webkit-box-orient: vertical; /*重点*/
    }
    .content-user {
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      .content-user-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 30px;
          height: auto;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>