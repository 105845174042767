<template>
  <div class="home-case-container">
    <div class="case-content" id="case-content">
      <el-row>
        <el-col :span="6" :offset="12">
          <div>
            <img v-lazy="require('@/assets/img/train/case_wzry.jpg')" />
            <div class="case-content-hover">
              <p>王者荣耀</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="case-enlist">
        <div class="case-title">
          <p>代表</p>
          <p>作品</p>
          <p></p>
        </div>
        <el-col :span="6" :offset="6">
          <div>
            <img v-lazy="require('@/assets/img/train/case_hgwz.jpg')" />
            <div class="case-content-hover">
              <p>哈利波特魔法学院</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" :offset="6">
          <div>
            <img v-lazy="require('@/assets/img/train/case_hpjy.jpg')" />
            <div class="case-content-hover">
              <p>和平精英</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="case-enlist">
        <el-col :span="6">
          <div>
            <img v-lazy="require('@/assets/img/train/case_yys.jpg')" />
            <div class="case-content-hover">
              <p>阴阳师</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6" :offset="6">
          <div>
            <img v-lazy="require('@/assets/img/train/case_paj.jpg')" />
            <div class="case-content-hover">
              <p>决战平安京</p>
            </div>
          </div>
        </el-col>
        <p @click="eventToPage(12)">
          <span>立即报名<i class="el-icon-right"></i></span>
        </p>
      </el-row>
      <el-row>
        <el-col :span="6" :offset="6">
          <div>
            <img v-lazy="require('@/assets/img/train/case_dwrg.jpg')" />
            <div class="case-content-hover">
              <p>第五人格</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseBox: undefined,
    };
  },
  mounted() {},
  methods: {
    eventToPage(n) {
      this.$emit("eventToPage", n);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-case-container {
  width: 100%;
  height: 100%;
  .case-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 23%;
    width: 54%;
    /deep/.el-col {
      position: relative;
      .case-content-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #171820;
        opacity: 0;
        color: #ffffff;
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        padding: 20px;
        box-sizing: border-box;
        transition: all 0.6s;
        // &.fadeIn {
        //     opacity: 0.9;
        //     transition: all 0.6s;
        // }
        // &.fadeOut {
        //     opacity: 0;
        //     transition: all 0.6s;
        // }
        &:hover {
          opacity: 0.9;
        }
      }
    }
    img {
      width: 100%;
      height: auto;
    }
    > span {
      width: 100%;
      height: auto;
    }
    .case-enlist {
      position: relative;
      > p {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        height: 60%;
        width: 20%;
        background-color: rgba(40, 45, 60, 0.9);
        color: #8fb1bd;
        padding-left: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        > span {
          display: flex;
          align-items: center;
        }
        .el-icon-right {
          font-size: 20px;
          margin-left: 0;
          margin-top: 3px;
          //动画的名称(必须要有)
          animation-name: move;
          //动画的运动曲线(linear是匀速stept()是分步)
          animation-timing-function: linear;
          //动画的运动时间
          animation-duration: 2s;
          //动画的运动次数(infinite是无限循环)
          animation-iteration-count: infinite;
          //动画是否逆序播放
          animation-direction: alternate;
        }
        @keyframes move {
          0% {
            margin-left: 0;
          }

          25% {
            margin-left: 5px;
          }

          50% {
            margin-left: 10px;
          }

          75% {
            margin-left: 5px;
          }

          100% {
            margin-left: 0;
          }
        }
      }
      > .case-title {
        font-size: 80px;
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        bottom: 20px;
        left: 0;
        p {
          display: inline;
        }
      }
    }
  }
}
</style>