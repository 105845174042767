<template>
    <div>
        <div class="recruitment-container">
            <div class="invite-content">
				<div class="back_btn" @click="handleBack">返回</div>
                <div class="invite-footer">
                    <div class="firm-icons" v-if="recruitDetail.sysUser">
                        <span class="publisher">发&nbsp;布 方：</span>
                        <img class="imgs" :src="recruitDetail.sysUser.avatar" >
                        <div class="firm-content">
                            <div class="firm-title">
                                {{recruitDetail.sysUser.nickName}}
                            </div>
                            <div class="firm-tags">
                                <!-- 互联网 -->
                            </div>
                        </div>
                    </div>
                    <div class="invite-times">发布时间：{{ recruitDetail.createTime | dateformat("yyyy-MM-DD HH:mm") }}</div>
                </div>
                <div class="invite-header">
                    <div class="invite-tag">
                        <span style="color: #fff;font-size: 22px">职&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：</span>
                        <div class="tag-title">{{recruitDetail.position}}</div>
                        <div class="urgent" v-if="recruitDetail.urgentFlag!==0">急</div>
                        <div class="hoticon" v-if="recruitDetail.hotFlag!==0">
                            <img src="@/assets/img/bbs/file.png" alt="">
                            <div class="lines-hot"></div>
                        </div>
                        <!-- <div class="note">
                            {{recruitDetail.employCity}} | 经验{{workExperiences[recruitDetail.workExp]}} | {{recruitDetail.eduBg}}
                        </div> -->
                    </div>
                    <!-- <div class="salary"><span style="color: #fff;font-size: 22px">薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资：</span>{{recruitDetail.emolument}}</div> -->
                </div>
                <div class="salary">
                    <span class="salary-title">薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资：</span>
                    <span class="salary-num">{{recruitDetail.emolument}}</span>
                </div>
                <div class="employCity">
                    <span class="employCity-title">地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点：</span>
                    <span class="employCity-content">{{recruitDetail.employCity}}</span>
                </div>
                <div class="experience">
                    <span class="experience-title">经验要求：</span>
                    <span class="experience-content">{{workExperiences[recruitDetail.workExp]}}</span>
                </div>
                <div class="eduBg">
                    <span class="eduBg-title">学历要求：</span>
                    <span class="eduBg-content">{{recruitDetail.eduBg}}</span>
                </div>
                <div class="post-duty">
                    <div class="duty-title">职位职责：</div>
                    <div class="duty-content-hidden" v-if="!showDuty" @click="handleClick" title="点击可展开" v-html="recruitDetail.postIntro"></div>
                    <div class="duty-content" v-if="showDuty" @click="handleClick" title="点击可收起" v-html="recruitDetail.postIntro"></div>
                    <!-- <div class="duty-content-hidden" v-if="!showDuty" @click="handleClick" title="点击可展开"> {{ recruitDetail.postIntro ||''}}</div>
                    <div class="duty-content" v-if="showDuty" @click="handleClick" title="点击可收起"> {{ recruitDetail.postIntro ||''}}</div> -->
                </div>
                <div class="invite-duty">
                    <div class="duty-title">福利关键字：</div>
                    <div class="duty-content" v-for="(key, indexs) in recruitDetail.postWelFares" :key="indexs">{{key}}</div>
                </div>
                <div class="profession">{{recruitDetail.postType}}</div>
                <div class="btn">
                	<button class="btn1" @click="open = true">咨询详情</button>
					<button class="btn2" style="margin-left: 200px;"><a href="mailto:glsxhr@glsxvfx.com">投递简历</a></button>
                </div>
                <!-- <div class="lines"></div> -->
            </div>
        </div>
		<el-dialog :visible.sync="open" style="margin-top: 100px;" width="400px" append-to-body :close-on-click-modal="false">
			<p style="color: #00ffff;text-align: center;line-height: 20px;height: 30px;cursor: default;">扫码添加人事QQ咨询详情</p>
			<img width="150px" src="../../assets/img/xiaomin.png" alt="">
			<img width="150px" style="float: right;" src="../../assets/img/xiaojie.png" alt="" srcset="">
		</el-dialog>
    </div>
    
</template>

<script>
    import {getRecruitDetail} from '@/api/company.js';
    export default {
        data(){
            return{
                recruitDetail: '',
                workExperiences: [
                    "一年以内","1~3年","3~5年","5~10年","10年以上"
                ],
                showDuty: false,
				open:false,
            }
        },
        created(){
        },
        methods:{
            async getRecruit(id){
                let {code,data} = await getRecruitDetail(id);
                if(code === 200){
                    this.recruitDetail = data;
                    this.$store.state.showNav = true;
                    this.$parent.activeIndex = 1;
                    this.$parent.companyIndex = 3;
                }
            },
            handleBack(){
                this.$router.push(`/home/recruitment?activeIndex=1&companyIndex=3`);
            },
            handleClick(){
                this.showDuty = !this.showDuty;
            }
        },
        mounted() {
            let id = this.$route.query.id
            if(id != null && id != undefined){
                this.getRecruit(id)
            }
        },
    }
</script>

<style lang="scss" scoped>
.btn{
	width: 400px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	button{
		cursor: pointer;
		width: 100px;
		height: 30px;
		background: linear-gradient(to right,#00c8c8,#669999);
		color: #ffffff;
		border: none;
		border-radius: 15px;
		transition: 0.2s all ease;
		&:hover{
			transform: scale(1.05);
			box-shadow: 2px 2px 2px #2D81D7;
		}
	}
	.btn2{
		a{
			color: #ffffff;
		}
	}
}

.recruitment-container{
    width:1100px;
    margin: 70px auto;
}
.back_btn{
    width: 50px;
    height: 25px;
    color: #2D2E2C;
    background: #00a5a5;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px 10px;
    cursor: pointer;
    &:hover{
        color: #000000;
        background: #00bdbd;
    }
}
.invite-content{
    width: 800px;
    min-height:280px;
    border-radius: 10px;
    // background: #2D2E2C;
    background: #0B1328;
    box-shadow: 0px 0px 5px 1px #4144AB;
    margin: 0 auto;
    padding: 10px 100px;
    &:nth-child(2n){
        margin-right:0px;
    }
    &:nth-child(1){
        margin-top:0px;
    }
    &:nth-child(2){
        margin-top:0px;
    }
    .invite-header{
        height:35px;
        display: flex;
        justify-content: space-between;
        margin-top:34px;
        .invite-tag{
            display: flex;
            align-items: center;
            .urgent{
                width: 30px;
                height: 30px;
                background: rgba(255, 85, 66, 0.36);
                border-radius: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                text-align: center;
                color: #FF5542;
                text-shadow: 0px 1px 1px rgba(28, 3, 0, 0.25);
                opacity: 1;
                margin-left: 20px;
            }
            .hoticon{
                height:18px;
                margin-left:10px;
                margin-right:10px;
                img{
                    width:15px;
                    height:16px;
                    object-fit: cover;
                }
                .lines-hot{
                    width: 15px;
                    height: 1px;
                    background: #EC4D36;
                    opacity: 1;
                }
            }
            .tag-title{
                font-size: 21px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 35px;
                color: #e0dcdc;
                opacity: 1;
                margin-left: 25px;
            }
            .note{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 300;
                line-height: 0px;
                color: #999999;
                opacity: 1;
                margin-left:20px;
            }
        }
        // .salary{
        //     font-size: 22px;
        //     font-family: Microsoft YaHei;
        //     font-weight: 400;
        //     line-height: 35px;
        //     color: #F6A812;
        //     text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
        //     opacity: 1;
        // }
    }
    .salary{
        margin-top: 25px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 35px;
        .salary-title{
            font-size: 22px;
            color: #FFFFFF;
        }
        .salary-num{
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #F6A812;
            margin-left: 25px
        }
    }
    .employCity{
        margin-top: 25px;
        .employCity-title{
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
        }
        .employCity-content{
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e0dcdc;
            margin-left: 25px
        }
    }
    .experience{
        margin-top: 25px;
        .experience-title{
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
        }
        .experience-content{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e0dcdc;
            margin-left: 25px
        }
    }
    .eduBg{
        margin-top: 25px;
        .eduBg-title{
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
        }
        .eduBg-content{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e0dcdc;
            margin-left: 25px
        }
    }
    .post-duty{
        display: flex;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        margin-top: 25px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);

        .duty-title{
            color: #FFFFFF;
            width: 124px; 
            font-size: 21px
        }
        .duty-content-hidden{
            width: 670px !important;
            font-size: 19px;
            color: #e0dcdc;

            /*文本超出隐藏，并显示省略号，超出的空白区域不换行*/
            overflow: hidden; 
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6; 
            cursor: pointer;
            &:hover{
                color: #2D81D7;
            }
        }
        .duty-content{
            width: 670px !important;
            font-size: 19px;
            color: #e0dcdc;
            cursor: pointer;
            &:hover{
                color: #2D81D7;
            }
        }
    }

    .invite-duty{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #FFFFFF;
        // opacity: 0.7;
        margin-top:21px;
        min-height: 40px;
        .duty-content{
            // border: 2px solid #5ED5C8;
            background-image: url('../../assets/newImg/27.png');
            background-size: 100% 100%;
            // border-radius: 20px;
            padding: 2px 10px;
            color: #ffffff;
            margin: 5px 5px;
            opacity: 0.85;
        }
        .duty-title{
            color: #FFFFFF !important;
            width: 124px; 
            font-size: 19px;
        }
    }

    .profession{
        height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #FFFFFF;
        opacity: 0.35;
        margin-top:10px;
    }
    .lines{
        height: 1px;
        background: #FFFFFF;
        opacity: 0.25;
        border-radius: 0px;
        margin-top:20px;
    }
    .invite-footer{
        width: 100%;
        overflow: hidden;
        display: flex;
        margin-top:20px;
        justify-content: space-between;
        .firm-icons{
            display: flex;
            align-items: center;
            .publisher{
                margin-top: -25px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 29px;
                color: #FFFFFF;
            }
            .imgs{
                width:55px;
                height:55px;
                border-radius: 5px;
                object-fit: cover;
                margin-left: 35px;
            }
            .firm-content{
                margin-left:13px;
                margin-top:2px;
                .firm-title{
                    height:29px;
                    max-width:90px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 29px;
                    color: #FFFFFF;
                    overflow: hidden; /*文本超出隐藏*/
                    text-overflow: ellipsis; /*文本超出显示省略号*/
                    white-space: nowrap;
                }
                .firm-tags{
                    max-width:90px;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 24px;
                    color: rgba(255, 255, 255, 0.35);
                    overflow: hidden; /*文本超出隐藏*/
                    text-overflow: ellipsis; /*文本超出显示省略号*/
                    white-space: nowrap;
                }
            }
        }
        .invite-times{
            height: 20px;
            font-weight: 400;
            font-size: 18px;
            color: #cac7c7;
            // color: rgba(255, 255, 255, 0.35);
            margin-top: 10px;
        }
        
    }
}
</style>