<template>
  <div class="item-container">
    <div class="item-title">
      <slot name="icon"></slot>
      {{title}}
    </div>
    <div class="item-content">
      <div class="content-nothing" v-if="!commentList.length">
        {{nothingText}}
      </div>
      <template v-else>
        <replay-message
          :hotComment="item"
          :workDetailList="workDetailList"
          @handleAddComment="addComment"
          @getCommentNums="getCommentNums"
          @getUserPersonal="getUserPersonal"
          style="padding-bottom: 0"
          v-for="item in commentList"
          :key="item.id"
        ></replay-message>
      </template>
    </div>
  </div>
</template>

<script>
import replayMessage from "@/views/home/components/replayMessage.vue";
import { getLiveLike, calcelLiveLike} from "@/api/work.js";
export default {
  data() {
    return {
      
    }
  },
  props: {
    commentList: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      require: true
    },
    nothingText: {
      type: String,
      default: '暂无内容'
    },
    workDetailList: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    replayMessage
  },
  methods: {
    addComment(event) {
      this.$emit('handleAddComment', event);
    },
    getCommentNums(ket) {
      this.$emit('handleLiveLike', ket);
    },
    // 评论点赞
    async getCommentNums2(ket) {
      let data = {
        userId: this.opusUserId,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      let calcelData = {
        userId: this.$store.state.userInfo.id,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      if (ket.likeFlag == 0) {
        let { code, msg } = await getLiveLike(data);
        if (code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "点赞成功",
          });
          //this.getCommentList();
        }
      } else {
        this.calcelLikeNum(calcelData);
      }
    },
    async calcelLikeNum(data) {
      data.del = 1;
      let { code } = await calcelLiveLike(data);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "取消点赞成功",
        });
        if (data.modelType == 1) {
          //this.getWorkDetail(this.$route.query.workDetailId);
        } else {
          //this.getCommentList();
        }
      }
    },
    getUserPersonal() {

    }
  }
}
</script>

<style lang="scss" scoped>
  .item-container{
    .item-title{
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      margin-top: 20px;
      color: #ffffff;
      font-family: biaoti;
      transform: scaleY(1.2);
      letter-spacing: 2px;
      align-items: center;
      // background-color: #3e5a99;
      background: linear-gradient(to right, #3e5a99, transparent 60%);
    }
    .item-content{
      .content-nothing{
        color: #999999;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
      }
        
    }
  }
</style>