<template>
  <menu-iframe :menuObject="menuObject">
    <component :is="activeCompany" slot="rightContent"></component>
  </menu-iframe>
</template>

<script>
import menuIframe from "../components/menu.vue";
import list from "./list/list.vue"
export default {
  data() {
    return {
      menuObject: {
        defaultActive: "0",
        list: [
          {
            title: "任务列表",
            icon: "el-icon-s-unfold",
            index: "0",
          },
        ],
      },
      activeCompany: "list"
    }
  },
  components: {
    menuIframe,
    list
  }
}
</script>

<style lang="scss" scoped>

</style>