<template>
  <div class="detail-container">
    <div class="detail-info-bg">
      <p>
        <span v-for="item in 5" :key="item"
          ><img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/crile.png"
        /></span>
      </p>
    </div>
    <div class="detail-info-time-item">发布时间：{{ jobInfo.createTime }}</div>
    <div class="detail-info-title-item">
      <p class="item-price">{{ jobInfo.emolUment }}</p>
      <p class="item-title">{{ jobInfo.position }}</p>
    </div>
    <div class="detail-type detail-item">
      <p class="detail-type-title">
        <img
          src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/email.png"
        />
        投递方式
      </p>
      <p class="detail-type-mail">投递邮箱：{{ jobInfo.email }}</p>
    </div>
    <div class="detail-condition detail-item">
      <div class="detail-condition-title">
        <div class="title-container">
          <p class="title-item">
            <i class="el-icon-location-outline"></i>
            {{ jobInfo.employCity }}
          </p>
          <p class="title-item">
            <i class="el-icon-document"></i>
            {{ jobInfo.workExp }}
          </p>
          <p class="title-item">
            <i class="el-icon-reading"></i>
            {{ jobInfo.eduBg }}
          </p>
        </div>
      </div>
      <div class="detail-condition-address">
        <p>{{ jobInfo.company }}</p>
        <p>公司地址：{{ jobInfo.address }}</p>
      </div>
    </div>
    <div class="detail-ask detail-item">
      <div class="detail-type-title">
        <p>
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/ask.png"
          />
          岗位要求
        </p>
      </div>
      <div
        class="detail-ask-content"
        v-html="
          escapeStringHTML(
            jobInfo.postIntro ? JSON.parse(jobInfo.postIntro) : ''
          )
        "
      ></div>
    </div>
    <div class="detail-welfare detail-item">
      <div class="detail-type-title">
        <p>
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/welfare.png"
          />
          福利待遇
        </p>
      </div>
      <div class="detail-welfare-content">
        <span v-for="(item, index) in postWelfareArr" :key="index">{{
          item
        }}</span>
      </div>
    </div>
    <div class="detail-comment detail-item">
      <comment
        :commentQuery="{ modelId: jobInfo.id, flag: 2 }"
        :userInfo="{
          id: jobInfo.rid,
          nickName: jobInfo.authorName,
          headImg: jobInfo.authorHead,
          fouseFlag: jobInfo.fouseFlag,
          collectFlag: jobInfo.collectFlag,
          focus: jobInfo.focus,
        }"
        ref="child"
      >
      </comment>
    </div>
  </div>
</template>

<script>
import { getJobQueryById } from "@/api/company.js";
import comment from "./components/comment/comment";
export default {
  data() {
    return {
      jobInfo: {},
      postWelfareArr: [],
      isShowEnvironment: false,
    };
  },
  computed: {
    showEnvironmentList() {
      return this.jobInfo.environmentList.slice(0, 3);
    },
  },
  components: {
    comment,
  },
  created() {
    let jobId = this.$route.query.id;
    this.getJobQueryById(jobId);
    //this.stopMove();
  },
  methods: {
    getJobQueryById(id) {
      getJobQueryById({ id: id }).then((res) => {
        this.jobInfo = res.data.Info;
        this.jobInfo.fouseFlag = res.data.fouseFlag;
        this.jobInfo.collectFlag = res.data.collectFlag;
        this.postWelfareArr = this.jobInfo.postWelfare
          ? this.jobInfo.postWelfare.split(",")
          : [];
        this.$refs.child.getCommentList({ modelId: this.jobInfo.id, flag: 2 });
      });
    },
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    eventToMore() {
      this.isShowEnvironment = true;
      this.stopMove();
    },
    eventCloseEnvironment() {
      this.isShowEnvironment = false;
      this.move();
    },
    //停止页面滚动
    stopMove() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, { passive: false }); //禁止页面滑动
    },
    //开启页面滚动
    move() {
      let m = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", m, { passive: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  width: 100vw;
  min-width: 1920px;
  height: calc(100vh - 60px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
  background-size: 100% 100%;
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  &::before {
    // content: "";
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 522px;
    // height: 496px;
    // background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/five.png");
    // background-repeat: no-repeat;
  }
  .detail-info-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 522px;
    height: 496px;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/five.png");
    background-repeat: no-repeat;
    >p{
      width: 100%;
      height: 100%;
      position: relative;
      >span{
        position: absolute;
        &:nth-child(1) {
          left: 76px;
          top: -25px;
        }
        &:nth-child(2) {
          right: 76px;
          top: -25px;
        }
        &:nth-child(3) {
          right: -20px;
          top: 280px;
        }
        &:nth-child(4) {
          right: 50%;
          bottom: -25px;
          transform: translateX(50%);
        }
        &:nth-child(5) {
          left: -20px;
          top: 280px;
        }
      }
    }
  }
  .detail-info-time-item {
    width: 522px;
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    text-align: center;
    letter-spacing: 2px;
  }
  .detail-info-title-item {
    width: 522px;
    height: 496px;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: "biaoti";
    letter-spacing: 5px;
    font-size: 48px;
    .item-price {
      color: #ffe325;
    }
    .item-title {
      color: #ffffff;
      padding: 0 50px;
      box-sizing: border-box;
      line-height: 80px;
      margin-top: 20px;
    }
  }
  .detail-item {
    min-height: 100px;
    border-right: 1px solid;
    border-left: 1px solid;
    color: #ffffff;
    /*设置线性渐变*/
    border-image: linear-gradient(
        180deg,
        rgba(144, 145, 147, 1) 0%,
        rgba(144, 145, 147, 0) 100%
      )
      2 2 2 2;
    border-top: 2px solid rgba(144, 145, 147, 1);
    border-radius: 20px;
  }
  .detail-type {
    position: absolute;
    top: 10%;
    left: 18%;
    width: 300px;
    padding: 20px;
    text-align: right;
    .detail-type-mail {
      text-align: left;
      margin-top: 20px;
      letter-spacing: 2px;
    }
  }
  .detail-type-title {
    background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
    color: #ffffff;
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
    font-family: "biaoti";
    font-size: 24px;
    display: inline-block;
    > img {
      margin-right: 10px;
    }
    > p {
      display: flex;
      align-items: center;
      > img {
        margin-right: 10px;
      }
    }
  }
  .detail-condition {
    position: absolute;
    top: 10%;
    right: 12%;
    color: #ffffff;
    padding: 20px;
    width: 400px;
    .detail-condition-title {
      display: inline-block;
      background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
      border-radius: 20px;
      .title-container {
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
      }
      .title-item {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .detail-condition-address {
      margin-top: 10px;
      > p {
        line-height: 40px;
      }
    }
  }
  .detail-ask {
    position: absolute;
    top: 40%;
    right: 5%;
    color: #ffffff;
    padding: 20px;
    width: 500px;

    .detail-ask-content {
      margin-top: 10px;
      max-height: 300px;
      overflow-y: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .detail-welfare {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    padding: 20px;
    width: 400px;
    text-align: center;

    .detail-welfare-content {
      margin-top: 20px;
      > span {
        padding: 5px;
      }
    }
  }
  .detail-comment {
    // position: absolute;
    // top: 40%;
    // left: 2%;
    // color: #ffffff;
    // padding: 20px;
    // width: 550px;

    position: absolute;
    top: 40%;
    left: 15%;
    color: #ffffff;
    padding: 20px;
    width: 320px;
    height: 200px;
  }
}
</style>