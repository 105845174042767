<template>
  <div class="recruit-container">
    <div class="recruit-top-number">共发布了{{ total || 0 }}条招聘信息</div>
    <router-link
      v-for="item in jobList"
      :key="item.id"
      tag="a"
      target="_blank"
      :to="`/recruitDetail_?activeIndex=4&id=` + item.id"
    >
      <r-item :recruitInfo="item"></r-item>
    </router-link>
    <div class="paginations" v-if="total">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageNum"
        :page-size="queryForm.pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import rItem from "../../recruit/components/item.vue";
import { getJobList } from "@/api/company.js";
export default {
  data() {
    return {
      jobList: [],
      total: 0,
      userId: undefined,
      // 筛选条件
      queryForm: {
        pageNum: 1,
        pageSize: 5,
      },
    };
  },
  components: {
    rItem,
  },
  created() {
    this.userId = this.$parent.userInfo.id;
    // if(userId){
    //     this.getUserInfo(userId);
    // }else{
    //     this.userInfo = this.$parent.userInfo;
    // }
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      getJobList({ ...this.queryForm, rid: this.userId }).then((res) => {
        if (res.code === 200) {
          this.total = res.total;
          this.jobList = res.rows;
        }
      });
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit-container {
  width: 100%;
  .recruit-top-number {
    margin-top: 50px;
    height: 30px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 31px;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
  }
}
</style>