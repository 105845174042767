<template>
  <div class="index-page-container index-consulting-container">
    <div class="index-page-content index-consulting-content">
      <div class="consulting-title">
        <div class="consulting-title-left">报名方式</div>
        <!-- <el-button class="consulting-title-right">留言咨询</el-button> -->
      </div>
      <div class="consulting-box">
        <div class="consulting-item">
          <ul>
            <li>
              1.咨询QQ：
              <p>2032686515</p>
            </li>
            <li>
              2.报名电话：
              <p>19120680543</p>
            </li>
            <li>
              3.报名地址：
              <p>
                <el-link
                  target="_blank"
                  href="https://map.baidu.com/search/%E5%B9%BF%E5%B7%9E%E5%B8%82%E9%BB%84%E6%B5%A6%E5%8C%BA%E5%85%89%E8%B0%B1%E4%B8%AD%E8%B7%AF11%E5%8F%B7%E4%BA%91%E5%8D%87%E7%A7%91%E5%AD%A6%E5%9B%ADd%E6%A0%8B16%E6%A5%BC/@12629447.150402185,2635205.78614,19z?querytype=s&da_src=shareurl&wd=%E5%B9%BF%E5%B7%9E%E5%B8%82%E9%BB%84%E6%B5%A6%E5%8C%BA%E5%85%89%E8%B0%B1%E4%B8%AD%E8%B7%AF11%E5%8F%B7%E4%BA%91%E5%8D%87%E7%A7%91%E5%AD%A6%E5%9B%ADD%E6%A0%8B16%E6%A5%BC&c=257&src=0&pn=0&sug=0&l=18&b=(12628718.415413685,2634907.560138462;12630076.060433563,2635570.1191924335)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1"
                >
                  广州市黄埔区光谱中路11号云升科学园D栋403
                </el-link>
              </p>
            </li>
          </ul>
        </div>
        <div class="consulting-item consulting-item-right">
          <ul>
            <li>怪力屋不一样</li>
            <li>网易、腾讯、字节等名师亲临指导</li>
            <li>百余款一线大厂优秀项目经验</li>
            <li>近百名高级特效师、200余人专业特效团队</li>
            <li>学技术、练项目、卷高薪</li>
            <li>更多大厂技术，等你来学</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.index-consulting-container {
  .index-consulting-content {
    .consulting-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 1158px;
      .consulting-title-left {
        font-size: 48px;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          width: 80px;
          height: 5px;
          background-image: linear-gradient(to right, #d73860, #6c85e5);
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
      .consulting-title-right {
        height: 48px;
        border-radius: 10px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        color: #ffffff;
        border: none;
        font-size: 18px;
      }
    }
    .consulting-box {
      margin-top: 120px;
      display: flex;
      justify-content: space-between;
      .consulting-item {
        background-color: rgba(40, 45, 60, 0.9);
        border: 1px solid #647a84;
        padding: 20px 30px;
        color: #809faa;
        width: 500px;
        > ul {
          font-size: 18px;
          > li {
            display: flex;
            line-height: 30px;
            border-bottom: 1px solid rgba($color: #647a84, $alpha: 0.4);
            padding: 20px 0;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            /deep/ .el-link--inner {
              color: #809faa;
              font-size: 18px;
            }
            /deep/ .el-link--inner:hover {
              color: #409eff;
            }
          }
        }
      }
      .consulting-item.consulting-item-right {
        width: 350px;
        > ul {
          > li {
            padding: 5px 0;
            &:nth-child(1) {
              font-size: 24px;
              border-bottom: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>