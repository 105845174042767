<template>
  <div class="login-container">
    <template v-if="isBindPhone">
      <div
      :class="['login-content', isBindPhone && 'login-phone']"
      v-if="!isShowWxLogin"
    >
      <div class="login-title">
        {{ isBindPhone ? "绑定手机号" : "登录" }}<span>Sign in</span>
      </div>
      <div class="login-number">
        <el-input
          v-model="number"
          ref="number"
          @keydown.enter.native="getCodes"
          placeholder="输入手机号"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          maxlength="11"
        ></el-input>
      </div>
      <div class="login-numbers">
        <div class="code">
          <el-input
            ref="code"
            v-model="password"
            placeholder="验证码"
            @keydown.enter.native="getLogin"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            maxlength="6"
          ></el-input>
        </div>
        <div class="yanzheng" @click="getCodes">{{ codeBtnText }}</div>
      </div>
      <div class="span-class">我已阅读并接受《怪力屋会员号注册协议》条款</div>
      <div class="login-btn" @click="getLogin">
        {{ isBindPhone ? "提交" : "登录" }}
      </div>
      <div class="span-class" v-if="!isBindPhone">
        未注册手机验证后自动注册并登录
      </div>

      <div
        class="wechat-login"
        @click="isShowWxLogin = true"
        v-if="!isBindPhone"
      >
        微信登录
      </div>
    </div>
    <!-- 微信登录 -->
    <div class="login-content login-wechat-qr" v-else>
      <wx-login
        appid="wx7d33275501e355ab"
        :scope="'snsapi_login'"
        :theme="'white'"
        state="123"
        :redirect_uri="encodeURIComponent('http://www.glsxvfx.com/login')"
        rel="external nofollow"
      >
      </wx-login>
      <div class="wechat-login" @click="isShowWxLogin = false">手机号登录</div>
    </div>
    </template>
    <!-- 手机登录 -->
    <template v-else>
      <new-login></new-login>
    </template>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import wxLogin from "vue-wxlogin";
import newLogin from "./components/login"
import { getPhoneAuthCode, codeLogin, wechatLogin } from "@/api/login.js";
import { Store } from "vuex";
export default {
  data() {
    return {
      // isshow:true,
      number: "",
      password: "",
      // 手机号正则
      phoneReg: /^(13|14|15|16|17|18|19)[0-9]{9}$/,
      // 倒计时
      codeTime: 60,
      // 防止频繁触发
      downBool: true,
      // 控制倒计时
      downNumber: 60,
      // 获取验证码按钮文本(倒计时)
      codeBtnText: "获取验证码",
      // 是否显示微信登录二维码
      isShowWxLogin: false,
      // 是否显示绑定电话号码
      isBindPhone: false,
      // 绑定手机号时
      phoneWxId: undefined,
    };
  },
  created() {
    if (this.$route.query.wxId) {
      this.isBindPhone = true;
      this.phoneWxId = this.$route.query.wxId;
    }
  },
  mounted() {
    this.$refs.number.focus();
  },
  components: {
    Vcode,
    wxLogin,
    newLogin
  },
  methods: {
    // 点击微信登录
    // getWeChatLogin(params) {
    // 	wechatLogin(params).then(res => {
    // 		if (res.code === 200) {
    // 			if(!res.data.hasOwnProperty('flag')) {
    // 				this.$message.success("登录成功, 即将跳转至论坛");
    // 				if (res.data.userInfo) {
    // 					this.$cookie.set('accessToken', res.data.token, {
    // 						expires: 30
    // 					});
    // 					this.$cookie.set('userInfo', res.data.userInfo, {
    // 						expires: 30
    // 					});
    // 					this.$store.commit('setUserInfo', JSON.parse(this.$cookie.get('userInfo')))
    // 					this.$store.commit('setisShowLogin', true);
    // 					setTimeout(() => {
    // 						this.$router.push('/home/newest?activeIndex=0&latest=0');
    // 					}, 500)
    // 				}
    // 			} else {
    // 				if (!res.data.flag) {
    // 					this.isBindPhone = true;
    // 					this.phoneWxId = res.data.wxId;
    // 				}
    // 			}
    // 		}
    // 	})
    // },
    verifyPhone(e) {
      if (!this.phoneReg.test(e)) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入正确的手机号码!",
        });
      }
    },
    // 验证码倒计时
    intervalDwon() {
      this.interval = setInterval(() => {
        this.codeTime = this.codeTime - 1;
        this.codeBtnText = this.codeTime + "s重新发送";
        if (this.codeTime == 0) {
          this.codeTime = this.downNumber;
          this.codeBtnText = "获取验证码";
          this.downBool = true;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    getCodes() {
      if (!this.number) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入手机号码!",
        });
        return;
      }
      if (!this.phoneReg.test(this.number)) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入正确的手机号码!",
        });
        return;
      }
      if (this.phoneReg.test(this.number)) {
        if (parseInt(this.codeTime) == this.downNumber && this.downBool) {
          this.downBool = false;
          let data = {
            account: this.number,
            type: 1,
          };
          getPhoneAuthCode(data)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  offset: 80,
                  message: res.msg,
                });
                this.intervalDwon();
                this.$refs.code.focus();
              } else {
                this.downBool = true;
              }
            })
            .catch((err) => {
              this.downBool = true;
            });
        }
      } else {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入手机号码!",
        });
      }
    },
    getLogin() {
      if (!this.number) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入手机号码!",
        });
        return;
      }
      if (!this.phoneReg.test(this.number)) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入正确的手机号码!",
        });
        return;
      }
      if (!this.password) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入验证码!",
        });
        return;
      }
      if (this.password.length < 6) {
        this.$message({
          type: "error",
          offset: 80,
          message: "请输入正确的验证码!",
        });
        return;
      }
      let data = {
        account: this.number,
        verifyCode: this.password,
        wxId: this.phoneWxId,
      };
      codeLogin(data).then((res) => {
        if (res.code == 200) {
          if (res.data.message) {
            this.$message({
              type: "success",
              offset: 80,
              message: "首次登录赠送100经验值!",
            });
          }
          this.$message.success("登录成功, 即将跳转至论坛");
          if (res.data.userInfo) {
            this.$cookie.set("accessToken", res.data.token, {
              expires: 30,
            });
            this.$cookie.set("userInfo", res.data.userInfo, {
              expires: 30,
            });
            this.$store.commit(
              "setUserInfo",
              JSON.parse(this.$cookie.get("userInfo"))
            );
            this.$store.commit("setisShowLogin", true);
            setTimeout(() => {
              this.$router.push("/home/newest?activeIndex=0&latest=0");
              window.location.reload();
            }, 2000);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: calc(100vh - 60px);
  overflow: hidden;
  background: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/login-bg.png");
  background-size: 100% 100%;
  .login-content {
    width: 350px;
    height: 450px;
    // 如果加上qq和微信的话，高度是560px；
    background: #333a4b;
    // background: #252525;
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-title {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      letter-spacing: 5px;
      color: rgba(255, 255, 255, 1);
      font-family: biaoti;
      transform: scaleY(1.1);
      span {
        font-size: 12px;
        margin-left: 10px;
        line-height: 36px;
        letter-spacing: 1px;
        transform: scaleY(0.9);
        font-family: Microsoft YaHei;
        color: #4a556d;
      }
    }

    .login-number {
      width: 300px;
      height: 35px;
      opacity: 1;
      border-radius: 2px;
      margin: 0 auto;

      /deep/ .el-input__inner {
        height: 35px;
        background: #2a3141;
        // background: #2D2E2C;
        border: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: rgba(255, 255, 255, 0.8);
        &::placeholder {
          letter-spacing: 3px;
          font-size: 14px;
        }
      }

      &:first-child {
        margin-top: 38px;
      }

      &:nth-of-type(n + 2) {
        margin-top: 20px;
      }
    }

    .login-numbers {
      width: 300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .code {
        width: 165px;
        height: 47px;

        /deep/ .el-input__inner {
          height: 37px;
          background: #2a3141;
          // background: #2D2E2C;
          border: none;
          border-radius: 0px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0px;
          color: rgba(255, 255, 255, 0.8);
          &::placeholder {
            letter-spacing: 3px;
            font-size: 14px;
          }
        }
      }

      .yanzheng {
        width: 135px;
        height: 37px;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        // color: rgba(143, 210, 98,1);
        // border:1px rgba(143, 210, 98,1) solid;
        cursor: pointer;
        background: #333a4b;
      }
    }

    .login-btn {
      width: 300px;
      height: 35px;
      background: #35e0ff;
      // background: #9EEA6A;
      opacity: 1;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 20px;
      text-indent: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 35px;
      text-align: center;
      color: #ffffff;
      // color: #333333;
      opacity: 1;
      margin: 0 auto;
      margin-top: 26px;
      cursor: pointer;
    }

    .span-class {
      text-align: center;
      color: #d6d6d6;
      font-size: 12px;
      letter-spacing: 1px;
      margin-top: 25px;
    }

    .login-way {
      width: 120px;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .ways {
        width: 45px;
        height: 45px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .wechat-login {
      width: 120px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      border: 2px solid #35e0ff;
      color: #ffffff;
      text-align: center;
      margin-top: 40px;
      transition: all 0.3s;
    }
    .wechat-login:hover {
      background-color: #35e0ff;
      transform: scale(1.1);
    }
  }

  .login-wechat-qr {
    height: 520px;
    padding-top: 20px;
  }

  .login-phone {
    height: 320px;
  }
}
</style>
