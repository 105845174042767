<template>
  <div class="publish-container">
    <div class="publish-content">
      <el-form
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        label-width="120px"
      >
        <el-form-item
          label="任务类型：（可选择如下）"
          prop="cid"
          class="form-type-label"
          required
        >
          <br />
          <el-radio-group v-model="dataForm.cid" class="form-type-item">
            <el-row :gutter="20">
              <el-col :span="8" v-for="item in typeList" :key="item.value">
                <el-radio-button :label="item.value">
                  <div class="type-item-content">
                    <img
                      :src="
                        dataForm.cid === item.value
                          ? item.activeIcon
                          : item.icon
                      "
                    />
                    <p>{{ item.label }}</p>
                  </div>
                </el-radio-button>
              </el-col>
            </el-row>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="任务封面" prop="image" required>
          <el-upload
            :action="uploadUrl"
            :show-file-list="false"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg"
            :limit="1"
            :on-success="
              (response) => {
                return handleUploadSuccess(response, 'image');
              }
            "
          >
            <img
              v-if="dataForm.image"
              :src="dataForm.image"
              class="upload-img"
            />
            <i class="el-icon-plus" v-else></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="任务标题" prop="title" required>
          <el-input
            placeholder="请输入任务名称"
            v-model="dataForm.title"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="资源名称" prop="projectName" required>
          <el-input
            placeholder="请输入资源名称"
            v-model="dataForm.projectName"
            clearable
          >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="私密任务" prop="title" required>
          <el-radio v-model="radio" label="1">是</el-radio>
          <el-radio v-model="radio" label="2">否</el-radio>
        </el-form-item> -->
        <el-form-item label="设置密码" prop="password" required>
          <el-input
            type="password"
            show-password
            placeholder="请设置密码"
            v-model="dataForm.password"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="预算" prop="money" required>
          <el-input
            placeholder="请输入预算，可输入准确金额或者金额范围"
            v-model="dataForm.money"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="人天单价" prop="price" required>
          <el-input
            placeholder="请输入人天单价"
            v-model="dataForm.price"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="制作总人天" prop="day" required>
          <el-input
            placeholder="请输入制作总人天"
            v-model="dataForm.day"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="报名截止时间" prop="deadlineTime" required>
          <el-date-picker
            v-model="dataForm.deadlineTime"
            type="date"
            placeholder="请选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="参考资料" prop="consult" required>
          <el-input
            placeholder="请输入参考资料"
            v-model="dataForm.consult"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="任务详情" prop="explain" required>
          <el-input
            type="textarea"
            placeholder="请输入任务详情"
            v-model="dataForm.explain"
            :autosize="{ minRows: 8, maxRows: 20 }"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="测试类型" prop="testType" required>
          <el-radio-group v-model="dataForm.testType" class="form-test-group">
            <el-radio-button :label="1">付费测试</el-radio-button>
            <el-radio-button :label="2">免费测试</el-radio-button>
            <el-radio-button :label="3">一对一试做</el-radio-button>
            <el-radio-button :label="0">无需测试</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="测试信息" prop="testInfo" required>
          <el-input
            type="textarea"
            placeholder="请输入测试信息"
            v-model="dataForm.testInfo"
            :autosize="{ minRows: 8, maxRows: 20 }"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="测试金额" prop="testMoney" required>
          <el-input
            placeholder="请输入测试金额"
            v-model="dataForm.testMoney"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="测试附件" prop="isAppendix" required>
          <el-radio v-model="dataForm.isAppendix" :label="1">有</el-radio>
          <el-radio v-model="dataForm.isAppendix" :label="0">没有</el-radio>
        </el-form-item>
        <div class="form-btn-box" v-if="JSON.parse(this.$cookie.get('userInfo')).permission === 'T-business'">
          <el-button @click="eventSubmit">发布</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { epibolyBusinessAdd } from "@/api/ectocyst.js";
export default {
  data() {
    return {
      dataForm: {
        cid: undefined,
        image: undefined,
        title: undefined,
        projectName: undefined,
        password: undefined,
        money: undefined,
        price: undefined,
        day: undefined,
        deadlineTime: undefined,
        explain: undefined,
        testType: undefined,
        testInfo: undefined,
        testMoney: undefined,
        isAppendix: undefined,
      },
      rules: {
        cid: [{ required: true, message: "请选择分类", trigger: "blur" }],
        image: [
          { required: true, message: "请上传任务封面", trigger: "change" },
        ],
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        projectName: [
          {
            required: true,
            message: "请输入资源名称",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请设置任务密码",
            trigger: ["blur", "change"],
          },
        ],
        money: [
          {
            required: true,
            message: "请输入预算",
            trigger: ["blur", "change"],
          },
        ],
        price: [
          {
            required: true,
            message: "请输入人天单价",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确的金额格式,可保留两位小数",
            trigger: ["blur", "change"],
          },
        ],
        day: [
          {
            required: true,
            message: "请输入制作总人天",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "格式不正确",
            trigger: ["blur", "change"],
          },
        ],
        deadlineTime: [
          {
            required: true,
            message: "请设置截止时间",
            trigger: ["blur", "change"],
          },
        ],
        consult: [
          {
            required: true,
            message: "请输入参考资料",
            trigger: ["blur", "change"],
          }
        ],
        explain: [
          {
            required: true,
            message: "请输入任务详情",
            trigger: ["blur", "change"],
          },
        ],
        testType: [
          { required: true, message: "请选择测试类型", trigger: "change" },
        ],
        testInfo: [
          {
            required: true,
            message: "请输入测试详情",
            trigger: ["blur", "change"],
          },
        ],
        testMoney: [
          {
            required: true,
            message: "请输入测试金额",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确的金额格式,可保留两位小数",
            trigger: ["blur", "change"],
          },
        ],
        isAppendix: [{ required: true, message: "请选择", trigger: "change" }],
      },
      typeList: [
        {
          label: "原画",
          value: 141,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%8E%9F%E7%94%BB.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%8E%9F%E7%94%BB1.png",
        },
        {
          label: "UI",
          value: 144,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/UI.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/UI-1.png",
        },
        {
          label: "模型",
          value: 142,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E6%A8%A1%E5%9E%8B.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E6%A8%A1%E5%9E%8B-1.png",
        },
        {
          label: "场景",
          value: 143,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%9C%BA%E6%99%AF.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%9C%BA%E6%99%AF-1.png",
        },
        {
          label: "动作",
          value: 140,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%8A%A8%E4%BD%9C.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%8A%A8%E4%BD%9C-1.png",
        },
        {
          label: "特效",
          value: 139,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E7%89%B9%E6%95%88.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E7%89%B9%E6%95%88-1.png",
        },
        {
          label: "其它",
          value: 145,
          icon: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%85%B6%E4%BB%96.png",
          activeIcon:
            "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/publish/%E5%85%B6%E4%BB%96-1.png",
        },
      ],
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
    };
  },
  methods: {
    // 上传成功后
    handleUploadSuccess(response, attributeName) {
      if (response.code === 200) {
        this.$set(this.dataForm, attributeName, response.data.url);
      } else {
      }
    },
    // 提交表单
    eventSubmit() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          epibolyBusinessAdd({ ...this.dataForm }).then((res) => {
            if (res.code === 200) {
              // this.$message({
              //   type: "success",
              //   offset: 80,
              //   message: "发布成功",
              // });
              // this.$refs.dataForm.resetFields();
              this.$confirm("发布成功, 是否继续发布?", "提示", {
                confirmButtonText: "继续发布",
                cancelButtonText: "返回首页",
                showClose: false,
                closeOnPressEscape: false,
                closeOnClickModal: false,
                customClass: 'ectocyst-message-box',
                type: "success",
              })
                .then(() => {
                  this.$refs.dataForm.resetFields();
                })
                .catch(() => {
                  this.$router.push("/ectocyst?page=assignment");
                });
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-container {
  width: 100%;
  padding: 30px 0;
  .publish-content {
    width: 1260px;
    margin: auto;
    padding: 50px;
    box-sizing: border-box;
    background-color: #ffffff;

    /deep/.el-form-item__label {
      color: #000000;
      font-weight: bold;
    }

    /deep/.el-date-editor.el-input {
      width: 100%;
    }

    /deep/.upload-img {
      width: 100%;
      height: 100%;
    }

    .form-type-label {
      /deep/.el-form-item__label {
        width: 100% !important;
        text-align: center;
      }
      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .form-type-item {
      width: 100%;
      /deep/.el-col {
        margin-bottom: 20px;
      }
      /deep/.el-radio-button,
      /deep/.el-radio-button__inner {
        width: 100%;
      }
      /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: var(--default-ectocyst-color);
      }
      .type-item-content {
        width: 100%;
        > img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
        > p {
          margin-bottom: 15px;
        }
      }
    }

    .form-test-group {
      /deep/.el-radio-button {
        margin-right: 15px;
      }
      /deep/.el-radio-button__inner {
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        color: #000000;
        //box-sizing: border-box;
      }
      /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #ffffff;
        border: none;
      }
    }

    .form-btn-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 80px;
      .el-button {
        padding-left: 80px;
        padding-right: 80px;
        background-color: var(--default-ectocyst-color);
        //border-color: var(--default-ectocyst-color);
        color: #ffffff;
        font-size: 24px;
      }
    }
  }
}
</style>