<template>
  <div class="index-page-container index-works-container">
    <div class="index-page-content index-works-content">
        <div class="page-content">
            <div class="page-header-box" style="margin-bottom: 80px">
                <h2 class="page-header-title"><span class="el-icon-menu"></span>获奖作品展示</h2>
            </div>
            <div class="page-work-empty" v-if="false">
                <span class="el-icon-s-promotion"></span>
                <p>作品正在飞来的路上...</p>
                <!-- <span class="empty-line empty-top-line"></span>
                <span class="empty-line empty-bottom-line"></span> -->
            </div>
            <template v-else>
                <div class="page-wroks-box">
                    <div class="works-item" v-for="item in 10" :key="item">
                        <div class="works-item-empty">敬请期待</div>
                        <!-- <div class="works-item-img">
                            <img src="@/assets/img/train/works/works-5-1.jpg" />
                            <p>一等奖</p>
                        </div>
                        <div class="works-item-bottom">
                            <div class="works-item-title">美国艺术家美国艺术家美国艺术家</div>
                            <div class="works-item-info">
                                <div class="works-item-author">
                                    <div class="author-img">
                                        <img src="https://glsx-1314030635.cos.ap-guangzhou.myqcloud.com/cover/48991664420894332.jpg" />
                                    </div>
                                    <p class="author-name">
                                        帅气的作者
                                    </p>
                                </div>
                                <div class="works-item-look">
                                    <img src="@/assets/newImg/37.png" alt="" />
                                    <span>723</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="paginations" v-if="total">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="queryForm.pageNum"
                        :page-size="queryForm.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        layout="prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </template>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            queryForm: {
                pageNum: 1,
                pageSize: 10
            }
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.queryForm.pageNum = val;
        }
    }
}
</script>

<style lang="scss" scoped>
.index-works-container{
    background: url(../../../../assets/img/linkage/page2_bg.jpg) center center
    repeat-x #000518;
    background-size: cover;
    .index-works-content {
        .page-content{
            .page-work-empty{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #f6443f;
                text-align: center;
                .el-icon-s-promotion{
                    font-size: 200px;
                }
                >p{
                    margin-top: 20px;
                    font-size: 24px;
                }
                .empty-line{
                    background-color: #f6443f;
                    position: absolute;
                    display: block;
                    width: 100px;
                    height: 1px;
                    //动画的运动曲线(linear是匀速stept()是分步)
                    animation-timing-function: linear;
                    //动画的运动时间
                    animation-duration: 1s;
                    //动画的运动次数(infinite是无限循环)
                    animation-iteration-count: infinite;
                }
                .empty-top-line{
                    top: 30px;
                    right: 45px;
                    transform: rotate(340deg);
                    //动画的名称(必须要有)
                    animation-name: top-line;
                }
                .empty-bottom-line{
                    top: 76px;
                    right: -13px;
                    transform: rotate(278deg);
                    //动画的名称(必须要有)
                    animation-name: bottom-line;
                }
                @keyframes top-line {
                    0% {
                        top: 30px;
                        right: 45px;
                    }
                    // 50% {
                    //     top: 35px;
                    //     right: 52.5px;
                    // }
                    100% {
                        top: 80px;
                        right: 150px; 
                    }
                }
                @keyframes bottom-line {
                    0% {
                        top: 76px;
                        right: -13px;
                    }
                    100% {
                        top: 144px;
                        right: -0px; 
                    }
                }
            }
            .page-wroks-box{
                display: flex;
                flex-wrap: wrap;
                width: 1445px !important;
                margin-left: -132.5px;
                .works-item{
                    width: 247px;
                    margin-right: 50px;
                    margin-bottom: 30px;
                    &:nth-child(5n) {
                        margin-right: 0;
                    }
                    &:nth-child(5) ~ .works-item {
                        margin-bottom: 0;
                    }
                }
                .works-item-empty{
                    width: 100%;
                    height: 220px;
                    background-color: rgba($color: #FFFFFF, $alpha: 0.5);
                    background: url('../../../../assets/img/linkage/247-220.jpg');
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 220px;
                    color: #FFFFFF;
                    font-size: 20px;
                    letter-spacing: 2px;
                }
                .works-item-img{
                    width: 247px;
                    height: 193px;
                    overflow: hidden;
                    position: relative;
                    >img{
                        width: 100%;
                        object-fit: cover;
                    }
                    >p{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 5px 15px;
                        background-color: #d51f1a;
                        letter-spacing: 2px;
                    }
                }
                .works-item-bottom{
                    display: flex;
                    flex-wrap: wrap;
                    background-color: #222222;
                    padding: 5px 15px;
                    .works-item-title{
                        line-height: 40px;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .works-item-info{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        .works-item-author{
                            display: flex;
                            align-items: center;
                            width: calc(100% - 60px);
                            .author-img{
                                width: 22px;
                                height: 22px;
                                border-radius: 50%;
                                overflow: hidden;
                                >img{
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                            .author-name{
                                margin-left: 5px;
                                font-size: 12px;
                                width: calc(100% - 22px);
                            }
                        }
                        .works-item-look{
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            >img{
                                width: 22px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>