<template>
  <div class="index-page-container index-about-container">
    <div class="index-page-content index-about-content">
      <!-- <div class="page-sort">
            <span class="sort-num">01</span>
        </div> -->
      <div class="page-content">
        <div class="page-header-box">
          <h2 class="page-header-title"><span class="el-icon-info"></span>大赛简介</h2>
          <!-- <p class="page-header-time">2022年11月7日至2022年11月28日</p> -->
        </div>
        <dl class="page-part">
          <!-- <dt class="page-part-title">大赛宗旨</dt> -->
          <dd class="page-part-content">
            <p>为培养学生的创新意识和能力，挖掘游戏美术界的创意人才，广州怪力视效网络科技有限公司（以下简称“怪力视效”）联合广州工商学院美术学院共同举办2023“超·能·力”怪力视效校园美术大赛。本次赛事活动是一场为游戏动漫美术相关专业学生量身打造的行业级作品大赛，大赛本着开放、自由、创造性的精神，旨在促进学生充分展示专业知识，赛出水平，赛出风采。</p>
          </dd>
        </dl>
        <!-- <dl class="page-part">
          <dt class="page-part-title">公司简介</dt>
          <dd class="page-part-content">
            <p>广州怪力视效成立2017年，位于广州黄埔区云升产业园，办公面积2000多平，发展至今将近350人。</p>
            <p>公司主要提供游戏动漫美术创作、美术设计服务及游戏教育培训。</p>
            <p>公司发展至今参与了众多的优秀游戏制作，全面掌握不同游戏的开发流程、游戏风格、美术制作以及整个项目流程的项目管理。</p>
          </dd>
        </dl> -->
        <dl class="page-part">
          <dd class="page-part-content">
            <img class="content-img" src="@/assets/img/linkage/wallhaven-rdl9vw.png" />
          </dd>
        </dl>
      </div>
      <div class="page-aside">
        <img class="aside-img" src="@/assets/img/linkage/advan.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
.index-about-container {
  background: url(../../../../assets/img/linkage/page2_bg.jpg) center center
    repeat-x #000518;
  background-size: cover;
  .index-about-content {
    .page-sort {
      float: left;
      font-size: 20px;
      color: #fff;
      padding-right: 30px;
      position: relative;
      margin-top: 100px;
      text-align: center;
      margin-right: 55px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        transform: rotate(45deg);
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: -15px;
        width: 4px;
        height: 60px;
        background-color: #f6443f;
      }
    }

    .page-content{
        width: 590px;
        .page-part{
            .page-part-content{
                line-height: 36px !important;
                font-size: 18px !important;
                letter-spacing: 1px !important;
                >p{
                    text-indent: 36px;
                }
                >.content-img{
                    width: 630px;
                    //height: 320px;
                    margin-top: 60px;
                }
            }
        }
    }
    .page-aside{
        float: left;
        margin-left: 130px;
        .aside-img{
            margin-top: 60px;
        }
    }
  }
}
</style>