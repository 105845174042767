<template>
  <div class="assignment-detail-container">
    <div class="detail-content">
      <div class="detail-content-header">
        <div class="content-header-left">
          <img :src="content.image" />
        </div>
        <div class="content-header-right">
          <ul class="header-right-line">
            <li class="line-item top-title">{{ content.title }}</li>
            <!-- <li class="line-item">
              <img
                src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/detail_man.png"
              />
              <span>8人</span>
            </li> -->
            <li class="line-item">
              <img
                src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/detail_time.png"
              />
              <span style="margin-top: 3px">{{ content.deadlineTime }}</span>
            </li>
          </ul>
          <ul class="header-right-line">
            <li class="line-item">
              编号：<span>{{ content.eid }}</span>
            </li>
            <li class="line-item">
              类型：<span>{{ content.classifyName }}</span>
            </li>
            <li class="line-item">
              测试类型：<span>{{
                getDictByVal(testTypeList, content.testType)
              }}</span>
            </li>
          </ul>
          <ul class="header-right-line">
            <li class="line-item line-item-money">
              预算：<span>{{ content.money }}</span>
            </li>
          </ul>
        </div>
        <div class="content-header-bottom">
          <div
            class="header-bottom-title"
            style="display: flex; justify-content: space-between"
          >
            <p>任务流程</p>
            <el-button
              icon="el-icon-upload"
              type="warning"
              @click="eventUploadVideo"
              v-if="
                JSON.parse(this.$cookie.get('userInfo')).permission ===
                'T-common'
              "
              >提交录屏</el-button
            >
          </div>
          <div class="header-bottom-content">
            <el-steps :active="4" align-center>
              <el-step
                title="报名任务"
                description="需完成注册与认证"
              ></el-step>
              <el-step
                title="测试"
                description="测试通过后才可正式制作"
              ></el-step>
              <el-step
                title="正式制作"
                description="收到多个物件的制作邀请"
              ></el-step>
              <el-step title="完成结款" description="企业需提供发票"></el-step>
            </el-steps>
          </div>
        </div>
      </div>
      <div class="detail-content-box">
        <div class="content-box-left">
          <div class="box-left-item" id="itemDetail">
            <div class="item-title">任务详情</div>
            <div class="item-content">
              <!-- <p>
                该项目由怪力屋官方代理发包，本次任务为欧美写实2D人物立绘的绘制。
              </p>
              <p>
                这是一些任务详情，这是一些任务详情，这是一些任务详情，这是一些任务详情这是
                一些任务详情这是一些任务详情发，这是一些任务详情，这是一些任务详情，这是一
                些任务详情，这是一些任务详情，这是一些任务详情这是一些任务详情，这是一些任
                务详情，这是一些任务详情，这是一些任务详情，这是一些任务详情，这是一些任务
                详情。
              </p> -->
              <p>{{ content.explain }}</p>
            </div>
          </div>
          <div class="box-left-item" id="itemInfo">
            <div class="item-title">参考资料</div>
            <div class="item-content">
              <p>{{ content.consult || "无" }}</p>
            </div>
          </div>
          <div class="box-left-item" id="itemTestInfo">
            <div class="item-title">测试信息</div>
            <div class="item-content">
              <p>{{ content.testInfo || "无" }}</p>
              <!-- <p>
                <span class="item-text-highlight">测试内容：</span
                >根据客户提供的需求文档进行<span class="item-text-highlight"
                  >欧美写实2D人物立绘</span
                >的制作
              </p>
              <p>
                <span class="item-text-highlight">制作要求：</span><br />
                1.根据客户提供的需求文档进行制作<br />2.根据客户提供的需求文档进行制作<br />3.根据客户提供的需求文档进行制作
              </p>
              <p>
                <span class="item-text-highlight">提交步骤：</span><br />
                1.根据客户提供的需求文档进行制作<br />2.根据客户提供的需求文档进行制作<br />3.根据客户提供的需求文档进行制作
              </p> -->
            </div>
          </div>
          <div class="box-left-item" id="itemTestFile">
            <div class="item-title">测试附件</div>
            <div class="item-content">
              <p>
                {{
                  content.isAppendix ? "根据客户提供的需求文档进行制作" : "无"
                }}
              </p>
            </div>
          </div>
          <div class="box-left-item" id="itemEmployer">
            <div class="item-title">发包方信息</div>
            <div class="item-content item-content-flex">
              <div class="item-content-left">
                <div class="item-content-img">
                  <img
                    :src="content.headImage || require('@/assets/img/1.png')"
                  />
                </div>
                <div class="item-content-user">
                  <p>官方发包</p>
                </div>
              </div>
              <!-- <el-button class="item-content-right">任务咨询</el-button> -->
            </div>
          </div>
        </div>
        <div class="content-box-right" :class="isBar && 'is-bar'">
          <ul>
            <li :class="anchorText === 'itemDetail' && 'active'" @click="changeActive('itemDetail')">任务详情</li>
            <li :class="anchorText === 'itemInfo' && 'active'" @click="changeActive('itemInfo')">参考资料</li>
            <li :class="anchorText === 'itemTestInfo' && 'active'" @click="changeActive('itemTestInfo')">测试信息</li>
            <li :class="anchorText === 'itemTestFile' && 'active'" @click="changeActive('itemTestFile')">测试附件</li>
            <li :class="anchorText === 'itemEmployer' && 'active'" @click="changeActive('itemEmployer')">发包方信息</li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="上传测试录屏" :visible.sync="uploadDialog" width="400px">
      <el-upload
        class="upload-demo"
        drag
        :show-file-list="false"
        :action="uploadUrl"
        :on-success="uploadVideoSuccess"
        :before-upload="beforeUpload"
        accept=".mp4,.avi,.wmv"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>，最大100M
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import { getEpibolyAddTestVideo } from "@/api/ectocyst.js";
import dialogLock from "../components/dialog.vue";
export default {
  data() {
    return {
      isBar: false,
      content: {},
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      // 测试类型
      testTypeList: [
        {
          value: 2,
          label: "付费测试",
        },
        {
          value: 1,
          label: "免费测试",
        },
        {
          value: 0,
          label: "无需测试",
        },
        {
          value: 3,
          label: "一对一试做",
        },
      ],
      uploadDialog: false,
      loading: true,
      // 锚点文字
      anchorText: 'itemDetail'
    };
  },
  components: {
    dialogLock,
  },
  created() {
    this.content = JSON.parse(decodeURI(this.$route.query.content));
  },
  mounted() {
    window.addEventListener("scroll", this.eventHandleScroll);
  },
  methods: {
    // 滚动
    eventHandleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 545) {
        this.isBar = true;
      } else {
        this.isBar = false;
      }
    },
    // 根据
    getDictByVal(option, val) {
      return option.find((el) => el.value == val).label;
    },
    // 上传录屏前调
    beforeUpload(file) {
      let isLtMax = file.size / 1024 / 1024 < 100;
      if (!isLtMax) {
        this.$message.error("上传的录屏大小不能超过 " + 100 + "MB!");
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return true;
    },
    // 上传录屏
    eventUploadVideo() {
      this.uploadDialog = true;
    },
    // 调用上传录屏接口
    async handleUpload(url) {
      let { code } = await getEpibolyAddTestVideo({
        eid: this.content.eid,
        testVideo: url,
      });
      if (code === 200) {
        this.loading.close();
        this.uploadDialog = false;
        this.$message({
          message: "提交成功,请等待审核",
          type: "success",
        });
      } else {
        this.loading.close();
      }
    },
    // 上传录屏成功
    uploadVideoSuccess(response) {
      if (response.code === 200) {
        this.handleUpload(response.data.url);
      }
    },
    // 修改
    changeActive(anchorText) {
      this.anchorText = anchorText;
      let url = '#'+ anchorText
      const a = document.createElement("a");
      a.href = url;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-detail-container {
  width: 100%;
  padding: 30px 0;
  .detail-content {
    width: 1260px;
    margin: auto;
    .detail-content-header {
      background-color: #ffffff;
      padding: 50px;
      display: flex;
      flex-wrap: wrap;
      .content-header-left {
        width: 180px;
        height: 180px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content-header-right {
        margin-left: 40px;
        width: calc(100% - 220px);
        .header-right-line {
          width: 100%;
          display: flex;
          align-items: flex-end;
          padding-bottom: 10px;
          color: #929bae;
          &:first-child {
            border-bottom: 1px solid #eeeeee;
          }
          &:not(:first-child) {
            margin-top: 20px;
          }
          .line-item {
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-right: 40px;
            > img {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
            span {
              color: #000000;
            }
            &.line-item-money {
              //align-items: flex-end;
              > span {
                font-size: 24px;
                color: #ebb92e;
              }
            }
          }
          .top-title {
            color: #000000;
            font-size: 24px;
            letter-spacing: 2px;
          }
        }
      }
      .content-header-bottom {
        margin-top: 40px;
        width: 100%;
        .header-bottom-title {
          font-size: 20px;
        }
        .header-bottom-content {
          margin-top: 10px;
          width: 100%;
          padding: 40px;
          box-sizing: border-box;
          border: 1px solid #eeeeee;
        }
      }
    }

    .detail-content-box {
      display: flex;
      margin-top: 25px;
      .content-box-left {
        width: calc(100% - 275px);
        background-color: #ffffff;
        padding: 30px 50px;
        box-sizing: border-box;
        .box-left-item {
          .item-title {
            position: relative;
            text-indent: 20px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 1px;
            &::before {
              content: "";
              width: 6px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: var(--default-ectocyst-color);
            }
          }
          .item-content {
            color: #737f97;
            > p {
              margin-top: 20px;
            }
            &.item-content-flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              .item-content-left {
                display: flex;
                align-items: center;
                .item-content-img {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  overflow: hidden;
                  margin-right: 10px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .item-content-user {
                  color: #000000;
                  font-weight: bold;
                }
              }

              .item-content-right {
                color: #ffffff;
                background-color: var(--default-ectocyst-color);
                font-size: 24px;
              }
            }
          }
          &:not(:first-child) {
            margin-top: 50px;
          }
          .item-text-highlight {
            color: #000000;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
      .content-box-right {
        width: 250px;
        height: auto;
        margin-left: 25px;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #737f97;
        > ul {
          background-color: #ffffff;
          padding: 30px 50px;
          > li {
            line-height: 40px;
            cursor: pointer;
            > a {
              color: #737f97;
            }
            &.active {
              color: #409eff;
              position: relative;
              > a {
                color: #409eff;
              }
              &::before {
                content: "";
                width: 8px;
                height: 8px;
                background-color: #409eff;
                border-radius: 50%;
                position: absolute;
                top: calc(50% - 4px);
                left: -20px;
              }
            }
          }
        }
        &.is-bar {
          position: fixed;
          top: 85px;
          right: calc((100% - 1260px) / 2);
        }
      }
    }
  }
}
</style>