<template>
  <div class="ectocyst-container">
    <!-- 头部 -->
    <ectocystHeader ref="ectocystHeader" :isManage="!showFooter"></ectocystHeader>
    <!-- 页面部分 -->
    <div
      class="ectocyst-content"
      :style="{ minHeight: minHeight + 'px', paddingTop: headerHeight + 'px' }"
    >
      <Transition name="fade" mode="out-in">
        <component :is="activeComponent"></component>
      </Transition>
    </div>
    <!-- 尾部 -->
    <ectocysFooter ref="ectocysFooter" v-if="showFooter"></ectocysFooter>
  </div>
</template>

<script>
// 头部
import ectocystHeader from "./components/header";
// 尾部
import ectocysFooter from "./components/footer";
// 任务界面
import assignment from "./assignment/index";
// 任务详情界面
import assignmentDetail from "./assignment/detail";
// 审核界面
import ectocystExamine from "./examine/index";
// 发包界面
import assignmentPublish from "./publish/index";
// 我的任务界面
import ectocystTasks from "./tasks/index";
// 财务结算界面
import ectocystFinancial from "./financial/index";
// 技术人员评论界面
import ectocystComment from "./comment/index";
export default {
  data() {
    return {
      activeComponent: undefined,
      headerHeight: 0,
      footerHeight: 0,
    };
  },
  computed: {
    minHeight() {
      return this.activeComponent !== "ectocystExamine" &&
        this.activeComponent !== "ectocystTasks"
        ? document.documentElement.clientHeight - this.footerHeight
        : document.documentElement.clientHeight;
    },
    // 是否显示尾部
    showFooter() {
      return (
        this.activeComponent !== "ectocystExamine" &&
        this.activeComponent !== "ectocystTasks" && 
        this.activeComponent !== "ectocystFinancial" &&
        this.activeComponent !== "ectocystComment"
      );
    },
  },
  components: {
    ectocystHeader,
    ectocysFooter,
    assignment,
    assignmentDetail,
    ectocystExamine,
    assignmentPublish,
    ectocystTasks,
    ectocystFinancial,
    ectocystComment
  },
  watch: {
    "$route.query.page": {
      handler() {
        this.showComponent();
        this.getContentMinHeight();
      },
      //immediate: true
    },
  },
  created() {
    // 监听浏览器前进后退事件
    window.addEventListener('popstate', this.popstate, false);
    this.showComponent();
    this.getContentMinHeight();
  },
  methods: {
    // 计算内容区域的最小高度
    getContentMinHeight() {
      this.$nextTick(() => {
        this.headerHeight = (this.$refs.ectocystHeader && this.$refs.ectocystHeader.$el.offsetHeight) || 0;
        this.footerHeight = (this.$refs.ectocysFooter && this.$refs.ectocysFooter.$el.offsetHeight) || 0;
      });
    },
    // 判断显示啥组件
    showComponent() {
      this.activeComponent = this.$route.query.page || "assignment";
    },
    // 
    popstate() {
      console.log('发生跳转');
      this.showComponent();
      this.getContentMinHeight();
    }
  },
  //销毁vm组件
  destroyed() { 
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false);
  },
};
</script>

<style lang="scss">
.ectocyst-container {
  width: 100%;
  .ectocyst-content {
    width: 100%;
    box-sizing: border-box;
    background-color: #ebf5ff;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/content_bg.png");
    background-repeat: no-repeat;
    background-size: 1585px 1658px;
    background-position: bottom right;
    position: relative;
  }
}

.ectocyst-message-box{
  // background-color: #262932 !important;
  background-color: #FFFFFF !important;
  border-top: none !important;
  // border: none !important;
  // border-top: 0.5rem solid #00d0f4 !important;
  // border-radius: 0.5rem !important;
  .el-message-box__title{
    color: #000000 !important; 
    font-weight: bold;
  }

  .el-message-box__message{
    color: #606266 !important;
  }

  .el-button--primary{
    background-color: var(--default-ectocyst-color) !important;
    border-color: var(--default-ectocyst-color) !important;
    color: #FFFFFF !important;
  }
}
</style>