<template>
  <div class="list-container">
    <div class="list-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="资源名称" prop="projectName">
              <el-select
                v-model="queryForm.projectName"
                placeholder="请选择"
                clearable
                size="small"
                @change="changeProjectName"
              >
                <el-option
                  v-for="(item, index) in projectNameList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务名称" prop="title">
              <el-input
                v-model="queryForm.title"
                type="text"
                placeholder="根据任务名称搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="接包方" prop="name">
              <el-input
                v-model="queryForm.name"
                type="text"
                placeholder="根据接包方搜索"
                clearable
                size="small"
                @blur="getList"
              ></el-input>
            </el-form-item>
            <el-form-item label="接包方类型" prop="userType">
              <el-select
                v-model="queryForm.userType"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                v-model="queryForm.phone"
                type="text"
                placeholder="根据联系电话搜索"
                clearable
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="提交时间">
              <el-date-picker
                v-model="createTimeRange"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="resetQuery">重置</el-button>
            </el-form-item>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button
                    size="small"
                    icon="el-icon-download"
                    type="warning"
                    @click="eventExport"
                    >导出</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="header-bottom">
        <el-tabs v-model="tabsObject.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in tabsObject.list"
            :key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="list-right-list">
      <el-row
        style="margin-bottom: 20px"
        v-if="tabsObject.activeName === 'unsettled'"
      >
        <el-col>
          <el-button
            size="small"
            icon="el-icon-edit"
            type="success"
            @click="eventVerify(tabsSelectList)"
            :disabled="!tabsSelectList.length"
            >结算勾选项</el-button
          >
        </el-col>
      </el-row>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="基本信息" align="center">
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column prop="projectName" label="资源名称" width="150px">
          </el-table-column>
          <el-table-column prop="title" label="任务名称" width="150px">
          </el-table-column>
          <el-table-column
            prop="classifyName"
            label="任务类型(环节)"
            width="150px"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="接包方"
            width="200px"
          ></el-table-column>
          <el-table-column
            prop="bankCard"
            label="银行卡账号"
            width="200px"
          ></el-table-column>
          <el-table-column
            prop="openingBank"
            label="开户行"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="bankHolders"
            label="开户人"
            width="120px"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="计划排期" align="center">
          <el-table-column
            prop="expectedStartTime"
            label="计划开始时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.expectedStartTime)"
          ></el-table-column>
          <el-table-column
            prop="firstTime"
            label="首次提交时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.firstTime)"
          ></el-table-column>
          <el-table-column
            prop="expectedEndTime"
            label="计划结束时间"
            width="150px"
            :formatter="(row) => timeFormatterFun(row.expectedEndTime)"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="结算" align="center">
          <el-table-column
            prop="flnalDay"
            label="制作总人天"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="finalOffer"
            label="人天单价"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="money"
            label="制作总价"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="proportion"
            label="结算比例"
            width="120px"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          prop="remar"
          label="备注"
          align="center"
          width="200px"
        ></el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="150px"
          v-if="tabsObject.activeName === 'unsettled'"
        >
          <template slot-scope="scope">
            <!-- <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="eventToDetail"
              >确认打款</el-button
            > -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="eventVerify(scope.row.eid)"
              >确认结算</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { parseTime } from "@/tools/timeCycle.js";
import {
  getEpibolyFinanceList,
  getEpibolyFinanceVerify,
  getEpibolyFinanceExport,
  getProjectNameList,
} from "@/api/ectocyst.js";
export default {
  data() {
    return {
      createTimeRange: undefined,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        userType: undefined,
        status: 3,
        title: undefined,
        name: undefined,
        phone: undefined,
        startTime: undefined,
        endTime: undefined,
        projectName: undefined,
      },
      total: 0,
      tabsObject: {
        activeName: "unsettled",
        list: [
          {
            label: "未结算",
            name: "unsettled",
          },
          {
            label: "已结算",
            name: "settled",
          },
        ],
      },
      // 用户类型
      userOptions: [
        {
          label: "个人",
          value: 1,
        },
        {
          label: "企业",
          value: 2,
        },
      ],
      tableData: [],
      // 选择的ID集合
      tabsSelectList: [],
      // 资源名称集合
      projectNameList: [],
    };
  },
  watch: {
    createTimeRange: {
      handler(v) {
        this.queryForm.startTime = v ? v[0] : undefined;
        this.queryForm.endTime = v ? v[1] : undefined;
      },
    },
  },
  created() {
    this.getList();
    this.getProjectNameList();
  },
  methods: {
    // 资源名称列表
    async getProjectNameList() {
      let { code, data } = await getProjectNameList();
      if (code === 200) {
        this.projectNameList = data;
      }
    },
    changeProjectName() {
      console.log(this.queryForm.projectName);
      this.queryForm.projectName =
        this.queryForm.projectName !== ""
          ? this.queryForm.projectName
          : undefined;
    },
    async getList() {
      let { code, rows, total } = await getEpibolyFinanceList(this.queryForm);
      if (code === 200) {
        this.tableData = rows;
        this.total = total;
      }
    },
    // 重置
    resetQuery() {
      this.createTimeRange = undefined;
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      this.$refs["queryForm"].resetFields();
      this.getList();
    },
    timeFormatterFun(time) {
      return parseTime(time, "{y}-{m}-{d}");
    },
    // 切换tab
    handleClick() {
      if (this.tabsObject.activeName === "unsettled") {
        this.queryForm.status = 3;
      } else if (this.tabsObject.activeName === "settled") {
        this.queryForm.status = 4;
      }
      this.getList();
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.queryForm.pageNum = 1;
      this.getList();
    },
    // 修改页码
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    // 打款
    eventVerify(eidList) {
      console.log(eidList);
      this.$confirm("是否确认结算", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
        customClass: "ectocyst-message-box",
      })
        .then(() => {
          let listQuery = qs.stringify({ eidList: eidList });
          console.log(listQuery);
          getEpibolyFinanceVerify(listQuery).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                offset: 80,
                message: "操作成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    // 选择
    handleSelectionChange(val) {
      console.log(val);
      this.tabsSelectList = [];
      val.forEach((el) => {
        this.tabsSelectList.push(el.eid);
      });
    },
    // 导出
    async eventExport() {
      if (!this.queryForm.name) {
        this.$message({
          showClose: true,
          message: "请输入你要导出数据的相关接包方",
          type: "warning",
        });
        return;
      }
      if (!this.tableData.length) {
        this.$message({
          showClose: true,
          message: "表格数据为空",
          type: "warning",
        });
        return;
      }
      if (this.queryForm.name && this.tableData.length) {
        let aid = this.tableData[0].aid;
        // 新建a标签对象
        let url = process.env.VUE_APP_BASE_API + '/api/epiboly/finance/export?aid=' + aid;
        const a = document.createElement('a');
        a.href = url;
        //a.download = res.success.fileName // 下载后文件名
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click(); // 点击下载
        document.body.removeChild(a); // 下载完成移除元素
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  .list-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      margin-top: 20px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .list-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  /deep/.el-pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>