var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"detail-info-bg"},[_c('p',_vm._l((5),function(item){return _c('span',{key:item},[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/crile.png"}})])}),0)]),_c('div',{staticClass:"detail-info-time-item"},[_vm._v("发布时间："+_vm._s(_vm.jobInfo.createTime))]),_c('div',{staticClass:"detail-info-title-item"},[_c('p',{staticClass:"item-price"},[_vm._v(_vm._s(_vm.jobInfo.emolUment))]),_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.jobInfo.position))])]),_c('div',{staticClass:"detail-type detail-item"},[_vm._m(0),_c('p',{staticClass:"detail-type-mail"},[_vm._v("投递邮箱："+_vm._s(_vm.jobInfo.email))])]),_c('div',{staticClass:"detail-condition detail-item"},[_c('div',{staticClass:"detail-condition-title"},[_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"title-item"},[_c('i',{staticClass:"el-icon-location-outline"}),_vm._v(" "+_vm._s(_vm.jobInfo.employCity)+" ")]),_c('p',{staticClass:"title-item"},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" "+_vm._s(_vm.jobInfo.workExp)+" ")]),_c('p',{staticClass:"title-item"},[_c('i',{staticClass:"el-icon-reading"}),_vm._v(" "+_vm._s(_vm.jobInfo.eduBg)+" ")])])]),_c('div',{staticClass:"detail-condition-address"},[_c('p',[_vm._v(_vm._s(_vm.jobInfo.company))]),_c('p',[_vm._v("公司地址："+_vm._s(_vm.jobInfo.address))])])]),_c('div',{staticClass:"detail-ask detail-item"},[_vm._m(1),_c('div',{staticClass:"detail-ask-content",domProps:{"innerHTML":_vm._s(
        _vm.escapeStringHTML(
          _vm.jobInfo.postIntro ? JSON.parse(_vm.jobInfo.postIntro) : ''
        )
      )}})]),_c('div',{staticClass:"detail-welfare detail-item"},[_vm._m(2),_c('div',{staticClass:"detail-welfare-content"},_vm._l((_vm.postWelfareArr),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0)]),_c('div',{staticClass:"detail-comment detail-item"},[_c('comment',{ref:"child",attrs:{"commentQuery":{ modelId: _vm.jobInfo.id, flag: 2 },"userInfo":{
        id: _vm.jobInfo.rid,
        nickName: _vm.jobInfo.authorName,
        headImg: _vm.jobInfo.authorHead,
        fouseFlag: _vm.jobInfo.fouseFlag,
        collectFlag: _vm.jobInfo.collectFlag,
        focus: _vm.jobInfo.focus,
      }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"detail-type-title"},[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/email.png"}}),_vm._v(" 投递方式 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-type-title"},[_c('p',[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/ask.png"}}),_vm._v(" 岗位要求 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-type-title"},[_c('p',[_c('img',{attrs:{"src":"https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/recruit/welfare.png"}}),_vm._v(" 福利待遇 ")])])}]

export { render, staticRenderFns }