<template>
  <div class="index-page-container index-works-container">
    <div class="index-page-content index-works-content">
      <div class="page-title works-title">学员作品</div>
      <div class="works-box">
        <div
          class="works-item"
          v-for="(item, index) in worksShowList"
          :key="item.id"
          @click="eventShowDialog(item, index)"
        >
          <img v-lazy="item.workPic" />
          <p>{{ item.title }}</p>
          <span class="el-icon-video-play" v-if="item.type === 'video'"></span>
        </div>
      </div>
      <div class="paginations">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="works-dialog__wrapper" v-show="showDialog">
      <div class="works-dialog">
        <div class="works-dialog-header">
          <div class="works-dialog-title">{{ dialogInfo.title }}</div>
          <span class="el-icon-close" @click="eventClose"></span>
        </div>
        <div class="works-dialog-body" :key="dialogInfo.id">
          <el-image
            class="works-image"
            :src="dialogInfo.url"
            fit="contain"
            v-if="dialogInfo.type === 'image'"
          >
          </el-image>
          <video
            controlslist="nodownload"
            autoplay="autoplay"
            controls="controls"
            class="works-video"
            v-if="dialogInfo.type === 'video'"
          >
            <source :src="dialogInfo.url" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
        <span
          class="el-icon-arrow-left"
          :class="showIndex === 0 && 'icon-disabled'"
          @click="eventToPrev"
        ></span>
        <span
          class="el-icon-arrow-right"
          :class="showIndex >= worksShowList.length - 1 && 'icon-disabled'"
          @click="eventToNext"
        ></span>
      </div>
      <div class="works-modal" style="z-index: 2002"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10,
      },
      worksShowList: [],
      worksList: [
        {
          id: 1,
          title: "五期学员作品1",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-1.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-1.mp4",
          type: "video",
        },
        {
          id: 2,
          title: "五期学员作品2",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-2.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-2.mp4",
          type: "video",
        },
        {
          id: 3,
          title: "五期学员作品3",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-3.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-5-3.mp4",
          type: "video",
        },
        {
          id: 4,
          title: "四期学员作品1",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-1.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-1.mp4",
          type: "video",
        },
        {
          id: 5,
          title: "四期学员作品2",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-2.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-2.mp4",
          type: "video",
        },
        {
          id: 6,
          title: "四期学员作品3",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-3.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-3.mp4",
          type: "video",
        },
        {
          id: 7,
          title: "四期学员作品4",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-4.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-4.mp4",
          type: "video",
        },
        {
          id: 8,
          title: "四期学员作品5",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-5.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-5.mp4",
          type: "video",
        },
        {
          id: 9,
          title: "四期学员作品6",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-6.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-6.mp4",
          type: "video",
        },
        {
          id: 10,
          title: "四期学员作品7",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-7.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-4-7.mp4",
          type: "video",
        },
        {
          id: 11,
          title: "二期学员作品1",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-1.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-1.mp4",
          type: "video",
        },
        {
          id: 12,
          title: "二期学员作品2",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-2.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-2.mp4",
          type: "video",
        },
        {
          id: 13,
          title: "二期学员作品3",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-3.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-2-3.mp4",
          type: "video",
        },
        {
          id: 14,
          title: "一期学员作品1",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-1.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-1.mp4",
          type: "video",
        },
        {
          id: 15,
          title: "一期学员作品2",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-2.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-2.mp4",
          type: "video",
        },
        {
          id: 16,
          title: "一期学员作品3",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-3.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-3.mp4",
          type: "video",
        },
        {
          id: 17,
          title: "一期学员作品4",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-4.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-4.mp4",
          type: "video",
        },
        {
          id: 18,
          title: "一期学员作品5",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-5.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-5.mp4",
          type: "video",
        },
        {
          id: 19,
          title: "一期学员作品6",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-6.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-6.mp4",
          type: "video",
        },
        {
          id: 20,
          title: "一期学员作品7",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-7.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-7.mp4",
          type: "video",
        },
        {
          id: 21,
          title: "一期学员作品8",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-8.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-8.mp4",
          type: "video",
        },
        {
          id: 22,
          title: "一期学员作品9",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-9.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-9.mp4",
          type: "video",
        },
        {
          id: 23,
          title: "一期学员作品10",
          workPic: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-10.jpg",
          url: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/work/works-1-10.mp4",
          type: "video",
        },
      ],
      showDialog: false,
      dialogInfo: {},
      showIndex: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.total = this.worksList.length;
      let startPage = (this.queryForm.pageNum - 1) * this.queryForm.pageSize;
      let endPage = startPage + this.queryForm.pageSize;
      this.worksShowList = this.worksList.slice(startPage, endPage);
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
    eventShowDialog(item, index) {
      this.dialogInfo = item;
      this.showIndex = index;
      this.showDialog = true;
      this.$emit("changeAllowSlide", false);
    },
    eventClose() {
      this.dialogInfo = {};
      this.showDialog = false;
      this.$emit("changeAllowSlide", true);
    },
    eventToPrev() {
      if (this.showIndex === 0) {
        return;
      }
      this.showIndex -= 1;
      this.dialogInfo = this.worksShowList[this.showIndex];
    },
    eventToNext() {
      if (this.showIndex >= this.worksShowList.length - 1) {
        return;
      }
      this.showIndex += 1;
      this.dialogInfo = this.worksShowList[this.showIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.index-works-container {
  .index-works-content {
    .works-box {
      margin-top: 50px;
      margin-left: -10vw;
      display: flex;
      flex-wrap: wrap;
      width: 1445px;
      min-height: 498px;
      .works-item {
        display: inline-block;
        border: 1px solid #35edff;
        margin-right: 50px;
        margin-bottom: 30px;
        position: relative;
        height: 234px;
        width: 247px;
        > img {
          width: 247px;
          height: 193px;
        }
        > p {
          color: #8eb1bd;
          line-height: 35px;
          padding-left: 20px;
          letter-spacing: 1px;
          height: 35px;
        }
        .el-icon-video-play {
          position: absolute;
          left: 20px;
          bottom: 50px;
          font-size: 50px;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
        &:nth-child(5) ~ .works-item {
          margin-bottom: 0;
        }
      }
    }
  }

  .works-dialog__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index: 2003;
    //display: none;
    .works-dialog {
      width: 800px;
      height: 510px;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      z-index: 2003;
      .works-dialog-header {
        //padding: 20px 20px 10px;
        display: flex;
        color: #30cada;
        justify-content: space-between;
        .works-dialog-title {
          line-height: 48px;
          letter-spacing: 1px;
        }
        .el-icon-close {
          font-size: 24px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .works-dialog-body {
        width: 800px;
        height: 400px;
        background-color: rgba($color: #000000, $alpha: 0.9);
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        position: relative;
        .works-image {
          width: 100%;
          height: 100%;
        }
        .works-video {
          max-width: 100%;
          height: 100%;
        }
        .el-icon-arrow-left {
          position: absolute;
          font-size: 32px;
        }
      }
      .el-icon-arrow-left {
        position: absolute;
        left: -50px;
        font-size: 48px;
        top: calc(50% - 40px);
        color: #30cada;
        cursor: pointer;
      }
      .el-icon-arrow-right {
        position: absolute;
        right: -50px;
        font-size: 48px;
        top: calc(50% - 40px);
        color: #30cada;
        cursor: pointer;
      }
      .icon-disabled {
        color: #cccccc;
        opacity: 0.5;
      }
    }
    .works-modal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: #000;
    }
  }
}
</style>