<template>
  <div class="detail-container">
    <div class="detail-header">
      <el-button type="text" icon="el-icon-arrow-left" @click="eventBack"
        >返回列表页</el-button
      >
    </div>
    <div class="detail-content">
      <el-form>
        <div class="attestation-basic-info">
          <p class="info-title">账户信息</p>
          <el-form-item :label="type === 'person' ? '头像' : '企业logo'">
            <br />
            <el-image
              :src="detailContent.headImage"
              :preview-src-list="[detailContent.headImage]"
              style="width: 120px; height: 120px; border-radius: 50%"
            ></el-image>
          </el-form-item>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="账号(手机号)">
                <br />
                <el-input
                  v-model="detailContent.phone"
                  placeholder="请输入账号"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="密码">
                <br />
                <el-input
                  v-model="detailContent.phone"
                  placeholder="请输入密码"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
        </div>
        <!-- 个人消息 -->
        <div class="attestation-basic-info" v-if="type === 'person'">
          <p class="info-title">个人信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="真实姓名">
                <br />
                <el-input
                  v-model="detailContent.name"
                  placeholder="请输入您的真实姓名"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号">
                <br />
                <el-input
                  v-model="detailContent.idCard"
                  placeholder="请输入您的身份证号"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <br />
                <el-input
                  v-model="detailContent.sex"
                  placeholder="请输入您性别"
                  readonly
                ></el-input>
                <!-- <el-select
                  v-model="detailContent.sex"
                  clearable
                  placeholder="请选择"
                  style="width: 100%"
                  disabled
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系邮箱">
                <br />
                <el-input
                  v-model="detailContent.email"
                  placeholder="请输入你的邮箱"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系QQ">
                <br />
                <el-input
                  v-model="detailContent.qqNumber"
                  placeholder="请输入你的QQ"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="擅长方向">
                <br />
                <el-input
                  v-model="detailContent.forte"
                  placeholder="请输入擅长方向"
                  readonly
                ></el-input>
                <!-- <el-select
                  v-model="value"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择或者输入你的擅长方向"
                  style="width: 100%"
                  disabled
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 企业/工作室信息 -->
        <div class="attestation-basic-info" v-if="type === 'company'">
          <p class="info-title">企业/工作室信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="公司名称" required>
                <br />
                <el-input
                  v-model="detailContent.name"
                  placeholder="请输入您的公司名称"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经营者姓名" required>
                <br />
                <el-input
                  v-model="detailContent.corporation"
                  placeholder="请输入您的公司法人姓名"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司地址" required>
                <br />
                <el-input
                  v-model="detailContent.companyAddress"
                  placeholder="请输入您的公司地址"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照注册号/统一社会信用代码" required>
                <br />
                <el-input
                  v-model="detailContent.creditCode"
                  placeholder="请输入您的公司营业执照注册号/统一社会信用代码"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照所在地" required>
                <br />
                <!-- <v-distpicker
                  class="dist-picker"
                  @selected="onSelected"
                  :province="licenseAddress.province"
                  :city="licenseAddress.city"
                  :area="licenseAddress.area"
                  disabled
                ></v-distpicker> -->
                <el-input
                  v-model="detailContent.licenseAddress"
                  placeholder="请输入营业执照所在地"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经营年限" required>
                <br />
                <el-input
                  v-model="detailContent.businessTerm"
                  placeholder="若证件有效期为长期，请输入长期。如2020年1月1日-长期"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 银行信息 -->
        <div class="attestation-basic-info">
          <p class="info-title">银行信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="银行卡账号" required>
                <br />
                <el-input
                  v-model="detailContent.bankCard"
                  placeholder="请输入您的银行卡账号"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行">
                <br />
                <el-input
                  v-model="detailContent.openingBank"
                  placeholder="请输入您的银行卡开户行"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户人" required>
                <br />
                <el-input
                  v-model="detailContent.bankHolders"
                  placeholder="请输入开户人姓名"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 项目信息 -->
        <div class="attestation-basic-info" v-if="type === 'company'">
          <p class="info-title">项目信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="项目联系人">
                <br />
                <el-input
                  v-model="detailContent.contacts"
                  placeholder="请输入项目联系人的姓名"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话">
                <br />
                <el-input
                  v-model="detailContent.enterprisePhone"
                  placeholder="请输入项目联系人的电话"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系邮箱">
                <br />
                <el-input
                  v-model="detailContent.email"
                  placeholder="请输入项目联系人的邮箱"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 上传资料 -->
        <div class="attestation-basic-info">
          <p class="info-title">上传资料</p>
          <el-row :gutter="86" v-if="type === 'person'">
            <el-col :span="6">
              <el-form-item label="上传手持身份证人像面">
                <br />
                <el-image
                  :src="detailContent.idcardFront"
                  :preview-src-list="[detailContent.idcardFront]"
                ></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上传手持身份证国徽面">
                <br />
                <el-image
                  :src="detailContent.idcardReversed"
                  :preview-src-list="[detailContent.idcardReversed]"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="86" v-if="type === 'company'">
            <el-col :span="12">
              <el-form-item label="盖企业章的营业执照照片">
                <br />
                <el-image
                  :src="detailContent.businessIicense"
                  :preview-src-list="[detailContent.businessIicense]"
                ></el-image>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 其它详细 -->
        <!-- <div class="attestation-basic-info">
          <p class="info-title">审核</p>
        </div> -->
        <!-- 审核按钮 -->
        <template
          v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business'"
        >
          <div
            class="attestation-basic-info"
            style="border-top: 1px solid #eeeeee; padding-top: 20px"
          >
            <el-row :gutter="86">
              <el-col :span="12">
                <el-form-item
                  label="审核结果"
                  :rules="[
                    {
                      required: true,
                      message: '请填写审核结果',
                      trigger: 'change',
                    },
                  ]"
                  required
                >
                  <br />
                  <el-radio-group v-model="status" size="small">
                    <el-radio :label="1" border>审核通过</el-radio>
                    <el-radio :label="2" border>审核未通过</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="attestation-basic-info">
            <div class="detail-btn-box">
              <el-button class="detail-btn-item" @click="eventSubmit"
                >提交</el-button
              >
            </div>
          </div>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import {
  getEpibolyBusinessDetailById,
  getEpibolyBusinessVerify,
} from "@/api/ectocyst.js";
export default {
  data() {
    return {
      //type: "person",
      // 营业执照所在地址
      licenseAddress: {
        province: undefined,
        city: undefined,
        area: undefined,
      },
      detailContent: {},
      status: undefined,
    };
  },
  props: {
    type: {
      type: String,
      require: true,
    },
    id: {
      require: true,
    },
  },
  components: {
    VDistpicker,
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let { code, data } = await getEpibolyBusinessDetailById({ id: this.id });
      if (code === 200) {
        this.detailContent = data;
      }
    },
    // 点击返回
    eventBack() {
      this.detailContent = {};
      this.$emit("back");
    },
    // 提交审核
    async eventSubmit() {
      if (!this.status) {
        this.$message({
          message: "请选择审核结果",
          type: "warning",
        });
        return;
      }
      let { code } = await getEpibolyBusinessVerify({
        aid: this.id,
        status: this.status,
      });
      if (code === 200) {
        this.$message({
          message: "审核成功",
          type: "success",
          duration: 2000,
        });
        this.$emit("list");
        this.eventBack();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  //width: 100%;

  //padding: 20px 50px;
  .detail-header {
    width: 100%;
    //border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    padding: 20px 50px 10px;
    background-color: #ffffff;
  }
  .detail-content {
    margin-top: 10px;
    padding: 10px 50px 20px;
    background-color: #ffffff;
    .attestation-basic-info {
      margin-top: 20px;
      .info-title {
        position: relative;
        text-indent: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: var(--default-ectocyst-color);
        }
      }
    }
  }

  .detail-btn-box {
    text-align: center;
    .detail-btn-item {
      background-color: var(--default-ectocyst-color);
      color: #ffffff;
      padding: 10px 60px;
      font-size: 20px;
    }
  }
}
</style>