<template>
  <div class="home-container">
    <div class="home-content">
      <div class="home-content-left">
        <p>怪屋集训营</p>
        <p>游戏美术精品课程</p>
      </div>
      <div class="home-content-right">
        <div class="content-line-container">
          <div class="content-line">
            <div class="content-line-icon">
              <p>精品</p>
            </div>
            <div class="content-line-text">怪屋 • 游戏美术</div>
            <div class="content-line-link">
              <!-- <img src="@/assets/img/train/link.png" /> -->
            </div>
          </div>
        </div>
        <div class="content-line-container content-line-container-bottom">
          <div class="content-line">
            <div class="content-line-icon">
              <img v-lazy="require('@/assets/img/train/Unity.png')" />
            </div>
            <div class="content-line-text">
              <p>Unity引擎特效课程</p>
              <span>基础课堂</span>
              <span>进阶课堂</span>
              <span>6个月</span>
            </div>
            <div class="content-line-link" @click="eventToPage(4)">
              <img v-lazy="require('@/assets/img/train/link.png')" />
            </div>
          </div>
          <div class="content-line">
            <div class="content-line-icon">
              <img v-lazy="require('@/assets/img/train/3dsmax.png')" />
            </div>
            <div class="content-line-text">
              <p>3dsMax游戏动作课程</p>
              <span>基础课堂</span>
              <span>进阶课堂</span>
              <span>6个月</span>
            </div>
            <div class="content-line-link" @click="eventToPage(5)">
              <img v-lazy="require('@/assets/img/train/link.png')" />
            </div>
          </div>
          <div class="content-line-btn" @click="eventToPage(15)">留言咨询</div>
        </div>
      </div>
    </div>
    <span class="el-icon-arrow-down"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    eventToPage(n) {
      this.$emit("eventToPage", n);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;
  .home-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 23%;
    display: flex;
    width: fit-content;
    align-items: center;
    .home-content-left {
      p {
        color: #ffffff;
        font-weight: bold;
      }
      p:nth-child(1) {
        font-size: 80px;
        letter-spacing: 20px;
      }
      p:nth-last-child(1) {
        margin-top: 20px;
        font-size: 32px;
        letter-spacing: 5px;
      }
    }
    .home-content-right {
      margin-left: 150px;
      .content-line-container {
        background-color: rgba(40, 45, 60, 0.4);
        padding: 20px 30px;
        border-radius: 10px;
        .content-line {
          display: flex;
          align-items: center;
          color: #9abfcc;
          border-bottom: 1px solid rgba($color: #9abfcc, $alpha: 0.4);
          .content-line-icon {
            padding: 0 15px;
            color: #47ffff;
            border: 1px solid #47ffff;
            line-height: 24px;
            border-radius: 17px;
            font-size: 12px;
            > p {
              width: 50px;
              text-align: center;
              letter-spacing: 2px;
              font-size: 16px;
            }
          }
          .content-line-text {
            margin-left: 20px;
            font-size: 18px;
            width: 180px;
            display: flex;
            flex-wrap: wrap;
            span {
              font-size: 14px;
              background-color: #4e535f;
              padding: 0px 8px;
              border-radius: 11px;
              margin-right: 10px;
              margin-top: 15px;
              height: 22px;
              line-height: 22px;
              display: inline-block;
            }
          }
          .content-line-link {
            font-size: 20px;
            font-weight: 100;
            margin-left: 20px;
            cursor: pointer;
          }
        }
        .content-line:nth-last-of-type(1) {
          border-bottom: none;
        }
        .content-line-btn {
          width: 100%;
          height: 60px;
          font-size: 18px;
          line-height: 60px;
          text-align: center;
          color: #8fb1bd;
          border: 1px solid #8fb1bd;
          cursor: pointer;
        }
      }
      .content-line-container.content-line-container-bottom {
        margin-top: 20px;
        padding: 0 30px 20px;
        .content-line {
          //margin-bottom: 10px;
          padding: 30px 0;
          .content-line-icon {
            width: 82px;
            height: 82px;
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
  .el-icon-arrow-down {
    color: rgba(255, 255, 255, 0.4);
    font-size: 32px;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    //动画的名称(必须要有)
    animation-name: move;
    //动画的运动曲线(linear是匀速stept()是分步)
    animation-timing-function: linear;
    //动画的运动时间
    animation-duration: 2s;
    //动画的运动次数(infinite是无限循环)
    animation-iteration-count: infinite;
    //动画是否逆序播放
    animation-direction: alternate;
  }
  @keyframes move {
    0% {
      bottom: 40px;
    }

    25% {
      bottom: 28px;
    }

    50% {
      bottom: 16px;
    }

    75% {
      bottom: 28px;
    }

    100% {
      bottom: 40px;
    }
  }
}
</style>