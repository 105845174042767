import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import Dialog from './components/common/Dialog/dialog.js'
// import Toast from './components/common/Toast/toast.js'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
// ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);
// 轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'

// 引入屏幕适配
import '@/tools/rem.js'

// 页面加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({     
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})
  
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import Cookie from "js-cookie";  //引入
Vue.prototype.$cookie = Cookie  //挂载全局

// vant 组件 按需导入 start
import { Button,Popup,Area,CountDown } from 'vant';

const TcPlayer = window.TCPlayer;
console.log('TcPlayer:',TcPlayer)
Vue.prototype.TcPlayer = TcPlayer;

// 数字动态


Vue.use(Button);
Vue.use(Popup);
Vue.use(Area);
Vue.use(CountDown);
// end


// Vue.use(Dialog)
// Vue.use(Toast)


// 过滤器 时间转换
const { timeago,getTime,compare, _isMobile } = require('./tools/timeCycle.js');

// 定义全局的时间过滤器
Vue.filter('Timeago', timeago);
Vue.filter('Remainingtime', getTime);
Vue.filter('Compare', compare);

import moment from 'moment'
//  全局过滤器 时间戳 
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (dataStr) {
    return moment(dataStr).format(pattern)
  } else {
    return dataStr
  }
})

// 延迟加载 用于图片
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload) 

Vue.config.productionTip = false

const isMobile = _isMobile();
if (process.env.NODE_ENV === 'production') {
  if (isMobile) {
    // 跳转至手机端路由
    window.location.href = "http://mobile.glsxvfx.com";
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
