<template>
  <div class="workList-container">
    <!-- <div class="conllection-type-box">
            <div class="type-item" :class="workType === 1 && 'is-active'" @click="changeWorkType(1)">作品</div>
            <div class="type-item" :class="workType === 2 && 'is-active'" @click="changeWorkType(2)">招聘</div>
        </div> -->
    <div class="conllection-list-box">
      <div class="uploadwork">
        共收藏{{ worknum || 0 }}组{{ workType === 1 ? "作品" : "招聘信息" }}
      </div>
      <div class="work-lists">
        <template v-if="workType === 1">
          <router-link
            class="work-container"
            v-for="(item, index) in workList"
            :key="index"
            tag="a"
            target="_blank"
            :to="`/workDetail?activeIndex=0&workDetailId=${item.workId}`"
          >
            <div class="work-cover">
              <div class="shadown"></div>
              <img :src="item.tbWork.cover" alt="" />
            </div>
            <div class="work-content">
              <div class="work-title">
                {{ item.tbWork.title }}
              </div>
              <div class="work-live">
                <div class="work-look">
                  <img
                    src="@/assets/newImg/37.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                  <div>{{ item.tbWork.browseNumber }}</div>
                </div>
                <div class="work-look">
                  <img
                    src="@/assets/newImg/38.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                  <div>{{ item.tbWork.commentNum }}</div>
                </div>
                <div class="work-look">
                  <img
                    src="@/assets/newImg/39.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                  <div>{{ item.tbWork.collectNum }}</div>
                </div>
                <div class="work-look">
                  <img
                    src="@/assets/newImg/43.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                  <div>{{ item.tbWork.collectNum }}</div>
                </div>
              </div>
              <div class="work-user">
                <div class="lasttime">
                  {{ item.tbWork.createTime | dateformat("YYYY-MM-DD") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-if="workType === 2">
          <router-link
            v-for="item in workList"
            :key="item.id"
            tag="a"
            target="_blank"
            style="width: 100%"
            :to="`/recruitDetail_?activeIndex=4&id=` + item.id"
          >
            <r-item :recruitInfo="item"></r-item>
          </router-link>
        </template>
      </div>
      <div class="kong" v-if="!workList.length">暂无数据！</div>
      <div class="paginations" v-if="workList.length">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="conllection-list-box" v-if="workType === 1">
      <div class="uploadwork">共收藏{{coursesNum || 0}}组课程</div>
      <div class="courses-lists">
        <router-link
          class="courses-container"
          v-for="(item, index) in coursesList"
          :key="index"
          tag="a"
          target="_blank"
          :to="`/coursesDetail?activeIndex=6&id=` + item.id"
        >
            <c-item :info="item"></c-item>
        </router-link>
      </div>
      <div class="kong" v-if="!coursesList.length">暂无数据！</div>
      <div class="paginations" v-if="coursesList.length">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="coursesForm.pageNum"
          :page-size="coursesForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total2"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollectList } from "@/api/message.js";
import { getJobCollectList } from "@/api/company.js";
import rItem from "@/views/recruit/components/item.vue";
import cItem from "@/views/courses/components/item.vue";
export default {
  data() {
    return {
      userInfo: "",
      workList: "",
      coursesList: [],
      total: 0,
      total2: 0,
      pageSize: 18,
      currentPage: 1,
      worknum: 0,
      coursesNum: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 6,
      },
      coursesForm: {
        pageNum: 1,
        pageSize: 6,
      },
      recruitPageForm: {
        pageNum: 1,
        pageSize: 5,
      },
      workType: 1,
      tabPosition: "left",
    };
  },
  props: {
    consIndex: {
      require: true,
    },
  },
  watch: {
    consIndex(v) {
      this.workType = v == 8 ? 2 : 1;
      this.getWorkList();
    },
  },
  components: {
    rItem,
    cItem
  },
  created() {
    this.userInfo = this.$parent.userInfo;
    console.log(this.$route.query, this.$parent.consIndex);
    this.workType = this.consIndex == 8 ? 2 : 1;
    this.getWorkList();
    // console.log(this.$store.state.userInfo);
  },
  methods: {
    // 收藏中职位收藏已被分出去
    async getWorkList() {
      if (this.workType === 1) {
        let data = {
          userId: this.userInfo.id,
          flag: 1,
          ...this.queryForm,
        };
        let { code, rows, total } = await getCollectList(data);
        if (code === 200) {
          this.workList = rows.filter((v) => v.tbWork);
          this.total = total;
          this.worknum = total;
        }
        let data2 = {
          userId: this.userInfo.id,
          flag: 3,
          ...this.coursesForm,
        };
        // let { code2, rows2, total2 } = await getCollectList(data2);
        // if (code2 === 200) {
        //   this.coursesList = rows2;
        //   this.total2 = total2;
        //   this.coursesNum = total2;
        // }
        getCollectList(data2).then(res => {
            if (res.code === 200) {
                this.coursesList = res.rows;
                this.total2 = res.total;
                this.coursesNum = res.total;
            }
        })
      } else if (this.workType === 2) {
        let data = {
          userId: this.userInfo.id,
          flag: 2,
          ...this.recruitPageForm,
        };
        // let {code,rows,total} = await getJobCollectList(data);
        let { code, rows, total } = await getCollectList(data);
        if (code === 200) {
          this.workList = rows;
          this.total = total;
          this.worknum = total;
        }
      }
    },
    getWorkDetail(item) {
      this.$router.push(
        `/workDetail?activeIndex=0&workDetailId=${item.tbWork.id}`
      );
    },
    // 页数切换
    handleCurrentChange(val) {
      if (this.workType === 1) {
        this.queryForm.pageNum = val;
      } else if (this.workType === 2) {
        this.recruitPageForm.pageNum = val;
      }
      this.getWorkList();
    },
    // 课程页数切换
    handleCurrentChange(val) {
      if (this.workType === 1) {
        this.queryForm.pageNum = val;
      } else if (this.workType === 2) {
        this.recruitPageForm.pageNum = val;
      }
      this.getWorkList();
    },
    changeWorkType(t) {
      if (this.workType === 1) {
        this.queryForm.pageNum = 1;
      } else if (this.workType === 2) {
        this.recruitPageForm.pageNum = 1;
      }
      this.workType = t;
      this.getWorkList();
    },
  },
};
</script>

<style lang="scss" scoped>
.workList-container {
  overflow: hidden;
  padding-top: 50px;
  //display: flex;
  .conllection-type-box {
    //display: inline-block;
    background: rgba(91, 91, 109, 0.3);
    padding: 5px;
    font-family: biaoti;
    width: 150px;
    height: auto;
    box-sizing: border-box;
    height: 200px;
    .type-item {
      font-size: 24px;
      height: 60px;
      line-height: 60px;
      color: #ffffff;
      letter-spacing: 5px;
      text-align: center;
      cursor: pointer;
      &.is-active {
        background-color: #35edff;
      }
    }
    // /deep/.el-tabs--left .el-tabs__item{
    //     font-size: 24px;
    //     height: 60px;
    //     line-height: 60px;
    //     color: #FFFFFF;
    // }
    // /deep/.el-tabs--left .el-tabs__item.is-active{
    //     color: #35edff;
    // }
    // /deep/.el-tabs__active-bar{
    //     background-color: #35edff;
    // }
  }

  .conllection-list-box {
    //width: calc(100% - 150px);
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .uploadwork {
    // width: 158px;
    height: 31px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 31px;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
    //margin-top:50px;
    //margin-left:56px;
  }
  .work-lists {
    //margin-top:15px;
    //margin-left:26px;
    display: flex;
    flex-wrap: wrap;
  }

  .courses-lists{
    display: flex;
    flex-wrap: wrap;

    .courses-container{
        width: 48%;
        &:nth-child(odd) {
            margin-right: 4%;
        }
    }
  }



  .work-container {
    width: 250px;
    height: 350px;
    background: #0b1525;
    overflow: hidden;
    margin-right: 20px;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 5px;
    .work-cover {
      width: 100%;
      height: 220px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .shadown {
        width: 100%;
        height: 220px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
          to top,
          rgba(0, 14, 14, 1) 0%,
          rgba(0, 14, 41, 0.3) 10%,
          rgba(0, 0, 0, 0)
        );
      }
    }
    .work-status {
      width: 100%;
      height: 20px;
      position: relative;
      bottom: 25px;
      // left: 0px;
      font-size: 11px;
      line-height: 20px;
      color: rgba(255, 255, 255, 1);
      background-color: rgba(0, 0, 0, 0.6);
      text-align: center;
      display: none;
      margin-bottom: 10px;
    }
    .remove-icon {
      width: 25px;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 6px;
      color: rgba(255, 255, 255, 1);
      display: none;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .work-content {
      width: 100%;
      // background: #252525;
      opacity: 1;
      .work-title {
        margin-top: 12px;
        width: 100%;
        height: 38px;
        font-size: 16px;
        font-family: SimHei;
        font-weight: 500;
        line-height: 38px;
        color: rgb(0, 225, 225);
        opacity: 1;
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .work-tags {
        height: 18px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.767);
        padding-left: 12px;
      }
      .work-live {
        width: 251px;
        display: flex;
        margin-top: 10px;
        .work-look {
          height: 18px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            line-height: 0px;
            color: rgba(255, 255, 255, 0.822);
            // color: rgba(154, 227, 103, 0.5);
            margin-left: 6px;
            display: block;
          }
          &:first-child {
            margin-left: 12px;
          }
          &:nth-of-type(n + 2) {
            margin-left: 20px;
          }
        }
      }
      .work-user {
        height: 18px;
        display: flex;
        justify-content: space-between;
        padding: 0px 12px;
        align-items: center;
        margin-top: 20px;
        .lasttime {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 500;
          line-height: 30px;
          color: rgba(190, 188, 188, 0.65);
        }
      }
    }
  }
  .kong {
    width: 100%;
    height: 300px;
    text-align: center;
    color: white;
    line-height: 300px;
  }
  .paginations {
    margin-top: 80px;
    margin-bottom: 100px;
    .el-pagination {
      text-align: center;
    }
    ::v-deep .el-pagination .btn-prev {
      // background: #353849;
      width: 65px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0px;
    }
    ::v-deep .el-pager li {
      background: #181a29;
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      background-color: #313345;
      margin-left: 10px;
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      text-align: center;
    }
    ::v-deep .el-pagination .btn-next {
      width: 65px;
      margin-left: 10px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0;
      // background: #353849;
    }
    ::v-deep .el-pager li.active {
      color: white !important;
      // background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
      background: #00b4d4;
    }
    ::v-deep .el-pagination__jump {
      display: inline-block;
      font-size: 16px;
      min-width: 35.5px;
      height: 35px;
      padding: 0 10px;
      line-height: 35px;
      vertical-align: top;
      box-sizing: border-box;
      border: 1px solid #414141;
      color: #ffffff;
      background-color: #313345;
    }
    ::v-deep .el-input {
      width: 70px;
      height: 33px;
    }
    ::v-deep .el-pagination__editor.el-input .el-input__inner {
      height: 24px;
      top: -2px;
      background: #001220 !important;
      color: white;
      border: none;
    }
    ::v-deep .el-pagination__editor {
      margin: 0 10px;
    }
  }
}
</style>