<template>
  <div class="examine-container">
    <div class="examine-content">
      <div class="examine-left">
        <div class="examine-left-header">
          <img
            :src="JSON.parse(this.$cookie.get('userInfo')).epibolyInfo.headImage || require('@/assets/img/1.png')"
          />
          <p>{{JSON.parse(this.$cookie.get('userInfo')).epibolyInfo.name}}</p>
        </div>
        <el-menu
          mode="vertical"
          :default-active="menuObject.defaultActive"
          background-color="transparent"
          text-color="#677288"
          active-text-color="#FFFFFF"
          @select="handleSelect"
        >
          <template v-for="item in menuObject.list">
            <!-- 有子菜单 -->
            <el-submenu :index="item.index" v-if="item.list" :key="item.index">
              <template slot="title"
                ><i :class="item.icon"></i>{{ item.title }}</template
              >
              <el-menu-item
                :index="citem.index"
                v-for="citem in item.list"
                :key="citem.index"
                >{{ citem.title }}</el-menu-item
              >
            </el-submenu>
            <!-- 没有子菜单 -->
            <el-menu-item v-else :index="item.index" :key="item.index">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="examine-right">
        <slot name="rightContent"></slot>
        <!-- <div class="examine-right-header">
          <div class="header-top">
            <div class="header-form">
              <slot name="headerForm"></slot>
            </div>
          </div>
          <div class="header-bottom">
            <el-tabs
              v-model="tabsObject.activeName"
              @tab-click="handleClick"
              v-if="tabsObject"
            >
              <el-tab-pane
                :label="item.label"
                :name="item.name"
                v-for="item in tabsObject.list"
                :key="item.name"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="examine-right-list">
          <slot name="dataList"></slot>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getEpibolyList } from "@/api/ectocyst.js";
import item from "../components/item.vue";
export default {
  data() {
    return {
      options: [],
      activeName: "all",
      queryForm: {
        state: undefined,
      },
      dataList: [],
      testContent: {
        flag: true,
        classifyName: "特效",
        title: "这是个测试的数据呀",
        eid: 101,
        status: 0,
        money: "￥1000 - 2000",
      },
    };
  },
  props: {
    menuObject: {
      type: Object,
      default: {},
    },
    tabsObject: {
      type: Object,
    },
  },
  components: {
    item,
  },
  created() {},
  methods: {
    handleSelect(key) {
      this.$emit("changeSelect", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.examine-container {
  position: absolute;
  height: calc(100% - 60px);
  width: 100%;
  .examine-content {
    width: 100%;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 5px;
      background-color: var(--default-ectocyst-color);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .examine-left {
      width: 230px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #262932;
      .examine-left-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        padding: 50px 0;
        > img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        > p {
          color: #677288;
          margin-top: 10px;
          font-size: 20px;
          letter-spacing: 1px;
          font-weight: bold;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      /deep/.el-menu {
        border: none;
      }

      /deep/.el-submenu__title:hover {
        background-color: var(--default-ectocyst-color) !important;
        color: #ffffff !important;
        > i {
          color: #ffffff !important;
        }
      }
      /deep/.el-menu-item {
        //padding-left: 30px !important;
        &.is-active {
          background-color: var(--default-ectocyst-color) !important;
        }
        &:hover {
          background-color: var(--default-ectocyst-color) !important;
          color: #ffffff !important;
          > i {
            color: #ffffff !important;
          }
        }
      }
    }

    .examine-right {
      width: calc(100% - 230px);
      margin-left: 230px;
      height: 100%;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .examine-right-header {
        width: 100%;
        //height: 120px;
        padding: 20px;
        padding-bottom: 0;
        box-sizing: border-box;
        background: #ffffff;
        .header-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          .header-title {
            font-weight: bold;
          }
          .header-form {
            //margin-top: 15px;
            text-align: right;
            /deep/.el-form-item {
              margin-bottom: 0;
            }
          }
        }
        .header-bottom {
          //margin-top: 10px;
          /deep/.el-tabs__header {
            margin-bottom: 0;
          }
          /deep/.el-tabs__nav-wrap::after {
            background-color: #ffffff;
            bottom: -2px;
          }
          /deep/.el-tabs__item {
            font-size: 16px;
          }
          /deep/.el-tabs__item:hover {
            color: var(--default-ectocyst-color);
          }
          /deep/.el-tabs__item.is-active {
            color: var(--default-ectocyst-color);
            font-weight: bold;
          }
          /deep/.el-tabs__active-bar {
            background-color: var(--default-ectocyst-color);
          }
        }
      }

      .examine-right-list {
        padding-top: 10px;
        box-sizing: border-box;
        // overflow: auto;
        // scrollbar-width: none;
        // -ms-overflow-style: none;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }
    }
  }
}
</style>