<template>
  <div class="workDetail" style="min-height: calc(100vh - 60px)">
    <div v-show="isshowinp" class="input-content2" id="input">
      <el-input v-model="comments2" :placeholder="placeholder"> </el-input>
      <div class="insertComment" @click="getInsertComment(2, 'comments2')">
        发送
      </div>
    </div>
    <div class="workLeft" :style="workDetailList.workType == 2 && {'width': '100%' }">
      <div class="leftConten">
        <div class="info-header">
          <div class="info-title">
            <div class="info-titles">{{ workDetailList.title }}</div>
          </div>
          <!-- {{ workclass }} -->
          <div class="info-tags" v-html="workclass"></div> 
          <div class="info-footer">
            <div class="info-live">
              <div class="work-look">
                <img
                  src="@/assets/newImg/37.png"
                  alt=""
                  title="浏览数"
                  style="width: 15px; height: 13px"
                />
                <div>{{ workDetailList.browseNumber }}</div>
              </div>
              <!-- <div class="work-look">
                <img
                  src="@/assets/newImg/38.png"
                  alt=""
                  title="评论数"
                  style="width: 17px; height: 14px"
                />
                <div>{{ workDetailList.commentNum }}</div>
              </div> -->
              <div class="work-look">
                <img
                  src="@/assets/newImg/39.png"
                  alt=""
                  title="收藏数"
                  style="width: 15px; height: 14px"
                />
                <div>{{ workDetailList.collectNum }}</div>
              </div>
              <div class="work-look">
                <img
                  src="@/assets/newImg/40.png"
                  alt=""
                  title="点赞数"
                  style="width: 15px; height: 14px"
                />
                <div>{{ workDetailList.likeNum }}</div>
              </div>
            </div>
            <div class="info-times">
              发布于
              {{ workDetailList.createTime | dateformat("YYYY/MM/DD HH:mm") }}
            </div>
          </div>
        </div>
        <div class="belows-contents">
          <div class="belows-contents-v" v-if="!workDetailList.classifyList || workDetailList.classifyList[0].id !== 41">
            <div class="belows-contents-video">
              <video
                controlslist="nodownload"
                autoplay="autoplay"
                controls="controls"
                class="video-item"
                v-if="activeWorkObj && activeWorkObj.type === 'video'"
                :key="videoKey"
                id="video_test"
              >
                <source :src="activeWorkObj && activeWorkObj.src" />
                您的浏览器不支持 video 标签。
              </video>
              <el-image
                  class="work_img"
                  :src="activeWorkObj && activeWorkObj.src"
                  fit="contain"
                  :preview-src-list="[activeWorkObj.src]"
                  v-else
                >
              </el-image>
            </div>
            <div class="more" v-if="workVideoImgList.length > 1">
              <div class="prev-btn" @click="getprev">
                <i class="el-icon-caret-left"></i>
              </div>
              <div class="moreImg">
                <div class="workImgs">
                  <div
                    class="workImg"
                    v-for="(item, index) in workVideoImgList"
                    :key="index"
                    @click="showWorkItem(index)"
                  >
                    <img :src="item.cover" />
                    <div
                      class="thisTag"
                      v-show="activeWorkIndex === index"
                    ></div>
                  </div>
                  <!-- <div
                    class="workImg"
                    v-for="(item, index) in workList"
                    :key="index"
                    @click="goWorkDetail(item)"
                  >
                    <img :src="item.cover" />
                    <div
                      class="thisTag"
                      v-show="item.id == workDetailList.id"
                    ></div>
                  </div> -->
                </div>
              </div>
              <div class="next-btn" @click="getnext">
                <i class="el-icon-caret-right"></i>
              </div>
            </div>
            <div class="belows-contents-text" @click="toInspirationPage(workDetailList.inspiration)" v-if="workDetailList.classifyList && workDetailList.classifyList[0].id === 42">灵感出处：{{workDetailList.inspiration}}</div>
            <div class="belows-contents-title" v-if="workDetailList.description">
              <p>作者寄语：</p>
              {{ workDetailList.description }}
            </div>
          </div>
          <div class="belows-contents-v belows-contents-skill" v-html="skillText" v-else></div>
          <div class="give-like" v-if="workDetailList.workType != 2">
            <div class="line-left"></div>
            <div class="likes" @click="getLikeNum">
              <img
                src="@/assets/newImg/like1.png"
                alt=""
                v-show="workDetailList.likeFlag == 0"
                title="点赞"
              />
              <img
                src="@/assets/newImg/like2.png"
                alt=""
                v-show="workDetailList.likeFlag !== 0"
                title="取消点赞"
              />
            </div>
            <div class="line-right"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="input-comment" v-if="workDetailList.workType != 2">
      <div class="input-release" v-show="!showTextarea" @click.stop="showTextarea = true"><img src="../../assets/newImg/46.png" alt="" srcset=""  />发表评论</div>
      <div class="input-textarea" v-show="showTextarea" id="textarea">
        <el-input
          type="textarea"
          placeholder="不妨夸夸作者~"
          v-model="comments"
          maxlength="30"
          show-word-limit>
        </el-input>
        <div class="input-release" @click="getInsertComment(1, 'comments')"><i class="el-icon-s-promotion"></i>发送</div>
      </div>
    </div> -->
    <div class="workRight" ref="workRight" :style="{height: showTextarea ? rightHeight.height - 115 + 'px' : rightHeight.height + 'px'} " v-if="workDetailList.workType != 2">
      <div class="users-content-header">
        <div v-if="workDetailList.userInfo" class="users-content-img">
          <img
            :src="workDetailList.userInfo.headImg || workDetailList.userInfo.avatar"
            title="点击可查看用户个人主页"
            @click="getUserPersonal(workDetailList.userId)"
          />
          <p class="users-content-level">Lv. {{ levelNum }}</p>
        </div>
        <div class="users-content-user" v-if="workDetailList.userInfo">
          <div class="users-content-username">
            {{ workDetailList.userInfo.nickName }}
          </div>
          <!-- <div class="users-content-tag">神秘组织/设计鬼才</div> -->
          <div class="users-content-lv">
            <Lv :userId="workDetailList.userId" @getLevel="getLevel"></Lv>
          </div>
          <!-- <div style="display: flex">
            <div class="medal" v-for="item in 3" :key="item">
              <img src="@/assets/img/layericon/medal.png" alt="" />
            </div>
          </div> -->
          <Medal :userId="workDetailList.userId"></Medal>
        </div>
      </div>
      <div class="gzsc" v-if="workDetailList.userInfo">
        <div class="gz" @click="goAddFocus">
          <div class="AddFocus" v-show="!workDetailList.userInfo.focusFlag">
            <span>+</span>关注
          </div>
          <div
            class="removeFocus"
            v-show="workDetailList.userInfo.focusFlag == 1"
          >
            已关注
          </div>
        </div>
        <div class="sc" @click="getCollection">
          <div class="AddCollection" v-show="!workDetailList.collectFlag">
            <span>√</span>收藏
          </div>
          <div
            class="removeCollection"
            v-show="workDetailList.collectFlag == 1"
          >
            已收藏
          </div>
        </div>
      </div>

      <!-- <div class="all-comment">
        <div class="all-comments">
          <div class="comment-title">
            <img src="../../assets/newImg/44.png" alt="" srcset="" />热评
          </div>
          <div class="hotcomment2" v-if="!hotcomment">
            暂时没有热评哦~~抢占第一个热评吧！
          </div>
          <div class="hotcomment" v-else>
            <replay-message
              :hotComment="hotcomment"
              :workDetailList="workDetailList"
              @handleAddComment="addComment"
              @getCommentNums="getCommentNums"
			        @getUserPersonal="getUserPersonal"
              style="padding-bottom: 0"
            ></replay-message>
          </div>
          <div class="comment-title">
            <img src="../../assets/newImg/45.png" alt="" srcset="" />全部评论
          </div>
          <div class="hotcomment2" v-if="!commentLists.length">
            暂时没有评论哦~~留下第一发评论吧！
          </div>
          <div class="" v-else>
            <replay-message
              :hotComment="item"
              :workDetailList="workDetailList"
              @handleAddComment="addComment"
              @getCommentNums="getCommentNums"
              @getUserPersonal="getUserPersonal"
              v-for="item in commentLists"
              :key="item.id"
            ></replay-message>
            <p class="content-more">没有更多啦~</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  getWorksDetail,
  getLiveLike,
  calcelLiveLike,
  addFocus,
  cancelFocus,
  commentList,
  insertComment,
  insertCollection,
  deleteCollection,
  getWorkClassInfo,
  hotComment,
  removeComment,
} from "@/api/work.js";
import { getFollowUs } from "@/api/company.js";
import Lv from "@/component/lv/newIndex.vue";
import medal from "@/component/medal/index.vue";
import replayMessage from "./components/replayMessage.vue";
import Medal from "@/component/medal/index.vue";
export default {
  data() {
    return {
      comments2: "",
      comments: "",
      workDetailList: {},
      likeNum: "",
      commentLists: "",
      hotcomment: undefined,
      srcList: [],
      workList: [],
      workclass: "",
      isshowComment: false,
      placeholder: "",
      parent_id: "",
      parentNickname: "",
      tuUserId: "",
      footerData: "",
      footerMessage: "",
      followUs: {
        wechatImg: "",
        qqImg: "",
      },
      showFlag1: false,
      showFlag2: false,
      isshowall: false,
      isshowinp: false,
      rightHeight: {
        height: "",
      },
      timeout: null,
      timeout1: null,
      timeout2: null,
      isshowmore: false,
      toContent: "",
      tranLeft: 0,
      tranTop: 0,
      translatax: 0,
      showTextarea: false,
      // 作品作者id
      opusUserId: "",
      levelNum: 1,
      // 该作品的视频与图片集合
      workVideoImgList: [],
      // 所选中展示的内容
      activeWorkObj: undefined,
      activeWorkIndex: 0,
      // 技巧内容
      skillText: undefined,
      videoKey: Math.random()
    };
  },
  components: {
    Lv,
    medal,
    replayMessage,
    Medal
},
  created() {
    if (this.$route.query.workDetailId) {
      this.getWorkDetail(this.$route.query.workDetailId);
      this.getCommentList();
    }
    this.rightHeight.height = window.innerHeight - 45 - 60;
    this.footerData = [
      {
        labelId: 1,
        labelName: "关于我们",
        url: "/home/homeInfo?labelId=1&className=default",
      },
      {
        labelId: 2,
        labelName: "用户协议",
        url: "/home/homeInfo?labelId=2&className=default",
      },
      {
        labelId: 3,
        labelName: "隐私政策",
        url: "/home/homeInfo?labelId=3&className=default",
      },
      {
        labelId: 4,
        labelName: "帮助中心",
        url: "/home/homeInfo?labelId=4&className=help",
      },
      {
        labelId: 5,
        labelName: "联系我们",
        url: "/home/homeInfo?labelId=5&className=contact",
      },
      {
        labelId: 6,
        labelName: "侵权投诉",
        url: "/home/homeInfo?labelId=6&className=contact",
      },
    ];
    this.footerMessage = [
      {
        title: "蜀ICP备 2021000163号",
        toUrl: "https://beian.miit.gov.cn",
      },
      {
        title: "增值电信业务经营许可证：川B2-20200879",
        toUrl:
          "https://designoss.oss-cn-beijing.aliyuncs.com/upload/2021/09/03/03eaf1aa0adb49fb81f0cc431a236140.pdf",
      },
      {
        title: "网络文化经营许可证  川网文 {2020} 4819-979号 ",
        toUrl:
          "https://designoss.oss-cn-beijing.aliyuncs.com/upload/2021/09/03/dfe1ba772bdd4910bb4a8ab7b66fc0c2.pdf",
      },
    ];
    this.getFollowUs();
  },
  methods: {
    getprev() {
      let moreimg = document.querySelector(".workImgs");
      let max = this.workVideoImgList.length * 156 - moreimg.offsetWidth;
      if (this.translatax < 0) {
        this.translatax += 156;
      }
      moreimg.style.transform = "translateX(" + this.translatax + "px)";
    },
    getnext() {
      let moreimg = document.querySelector(".workImgs");
      let max = this.workVideoImgList.length * 156 - moreimg.offsetWidth;
      if (this.translatax > -max) {
        this.translatax -= 156;
      }
      moreimg.style.transform = "translateX(" + this.translatax + "px)";
    },
    moreintro(e) {
      if (this.isshowmore == false) {
        e.target.style.webkitLineClamp = 10;
        this.isshowmore = true;
      } else if (this.isshowmore == true) {
        e.target.style.webkitLineClamp = 2;
        this.isshowmore = false;
      }
    },
    goGuide() {
      this.$router.push("/guide");
    },
    backtop() {
      document.documentElement.scrollTop = 0;
    },
    gocontnctus() {
      this.companyIndex = 4;
      this.$router.push("/home/contnctus?activeIndex=1&companyIndex=4");
    },
    getFollowUs() {
      getFollowUs().then((res) => {
        this.followUs = res.data;
      });
    },
    // 处理显示回复的数量
    getShowMessageNum(messageArr) {
      messageArr = !this.isshowall && messageArr.slice(0, 2);
      return messageArr;
    },

    handleClick1() {
      this.showFlag1 = !this.showFlag1;
      this.showFlag2 = false;
    },
    handleClick2() {
      this.showFlag1 = false;
      this.showFlag2 = !this.showFlag2;
    },
    goWorkDetail(item) {
      this.$router.push(
        `/workDetail?activeIndex=0&workDetailId=${item.workId}`
      );
      location.reload();
    },

    getUserPersonal(id) {
      this.$router.push(`/personalhome?isShowMsg=2&usersId=${id}`);
    },
    // 获取作品详情
    async getWorkDetail(id) {
      let { code, data } = await getWorksDetail(id);
      if (code === 200) {
        this.opusUserId = data.userId;
        this.workDetailList = data;
        this.tuUserId = this.workDetailList.userId;
        if (this.workDetailList.classifyList && this.workDetailList.classifyList[0].id === 41) {
          this.skillText = this.escapeStringHTML(JSON.parse(this.workDetailList.description));
        } else {
          this.handleVideoImg(data.videoUrl, data.files);
        }
        // this.workList = data.workCover;
        this.likeNum = data.likeNum;
        this.workclass = data.classifyList && data.classifyList.map(v => v.classify_name).join("<i class='el-icon-arrow-right'></i>");
      }
    },
    // 把实体格式字符串转义成HTML格式的字符串
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    // 处理视频和图片的方法
    handleVideoImg(videoList, imgList) {
      this.workVideoImgList = [];
      videoList && videoList.forEach(item => {
        this.workVideoImgList.push({
          type: 'video',
          src: item,
          cover: this.getVideoCover(item)
        })
      })
      //this.getVideoBase64(this.workVideoImgList.filter(v => v.type === 'video'));
      imgList && imgList.forEach(item => {
        this.workVideoImgList.push({
          type: 'img',
          src: item.url,
          cover: item.url
        })
      })
      this.activeWorkObj = this.workVideoImgList[0];
    },
    getVideoCover(fileSrc) {
      let lastIndex_start = fileSrc.lastIndexOf('/');
      let name = fileSrc.substring(lastIndex_start + 1);
      let file_prefix = fileSrc.substring(0, fileSrc.lastIndexOf('/', fileSrc.lastIndexOf('/') - 1) + 1);
      return file_prefix + 'images/' + name + '_0.jpg'
    },
    // 给视频搞个封面图，腾讯云cos不支持
    getVideoBase64(videoList) {
      videoList.forEach(item => {
          let dataURL = '';
          let video = document.createElement("video");
          video.setAttribute('crossOrigin', 'anonymous');//处理跨域
          video.setAttribute('src', item.src);
          video.setAttribute('width', 146);
          video.setAttribute('height', 96);
          video.currentTime = 1;
          let canvas = document.createElement("canvas");
          video.addEventListener('loadeddata', () => {
              let width = video.width; //canvas的尺寸和图片一样
              let height = video.height;
              canvas.width = width;
              canvas.height = height;
              console.log(video);
              console.log(canvas.getContext("2d").drawImage(video, 0, 0, width, height));
              canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
              dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
              console.log(dataURL);
              item.cover = dataURL;
          });
      })
    },
    
    // 更换作品查看
    showWorkItem(index) {
      if(index >= this.workVideoImgList.length) return;
      this.activeWorkIndex = index;
      this.activeWorkObj = this.workVideoImgList[index];
      this.videoKey = Math.random();
    },
    // 点赞和取消点赞
    async getLikeNum() {
      let data = {
        userId: this.opusUserId,
        modelType: 1,
        modelId: this.workDetailList.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      let cancleData = {
        userId: this.$store.state.userInfo.id,
        modelType: 1,
        modelId: this.workDetailList.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      if (this.workDetailList.likeFlag == 0) {
        let { code, msg } = await getLiveLike(data);
        if (code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "点赞成功",
          });
          this.getWorkDetail(this.$route.query.workDetailId);
        }
      } else {
        this.calcelLikeNum(cancleData);
      }
    },
    //获取更多作品
    async getWorkList() {
      let data = {};
      data = {
        type: 1,
        workType: 1,
        userId: this.workDetailList.userId,
      };
      let { code, rows } = await getWorkClassInfo(data);
      if (code === 200) {
        this.workList = rows;
      }
    },
    getCommentNums(ket) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getCommentNums2(ket);
      }, 500);
    },
    // 评论点赞
    async getCommentNums2(ket) {
      let data = {
        userId: this.opusUserId,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      let calcelData = {
        userId: this.$store.state.userInfo.id,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      if (ket.likeFlag == 0) {
        let { code, msg } = await getLiveLike(data);
        if (code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "点赞成功",
          });
          this.getCommentList();
        }
      } else {
        this.calcelLikeNum(calcelData);
      }
    },
    async calcelLikeNum(data) {
      data.del = 1;
      let { code } = await calcelLiveLike(data);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "取消点赞成功",
        });
        if (data.modelType == 1) {
          this.getWorkDetail(this.$route.query.workDetailId);
        } else {
          this.getCommentList();
        }
      }
    },
    // 关注和取消关注
    async goAddFocus() {
      if (this.workDetailList.userInfo.focusFlag == 0) {
        let data = {
          toUserId: this.workDetailList.userInfo.id,
          userId: this.$store.state.userInfo.id,
          headImg: this.$store.state.userInfo.headImg,
        };
        let { code } = await addFocus(data);
        if (code === 200) {
          this.getWorkDetail(this.$route.query.workDetailId);
          this.$message({
            type: "success",
            offset: 80,
            message: "关注成功",
          });
        }
      } else {
        let data = {
          toUserId: this.workDetailList.userInfo.id,
          userId: this.$store.state.userInfo.id,
        };
        let { code } = await cancelFocus(data);
        if (code === 200) {
          this.getWorkDetail(this.$route.query.workDetailId);
          this.$message({
            type: "success",
            offset: 80,
            message: "取消关注成功",
          });
        }
      }
    },

    // 获取评论列表
    async getCommentList() {
      let queryData = {
        modelId: this.$route.query.workDetailId,
      };
      let { code, data } = await commentList(queryData);
      if (code == 200) {
        this.hotcomment = data.hotComment;
        this.commentLists = data.commentList;
        //this.$set(this.hotcomment, "isShowAll", false);
        this.commentLists.forEach((item) => {
          //this.$set(item, "isShowAll", false);
        });
      }
    },
    // 添加评论
    addComment({ nickname, id, tuUserId, toContent, e }) {
      this.showTextarea = false;
      this.isshowinp = true;
      this.comments2 = "";
      const input = document.getElementById("input");
      e.target.parentNode.appendChild(input);
      this.placeholder = "@" + nickname;
      this.parent_id = id;
      this.parentNickname = nickname;
      this.tuUserId = tuUserId;
      this.toContent = toContent;
    },
    async getInsertComment(type, contentName) {
      if (!this[contentName]) {
        this.$message.warning("评论内容不能为空！");
        return;
      }
      if (this[contentName].length > 30) {
        this.$message.warning("评论内容长度不能超过30个字符！");
        return;
      }
      let queryData = {
        modelId: this.workDetailList.id,
        userId: this.$store.state.userInfo.id,
        content: this[contentName]
      };
      if (type === 2) {
        queryData = {
          ...queryData,
          content: this[contentName],
          parentId: this.parent_id,
          parentNickname: this.parentNickname,
          tuUserId: this.tuUserId,
          toContent: this.toContent,
          headImg: this.$store.state.userInfo.headImg,
        };
      }
      let { code, msg } = await insertComment(queryData);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: msg,
        });
        this[contentName] = "";
        this.isshowinp = false;
        this.showTextarea = false;
        this.getCommentList();
      }
    },
    // 添加收藏
    async getCollection() {
      if (this.workDetailList.collectFlag == 0) {
        let data = {
          workId: this.$route.query.workDetailId,
          userId: this.$store.state.userInfo.id,
          headImg: this.$store.state.userInfo.headImg,
          toUserid: this.workDetailList.userInfo.id,
          flag: 1
        };
        let { code, msg } = await insertCollection(data);
        if (code === 200) {
          this.getWorkDetail(this.$route.query.workDetailId);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      } else {
        let id = this.$route.query.workDetailId;
        //let toId = this.$store.state.userInfo.id;
        let { code, msg } = await deleteCollection({
          id,
          flag: 1
        });
        if (code === 200) {
          this.getWorkDetail(this.$route.query.workDetailId);
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
        }
      }
    },
    // 获取等级
    getLevel(event) {
      this.levelNum = event;
    },
    toInspirationPage(url) {
      //window.open(url);
    }
  },
  mounted() {
    document.addEventListener("click", (e) => {
      const usercon = document.getElementById("input");
      if (
        usercon &&
        !usercon.contains(e.target) &&
        e.target.className !== "addComment"
      ) {
        this.comments2 = "";
        this.isshowinp = false;
      }
      const usercon2 = document.getElementById("textarea");
      if (
        usercon2 &&
        !usercon2.contains(e.target) &&
        e.target.className !== "sdsm"
      ) {
        this.comments = "";
        this.showTextarea = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.input-comment {
  position: fixed;
  width: 400px;
  background-color: #0f1128;
  bottom: 0;
  right: 0;
  z-index: 999;
  //height: 160px;

  .input-release{
    width: 100%;
    height: 45px;
    background-color: #234767;
    line-height: 45px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    cursor: pointer;
    img{
      width: 25px;
      height: 25px;
      margin-right: 4px;
    }
    .el-icon-s-promotion{
      margin-bottom: 4px;
      margin-right: 4px;
    }
  }

  .input-textarea{
    width: 100%;
    height: 160px;

    textarea{
      width: 100%;
      height: calc(100% - 55px);
      background-color: #234767;
      padding:8px;
      border-color: #9ffff7;
      box-sizing: border-box;
      color: #ffffff;
      font-size: 14px;
    }

    .el-textarea{
      height: calc(100% - 50px);
      margin-bottom: 5px;
      box-sizing: border-box;

      /deep/.el-textarea__inner{
        height: 100%;
        outline: none;
        border: none;
        background-color: #234767;
        color: #ffffff;
      }

      /deep/.el-input__count{
        background-color: transparent;
      }
    }
  }

  .input-content {
    width: 360px;
    height: 40px;
    // margin-left: 35px;
    display: flex;
    align-items: center;
    background-color: #234767;

    img {
      width: 30px;
      height: 30px;
    }

    .showtex {
      position: relative;

      .sdsm {
        width: 200px;
        background-color: #000322;
        color: #ffffff;
        text-indent: 10px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #525354;
        border-radius: 10px;
      }

      textarea {
        position: absolute;
        width: 200px;
        top: 0;
        left: 0;
        background-color: #000322;
        border-radius: 10px;
        color: #ffffff;
        text-indent: 10px;
        line-height: 30px;
      }
    }
  }

  .allcomments-btn {
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-left: 5px;
    cursor: pointer;
    background-color: #008ca5;
  }
}

.input-content2 {
  width: 100%;
  position: relative;
  border-radius: 12px 12px 12px 12px;
  overflow: hidden;

  .insertComment {
    width: 45px;
    height: 18px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #2ca2e4;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    text-indent: 5px;
    text-align: center;
    letter-spacing: 3px;
    cursor: pointer;
    border-radius: 12px 0px 12px 0px;
  }
}

.workDetail {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;

  .workLeft {
    // width: 84%;
    width: calc(100% - 400px);
    background-color: #0f1128;

    .wechant-class {
      position: relative;
      bottom: 200px;
      right: 30px;
    }

    .qq-class {
      z-index: 100;
    }

    .footer-content {
      width: 1100px;
      height: 175px;
      display: flex;
      align-items: center;
      margin: 180px auto 40px;

      .footer-logo {
        //width: 360px;
        // width: 196px;
        height: 84px;
        opacity: 1;
        //margin-right: 30px;
        flex: 1;

        // border-radius: 0px;
        img {
          width: 269px;
          // object-fit: cover;
        }
      }

      .footer-about {
        flex: 1;
        //width: 640px;
        height: 175px;
        overflow: hidden;
        border-left: 2px solid #00ffff;
        border-right: 2px solid #00b3ce;
        background: linear-gradient(to right, #00ffff, #00d0f4 50%);
        -webkit-background-clip: text;
        color: transparent;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .power-case {
          margin-bottom: 10px;
          //margin-top: 32px;
          font-size: 18px;
          font-weight: 600;
          //font-family: Microsoft YaHei;
          color: #00ffff;
          color: transparent;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .power {
            cursor: pointer;
            color: #00ffff;
            color: transparent;

            // &:hover {
            // 	color: #00ffff;
            // }
          }

          .line {
            height: 16px;
            border: 1px solid #00ffff;
            // border: 1px solid #68AC19;
            opacity: 1;
            margin: 0px 8px;
          }

          .website {
            cursor: pointer;
            color: #00ffff;
            color: transparent;
            //width: 100px;

            // &:hover {
            // 	color: #00ffff;
            // }
          }
        }

        .case {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #00ffff;
          color: transparent;
          opacity: 1;
          display: flex;
          justify-content: center;
          margin-top: 10px;
          cursor: pointer;

          // &:hover {
          // 	color: #00ffff;
          // }
        }

        .case1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #00ffff;
          color: transparent;
          opacity: 1;
          margin-top: 10px;
          text-align: center;
          padding: 5px 35px;
          // cursor: pointer;
          // &:hover{
          //     color: #0058ca;
          // }
        }
      }

      .focusme {
        flex: 1;
        //width: 96px;
        height: 110px;
        //margin-left: 30px;

        .focu {
          margin-left: 80px;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #00ffff;
          color: transparent;
          background: linear-gradient(to right, #00ffff, #00d0f4 50%);
          -webkit-background-clip: text;
          // &:hover{
          //     color: #0058ca;
          // }
          opacity: 1;
        }

        .footer-icons {
          margin-left: 80px;
          height: 38px;
          width: 96px;
          display: flex;
          justify-content: space-between;
          margin-top: 15px;

          .weixinicons {
            width: 38px;
            height: 38px;
            border: 2px solid #00b3ce;
            border-radius: 50%;
            overflow: hidden;

            &:hover {
              border: 2px solid #00ffff;
            }

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              transition: 0.2s all linear;

              &:hover {
                border-radius: 50%;
                transform: scale(1.2);
              }
            }
          }

          .qqicons {
            width: 38px;
            height: 38px;
            border: 2px solid #00b3ce;
            border-radius: 50%;
            overflow: hidden;

            &:hover {
              border: 2px solid #00ffff;
            }

            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              transition: 0.2s all linear;

              &:hover {
                border-radius: 50%;
                // color: #0058ca;
                transform: scale(1.2);
              }
            }
          }

          .click-class {
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .leftConten {
      width: 80%;
      min-height: 800px;
      margin: 0 auto;

      .info-header {
        color: white;
        letter-spacing: 2px;

        .info-title {
          // font-family: biaoti;
          font-size: 26px;
          font-weight: 600;
          // transform: translateY(1.1);
          letter-spacing: 4px;
          line-height: 80px;
          margin-top: 20px;
        }

        .info-tags {
          font-size: 14px;
          color: #d6d6d6;
        }

        .info-footer {
          margin-top: 20px;
          width: 100%;
          color: #d6d6d6;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .info-live {
            display: flex;
            align-items: center;

            div {
              margin-right: 5px;
            }

            img {
              margin-right: 10px;
            }

            .work-look {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          .info-times {
            font-size: 14px;
            //margin-right: 10%;
          }
        }
      }

      .belows-contents {
        margin-top: 30px;
        margin-bottom: 80px;

        .more {
          width: 100%;
          height: 120px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          background-color: #040608;
          border-top: 1px solid #364155;

          .prev-btn {
            background-color: #234767;
            color: #2dffff;
            font-size: 26px;
            height: 96px;
            display: flex;
            margin-left: 10px;
            align-items: center;
          }

          .next-btn {
            background-color: #234767;
            color: #2dffff;
            font-size: 26px;
            height: 96px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            margin-left: 10px;
          }

          .moreImg {
            overflow-x: hidden;
            overflow-y: hidden;
            width: 100%;

            .workImgs {
              display: flex;
              flex-direction: row;
              height: 96px;
              transition: all 0.3s linear;

              .workImg {
                position: relative;
                cursor: pointer;
                margin-left: 10px;

                img {
                  width: 146px;
                  height: 96px;
                }

                .thisTag {
                  position: absolute;
                  width: 144px;
                  height: 94px;
                  top: 0;
                  left: 0;
                  text-align: center;
                  border: 1px solid #00dfff;
                  background-color: rgba(255, 255, 255, 0.3);
                  font-size: 12px;
                }
              }
            }
          }
        }

        .belows-contents-title {
          margin: 10px 0 0 20px;
          width: 90%;
          color: white;
          letter-spacing: 3px;
          font-size: 16px;
          p{
            font-weight: bold;
          }
        }
        .belows-contents-text {
          margin-top: 10px;
          color: white;
          letter-spacing: 3px;
          font-size: 16px;
          background-color: #00031f;
          padding: 10px 20px 10px 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .belows-contents-v.belows-contents-skill{
          color: #ffffff;
          padding-bottom: 50px;
          border-bottom: 1px solid #1b2f43;
        }

        .belows-contents-video {
          max-width: 100%;
          height: 600px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #040608;
          // width: 100%;
          video {
            max-width: 100%;
            //margin-bottom: 10px;
            height: 100%;
            // width: 100%;
          }
        }
        .belows-contents-Text {
          color: #fff;
          text-align: left;
          background-color: #181744;
          width: 100%;
          font-size: 16px;
        }
        .work_img {
          height: 100%;
          max-width: 100%;
          //margin-bottom: 10px;
          // width: 100%;
        }

        .give-like {
          display: flex;
          margin-top: 50px;
          justify-content: center;
          align-items: center;

          .likes {
            img {
              width: 30px;
              cursor: pointer;
              margin-top: 10px;
            }
          }

          .line-left {
            width: 45%;
            height: 5px;
            margin-right: 10px;
            margin-top: 15px;
            background-image: linear-gradient(120deg, #0f1128, 45%, #00dfff);
          }

          .line-right {
            width: 45%;
            height: 5px;
            margin-left: 10px;
            margin-top: 15px;
            background-image: linear-gradient(60deg, #00dfff, 45%, #0f1128);
          }
        }
      }
    }
  }

  /deep/.el-image__error{
    background-color: transparent;
  }

  .workRight {
    width: 400px;
    height: 665px;
    // background-image: linear-gradient(180deg, #202a38, 60%, #0f1128, #0f1128);
    background-color: #202a38;
    position: fixed;
    z-index: 99;
    overflow-x: hidden;
    overflow-y: auto;
    right: 0;

    &::-webkit-scrollbar-thumb {
      background-color: #00d8ff;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #364155;
    }

    .users-content-header {
      display: flex;
      width: 280px;
      margin-top: 30px;
      //margin-left: 20px;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .users-content-img {
        cursor: pointer;
        width: 40%;
        height: 40%;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 5px;

        img {
          width: 60%;
          height: 100%;
          border-radius: 50%;
        }

        .users-content-level {
          padding: 3px 10px;
          height: 14px;
          background-color: #234767;
          border-radius: 16px;
          font-size: 12px;
          color: #24c3d9;
          border: 1px solid #24c3d9;
          font-weight: 600;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(-30%, -70%);
        }
      }

      .users-content-user {
        width: 65%;
        //height: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #ffffff;
        //margin-left: 15px;
        //justify-content: space-between;

        .users-content-username {
          width: 100%;
          font-size: 28px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 5px;
          font-family: biaoti;
          letter-spacing: 4px;
        }

        .users-content-lv {
          width: 100%;
          margin-bottom: 10px;
        }

        .users-content-tag {
          width: 100%;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .medal {
          width: 100%;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .gzsc {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      img {
        width: 80px;
        cursor: pointer;
      }

      .gz {
        margin-right: 10px;

        div {
          width: 80px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 4px;
          font-weight: 500;
          cursor: pointer;
          border-radius: 18px;
          //border: 1px solid #00ffff;
          color: #1cebf4;
          // border: 1px solid #ffb83e;
          // background-image: linear-gradient(-90deg, #ffb83e 40%, #fce108);
          background-color: #234767;

          span {
            font-size: 26px;
            font-weight: 000;
            transform: translateY(1px);
          }
        }

        .removeFocus {
          color: #000000;
          font-weight: 600;
          font-family: SimHei;
          background-color: #54c9dc;
        }
      }

      .sc {
        div {
          width: 80px;
          height: 35px;
          display: flex;
          cursor: pointer;
          font-weight: 500;
          letter-spacing: 4px;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          background-color: #234767;
          color: #1cebf4;
          // border: 1px solid #077ae0;
          // background-image: linear-gradient(90deg, #0bc6f2, #077ae0);

          span {
            font-family: baoti;
            transform: scaleX(1.5) skewX(-5deg);
            margin-right: 3px;
            font-size: 16px;
          }
        }

        .removeCollection {
          color: #000000;
          font-weight: 600;
          font-family: SimHei;
          background-color: #54c9dc;
        }
      }
    }

    .all-comment {
      display: flex;
      width: 340px;
      justify-content: center;
      //margin-top: 30px;

      .all-comments {
        width: 100%;
        position: absolute;
        z-index: 9999;
        color: #ffffff;
        right: 0;
        // transform: translateX(-10px);
        overflow: hidden;
		    padding-left: 20px;
    	  box-sizing: border-box;

        .comment-title {
          display: flex;
          align-items: center;
          height: 30px;
          width: 100%;
          margin-top: 20px;
          // margin-left: -2px;
          color: #ffffff;
          font-family: biaoti;
          transform: scaleY(1.2);
          letter-spacing: 2px;
          align-items: center;
          background-color: #234767;
        }

        .hotcomment2 {
          color: #999999;
          font-size: 14px;
          margin-top: 10px;
          text-align: center;
        }

        .replay-container{
          border-top: 1px solid #00455d;
        }
        .replay-container:nth-last-child(1){
          //padding-bottom: 20px;
        }

        .content-more{
          margin-bottom: 20px;
          text-align: center;
          height: 40px;
          line-height: 40px;
          border-top: 1px solid #00455d;
          color: #838897;

        }
	  }
    }
  }
} 
</style>

<style lang="scss">
.input-content2 {
  .el-input__inner {
    width: 100%;
    height: 60px;
    background-color: #3b4252;
    border: none;
    border-radius: 12px;
    color: #ffffff;
  }
}
</style>
