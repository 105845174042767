<template>
	<div id="app">
		<!-- guide界面 -->
		<transition name="slide-fade">
			<v-guide v-if="!$store.state.showGuide"></v-guide>
		</transition>
		<template v-if="$store.state.showGuide">
			<!-- 头部 -->
			<v-header v-if="$store.state.showHeader"></v-header>
			<!-- 内容 -->
			<router-view />
			<!-- 底部 -->
			<v-footer v-if="$store.state.showFooter"></v-footer>
			<!-- 登录提示框 -->
			<VDialog :centerDialogVisible="$store.state.showLoginDialog"></VDialog>
		</template>
		
	</div>
</template>
<script>
	import VHeader from './component/public/header1.vue';
	import VFooter from './component/public/footer.vue';
	// import VGuide from './component/public/guide.vue';
	import VGuide from '@/views/guide/index.vue'
	import VDialog from './component/public/signOut.vue'
	// import DevicePixelRatio from './tools/devicePixelRatio';

	export default {
		components: {
			VHeader,
			VFooter,
			VGuide,
			VDialog
		},
		methods: {
			// 警告信息
			messageWarn(text) {
				this.$message({
					message: text,
					offset: 80,
					type: "warning",
				});
			}
		},
		// created(){
		//   new DevicePixelRatio().init();
		// },
		// mounted () {
		//   window.onresize = () => {
		//     return (() => {
		//       this.detectZoom()
		//     })()
		//   };
		// },
		// methods:{
		//   detectZoom (){ 
		//     var ratio = 0,
		//     screen = window.screen,
		//     ua = navigator.userAgent.toLowerCase();
		//     if (window.devicePixelRatio !== undefined) {
		//         ratio = window.devicePixelRatio;
		//     }
		//     else if (~ua.indexOf('msie')) {  
		//       if (screen.deviceXDPI && screen.logicalXDPI) {
		//         ratio = screen.deviceXDPI / screen.logicalXDPI;
		//       }
		//     }
		//     else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
		//       ratio = window.outerWidth / window.innerWidth;
		//     }

		//     if (ratio){
		//       ratio = Math.round(ratio * 100);
		//     }
		//     //ratio就是获取到的百分比
		//     console.log(ratio)
		//     this.onresize_height = ratio
		//     // return ratio;
		//   },
		// }
	}
</script>
<style lang="scss">
	:root{
		--default-ectocyst-color: #5078be;
	}

	*{
		margin: 0;
		padding: 0;
	}
	body{
		overflow-y: overlay;
		scrollbar-width: none;
        -ms-overflow-style: none;
		&::-webkit-scrollbar{
			width: 0px !important;
		}
		// ::-webkit-scrollbar-track
		// {
		//     background-color:#00000f;
		// }
		
		// ::-webkit-scrollbar-thumb
		// {
		//     border-radius:10px;
		//     -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
		//     background-color:#555;
		// }
	}
	#app {
		// background: #181A29;
		//  min-height: 900px;
		// overflow-y: auto;

		scrollbar-width: none;
        -ms-overflow-style: none;
		&::-webkit-scrollbar{
			width: 0px !important;
		}

		.slide-fade-enter-active {
			transition: all 3s ease-out;
		}

		.slide-fade-leave-active {
			transition: all 0.8s ease-out;
		}

		.slide-fade-enter {
			opacity: 0;
		}

		.slide-fade-leave-to

		/* .slide-fade-leave-active for below version 2.1.8 */
		{
			opacity: 0.3;
			transform-origin: 0px 0px;
			transform: scale(0);
		}
	}

	.paginations {
        margin-top: 40px;
        //margin-bottom: 100px;
        //margin-left: -10vw;
        .el-pagination {
            text-align: center;
        }

        .el-pagination .btn-prev {
            width: 65px;
            height: 35px;
            background: #313345;
            color: #ffffff;
            padding: 0px;
        }

        .el-pager li {
            background-color: #181a29;
            font-size: 16px;
            font-family: Arial;
            font-weight: 400;
            color: #ffffff;
            background-color: #313345;
            margin-left: 10px;
            min-width: 35px;
            height: 35px;
            line-height: 35px;
            box-sizing: border-box;
            text-align: center;
			&.btn-quickprev,&.btn-quicknext{
				color: #ffffff;
			}
        }

        .el-pagination .btn-next {
            width: 65px;
            margin-left: 10px;
            height: 35px;
            background: #313345;
            color: #ffffff;
            padding: 0;
            // background: #353849;
        }

        .el-pager li.active {
            color: white !important;
            // background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
            background: #00b4d4;
        }

        .el-pagination__jump {
            display: inline-block !important;
            font-size: 16px !important;
            min-width: 35.5px !important;
            height: 35px !important;
            padding: 0 10px !important;
            line-height: 35px !important;
            vertical-align: top !important;
            box-sizing: border-box !important;
            border: 1px solid #414141 !important;
            color: #ffffff !important;
            background-color: #313345 !important;
        }

        .el-input {
            width: 70px;
            height: 33px;
        }

        .el-pagination__editor.el-input .el-input__inner {
            height: 24px;
            top: -2px;
            background: #001220 !important;
            color: white;
            border: none;
        }

        .el-pagination__editor {
            margin: 0 10px;
        }

    }

	/deep/.el-form .el-form-item .el-form-item__label {
		color: #fff;
		letter-spacing: 3px;
		font-size: 16px;
	}


</style>
