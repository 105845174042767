<template>
  <div class="index-page-container index-case-container">
    <div class="index-page-content index-case-content">
      <div class="page-title case-title">课程案例</div>
      <div class="case-box">
        <div
          class="case-item"
          v-for="(item, index) in caseShowList"
          :key="item.id"
        >
          <div class="case-item-index">
            <p>{{ item.index }}</p>
          </div>
          <div class="case-item-left" @click="eventShowDialog(item, index)">
            <div class="case-item-title">
              {{ item.title }}
            </div>
            <div class="case-item-content">
              <div
                class="content-box"
                v-for="(citem, cindex) in item.contentList"
                :key="cindex"
              >
                <span :class="citem.icon"></span>
                <ul>
                  <li v-for="(titem, tindex) in citem.textList" :key="tindex">
                    {{ titem }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="case-item-right" @click="eventShowDialog(item, index)">
            <img v-lazy="item.imgUrl" />
          </div>
        </div>
        <div class="case-item case-item-hide-after" v-if="caseFooterShow">
          <div class="case-item-index">
            <p>{{ caseFooterShow.index }}</p>
          </div>
          <div class="case-hide-box" @click="eventToCaseNext">
            <div class="case-item-left">
              <div class="case-item-title">
                {{ caseFooterShow.title }}
              </div>
              <div class="case-item-content">
                <div
                  class="content-box"
                  v-for="(citem, cindex) in caseFooterShow.contentList"
                  :key="cindex"
                >
                  <span :class="citem.icon"></span>
                  <ul>
                    <li v-for="(titem, tindex) in citem.textList" :key="tindex">
                      {{ titem }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="case-item-right">
              <img v-lazy="caseFooterShow.imgUrl" />
            </div>
          </div>
        </div>
      </div>
      <div class="paginations">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="works-dialog__wrapper" v-show="showDialog">
      <div class="works-dialog">
        <div class="works-dialog-header">
          <div class="works-dialog-title">{{ dialogInfo.title }}</div>
          <span class="el-icon-close" @click="eventClose"></span>
        </div>
        <div class="works-dialog-body" :key="dialogInfo.id">
          <video
            controlslist="nodownload"
            autoplay="autoplay"
            controls="controls"
            class="works-video"
          >
            <source :src="dialogInfo.videoUrl" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
        <span
          class="el-icon-arrow-left"
          :class="showIndex === 0 && 'icon-disabled'"
          @click="eventToPrev"
        ></span>
        <span
          class="el-icon-arrow-right"
          :class="showIndex >= caseShowList.length - 1 && 'icon-disabled'"
          @click="eventToNext"
        ></span>
      </div>
      <div class="works-modal" style="z-index: 2002"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 4,
      },
      showDialog: false,
      showIndex: 0,
      dialogInfo: {},
      caseShowList: [],
      caseFooterShow: undefined,
      caseList: [
        {
          id: 1,
          index: 1,
          title: "基础篇-弹道类",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_1.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/1.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["粒子基础属性学习", "流动材质制作"],
            },
            {
              icon: "el-icon-check",
              textList: ["光的色彩和分布", "初识亮色与暗色"],
            },
          ],
        },
        {
          id: 2,
          index: 2,
          title: "基础篇-爆点受击",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_2.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/2.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["粒子基础属性学习", "遮罩材质制作"],
            },
            {
              icon: "el-icon-check",
              textList: ["如何体现节奏", "不同的打击感"],
            },
          ],
        },
        {
          id: 3,
          index: 3,
          title: "基础篇-护盾Buff",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_3.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/3.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["粒子基础属性学习", "单面双面材质的制作"],
            },
            {
              icon: "el-icon-check",
              textList: ["科技风的贴图绘制", "特效与人物动作的配合"],
            },
          ],
        },
        {
          id: 4,
          index: 4,
          title: "基础篇-护盾Buff",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_4.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/4.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["特效模型制作技巧", "顶点位移材质"],
            },
            {
              icon: "el-icon-check",
              textList: ["模型与贴图的巧妙配合", "思路拓展训练"],
            },
          ],
        },
        {
          id: 5,
          index: 5,
          title: "基础篇-刀光",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_5.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/5.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["基础刀光层次与纹理表现"],
            },
            {
              icon: "el-icon-check",
              textList: ["运动节奏与颜色节奏"],
            },
          ],
        },
        {
          id: 6,
          index: 6,
          title: "进阶篇-高级刀光",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_6.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/6.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["特殊刀光的制作思路"],
            },
            {
              icon: "el-icon-check",
              textList: ["模型与贴图的制作思路"],
            },
          ],
        },
        {
          id: 7,
          index: 7,
          title: "进阶篇-范围技能1",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_7.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/7.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["风格的表现形式", "精致度的表现方式"],
            },
            {
              icon: "el-icon-check",
              textList: ["多段攻击的节奏表达", "主体元素与辅助元素的搭配"],
            },
          ],
        },
        {
          id: 8,
          index: 8,
          title: "进阶篇-水系技能",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_8.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/8.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["扰动材质制作"],
            },
            {
              icon: "el-icon-check",
              textList: ["液体元素的难点解析"],
            },
          ],
        },
        {
          id: 9,
          index: 9,
          title: "进阶篇-刀剑连击技",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_9.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/9.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["人物模型在特效中的使用方法"],
            },
            {
              icon: "el-icon-check",
              textList: ["战士与法师技能的区别"],
            },
          ],
        },
        {
          id: 10,
          index: 10,
          title: "进阶篇-范围技能2",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_10.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/10.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["软溶解于硬溶解材质制作"],
            },
            {
              icon: "el-icon-check",
              textList: ["特定元素的制作思路"],
            },
          ],
        },
        {
          id: 11,
          index: 11,
          title: "进阶篇-范围技能3",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_11.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/11.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["制作思路综合拓展"],
            },
            {
              icon: "el-icon-check",
              textList: ["美术风格拓展训练", "制作效率训练"],
            },
          ],
        },
        {
          id: 12,
          index: 12,
          title: "进阶篇-范围技能4",
          imgUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/cover_12.jpg",
          videoUrl: "https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/train/case/12.mp4",
          contentList: [
            {
              icon: "el-icon-s-tools",
              textList: ["制作思路综合拓展"],
            },
            {
              icon: "el-icon-check",
              textList: ["美术风格拓展训练", "制作效率训练"],
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.total = this.caseList.length;
      let startPage = (this.queryForm.pageNum - 1) * this.queryForm.pageSize;
      let endPage = startPage + this.queryForm.pageSize;
      this.caseShowList = this.caseList.slice(startPage, endPage);
      this.caseFooterShow = this.caseList[endPage];
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
    eventToCaseNext() {
      this.queryForm.pageNum += 1;
      this.getList();
    },
    eventShowDialog(item, index) {
      this.dialogInfo = item;
      this.showIndex = index;
      this.showDialog = true;
      this.$emit("changeAllowSlide", false);
    },
    eventClose() {
      this.dialogInfo = {};
      this.showDialog = false;
      this.$emit("changeAllowSlide", true);
    },
    eventToPrev() {
      if (this.showIndex === 0) {
        return;
      }
      this.showIndex -= 1;
      this.dialogInfo = this.caseShowList[this.showIndex];
    },
    eventToNext() {
      if (this.showIndex >= this.caseShowList.length - 1) {
        return;
      }
      this.showIndex += 1;
      this.dialogInfo = this.caseShowList[this.showIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.index-case-container {
  .index-case-content {
    .case-box {
      margin-top: 100px;
      width: 1450px;
      height: 540px;
      display: flex;
      margin-left: -90px;
      .case-item {
        //display: flex;
        //height: 280px;
        width: 250px;
        box-sizing: border-box;
        position: relative;
        &:not(:last-child) {
          margin-right: 50px;
        }
        &::before {
          content: "";
          position: absolute;
          top: -37.5px;
          left: -25px;
          height: 2px;
          width: 110px;
          background-color: #4f616a;
        }
        &::after {
          content: "";
          position: absolute;
          top: -37.5px;
          right: -25px;
          height: 2px;
          width: 110px;
          background-color: #4f616a;
        }
        .case-item-index {
          position: absolute;
          top: -60px;
          left: 50%;
          transform: translateX(-50%);
          width: 45px;
          height: 45px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          box-shadow: 0px 0px 15px 1px rgba(0, 154, 226, 0.76);
          text-align: center;
          line-height: 45px;
          font-size: 30px;
          font-weight: bold;
        }
        .case-hide-box {
          width: 125px;
          overflow: hidden;
        }
        .case-item-left {
          width: 250px;
          position: relative;
          padding: 30px 0 10px;
          background-size: 100% 100%;
          background-image: linear-gradient(
            to right,
            rgba($color: #4f112a, $alpha: 0.7),
            rgba($color: #18315b, $alpha: 0.7)
          );
          &::before {
            content: "";
            background-image: url("../../../../assets/newImg/GLlogo.png");
            background-size: 100% 100%;
            opacity: 0.4;
            z-index: -1;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 60px;
          }
          .case-item-title {
            font-size: 20px;
            padding-left: 20px;
            color: #ffffff;
            font-weight: bold;
          }
          .case-item-content {
            margin-top: 20px;
            .content-box {
              display: flex;
              align-items: center;
              padding: 5px 20px;
              height: 40px;
              > span {
                font-size: 24px;
                color: #8eb1bd;
                font-weight: bold;
              }
              > ul {
                margin-left: 15px;
                width: calc(100% - 40px);
                > li {
                  color: #8eb1bd;
                  line-height: 20px;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .case-item-right {
          width: 250px;
          height: 345px;
          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .case-item-hide-after {
        &::after {
          content: "";
          width: 0;
        }
      }
    }
  }

  .paginations {
    margin-top: 40px;
    //margin-bottom: 100px;
    margin-left: -10vw;
    .el-pagination {
      text-align: center;
    }

    ::v-deep .el-pagination .btn-prev {
      // background: #353849;
      width: 65px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0px;
    }

    ::v-deep .el-pager li {
      background-color: #181a29;
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      background-color: #313345;
      margin-left: 10px;
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      text-align: center;
    }

    ::v-deep .el-pagination .btn-next {
      width: 65px;
      margin-left: 10px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0;
      // background: #353849;
    }

    ::v-deep .el-pager li.active {
      color: white !important;
      // background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
      background: #00b4d4;
    }

    ::v-deep .el-pagination__jump {
      display: inline-block;
      font-size: 16px;
      min-width: 35.5px;
      height: 35px;
      padding: 0 10px;
      line-height: 35px;
      vertical-align: top;
      box-sizing: border-box;
      border: 1px solid #414141;
      color: #ffffff;
      background-color: #313345;
    }

    ::v-deep .el-input {
      width: 70px;
      height: 33px;
    }

    ::v-deep .el-pagination__editor.el-input .el-input__inner {
      height: 24px;
      top: -2px;
      background: #001220 !important;
      color: white;
      border: none;
    }

    ::v-deep .el-pagination__editor {
      margin: 0 10px;
    }
  }

  .works-dialog__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index: 2003;
    //display: none;
    .works-dialog {
      width: 800px;
      height: 510px;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      z-index: 2003;
      .works-dialog-header {
        //padding: 20px 20px 10px;
        display: flex;
        color: #30cada;
        justify-content: space-between;
        .works-dialog-title {
          line-height: 48px;
          letter-spacing: 1px;
        }
        .el-icon-close {
          font-size: 24px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      .works-dialog-body {
        width: 800px;
        height: 400px;
        background-color: rgba($color: #000000, $alpha: 0.9);
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        position: relative;
        .works-image {
          width: 100%;
          height: 100%;
        }
        .works-video {
          max-width: 100%;
          height: 100%;
        }
        .el-icon-arrow-left {
          position: absolute;
          font-size: 32px;
        }
      }
      .el-icon-arrow-left {
        position: absolute;
        left: -50px;
        font-size: 48px;
        top: calc(50% - 40px);
        color: #30cada;
        cursor: pointer;
      }
      .el-icon-arrow-right {
        position: absolute;
        right: -50px;
        font-size: 48px;
        top: calc(50% - 40px);
        color: #30cada;
        cursor: pointer;
      }
      .icon-disabled {
        color: #cccccc;
        opacity: 0.5;
      }
    }
    .works-modal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: #000;
    }
  }
}
</style>