<template>
  <div
    class="item-container"
    @mousemove="showallnews()"
    @mouseleave="showomit()"
    @click="readMessage([info.id], info.readFlag)"
  >
    <div class="message-unread-flag" v-if="info.readFlag == 0"></div>
    <div class="message-remove-btn" @click="remove([item.id])">
      <img src="@/assets/img/homePage/nav_icon_close_nor.png" />
    </div>
    <div class="message-cover" style="background: #292626">
      <img v-if="info.headImg" :src="info.headImg" />
      <img src="@/assets/img/bbs/icon_xitong_01.png" alt="" v-else />
    </div>
    <div class="message-detail">
      <div class="usertag" v-html="info.title" :title="info.title"></div>
      <div class="userintro" v-show="!showCoent" v-html="info.content"></div>
      <div class="userintroall" v-show="showCoent" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCoent: false,
    };
  },
  props: {
    info: {},
  },
  methods: {
    showallnews() {
      this.showCoent = true;
    },
    showomit() {
      this.showCoent = false;
    },
    readMessage(ids, flag) {
      this.$emit('readMessage', {ids, flag});
    },
    remove(ids) {
      this.$emit('remove', ids);
    }
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 100%;
  height: 100px;
  background: #16182e;
  margin-top: 1px;
  overflow: hidden;
  display: flex;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #1c1e36;
  }
  .message-unread-flag {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    left: 15px;
    top: 15px;
  }
  .message-remove-btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 30px;
  }
  .message-cover {
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin-top: 29px;
    margin-left: 40px;
    margin-right: 20px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .message-detail {
    width: calc(100% - 50px);
    height: 120px;
    margin-top: 20px;

    .usertag {
      height: 25px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      line-height: 25px;
      color: rgba(255, 255, 255, 0.699);
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .userintro {
      width: 150px;
      height: 35px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.7);
      margin-top: 10px;
      // white-space:nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .userintroall {
      height: 35px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.7);
      margin-top: 10px;
      // white-space:nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>