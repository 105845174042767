<template>
  <div class="recruit-form-right-container">
    <el-form-item label="上传封面" label-width="120px">
      <div class="uploadcov">
        <div class="work-cov">
          <div
            class="drag"
            v-for="(item, index) in docImgFileList"
            :key="index"
          >
            <img :src="item.url" />
            <div class="covbtn">
              <i
                @click="handleRemoveDoc(item.fileName)"
                class="el-icon-delete"
              ></i>
            </div>
          </div>
          <el-upload
            :action="uploadUrl"
            :on-success="succesCoverPicture"
            v-show="!docImgFileList.length"
            :on-preview="handlePictureCardPreview"
            :before-upload="
              (file) => {
                return beforeAvatarUpload(file, 2, true);
              }
            "
            :show-file-list="false"
            :file-list="docImgFileList"
            :on-exceed="handleCoverExceed"
            :limit="1"
            accept=".jpg,.png,.gif"
            multiple
          >
            <div class="uploadcovs">
              <img src="@/assets/img/add.png" alt="" srcset="" />
              <p>支持jpg,gif,png格式</p>
              <p>封面大小不超过2M</p>
              <p>只能上传一张封面</p>
            </div>
          </el-upload>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="办公环境" label-width="120px">
      <el-switch
        v-model="isUploadImg"
        active-color="#35edff"
        inactive-color="#393e4b"
      ></el-switch>
      <div class="uploadimg" v-show="isUploadImg">
        <div class="work-img">
          <template v-if="environmentImageList">
            <div
              v-for="(item, index) in environmentImageList"
              :key="item.fileName"
              class="drag"
            >
              <img :src="item.url" />
              <div class="imgbtn">
                <i @click="changeprev_img(index)" class="el-icon-back"></i>
                <i
                  @click="handlePictureCardPreview(item)"
                  class="el-icon-zoom-in"
                ></i>
                <i
                  @click="handleRemove(index, item.fileName)"
                  class="el-icon-delete"
                ></i>
                <i @click="changenext_img(index)" class="el-icon-right"></i>
              </div>
            </div>
          </template>
          <el-upload
            :action="uploadUrl"
            :on-success="succesPicture"
            :on-preview="handlePictureCardPreview"
            :before-upload="
              (file) => {
                return beforeAvatarUpload(file, 10);
              }
            "
            :on-exceed="handleEnvironmentExceed"
            :file-list="environmentImageList"
            :limit="20"
            accept=".jpg,.png,.gif"
            :show-file-list="false"
            multiple
          >
            <div class="uploadimgs">
              <img src="@/assets/img/add.png" alt="" srcset="" />
              <p>支持jpg,gif,png格式</p>
              <p>图片大小不超过10M</p>
              <p>建议图片宽高比为4：3</p>
              <p>最多上传二十张图片</p>
              <p>点击左右箭头可以更换顺序</p>
            </div>
          </el-upload>
        </div>
      </div>
    </el-form-item>
    <el-form-item
      label="岗位描述"
      :style="{ marginBottom: 0 }"
      label-width="120px"
    >
    </el-form-item>
    <div class="el-form-rich">
      <rich-text
        @getContent="changePostIntroText($event)"
        :content.sync="postIntroText"
        placeholder="请填写"
      ></rich-text>
    </div>
  </div>
</template>

<script>
import richText from "@/component/richText/index.vue";
import {
  getClassifyList,
  getChildList,
  deleteUpload,
  addJobApi,
  addJobBefo,
} from "@/api/work.js";
export default {
  data() {
    return {
      postIntroText: "",
      docImgFileList: [],
      isUploadImg: true,
      environmentImageList: [],
      dataForm: {},
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
    };
  },
  components:{
    richText
  },
  methods: {
    changePostIntroText(event) {
      this.postIntroText = event;
      let content = event
        ? JSON.stringify(this.escapeStringHTML(event))
        : undefined;
      this.$set(this.dataForm, "postIntro", content);
    },
    escapeStringHTML(str) {
      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");
      return str;
    },
    // 删除封面图
    async handleRemoveDoc(fileName) {
      let result = await deleteUpload({ fileName });
      if (result.code === 200) {
        this.docImgFileList = [];
      }
    },
    succesCoverPicture(response, file, fileList) {
      this.docImgFileList.push({
        url: response.data.url,
        fileName: response.data.fileName,
        fileType: 1,
      });
    },
    succesPicture(response) {
      this.environmentImageList.push({
        url: response.data.url,
        fileType: 1,
        fileName: response.data.fileName,
      });
    },
    // 超出封面图限制条件的回调函数
    handleCoverExceed() {
      this.$message.warning("只能上传一张封面图哟");
    },
    handleEnvironmentExceed() {
      this.$message.warning("最多只能上传20张办公环境图片哟");
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.dialogImageUrl = [];
      this.dialogImageUrl.push(file.url);
      this.showViewer = true;
    },
    changeprev_img(index) {
      if (index !== 0) {
        [
          this.environmentImageList[index],
          this.environmentImageList[index - 1],
        ] = [
          this.environmentImageList[index - 1],
          this.environmentImageList[index],
        ];
      }
      this.environmentImageList = this.environmentImageList.concat();
    },
    changenext_img(index) {
      if (index !== this.environmentImageList.length - 1) {
        [
          this.environmentImageList[index],
          this.environmentImageList[index + 1],
        ] = [
          this.environmentImageList[index + 1],
          this.environmentImageList[index],
        ];
      }
      this.environmentImageList = this.environmentImageList.concat();
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-input {
  width: 100% !important;
}
/deep/.el-form-rich {
  height: 500px;
  margin-bottom: 50px;
}
.recruit-form-right-container {
  .uploadcov {
    box-sizing: border-box;
    //margin-top: 55px;
    width: 100%;
    border-radius: 15px;
    background: rgba(195, 195, 195, 0.2);

    .work-cov {
      display: flex;
      flex-wrap: wrap;

      .drag {
        // flex: 200px;
        width: 200px !important;
        height: 200px !important;
        border-radius: 15px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #23272f;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .covbtn {
          position: absolute;
          display: flex;
          width: 180px;
          padding: 10px;
          top: 70px;
          justify-content: center;
          align-items: center;

          i {
            cursor: pointer;
            font-size: 25px;
            transition: all 0.2s linear;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .uploadcovs {
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
      border: 2px dashed #35edff;
      border-radius: 15px;
      background-color: #4b505e;

      img {
        margin: 30px auto;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: normal;
      }
    }
  }

  .uploadimg {
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    border-radius: 15px;
    background: rgba(195, 195, 195, 0.2);

    .work-img {
      display: flex;
      flex-wrap: wrap;

      .drag {
        // flex: 200px;
        width: 200px !important;
        height: 200px !important;
        border-radius: 15px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #23272f;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .imgbtn {
          position: absolute;
          display: flex;
          width: 180px;
          padding: 10px;
          top: 70px;
          justify-content: space-between;
          align-items: center;

          i {
            cursor: pointer;
            font-size: 25px;
            transition: all 0.2s linear;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .uploadimgs {
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
      border: 2px dashed #35edff;
      border-radius: 15px;
      background-color: #4b505e;
      img {
        margin: 30px auto 10px;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: normal;
      }
    }
  }
}
</style>