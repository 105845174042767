<template>
  <div class="recruit-form-right-container">
    <el-form-item label="营业执照" label-width="100px">
      <file-upload
        :uploadUrl="uploadUrl"
        :fileList="licenseImageList"
        fileType="image"
        accept=".jpg,.png,.gif"
        :maxSize="10"
        @callbackFile="getFileList($event, 'licenseImageList')"
        :tipsList="['支持jpg,gif,png格式', '图片大小不超过10M']"
      >
      </file-upload>
    </el-form-item>
    <el-form-item label="HR姓名" label-width="100px">
      <glsx-input
        :value="dataForm.hrName"
        placeholderText="请输入HR姓名"
        @getValue="changeInputValue($event, 'hrName')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="联系电话" label-width="100px">
      <glsx-input
        :value="dataForm.phone"
        placeholderText="请输入联系电话，请一定要填正确的哟~"
        @getValue="changeInputValue($event, 'phone')"
      ></glsx-input>
    </el-form-item>
  </div>
</template>

<script>
import glsxInput from "@/component/form/glsxInput.vue";
import fileUpload from '@/component/form/fileUpload.vue';
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      licenseImageList: [],
      dataForm: {}
    };
  },
  components: {
    glsxInput,
    fileUpload
  },
  methods: {
    // 上传文件后的回调
    getFileList(fileList, listName) {
      console.log(fileList, listName);
      this[listName] = fileList;
    },
    changeInputValue(event, attributeName) {
      this.$set(this.dataForm, attributeName, event);
    },
  },
};
</script>

<style lang="scss" scoped>

/deep/.el-input {
  width: 100% !important;
}
/deep/ .el-form-item__label {
  color: #fff;
  letter-spacing: 3px;
  font-size: 16px;
}
</style>