<template>
  <div class="select-style">
    <el-select
      v-model="optionValueChild"
      clearable
      :placeholder="placeholder"
      :popper-append-to-body="false"
      @change="getChange()"
      :filterable = "allowCreate"
      :allow-create = "allowCreate"
      :default-first-option = "allowCreate"
      :multiple = "multiple"
      :size="size"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        popper-class="select-popper"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      optionValueChild: undefined,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    optionValue: {
      require: true
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    }
  },
  watch: {
    // options(){
    //   this.optionValue = undefined;
    // }
    // optionValue(v) {
    //   this.optionValueChild = v;
    // },
    optionValue: {
      handler(v) {
        this.optionValueChild = v;
      },
      immediate: true
    }
  },
  methods: {
    getChange() {
      this.$emit('getValue',this.optionValueChild);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-style {
  /deep/ .el-select {
    color: #fff;
    width: 100%;
    /deep/ .el-input {
      color: #fff;
    }
  }
  /deep/ .el-input__inner {
    color: #fff;
    background-color: #494d59;
    border: 0.0625rem solid #9ba0ad;
    border-radius: 0;
  }
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: #9ba0ad;
  }
  /deep/ .el-select .el-input__inner:focus {
    border-color: #9ba0ad;
  }

  /deep/ .el-select-dropdown {
    background-color: #393e4b;
    margin: 0px;
    border: 0px;
    border-radius: 0px;
    border: none;
  }
  //修改单个的选项的样式
  /deep/ .el-select-dropdown__item {
    background-color: transparent;
    color: #fff;
  }
  //选项的hover样式
  /deep/ .el-select-dropdown__item.hover,
  /deep/ .el-select-dropdown__item:hover {
    background-color: rgba(53, 237, 255, 0.8);
  }
  /deep/ .el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #494d59;
  }
}
</style>