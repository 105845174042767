<template>
  <div class="index-page-container index-periphery-container">
    <div class="index-page-content index-periphery-content">
        <div class="page-content">
            <div class="page-header-box">
                <h2 class="page-header-title"><span class="el-icon-menu"></span>赛事周边</h2>
            </div>
            <!-- <div class="page-periphery-empty" v-if="true">
                <span class="el-icon-s-promotion"></span>
                <p>周边正在飞来的路上...</p>
            </div> -->
            <div class="page-periphery-box">
                <div class="periphery-item" v-for="item in 4" :key="item">
                    <div class="periphery-item-img">
                        <!-- <img src="@/assets/img/train/works/works-5-1.jpg" /> -->
                        <div class="periphery-item-empty">敬请期待</div>
                    </div>
                    <!-- <div class="periphery-item-text">
                        广州工商学院院长采访视频
                    </div> -->
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.index-periphery-container{
    background: url(../../../../assets/img/linkage/page2_bg.jpg) center center
    repeat-x #000518;
    background-size: cover;
    .index-periphery-content{
        .page-content{
            .page-periphery-box{
                /* 声明一个容器 */
                // display: grid;
                // /*  声明列的宽度  */
                // grid-template-columns: repeat(2, 450px);
                // /*  声明行间距和列间距  */
                // grid-gap: 100px 130px;
                // /*  声明行的高度  */
                // grid-template-rows: 260px 260px;
                display: flex;
                flex-wrap: wrap;
                .periphery-item{
                    width: 450px;
                    height: 260px;
                    margin-right: 130px;
                    margin-bottom: 100px;
                    .periphery-item-img{
                        width: 100%;
                        height: 260px;
                        overflow: hidden;
                        >img{
                            width: 100%;
                            object-fit: cover;
                        }
                        .periphery-item-empty{
                            width: 100%;
                            height: 100%;
                            background-color: rgba($color: #FFFFFF, $alpha: 0.5);
                            background: url('../../../../assets/img/linkage/450-260.jpg');
                            text-align: center;
                            line-height: 260px;
                            color: #FFFFFF;
                            font-size: 24px;
                            letter-spacing: 2px;
                        }
                    }
                    .periphery-item-text{
                        width: 100%;
                        text-align: center;
                        margin-top: 25px;
                        font-size: 18px;
                    }
                }
            }
            .page-periphery-empty{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #f6443f;
                text-align: center;
                .el-icon-s-promotion{
                    font-size: 200px;
                }
                >p{
                    margin-top: 20px;
                    font-size: 24px;
                }
            }
        }
    }
}

</style>