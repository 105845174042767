import instance from "./config.js";

// 查看系列列表
export function getSeriesList(data){
    return instance.get("/api/video/list",{params:data});
}

// 查看系列详情
export function getSeriesDetailById(data){
    return instance.get("/api/video/query",{params:data});
}


// 微信支付
export function handleWxPay(data){
    return instance.get("/api/wx-pay/native",{params:data});
}

// 获取微信支付结果
export function getWxPayResult(data){
    return instance.get("/api/wx-pay/query",{params:data});
}

// 支付宝支付 
export function handleAliPay(data) {
    return instance.post("/api-alipay/buy?pid="+data.pid+"&name="+ data.name);
}

// 获取支付宝支付结果
export function getAliPayResult(data) {
    return instance.get("/api-alipay/query",{params:data});
}

// 根据观看时长获取积分
export function setIntegralByTime(data) {
    return instance.get(`/api/video/watch/${data.vid}/${data.type}`);
}

// 提交作业
export function handleAddWork(data) {
    return instance.post(`/api/videoCoursework/add`, data);
}

// 全部作业列表
export function getCourseWorkList(data) {
    return instance.get(`/api/videoCoursework/list`, {params:data});
}

// 查询作业详情
export function getCourseWorkDetailById(data) {
    return instance.get(`/api/videoCoursework/query/${data.id}`);
}

// 删除作业
export function handleDeleteWorkById(data) {
    return instance.delete(`/api/videoCoursework/${data.id}`);
}

// 修改作业
export function handlEditWorkById(data) {
    return instance.put(`/api/videoCoursework`, data);
}

// 签到
export function handleSign(data) {
    return instance.get(`/api/user/sign/${data.type}`);
}

// 查看签到情况
export function getSignInfo(data) {
    return instance.get(`/api/user/signInfo/${data.type}`);
}

// 查看便签
export function queryNote(data) {
    return instance.get(`/api/video/queryNote/${data.vid}`);
}

//添加视频便签
export function addNote(data) {
    return instance.post(`/api/video/addNote`, data);
}

//修改视频便签
export function updateNote(data) {
    return instance.post(`/api/video/updateNote`, data);
}
