<template>
	<div class="focus-container">
		<div class="focus-content">
			<div class="all-focus">
				全部关注
			</div>
			<div class="focus-contents" v-for="(item,index) in focusList" :key="index">
				<div v-if="item.toUserInfo" style=" display: flex;justify-content: space-between;" class="focus-info">
					<div class="focus-user">
						<div class="focus-user-img" @click="getUserPersonal(item.toUserId)">
							<img :src="item.toUserInfo.headImg" alt="">
						</div>
						<div class="focus-user-content">
							<div class="focus-user-name">
								{{item.toUserInfo.nickName}}
							</div>
							<!-- <div class="focus-user-intro">
                                {{item.toUserInfo.intro || '暂无简介'}}
                            </div> -->
						</div>
					</div>
					<div class="iffocus"
						v-if="item.toUserInfo.focusFlag==0 && item.toUserInfo.id!==$store.state.userInfo.id"
						@click="getAddFocus(item.toUserInfo.id)">
						关注
					</div>
					<div class="iffocus"
						v-if="item.toUserInfo.focusFlag==1 && item.toUserInfo.id!==$store.state.userInfo.id"
						@click="getCancelFocus(item.toUserInfo.id)">
						已关注
					</div>
				</div>
			</div>
			<div class="kong" v-if="focusList.length == 0">
				暂无数据！
			</div>
			<div class="paginations">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="currentPage" :page-size="pageSize" prev-text="上一页" next-text="下一页"
					layout="prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getFocusList
	} from '@/api/message.js';
	import {
		addFocus,
		cancelFocus
	} from '@/api/work.js';
	export default {
		data() {
			return {
				userInfo: '',
				focusList: '',
				total: 0,
				pageSize: 6,
				currentPage: 1
			}
		},
		created() {
			this.userInfo = this.$parent.userInfo;
			this.getfocusLsit()
		},
		methods: {
			async getfocusLsit() {
				let data = {
					userId: this.userInfo.id,
					pageSize: this.pageSize,
					pageNum: this.currentPage,
				}
				let {
					code,
					rows,
					total
				} = await getFocusList(data);
				if (code === 200) {
					console.log(rows)
					this.focusList = rows;
					this.total = total
				}
			},
			// 关注和取消关注
			async getAddFocus(id) {
				let data = {
					toUserId: id,
					userId: this.$store.state.userInfo.id
				}
				let {
					code
				} = await addFocus(data);
				if (code === 200) {
					this.$message({
						type: 'success',
						offset: 80,
						message: "关注成功"
					});
					this.getfocusLsit()
				}
			},

			async getCancelFocus(id) {
				let data = {
					toUserId: id,
					userId: this.$store.state.userInfo.id
				}
				let {
					code
				} = await cancelFocus(data);
				if (code === 200) {
					this.$message({
						type: 'success',
						offset: 80,
						message: "取消关注成功"
					});
					this.getfocusLsit()
				}
			},

			getUserPersonal(id) {
				this.$router.push(`/personalhome?isShowMsg=2&usersId=${id}`)
			},

			// 分页切换
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getfocusLsit();
				document.documentElement.scrollTop = 380
			},
			handleSizeChange(val) {},
		}
	}
</script>

<style lang="scss" scoped>
	.focus-container {
		.focus-content {
			width: 90%;
			height: 100%;
			margin: 0 auto;
			overflow: hidden;

			.all-focus {
				height: 31px;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 31px;
				color: rgba(255, 255, 255, 0.8);
				text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
				margin-top: 50px;
				margin-bottom: 50px;
			}

			.focus-contents {
				width: 95%;
				min-height: 100px;
				border-top: 1px rgba(255, 255, 255, 0.1) solid;
				border-radius: 6px;

				// padding: 0 10px;
				.focus-info {
					width: 100%;
					padding: 0 10px;

					&:hover {
						background: rgba(110, 110, 110, 0.3);
						border-radius: 6px;
					}
				}

				.focus-user {
					display: flex;
					margin-top: 20px;
					margin-bottom: 20px;

					.focus-user-img {
						width: 95px;
						height: 95px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 29px;
						cursor: pointer;
						border: 2px solid rgba(0, 0, 0, 0);

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						&:hover {
							border-radius: 50%;
							border: 2px solid #06b0ff;
						}
					}

					.focus-user-content {
						width: 680px;
						display: flex;
						align-items: center;

						.focus-user-name {
							width: 100%;
							height: 29px;
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 29px;
							color: #e7e5e5;
							text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
							opacity: 1;
							margin-top: 16px;
							margin-bottom: 10px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.focus-user-intro {
							width: 100%;
							height: 24px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 24px;
							color: rgba(255, 255, 255, 0.8);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.iffocus {
					width: 180px;
					height: 40px;
					text-align: center;
					border: 2px solid rgba(255, 255, 255, 0.8);
					border-radius: 241px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					line-height: 40px;
					color: rgba(255, 255, 255, 0.8);
					margin-top: 53px;
					cursor: pointer;

					&:hover {
						color: #06b0ff;
						border: 2px solid #06b0ff;
					}
				}
			}

			.kong {
				width: 100%;
				height: 300px;
				text-align: center;
				color: white;
				line-height: 300px;
			}

			.paginations {

				margin-top: 80px;
				margin-bottom: 100px;

				.el-pagination {
					text-align: center;
				}

				::v-deep .el-pagination .btn-prev {
					// background: #353849;
					width: 65px;
					height: 35px;
					background: #313345;
					color: #FFFFFF;
					padding: 0px;

				}

				::v-deep .el-pager li {
					background: #181A29;
					font-size: 16px;
					font-family: Arial;
					font-weight: 400;
					color: #ffffff;
					background-color: #313345;
					margin-left: 10px;
					min-width: 35px;
					height: 35px;
					line-height: 35px;
					box-sizing: border-box;
					text-align: center;
				}

				::v-deep .el-pagination .btn-next {
					width: 65px;
					margin-left: 10px;
					height: 35px;
					background: #313345;
					color: #FFFFFF;
					padding: 0;
					// background: #353849;
				}

				::v-deep .el-pager li.active {
					color: white !important;
					// background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
					background: #00b4d4;
				}

				::v-deep .el-pagination__jump {
					display: inline-block;
					font-size: 16px;
					min-width: 35.5px;
					height: 35px;
					padding: 0 10px;
					line-height: 35px;
					vertical-align: top;
					box-sizing: border-box;
					border: 1px solid #414141;
					color: #ffffff;
					background-color: #313345;
				}

				::v-deep .el-input {
					width: 70px;
					height: 33px;
				}

				::v-deep .el-pagination__editor.el-input .el-input__inner {
					height: 24px;
					top: -2px;
					background: #001220 !important;
					color: white;
					border: none;
				}

				::v-deep .el-pagination__editor {
					margin: 0 10px;
				}
			}
		}
	}
</style>
