<template>
  <div class="recruit-container">
    <div class="recruit-query-header">
      <div class="recruit-header-menu">
        <ul class="menu-list">
          <li
            class="menu-item"
            :class="activeTypeIndex === 1 && 'menu-item-active'"
            @click="eventChangeIndex(1)"
          >
            首页
          </li>
          <li
            class="menu-item"
            @mouseenter="getPageClassifyList"
            @mouseleave="eventHideChildren"
            name="cid"
          >
            <p>
              <span>{{ classifyName || "分类" }}</span>
            </p>
            <i
              :class="classifyName ? 'el-icon-close' : 'el-icon-arrow-down'"
              @click="
                () => {
                  classifyName && iconClick();
                }
              "
            ></i>
            <div class="menu-children-box">
              <ul class="menu-children-list" v-show="menuList.length">
                <li
                  class="menu-children-item"
                  :data="item.id"
                  @mouseenter="eventShowChildren(item.id)"
                  :active="queryForm.cid === item.id"
                  name="cid"
                  @click.stop="
                    eventItemClick(item.id, undefined, item.classifyName)
                  "
                  v-for="item in menuList"
                  :key="item.id"
                >
                  {{ item.classifyName }}
                  <div class="menu-children-list-son">
                    <ul
                      class="menu-children-list"
                      v-show="majorList.length && queryForm.cid === item.id"
                    >
                      <li
                        class="menu-children-item"
                        :data="citem.id"
                        :active="queryForm.classify === citem.id"
                        name="classify"
                        @click.stop="
                          eventItemClick(
                            item.id,
                            citem.id,
                            item.classifyName + '-' + citem.classifyName
                          )
                        "
                        v-for="citem in majorList"
                        :key="citem.id"
                      >
                        {{ citem.classifyName }}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="menu-item"
            :class="activeTypeIndex === 2 && 'menu-item-active'"
            @click="eventChangeIndex(2)"
          >
            最新
          </li>
          <li
            class="menu-item"
            :class="activeTypeIndex === 3 && 'menu-item-active'"
            @click="eventChangeIndex(3)"
          >
            最热
          </li>
        </ul>
        <div class="menu-search">
          <div class="search-left">
            <el-select
              v-model="search_type"
              placeholder="请选择"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in search_type_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-center">
            <input
              type="text"
              @keydown.enter="search"
              v-model="searchtext"
              placeholder="搜索岗位名或用户昵称"
              class="search-input"
            />
          </div>
          <div class="search-right">
            <button @click="search" class="search-go"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="recruit-content">
      <div class="recruit-hot-list">
        <p class="hot-title">热招岗位</p>
        <div class="hot-list">
          <p class="recruit-none-data">
            <i class="el-icon-postcard"></i><br />暂无数据
          </p>
        </div>
        <div class="hot-text">
          <p>海量优质人才</p>
          <p>让招聘更高效</p>
          <span class="text-flag">怪屋招聘</span>
        </div>
      </div>
      <div class="recruit-list">
        <router-link
          class="recruit-item"
          v-for="item in jobList"
          :key="item.id"
          tag="a"
          target="_blank"
          :to="`/recruitDetail_?activeIndex=4&id=` + item.id"
        >
          <item :recruitInfo="item"></item>
        </router-link>
      </div>
      <div class="recruit-item-line">
        <p class="line-item" v-for="item in jobList.length" :key="item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/crile.png"
          />
          <svg v-if="item === 1">
            <line x1="25%" y1="100%" x2="40%" y2="130%"></line>
            <line x1="40%" y1="130%" x2="100%" y2="130%"></line>
          </svg>
          <svg v-else-if="item === 4">
            <line x1="25%" y1="30%" x2="40%" y2="0%"></line>
            <line x1="40%" y1="0%" x2="100%" y2="0%"></line>
          </svg>
          <svg v-else>
            <line x1="40%" y1="70%" x2="100%" y2="70%"></line>
          </svg>
        </p>
      </div>
      <div class="paginations">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageNum"
          :page-size="queryForm.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import item from "./components/item1.vue";
import { getJobList, getHotList } from "@/api/company.js";
import { getClassifyList, getChildList } from "@/api/work.js";
export default {
  data() {
    return {
      activeTypeIndex: 1,
      menuList: [],
      majorList: [],
      classifyName: undefined,
      queryForm: {
        pageNum: 1,
        pageSize: 4,
        title: undefined,
        authorName: undefined,
        // 方式
        cid: undefined,
        // 专业
        classify: undefined,
      },
      search_type_options: [
        {
          label: "岗位",
          value: 1,
        },
        {
          label: "用户",
          value: 2,
        },
      ],
      search_type: 1,
      searchtext: undefined,
      // 普通岗位
      jobList: [],
      total: 0,
    };
  },
  components: {
    item,
  },
  created() {
    this.$store.commit("setShowNav", false);
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      getJobList(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.total = res.total;
          this.jobList = res.rows;
        }
      });
    },
    // 点击筛选栏
    eventChangeIndex(index) {
      this.activeTypeIndex = index;
    },
    // 获取分类标签数据
    async getPageClassifyList() {
      let state = {
        queryType: 2,
      };
      let { code, data } = await getClassifyList(state);
      if (code === 200) {
        this.menuList = data;
      }
    },
    async eventShowChildren(parentId) {
      this.queryForm.cid = parentId;
      let { code, data } = await getChildList({ parentId, queryType: 2 });
      if (code === 200) {
        this.majorList = data;
      }
    },
    // 鼠标移出触发
    eventHideChildren(e) {
      this.menuList = [];
      this.majorList = [];
    },
    // 点击事件
    eventItemClick(cid, classifyId, classifyName) {
      this.queryForm.cid = cid;
      this.queryForm.classify = classifyId;
      //this.classifyName = '：'+ classifyName;
      this.classifyName = classifyName;
      this.getList();
    },
    // 点击X 事件
    iconClick(e) {
      this.classifyName = "";
      this.queryForm.cid = undefined;
      this.queryForm.classify = undefined;
      this.getList();
    },
    search() {
      this.queryForm.title = undefined;
      this.queryForm.authorName = undefined;
      this.search_type === 1
        ? (this.queryForm.title = this.searchtext)
        : (this.queryForm.authorName = this.searchtext);
      this.getList();
    },
    // 页码发生变化
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit-container {
  width: 100vw;
  height: calc(100vh - 60px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
  background-size: 100% 100%;
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    background-position: 30px 50%;
    background-repeat: no-repeat;
  }
  .recruit-query-header {
    position: absolute;
    top: 10px;
    right: 20px;
    .recruit-header-menu {
      display: flex;
      align-items: flex-end;
      margin-right: 40px;
      .menu-list {
        display: flex;
        height: 48px;
        color: #ffffff;
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #929292;
          position: absolute;
          bottom: 0;
        }
        .menu-item {
          padding: 0 15px;
          margin: 0 5px;
          cursor: pointer;
          position: relative;
          line-height: 48px;
          > p {
            display: inline-block;
          }
          > .el-icon-close {
            margin-left: 5px;
          }
          .menu-children-box {
            position: absolute;
            top: 46px;
            left: 0;
            //transform: translateX(-50%);
            z-index: 2001;
            .menu-children-list {
              background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
              width: 100px;
              text-align: center;
              padding: 10px 0px;
              //display: none;
              margin-right: 2px;
              //display: flex;
              .menu-children-item {
                height: 30px;
                line-height: 30px;
                //padding: 0 10px;
                //width: auto;
                &:hover {
                  background-color: #00a8b9;
                }
                &[active="true"] {
                  background-color: #00a8b9;
                }
              }
              // .menu-children-item.menu-children-item-active{
              //     background-color: #00a8b9;

              // }
              &[type="show"] {
                display: block;
              }
            }
            .menu-children-list-son {
              position: relative;
              left: 100%;
              top: -40px;
              padding-left: 5px;
            }
          }
          &[type="show"] {
            .menu-children-list {
              display: block;
            }
          }
        }
        .menu-item.menu-item-active {
          &:after {
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 2px;
            background-color: #34e8fa;
            z-index: 999;
            left: 15px;
            bottom: 0;
          }
        }
      }
      .menu-search {
        display: flex;
        width: 290px;
        height: 36px;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border-radius: 5px;
        margin-left: 40px;
        .search-center {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 160px;
            height: 2px;
            background-color: transparent;
            z-index: 999;
            left: 15px;
            bottom: 3px;
          }
          .search-input {
            position: relative;
            height: 2.25rem;
            width: 12.1875rem;
            border: none;
            color: #ffffff;
            font-size: 0.875rem;
            letter-spacing: 0.0625rem;
            text-indent: 0.9375rem;
            background-color: transparent;
            caret-color: #ffffff;
          }
        }
        .search-go {
          width: 36px;
          height: 36px;
          background-color: transparent;
          border: none;
          border-radius: 0 1px 1px 0;
          cursor: pointer;
          background-image: url("../../assets/newImg/search.png");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 26px 26px;
        }
      }
    }
  }
  .recruit-content {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    // background-position: 30px 50%;
    // background-repeat: no-repeat;

    .recruit-hot-list{
      position: absolute;
      left: 160px;
      top: 250px;
      color: #FFFFFF;
      width: 300px;
      .hot-title{
        font-family: 'biaoti';
        font-size: 48px;
        letter-spacing: 5px;
        text-align: center;
      }
      .hot-list{
        width: 100%;
        height: 200px;
        .recruit-none-data{
          text-align: center;
          color: #87888d;
          .el-icon-postcard{
            font-size: 32px;
            margin-top: 50px;
          }
        }
      }
      .hot-text{
          >p{
            letter-spacing: 2px;
            line-height: 24px;
          }
          .text-flag{
            background-color: #1A4CA2;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 9px;
            display: inline-block;
            margin-top: 20px;
          }
      }
    }

    .recruit-list {
      position: relative;
      .recruit-item {
        position: absolute;
        &:nth-child(1) {
          left: 550px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 650px;
          top: 180px;
        }
        &:nth-child(3) {
          left: 650px;
          top: 360px;
        }
        &:nth-child(4) {
          left: 550px;
          top: 540px;
        }
      }
    }

    .recruit-item-line {
      position: relative;
      .line-item {
        position: absolute;
        &:nth-child(1) {
          left: 370px;
          top: 70px;
          width: 180px;
          height: 80px;
        }
        &:nth-child(2) {
          left: 525px;
          top: 230px;
          width: 125px;
          height: 80px;
        }
        &:nth-child(3) {
          left: 520px;
          top: 410px;
          width: 130px;
          height: 80px;
        }
        &:nth-child(4) {
          left: 370px;
          top: 550px;
          width: 180px;
          height: 80px;
        }
      }
      .line-item svg {
        width: 100%;
        height: 100%;
        color: #ffffff;
        stroke: currentColor;
        stroke-width: 1;
        overflow: visible;
        transform: scaleY(-1);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .paginations {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<style lang="scss">
.search-left {
  .el-popper {
    position: absolute !important;
    left: 0;
  }
  .el-input__inner {
    width: 60px !important;
    color: #ffffff;
    background-color: transparent;
    padding-right: 0.875rem !important;
    padding-left: 0.375rem !important;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 1px 0 0 1px;
    letter-spacing: 3px;
    line-height: 2rem;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    // &:hover{
    // 	color: #00ceff !important;
    // }
  }

  .el-input__icon {
    width: 12px;
    margin-right: -3px;
    color: #ffffff;
    font-weight: bold;
  }

  .el-select__caret {
    color: #ffffff !important;
  }

  .el-select-dropdown {
    position: absolute;
    background-color: #232538;
    border: none;

    .popper__arrow {
      display: none;
    }

    .el-select-dropdown__item {
      color: #fff !important;
      height: 38px;
      &:hover {
        background-color: #3e4ea6;
      }
    }

    .selected {
      color: #ffffff;
      font-weight: 0;
    }

    .hover {
      font-weight: 600 !important;
      background-color: #3e4ea6;
    }
  }

  .el-input__icon {
    line-height: 2rem;
    color: #3e4ea6;
  }

  .el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
}
</style>