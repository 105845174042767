<template>
  <div class="header-container">
    <div style="height: 60px"></div>
    <div class="header-nav">
      <div class="header-left">
        <div class="header-left-logo">
          <el-image
            @click="eventToUrl('/')"
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/logo.png"
            class="el-image"
            lazy
          ></el-image>
        </div>
        <div class="header-menu">
          <div
            class="header-menu-option"
            :class="activeIndex == 0 && 'active'"
            @click="eventToUrl('/home/newest?activeIndex=0')"
          >
            <p>作品</p>
          </div>
          <div class="header-menu-option" :class="activeIndex == 1 && 'active'"  v-if="isShowCompWork">
            <p>公司简介</p>
            <div class="header-submenu">
              <div
                class="header-submenu-option"
                @click="
                  eventToUrl('/home/profile?activeIndex=1&companyIndex=0')
                "
                
              >
                <p>关于我们</p>
              </div>
              <div
                class="header-submenu-option"
                @click="
                  eventToUrl('/home/partners?activeIndex=1&companyIndex=2')
                "
              >
                <p>合作伙伴</p>
              </div>
              <div
                class="header-submenu-option"
                @click="
                  eventToUrl('/home/contnctus?activeIndex=1&companyIndex=4')
                "
              >
                <p>联系我们</p>
              </div>
            </div>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 2 && 'active'"
            @click="eventToUrl('/home/materialList?activeIndex=2')"
            v-if="isShowCompWork"
          >
            <p>公司作品</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 4 && 'active'"
            @click="eventToUrl('/home/recruit?activeIndex=4')"
          >
            <p>招聘</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 3 && 'active'"
            @click="eventToUrl('/train?activeIndex=3')"
          >
            <p>教育培训</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 5 && 'active'"
            @click="eventToUrl('/linkage?activeIndex=5')"
          >
            <p>校企联动</p>
          </div>
          <div
            class="header-menu-option"
            :class="activeIndex == 6 && 'active'"
            @click="eventToUrl('/courses?activeIndex=6')"
          >
            <p>在线课程</p>
          </div>
          <div
            class="header-menu-option"
            @click="eventToUrl('/ectocyst', '_blank')"
            v-if="isShowCompWork"
          >
            <p>外包平台</p>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="header-menu">
          <div
            class="header-menu-option"
            @click="eventToUrl('/login')"
            v-if="
              !(
                $cookie.get('userInfo') &&
                $cookie.get('userInfo') != 'undefined'
              )
            "
          >
            <i
              class="el-icon-user-solid"
              style="font-size: 24px; margin-right: 10px"
            ></i>
            <p
              style="
                font-family: 'Microsoft Yahei';
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 2px;
              "
            >
              登录
            </p>
          </div>
          <template v-else>
            <!-- 上传界面 -->
            <div
              class="header-menu-option"
              :class="activeIndex == 7 && 'active'"
            >
              <i
                class="el-icon-upload"
                @click="eventToUrl('/release?activeIndex=7')"
              ></i>
            </div>
            <!-- 通知界面 -->
            <div
              class="header-menu-option"
              :class="activeIndex == 8 && 'active'"
            >
              <i
                class="el-icon-message-solid"
                @click="eventToUrl('/usermessage?activeIndex=8')"
                style="font-size: 26px"
              ></i>
              <!-- this.$store.state.unReadNum -->
              <div class="unread-message" v-show="this.$store.state.unReadNum">
                <span>{{
                  this.$store.state.unReadNum > 99
                    ? "99+"
                    : this.$store.state.unReadNum
                }}</span>
              </div>
            </div>
            <!-- 头像 -->
            <div class="header-menu-option">
              <el-image
                @click="eventToUrl('/personalhome?open=0')"
                :src="
                  JSON.parse($cookie.get('userInfo')).headImg ||
                  require('@/assets/img/1.png')
                "
                class="el-image"
                lazy
              ></el-image>
              <div class="header-submenu">
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('/personalhome?open=0')"
                >
                  <p>个人中心</p>
                </div>
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('information')"
                >
                  <p>修改资料</p>
                </div>
                <div
                  class="header-submenu-option"
                  @click="eventToUrl('resetpassword')"
                >
                  <p>密码设置</p>
                </div>
                <div class="header-submenu-option" @click="eventToUrl('task')">
                  <p>引导中心</p>
                </div>
                <div
                  class="header-submenu-option"
                  style="border-top: 1px solid #525252"
                  @click="loginout"
                >
                  <p>退出登录</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="home-swiper" v-if="$store.state.showNav">
      <swiper
        :options="swiperOption"
        class="swiper-container-par"
        v-if="bannerList.length"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="item in bannerList"
          :key="item.id"
        >
          <img :src="item.imgUrl" alt="" />
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { getBannerImgList } from "@/api/company.js";
import { loginUp } from "@/api/login.js";
export default {
  data() {
    return {
      isShowCompWork:false,
      activeIndex: "0",
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        },
      },
      bannerList: [],
    };
  },

  computed: {
  // userInfo() {
  //   return this.$cookie.get("userInfo");
  // }
  listenfooterInfo() {
    
    return this.$store.state.userInfo;
  },
},
  created() {
   
    this.isShowCompInfo = false;
    if (this.$route.query.companyIndex) {
      this.companyIndex = this.$route.query.companyIndex;
    }
    if (this.$route.query.activeIndex) {
      this.activeIndex = this.$route.query.activeIndex;
    }
    if (this.$route.query.isShowMsg) {
      this.isShowMsg = this.$route.query.isShowMsg;
    }
    if (this.$route.query.activeIndex == 1 && this.$route.query.companyIndex) {
      this.companyIndex = this.$route.query.companyIndex;
    }

    // if (
      
    //   this.$cookie.get("userInfo") &&
    //   this.$cookie.get("userInfo") != "undefined"  
    // ) {
    //    let userInfo=this.$cookie.get("userInfo");
       
    //    console.log("用户信息",userInfo)
       
      
    //    let roleType=userInfo.roleType
    //    console.log("拿到的type",roleType)
       
    //   this.isShowCompWork = true;
    // } 
   
  //   this.$watch(
  //   () => this.$cookie.get("userInfo").roleType,
  //   (newVal, oldVal) => {
   
  //    console.log("执行进来的")
  //     console.log("roleType change", newVal);
  //   }
  // );
  
  }, 

 

  watch:{
    "$route.query.activeIndex": {
      handler(v) {
        this.activeIndex = v;
        this.getBannerImgList();
      },
      immediate: true,
    },

    listenfooterInfo: {
      handler(newval, oldval) {

      
        this.headImgKey = Math.random();
        if (
          !this.$store.state.userInfo == "" &&
          this.$store.state.userInfo.roleType == 3
        ) { 
          this.isShowCompWork = true;
        }
      },
      immediate: true,
      deep: true,
    },

  },
  mounted() {},
  methods: {
    // 获取轮播图
    async getBannerImgList() {
      let type =
        this.activeIndex == 0
          ? 1
          : this.activeIndex == 4
          ? 4
          : this.activeIndex == 6
          ? 5
          : 2;
      this.bannerList = [];
      let formData = {
        type: type,
      };
      let { code, rows } = await getBannerImgList(formData);
      if (code === 200) {
        this.bannerList = rows;
      }
    },
    // 跳转界面
    eventToUrl(url, type) {
      if (type === "_blank") {
        let routeUrl = this.$router.resolve({ path: url });
        window.open(routeUrl.href, "_blank");
        return;
      }
      this.$router.push(url);
    },
    // 退出登录
    async loginout() {
      let data = {
        token: this.$cookie.get("accessToken"),
      };
      let { code } = await loginUp(data);
      if (code == 200) {
        this.$cookie.remove("accessToken");
        this.$cookie.remove("userInfo");
        this.$router.push("/login");
        window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  .header-nav {
    height: 60px;
    background-color: #060709;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    .header-left {
      height: 100%;
      display: flex;
      align-items: center;
      .header-left-logo {
        margin: 0 80px 0 50px;
        width: 160px;
        height: 45px;
        cursor: pointer;
        /deep/.el-image {
          width: 100%;
          height: 100%;
        }
      }
    }

    .header-right {
      position: absolute;
      right: 60px;
      top: 0;
      height: 60px;
      display: flex;
      align-items: center;
      .header-menu-option > i {
        font-size: 32px;
        color: #ffffff;
        // margin-right: 30px;
        cursor: pointer;

        &:hover {
          color: #2376d2;
        }
      }
      .header-menu-option .unread-message{
        position: absolute;
        min-width: 18px;
        height: 18px;
        line-height: 18px;
        background-color: red;
        font-size:12px;
        font-weight: 600;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        top: 12px;
        right: -10px;
      }
      .el-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    .header-menu {
      display: flex;
      align-items: center;
      height: 60px;
      .header-menu-option {
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;
        font-family: biaoti;
        letter-spacing: 2px;
        cursor: pointer;
        margin: 0 20px;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        > p {
          transform: scaleY(1.15);
        }

        &:hover {
          color: #2376d2;
          & > .header-submenu {
            display: block;
          }
        }

        &.active {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #1752ac;
            width: 100%;
            height: 5px;
          }
        }

        .header-submenu {
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
          z-index: 9;
          padding-top: 5px;
          display: none;
          .header-submenu-option {
            text-align: center;
            color: #ffffff;
            line-height: 60px;
            font-size: 20px;
            padding: 0 20px;
            cursor: pointer;
            letter-spacing: 2px;
            background-color: #060709;
            > p {
              transform: scaleY(1.15);
            }
            &:hover {
              color: #2376d2;
            }
          }
        }
      }
    }
  }

  .home-swiper {
    width: 100%;
    height: 365px;
    opacity: 1;
    border-radius: 0px;
    margin: 0 auto;

    .swiper-slide {
      > img {
        width: 100%;
        //height: 130%;
        object-fit: cover;
      }
    }

    ::v-deep .swiper-container {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: hidden;
      list-style: none;
      padding: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    ::v-deep .swiper-pagination-bullet {
      margin: 0 12px !important;
      width: 35px;
      height: 10px;
      background: #ffffff;
      opacity: 0.35;
      border-radius: 117px;
      transition: all 0.3s linear;
      &:hover {
        transform: scale(1.2);
      }
    }

    ::v-deep .swiper-pagination-bullet-active {
      width: 35px;
      height: 10px;
      background: #2376d2;
      // background: #9EEB6A;
      opacity: 1;
      border-radius: 117px;
    }

    ::v-deep .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 80px;
      line-height: 100px;
      background: linear-gradient(
        180deg,
        rgba(45, 32, 35, 0) 0%,
        rgba(29, 20, 24, 0.46) 45%,
        #252525 100%
      );
    }
  }
}
</style>
