<template>
  <div class="toast-container">
    <el-steps :active="stepActive" finish-status="success" simple>
      <el-step title="提交订单"></el-step>
      <el-step title="选择支付方式并支付"></el-step>
      <el-step title="结果反馈"></el-step>
    </el-steps>
    <template v-if="stepActive === 1">
      <div class="toast-content">
        <p>账户名：{{ JSON.parse($cookie.get("userInfo")).nickName }}</p>
        <p>提交时间：{{info.time}}</p>
      </div>
      <el-table :data="tableData" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="seriesName" label="名称" align="center">
        </el-table-column>
        <el-table-column prop="price" label="金额" align="center">
        </el-table-column>
      </el-table>
      <div class="toast-price">
        <p>需支付：￥{{ info.price }}元</p>
      </div>
      <div class="toast-btn">
        <el-form
          ref="dataForm"
          :rules="rules"
          :model="dataForm"
          label-width="60px"
        >
          <el-form-item label="姓名" prop="name" required>
            <glsx-input
              type="text"
              :value="dataForm.name"
              placeholderText="请输入姓名"
              @getValue="changeInputValue('dataForm', $event, 'name')"
            ></glsx-input>
          </el-form-item>
        </el-form>
        <div class="toast-order-submit" @click="eventSubmitOrder">提交订单</div>
      </div>
    </template>
    <template v-else-if="stepActive === 2">
      <!-- <p class="pay-type-title">选择支付方式去支付吧</p> -->
      <div class="pay-img-box" v-show="isShowPayType">
        <img src="@/assets/img/cursor/pay_wx_icon.png" @click="eventWxPay" />
        <img src="@/assets/img/cursor/pay_ali_icon.png" @click="eventAliPay" />
      </div>
      <div class="pay-wechat-code" v-if="isShowWechatCode">
        <p class="code-back" @click="eventBackType">
          <i class="el-icon-back"></i>重新选择支付方式
        </p>
        <p class="code-title">微信扫码支付</p>
        <div class="code-img">
          <div
            id="wechatcode"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          ></div>
        </div>
        <p class="code-price">金额：￥{{ info.price }}元</p>
      </div>
      <div class="pay-wechat-code" v-if="isShowAliCode">
        <p class="code-back" @click="eventBackType">
          <i class="el-icon-back"></i>重新选择支付方式
        </p>
        <p class="code-title">支付宝扫码支付</p>
        <div
          v-loading="loading_ali"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="code-ali-box"
        >
          <iframe
            :srcdoc="aliCodeHtml"
            frameborder="no"
            border="0"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            width="200"
            height="200"
            style="overflow: hidden"
          >
          </iframe>
        </div>
        <p class="code-price">金额：￥{{ info.price }}元</p>
      </div>
    </template>
    <template v-else-if="stepActive === 3">
      <div class="pay-status-box">
        <i class="el-icon-success"></i>
        <p>支付成功</p>
      </div>
    </template>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import glsxInput from "@/component/form/glsxInput.vue";
import {
  handleWxPay,
  handleAliPay,
  getWxPayResult,
  getAliPayResult,
} from "@/api/series.js";
export default {
  data() {
    return {
      tableData: [],
      stepActive: 1,
      loading: false,
      // 支付宝二维码加载
      loading_ali: false,
      isShowWechatCode: false,
      isShowPayType: true,
      isShowAliCode: false,
      aliCodeHtml: undefined,
      interval: undefined,
      dataForm: {
        name: undefined,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    info: {
      type: Object,
      require: true,
    },
  },
  components: {
    glsxInput,
  },
  created() {
    this.getList();
  },
  methods: {
    // 提交订单
    eventSubmitOrder() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.stepActive = 2;
        }
      });
    },
    changeInputValue(objectName, event, attributeName) {
      this.$set(this[objectName], attributeName, event);
    },
    // 获取
    getList() {
      this.tableData = [];
      this.tableData.push(this.info);
    },
    // 微信支付
    async eventWxPay() {
      this.loading = true;
      this.isShowWechatCode = true;
      this.isShowPayType = false;
      let { code, data } = await handleWxPay({ pid: Number(this.info.pid), name: this.dataForm.name });
      if (code === 200) {
        this.$nextTick(() => {
          let wechatcode = new QRCode("wechatcode", {
            width: 200,
            height: 200,
            text: data.codeUrl, // 二维码地址
            colorDark: "#000",
            colorLight: "#fff",
          });
          this.loading = false;
          this.interval = setInterval(() => {
            setTimeout(() => {
              this.watchPayStatus(data.orderNo, "wechat");
            }, 0);
          }, 2000);
        });
      }
    },
    // 返回支付选择
    eventBackType() {
      this.isShowWechatCode = false;
      (this.isShowAliCode = false), (this.isShowPayType = true);
      clearInterval(this.interval);
      this.interval = undefined;
    },
    // 阿里支付
    async eventAliPay() {
      this.loading_ali = true;
      this.isShowPayType = false;
      this.isShowAliCode = true;
      console.log(this.dataForm.name);
      let { code, data } = await handleAliPay({ pid: Number(this.info.pid), name: this.dataForm.name });
      if (code === 200) {
        //this.isShowAliCode = true;
        //this.isShowPayType = false;
        this.aliCodeHtml = data.code;
        this.$nextTick(() => {
          this.loading_ali = false;
          this.interval = setInterval(() => {
            setTimeout(() => {
              this.watchPayStatus(data.orderNo, "ali");
            }, 0);
          }, 2000);
        });
      }
    },
    // 监听支付状态
    async watchPayStatus(orderNo, payType) {
      if (payType === "wechat") {
        var { code, data } = await getWxPayResult({ orderNo });
      } else if (payType === "ali") {
        var { code, data } = await getAliPayResult({ orderNo });
      }
      let that = this;
      if (code === 200) {
        if (data.flag) {
          clearInterval(this.interval);
          that.interval = undefined;
          that.stepActive = 3;
          that.$emit("refreshPage");
        }
      }
    },
    // 监听支付宝支付 getAliPayResult
    // async watchAliPay(orderNo) {
    //     let {code, data}  = await getAliPayResult({orderNo});
    //     if (code === 200) {
    //       if (data.flag) {
    //         this.stepActive = 3;
    //       }
    //     }
    // }
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = undefined;
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  .toast-content {
    color: #ffffff;
    margin-top: 20px;
    > p {
      line-height: 30px;
    }
  }
  .toast-price {
    margin-top: 20px;
    color: #dfc77d;
    font-size: 22px;
    text-align: right;
  }

  .toast-btn {
    border-top: 1px solid #7c7272;
    margin-top: 20px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    /deep/.el-form-item__label {
      color: #ffffff;
    }

    .toast-order-submit {
      color: #ffffff;
      text-align: center;
      font-size: 24px;
      padding: 10px 20px;
      border: 2px solid #dfc77d;
      border-radius: 15px;
      cursor: pointer;
      transition: background-color 0.5s ease;
      &:hover {
        background-color: #dfc77d;
      }
    }
  }

  .pay-type-title {
    font-size: 20px;
    color: #ffffff;
  }

  .pay-img-box {
    padding: 80px;
    display: flex;
    justify-content: space-around;
    > img {
      width: 194px;
      height: 62px;
      cursor: pointer;
    }
  }

  .code-ali-box {
    width: 200px;
    height: 200px;
    margin-left: calc(50% - 100px);
  }

  .pay-wechat-code {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    color: #ffffff;
    margin-top: 30px;
    text-align: center;
    // width: 300px;
    // padding: 20px 50px;
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // background-color: #234767;
    position: relative;
    .code-title {
      font-size: 24px;
      margin: 50px 0 20px;
      width: 100%;
    }
    .code-img {
      // display: flex;
      // justify-content: center;
      width: 200px;
      height: 200px;
      margin-left: calc(50% - 100px);
      #wechatcode {
        height: 200px;
      }
    }
    .code-price {
      color: rgb(201, 71, 71);
      font-weight: bold;
      margin-top: 10px;
      width: 100%;
    }
    .code-back {
      position: absolute;
      left: 0px;
      top: -20px;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.9);
      i {
        margin-right: 5px;
      }
    }
  }

  .pay-status-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 20px;
    > i {
      font-size: 200px;
      color: #35edff;
    }
    > p {
      font-size: 42px;
      color: #35edff;
    }
  }

  /deep/.el-steps--simple {
    background-color: #0f1128;
  }
  /deep/.el-step__head.is-success {
    color: #35edff;
    border-color: #35edff;
    font-weight: bold;
  }
  /deep/.el-step__title.is-success {
    color: #35edff;
    font-weight: bold;
  }
  /deep/.el-step__head.is-wait {
    color: #303133;
    border-color: #303133;
  }
  /deep/.el-step__title.is-wait {
    color: #303133;
  }

  /deep/.el-table th.el-table__cell {
    background-color: #234767;
    color: #ffffff;
  }
  /deep/.el-table tr {
    // background-color: #234767;
    // color: #35edff;
    // &:hover{
    //     background-color: #234767;
    //     color: #35edff;
    // }
  }
  /deep/.el-table--enable-row-transition .el-table__body td.el-table__cell {
    background-color: #234767;
    color: #35edff;
  }
}
</style>