<template>
    <div class="main-container">
        <!-- <div class="map-picture">
            <el-image class="pic-class" :src="msgList.addrImgUrl" :preview-src-list="srcList"></el-image>
        </div> -->
        <div class="callpay">
            <div class="callpay-header">
                <div class="call-phone">
                    <div class="call-icon">
                        <img src="@/assets/newImg/29.png">
                    </div>
                    <div class="call-way">
                        {{msgList.phoneNumber1}}
                    </div>
                    <div class="call-way">
                        {{msgList.phoneNumber2}}
                    </div>
                </div>
            </div>
            <div class="callpay-email">
                <div class="call-phone">
                    <div class="call-icon">
                        <img src="@/assets/newImg/30.png">
                    </div>
                    <div class="call-way">
                        公司邮箱：{{msgList.compEmail}}
                    </div>
                    <div class="call-way">
                        招聘邮箱：{{msgList.inviteEmail}}
                    </div>
                </div>
            </div>
            <div class="callpay-address">
                <div class="call-phone">
                    <div class="call-icon">
                        <img src="@/assets/newImg/31.png">
                    </div>
                    <div class="call-way">
                        总部地址：<a class="call-addr" href="#">{{msgList.headAddr}}</a>
                        <!-- 总部地址：<a class="call-addr" :href="headAddr" target="_blank">{{msgList.headAddr}}</a> -->
                    </div>
                    <div class="call-way">
                        深圳分公司地址：<a class="call-addr" href="#" >{{msgList.shenzhenBranchAddr}}</a>
                        <!-- 深圳分公司地址：<a class="call-addr" :href="shenzhenBranchAddr" target="_blank">{{msgList.shenzhenBranchAddr}}</a> -->
                    </div>
                    <div class="call-way">
                        <!-- 福州分公司地址：<a class="call-addr" :href="fuzhouBranchAddr" target="_blank">{{msgList.fuZhouBranchAddr}}</a> -->
                        福州分公司地址：<a class="call-addr" href="#" >{{msgList.fuZhouBranchAddr}}</a>
                    </div>
                    <div class="call-way">
                        成都分公司地址：<a class="call-addr" href="#" >{{msgList.chengDouBranchAddr}}</a>
                        <!-- 成都分公司地址：<a class="call-addr" :href="chengduBranchAddr" target="_blank">{{msgList.chengDouBranchAddr}}</a> -->
                    </div>
                </div>
            </div>
           
        </div>
        <div class="map-picture">
            <el-image class="pic-class" v-if="msgList.addrImgUrl" :src="msgList.addrImgUrl" :preview-src-list="srcList" fit="cover"></el-image>
        </div>
    </div>
</template>

<script>
    import {getContactUs} from '@/api/company.js';
    export default {
        data(){
            return{
                msgList: '',
                headAddr: 'https://www.baidu.com/s?wd=${myHeadAddr}&rsv_spt=1&rsv_iqid=0x91ed1ec6004217e3&issp=1&f=8&rsv_bp=1&rsv_idx=2&ie=utf-8&tn=baiduhome_pg&rsv_enter=0&rsv_dl=tb&rsv_sug3=6&rsv_sug1=2&rsv_sug7=100&rsv_n=2&rsv_btype=i&inputT=10732&rsv_sug4=10732',
                fuzhouBranchAddr: 'https://www.baidu.com/s?wd=${fuzhouBranchAddr}&rsv_spt=1&rsv_iqid=0x91ed1ec6004217e3&issp=1&f=8&rsv_bp=1&rsv_idx=2&ie=utf-8&tn=baiduhome_pg&rsv_enter=0&rsv_dl=tb&rsv_sug3=6&rsv_sug1=2&rsv_sug7=100&rsv_n=2&rsv_btype=i&inputT=10732&rsv_sug4=10732',
                chengduBranchAddr: 'https://www.baidu.com/s?wd=${chengduBranchAddr}&rsv_spt=1&rsv_iqid=0x91ed1ec6004217e3&issp=1&f=8&rsv_bp=1&rsv_idx=2&ie=utf-8&tn=baiduhome_pg&rsv_enter=0&rsv_dl=tb&rsv_sug3=6&rsv_sug1=2&rsv_sug7=100&rsv_n=2&rsv_btype=i&inputT=10732&rsv_sug4=10732',
                shenzhenBranchAddr: '#',
                srcList: [],
            }
        },
        methods:{
            async getDetail(){
                let {code,data} = await getContactUs();
                if(code===200){
                    console.log(data);
                    this.msgList=data;
                    this.headAddr = this.headAddr.replace("${myHeadAddr}", this.msgList.headAddr); 
                    this.chengduBranchAddr = this.chengduBranchAddr.replace("${chengduBranchAddr}", this.msgList.chengDouBranchAddr); 
                    this.fuzhouBranchAddr = this.fuzhouBranchAddr.replace("${fuzhouBranchAddr}", this.msgList.fuZhouBranchAddr); 
                    this.srcList.push(data.addrImgUrl);
                }
            }
        },
        created(){
            this.getDetail()
        }
    }
</script>

<style lang="scss" scoped>
.main-container{
    margin: 0 auto;
    width: 1400px;
    overflow: hidden;
    position: relative; 
    display: flex;
    min-height: 800px;
    .map-picture{
        width: 692px;
        height: 690px;
        background: rgb(255, 255, 255);
        padding: 8px;
        border-radius: 10px;
        margin-top: 67px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        .pic-class{
            // margin-right: 20px;
            // position: relative;
            // right: -100px;
            margin: 0 auto;
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
        }
    }
    .callpay{
        width: 691px;
        min-height: 800px;
        background: #343F53;
        // background: #2D2E2C;
        box-shadow: 10px 3px 30px rgba(0, 0, 0, 0.3);
        opacity: 1;
        // border-radius: 10px;s
        position: relative;
        top: 42px;
        // position: absolute;
        // top:0;
        // left: 13.2%;
        .callpay-header{
            height: 215px;
            border-bottom:2px #252525 solid;
            overflow: hidden;
            .call-phone{
                margin-top:48px;
                margin-left:55px;
                .call-icon{
                    width: 35px;
                    height: 35px;
                    img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
                .call-way{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 36px;
                    color: rgba(255, 255, 255, 0.8);
                    &:nth-child(1){
                        margin-top:20px;
                    }
                    &:nth-child(2){
                        margin-top:10px;
                    }
                }
            }
        }
        .callpay-email{
            height: 231px;
            border-bottom: 2px #252525 solid;
            overflow: hidden;
            .call-phone{
                margin-top:48px;
                margin-left:55px;
                .call-icon{
                    width: 36px;
                    height: 30px;
                    img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
                .call-way{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 36px;
                    color: rgba(255, 255, 255, 0.8);
                    &:nth-child(1){
                        margin-top:20px;
                    }
                    &:nth-child(2){
                        margin-top:20px;
                    }
                }
            }
        }
        .callpay-address{
            width: 591px;
            height: 308px;
            margin-left: 55px;
            overflow: hidden;
            .call-phone{
                margin-top: 40px;
                margin-bottom: 40px;
                // margin-left: 55px;
                .call-icon{
                    width: 32px;
                    height: 40px;
                    img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
                .address-v{
                    display: flex;
                    &:nth-child(1){
                        width: 140px;
                        margin-top: 20px;
                    }
                }
                .call-way{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 36px;
                    color: rgba(255, 255, 255, 0.8);
                    &:nth-child(1){
                        margin-top:10px;
                    }
                    &:nth-child(2){
                        margin-top:10px;
                    }
                    // display: -webkit-box;
                    // -webkit-box-orient: vertical;
                    // word-break: break-all;
                    // -webkit-line-clamp: 2;
                    // overflow: hidden;
                }
                .call-addr{
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 36px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-top: 20px;
                    // margin-right: 55px;
                    &:hover{
                        color: #2376D2;
                        // color: #9EEA6A;
                    }
                }
            }
        }
    }
}
</style>