<template>
	<footer class="footer-container">
		<div class="footer-content" v-if="true">
			<div class="footer-logo" @click="goGuide"><img src="@/assets/newImg/GLlogo.png" alt=""></div>
			<div class="footer-about">
				<div class="power-case">
					<a class="power" :href="`/home/profile?activeIndex=1&companyIndex=0`" target="_blank">关于我们</a>
					<div class="line"></div>
					<a class="website" :href="`/home/contnctus?activeIndex=1&companyIndex=4`" target="_blank">联系我们</a>
				</div>
				<div><a class="case" href="https://beian.miit.gov.cn/" target="view_window">粤ICP备2021083385号</a></div>
				<div class="case1">Copyright©2021 广州怪力视效网络科技有限公司 版权所有</div>
			</div>
			<div class="focusme">
				<div class="focu">关注我们</div>
				<div class="footer-icons">
					<div :class="showFlag1 ? 'weixinicons click-class':'weixinicons'">
						<img src="@/assets/newImg/wechat.png" alt="" @click="handleClick1">
					</div>
					<div :class="showFlag2 ? 'qqicons click-class':'qqicons'">
						<img src="@/assets/newImg/QQ (1).png" alt="" @click="handleClick2">
						<!-- <img src="@/assets/newImg/QQ-circle-fill.png" alt="" @click="handleClick2"> -->
					</div>
				</div>
				<div style="display:inline">
					<div v-if="showFlag1" class="wechant-class">
						<p style="width: 160px;line-height: 30px;color: #00ffff;text-align: center;">扫码关注微信公众号</p>
						<img width="160px" src="../../assets/img/wechat.jpg" alt="">
						<!-- <img :src="followUs.wechatImg" title="微信二维码" width="150px" height="150px"> -->
					</div>
					<div v-if="showFlag2" class="wechant-class">
						<p style="width: 160px;line-height: 30px;color: #00ffff;text-align: center;">扫码加入QQ交流群</p>
						<img width="160px" src="../../assets/img/qqqun.jpg" alt="">
						<!-- <img :src="followUs.qqImg" title="QQ二维码" width="150px" height="150px"> -->
					</div>
				</div>
			</div>
		</div>
		<div class="collection-option">
			<div class="wx" @click="handleClick3">
				<transition name="fade2">
				<div v-if="showFlag3" class="wechant-class2">
					<p style="width: 160px;line-height: 30px;color: #00ffff;text-align: center;">扫码关注微信公众号</p>
					<img width="160px" src="../../assets/img/wechat.jpg" alt="">
					<!-- <img :src="followUs.wechatImg" title="微信二维码" width="150px" height="150px"> -->
				</div>
				</transition>
				<img src="@/assets/img/6-wechat.png" title="微信" alt="">
			</div>
			<div class="qq" @click="handleClick4">
				<transition name="fade3">
				<div v-if="showFlag4" class="wechant-class2">
					<p style="width: 160px;line-height: 30px;color: #00ffff;text-align: center;">扫码加入QQ交流群</p>
					<img width="160px" src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/footer/qqqun.png" alt="">
					<!-- <img :src="followUs.qqImg" title="QQ二维码" width="150px" height="150px"> -->
				</div>
				</transition>
				<img src="@/assets/img/6-QQ.png" title="QQ" alt="">
			</div>
			<div class="pinglun" @click="handleClick5">
				<img src="@/assets/img/6-message.png" title="留言板" alt="">
			</div>
			<div class="back-top" @click="backtop">
				<img src="@/assets/img/6-back.png" title="返回顶部" alt="">
			</div>
			
		</div>
		<transition name="fade">
			<div class="lyq" v-show="showFlag5">
				<div class="ly-title">
					留言板<span>Message Board</span>
				</div>
				<p>注：给管理员留言表达你的意见建议时，留下您的联系方式；管理员会尽快与您联系。</p>
				<div class="ly-conten">
					<textarea style="resize: none;" v-model="board" maxlength="249"></textarea>
				</div>
				<div class="ly-footer">
					<div class="btn2" @click="addBoard">提交</div> 
					<div class="btn1" @click="showFlag5 = false;board = ''">取消</div> 
				</div>
			</div>
		</transition>
	</footer>
</template>

<script>
	import {
		getFollowUs
	} from '@/api/company.js'
	import{
		getBoard
	} from '../../api/work.js'
	export default {
		name: "VFooter",
		data() {
			return {
				footerData: '',
				footerMessage: '',
				followUs: {
					wechatImg: "",
					qqImg: ""
				},
				showFlag1: false,
				showFlag2: false,
				showFlag3: false,
				showFlag4: false,
				showFlag5: false,
				board:'',
			}
		},
		methods: {
			async addBoard(){
				if(this.board){
					let data = {
						flag:1,
						content:this.board,
						userId:this.$store.state.userInfo.id,
						nickName:this.$store.state.userInfo.nickName
					}
					let {
						code
					}= await getBoard(data);
					if(code==200){
						this.$message({
							type: 'success',
							offset: 80,
							message: "提交成功"
						});
						this.board = '';
					}
				}else {
					this.$message({
						type: 'error',
						offset: 80,
						message: "不能提交空评论哦"
					});
				}
				
			},
			goGuide() {
				this.$router.push('/guide');
			},
			backtop() {
				document.documentElement.scrollTop = 0
			},
			gocontnctus() {
				this.companyIndex = 4;
				this.$router.push('/home/contnctus?activeIndex=1&companyIndex=4');
			},
			getFollowUs() {
				getFollowUs().then(res => {
					this.followUs = res.data;
				})
			},
			handleClick1() {
				this.showFlag1 = !this.showFlag1;
				this.showFlag2 = false;
				this.showFlag3 = false;
				this.showFlag4 = false;
				this.showFlag5 = false;
			},
			handleClick2() {
				this.showFlag1 = false;
				this.showFlag2 = !this.showFlag2;
				this.showFlag3 = false;
				this.showFlag4 = false;
				this.showFlag5 = false;
			},
			handleClick3() {
				this.showFlag1 = false;
				this.showFlag2 = false;
				this.showFlag3 = !this.showFlag3;
				this.showFlag4 = false;
				this.showFlag5 = false;
			},
			handleClick4() {
				this.showFlag1 = false;
				this.showFlag2 = false;
				this.showFlag3 = false;
				this.showFlag4 = !this.showFlag4;
				this.showFlag5 = false;
			},
			handleClick5() {
				this.showFlag1 = false;
				this.showFlag2 = false;
				this.showFlag3 = false;
				this.showFlag4 = false;
				this.showFlag5 = !this.showFlag5;
				this.board = ''
			},
		},
		created() {
			this.footerData = [{
					labelId: 1,
					labelName: '关于我们',
					url: '/home/homeInfo?labelId=1&className=default'
				},
				{
					labelId: 2,
					labelName: '用户协议',
					url: '/home/homeInfo?labelId=2&className=default'
				},
				{
					labelId: 3,
					labelName: '隐私政策',
					url: '/home/homeInfo?labelId=3&className=default'
				},
				{
					labelId: 4,
					labelName: '帮助中心',
					url: '/home/homeInfo?labelId=4&className=help'
				},
				{
					labelId: 5,
					labelName: '联系我们',
					url: '/home/homeInfo?labelId=5&className=contact'
				},
				{
					labelId: 6,
					labelName: '侵权投诉',
					url: '/home/homeInfo?labelId=6&className=contact'
				},
			];
			this.footerMessage = [{
					title: '蜀ICP备 2021000163号',
					toUrl: 'https://beian.miit.gov.cn',
				},
				{
					title: '增值电信业务经营许可证：川B2-20200879',
					toUrl: 'https://designoss.oss-cn-beijing.aliyuncs.com/upload/2021/09/03/03eaf1aa0adb49fb81f0cc431a236140.pdf',
				},
				{
					title: '网络文化经营许可证  川网文 {2020} 4819-979号 ',
					toUrl: 'https://designoss.oss-cn-beijing.aliyuncs.com/upload/2021/09/03/dfe1ba772bdd4910bb4a8ab7b66fc0c2.pdf',
				},
			]
			this.getFollowUs();
		}

	}
</script>

<style lang="scss" scoped>
	.fade-enter-active {
		transition: all 0.8s ease-out;
	}
	
	.fade-leave-active {
		transition: all 0.8s ease-out;
	}
	
	.fade-enter {
		opacity: 0;
	}
	
	.fade-leave-to{
		opacity: 0;
	}
	.fade2-enter-active {
		transition: all 0.2s ease-out;
	}
	
	.fade2-leave-active {
		transition: all 0.2s ease-out;
	}
	
	.fade2-enter {
		opacity: 0;
		transform: scale(0);
	}
	
	.fade2-leave-to{
		opacity: 0;
		transform: scale(0);
	}
	.fade3-enter-active {
		transition: all 0.2s ease-out;
	}
	
	.fade3-leave-active {
		transition: all 0.2s ease-out;
	}
	
	.fade3-enter {
		opacity: 0;
		transform: scale(0);
	}
	
	.fade3-leave-to{
		opacity: 0;
		transform: scale(0);
	}
	.lyq{
		width: 800px;
		height: 400px;
		background-color: #262736;
		color: #fff;
		position: fixed;
		z-index: 99999;
		top: 250px;
		left: 30%;
		overflow: hidden;
		p{
			color: #818181;
			margin-top: 10px;
			font-size: 14px;
			text-align: center;
			letter-spacing: 3px;
		}
		.ly-title{
			position: relative;
			height: 60px;
			font-family: biaoti;
			transform: scaleY(1.1);
			letter-spacing: 3px;
			margin-left: 50px;
			line-height: 60px;
			font-size: 30px;
			cursor: default;
			span{
				font-size: 14px;
				font-family: Microsoft Yahei;
				transform: scaleY(0.8);
				color: #363745;
				margin-left: 5px;
				letter-spacing: 2px;
			}
		}
		.ly-conten{
			margin-top: 25px;
			margin-left: 10%;
			width: 640px;
			height: 220px;
			border: 1px solid #666;
			border-radius: 3px;
			textarea{
				width: 100%;
				height: 100%;
				border: none;
				border-radius: 3px;
				color: #fff;
				background-color: #181a29;
				font-size: 16px;
				line-height: 20px;
				text-indent: 32px;
			}
			
		}
		.ly-footer{
			display: flex;
			justify-content: space-between;
			align-items: center;
			div{
				margin-top: 15px;
				width: 80px;
				height: 30px;
				line-height: 30px;
				display: flex;
				font-size: 16px;
				font-family: SimHei;
				letter-spacing: 10px;
				text-indent: 19px;
				border-radius: 60px;
				border: none;
				background-color: #00d0f4;
				cursor: pointer;
			}
			.btn1{
				margin-right: 40px;
				color: #fff;
				background-color: #323543;
			}
			.btn2{
				margin-left: 40px;
			}
		}
	}
	.wechant-class {
		position: relative;
		bottom: 240px;
		right: 30px;
	}

	.qq-class {
		z-index: 100;
	}

	.footer-container {
		width: 100%;
		height: 380px;
		border-radius: 0px;
		display: flex;
		z-index: 2039;
		background: linear-gradient(180deg, #181A29 0%, #181A29 28%, #181A29 48%, #181A29 100%);
		// background: linear-gradient(180deg, rgba(45, 46, 44, 0) 0%, rgba(45, 46, 44, 0.84) 28%, #2D2E2C 48%, #2D2E2C 100%);
		border-radius: 0px;
		position: relative;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: center;
		//border-top: 1px solid rgba(151,151,151,0.2);

		.footer-content {
			width: 1153px;
			height: 175px;
			display: flex;
			align-items: center;
			margin-top: 40px;
			transform: translateX(-110px);

			.footer-logo {
				width: 468px;
				// width: 196px;
				height: 84px;
				opacity: 1;
				margin-right: 175px;

				// border-radius: 0px;
				img {
					width: 100%;
					height: 100%;
					// object-fit: cover;
				}
			}

			.footer-about {
				width: 640px;
				height: 175px;
				overflow: hidden;
				border-left: 2px solid #00d0f4;
				border-right: 2px solid #00d0f4;
				background-color: #00d0f4;
				-webkit-background-clip: text;
				color: transparent;

				.power-case {
					margin-top: 32px;
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: transparent;
					opacity: 1;
					display: flex;
					align-items: center;
					justify-content: center;

					.power {
						cursor: pointer;
						color: transparent;

						// &:hover {
						// 	color: #00ffff;
						// }
					}

					.line {
						height: 16px;
						border: 1px solid #00d0f4;
						// border: 1px solid #68AC19;
						opacity: 1;
						margin: 0px 8px;
					}

					.website {
						cursor: pointer;
						color: #00ffff;
						color: transparent;
						//width: 100px;

						// &:hover {
						// 	color: #00ffff;
						// }
					}
				}

				.case {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: #00ffff;
					color: transparent;
					opacity: 1;
					display: flex;
					justify-content: center;
					margin-top: 15px;
					cursor: pointer;

					// &:hover {
					// 	color: #00ffff;
					// }
				}

				.case1 {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: transparent;
					opacity: 1;
					margin-top: 10px;
					text-align: center;
					padding: 5px 35px;
					// cursor: pointer;
					// &:hover{
					//     color: #0058ca;
					// }
				}
			}

			.focusme {
				width: 96px;
				height: 110px;
				margin-left: 175px;

				.focu {
					font-size: 22px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: transparent;
					background-color: #00d0f4;
					-webkit-background-clip: text;
					// &:hover{
					//     color: #0058ca;
					// }
					opacity: 1;
				}

				.footer-icons {
					height: 38px;
					width: 96px;
					display: flex;
					justify-content: space-between;
					margin-top: 15px;

					.weixinicons {
						width: 38px;
						height: 38px;
						border: 2px solid #00b3ce;
						border-radius: 50%;
						overflow: hidden;
						&:hover {
							border: 2px solid #00ffff;
						}
						img {
							width: 100%;
							height: 100%;
							cursor: pointer;
							transition: 0.2s all linear;
							&:hover {
								border-radius: 50%;
								transform: scale(1.2);
							}
						}
					}

					.qqicons {
						width: 38px;
						height: 38px;
						border: 2px solid #00b3ce;
						border-radius: 50%;
						overflow: hidden;
						&:hover {
							border: 2px solid #00ffff;
						}
						img {
							width: 100%;
							height: 100%;
							cursor: pointer;
							transition: 0.2s all linear;
							&:hover {
								border-radius: 50%;
								// color: #0058ca;
								transform: scale(1.2);
							}
						}
					}

					// .click-class {
					// 	img {
					// 		width: 100%;
					// 		height: 100%;
					// 		cursor: pointer;
					// 		border-radius: 50%;
					// 	}
					// }
				}
			}
		}

		.collection-option {
			position: fixed;
			z-index: 9;
			//width: 50px;
			//height: 208px;
			bottom: 160px;
			display: flex;
			flex-direction: column;
			//justify-content: end;
			border-radius: 12px;
			//background-color: #00d0f4;
			right: 20px;
			
			img {
				width: 50px;
				//height: 100%;
				margin: 0;
				padding: 0;
			}
			.wechant-class2{
				// transform-origin: 160px 0;
				// height: 0;
				// margin-left: -160px;
				// img{
				// 	width: 160px;
				// 	height: 160px;
				// }
				position: absolute;
				right: 60px;
				display: flex;
    			flex-direction: column;
				img{
					width: 160px;
					height: 160px;
				}
			}
			div {
				//height: 50px;
				cursor: pointer;
				display: inherit;
				position: relative;
			}
			// div:last-child{
			// 	border-radius: 0 0 8px 8px;
			// }
			.wx {
				//border-bottom: 1px solid #001828;
			}
		}
	}
</style>
