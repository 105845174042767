<template>
  <div class="works-containers" v-loading="loading">
    <div class="work-list" v-if="!isShowshuju">
      <router-link
        class="work-container"
        v-for="(item, index) in workList"
        :key="index"
        tag="a"
        target="_blank"
        :to="`/workDetail?activeIndex=0&workDetailId=${item.id}`"
      >
        <div class="shadown1"></div>
        <div class="start" v-show="item.recommendFlag == 1">
          <img src="../../assets/home/start.png" alt="" />
          <img src="../../assets/home/start.png" alt="" />
          <img src="../../assets/home/start.png" alt="" />
          <img src="../../assets/home/start.png" alt="" />
          <img src="../../assets/home/start.png" alt="" />
        </div>

        <div class="work-cover">
          <div class="shadown"></div>
          <img class="imgContent" :src="item.cover" alt="" />
        </div>
        <div class="work-user">
          <div class="user-headImg">
            <!-- <img :src="item.headImg" alt="头像"> -->
            <el-image :src="item.headImg">
              <div slot="error" class="image-slot">
                <i class="el-icon-s-custom"></i>
              </div>
            </el-image>
            <div>{{ item.nickName || "无名" }}</div>
          </div>
        </div>
        <div class="work-content">
          <div class="work-title">
            {{ item.title }}
          </div>
          <div class="work-type">
            <img
              src="../../assets/img/unity.png"
              alt=""
              v-if="item.classfyId === 64"
            />
            <img
              src="../../assets/newImg/UE.png"
              alt=""
              v-if="item.classfyId === 65"
            />
            <img
              src="../../assets/img/AE.png"
              alt=""
              v-if="item.classfyId === 66"
            />
            <span>{{ item.className }}</span>
          </div>
          <div class="work-line"></div>
          <div class="work-live">
            <div class="work-look">
              <img src="@/assets/newImg/37.png" alt="" />
              <div>{{ item.browseNumber }}</div>
            </div>
            <!-- <div class="work-look">
              <img src="@/assets/newImg/38.png" alt="" />
              <div>{{ item.commentNum }}</div>
            </div> -->
            <div class="work-look">
              <img src="@/assets/newImg/39.png" alt="" />
              <div>{{ item.collectNum }}</div>
            </div>
            <div class="work-look">
              <img
                src="@/assets/newImg/40.png"
                alt=""
                style="width: 14px; height: 12px; margin: 0 4px"
              />
              <div>{{ item.likeNum }}</div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="kong" v-if="isShowshuju">暂无数据！</div>
    <div class="paginations" v-show="!loading">
      <el-pagination
        @size-change="handleSizeChange"
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getWorkClassInfo } from "@/api/work.js";
import Cookie from "js-cookie";
import loading from "element-ui";
export default {
  data() {
    return {
      loading: true,
      currentPage: 1,
      pageSize: 18,
      workList: [],
      isShowshuju: false,
      isShowshujus: false,
      total: 0,
      classifyId: undefined,
      classify: undefined,
      userName: undefined,
      title: undefined,
    };
  },
  props: {
    queryParams: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  components: {},
  watch: {
    // '$route.query.latest'(newVla, oldVla) {
    // 	this.getWorkClassInfos(newVla)
    // },
    "$route.query"() {
      this.getWorkClassInfos();
    },
    // '$route.query.classifyId'(newVla, oldVla) {
    // 	this.classifyId = newVla;
    // 	if(!this.classify&&!this.classifyId){
    // 		this.classify = undefined;
    // 		this.classifyId = undefined;
    // 		this.userName = undefined
    // 		this.title = undefined
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 		return
    // 	}
    // 	if (newVla == undefined) {
    // 		return
    // 	}
    // 	this.classify = undefined;
    // 	this.classifyId = newVla;
    // 	this.userName = undefined;
    // 	this.title = undefined;
    // 	this.getWorkClassInfos2(this.$route.query.latest)
    // },
    // '$route.query.classify'(newVla, oldVla) {
    // 	this.classify = newVla;
    // 	if(!this.classify&&!this.classifyId){
    // 		this.classify = undefined;
    // 		this.classifyId = undefined;
    // 		this.userName = undefined;
    // 		this.title = undefined;
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 		return
    // 	}
    // 	if (newVla == undefined) {
    // 		return
    // 	}
    // 	this.classify = newVla;
    // 	this.classifyId = undefined;
    // 	this.userName = undefined;
    // 	this.title = undefined;
    // 	this.getWorkClassInfos2(this.$route.query.latest)
    // },
    // '$route.query.type'() {
    // 	if (this.$route.query.type == 'username') {
    // 		this.userName = this.$route.query.search
    // 		this.title = undefined;
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 	}
    // 	if (this.$route.query.type == 'title') {
    // 		this.userName = undefined;
    // 		this.title = this.$route.query.search
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 	}
    // },
    // '$route.query.search'() {
    // 	this.classify = undefined;
    // 	this.classifyId = undefined;
    // 	if (this.$route.query.type == 'username') {
    // 		this.userName = this.$route.query.search
    // 		this.title = undefined;
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 	}
    // 	if (this.$route.query.type == 'title') {
    // 		this.userName = undefined;
    // 		this.title = this.$route.query.search
    // 		this.getWorkClassInfos2(this.$route.query.latest)
    // 	}
    // }
  },
  created() {
    this.$parent.optionIndex = 0;
    // if (this.$route.query.latest == 1 || this.$route.query.latest == undefined) {
    // 	// 获取最新
    // 	let id = 1
    // 	this.getWorkClassInfos(id)
    // } else if (this.$route.query.latest == 2) {
    // 	// 获取精选
    // 	let id = 2
    // 	this.getWorkClassInfos(id)
    // }
    this.getWorkClassInfos();
  },
  methods: {
    // 处理分类
    getTypeValue(classifyList) {
      let classifyObj = {};
      if (classifyList && classifyList.length) {
        classifyObj = classifyList.slice(-1)[0];
      }
      return classifyObj;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getWorkClassInfos();
      document.documentElement.scrollTop = 320;
    },
    // 处理请求
    handleQueryParams() {},
    async getWorkClassInfos() {
      this.workList = [];
      this.loading = true;
      this.isShowshuju = false;
      if (Cookie.get("userInfo")) {
        this.$store.commit("setUserInfo", JSON.parse(Cookie.get("userInfo")));
      }
      let data = {
        workType: 1,
        type: parseInt(this.$route.query.latest),
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        userName:
          this.$route.query.type == "username"
            ? this.$route.query.search
            : undefined,
        title:
          this.$route.query.type == "title"
            ? this.$route.query.search
            : undefined,
        classifyId: this.$route.query.classifyId,
        classify: this.$route.query.classify,
      };
      let queryData = {
        workType: 1,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        ...this.queryParams,
      };
      let { code, rows, total } = await getWorkClassInfo(queryData);
      if (code == 200) {
        this.workList = rows;
        this.loading = false;
        this.total = total;
        if (rows.length == 0) {
          this.isShowshuju = true;
        }
      }
    },
    async getWorkClassInfos2(a) {
      this.workList = [];
      this.loading = true;
      this.isShowshuju = false;
      if (Cookie.get("userInfo")) {
        this.$store.commit("setUserInfo", JSON.parse(Cookie.get("userInfo")));
      }
      let data = {
        workType: 1,
        type: a,
        userName: this.userName,
        title: this.title,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        classifyId: this.classifyId,
        classify: this.classify,
      };
      let { code, rows, total } = await getWorkClassInfo(data);
      if (code == 200) {
        this.workList = rows;
        this.loading = false;
        this.total = total;
        if (rows.length == 0) {
          this.isShowshuju = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-loading-mask {
  width: 1680px;
  //height: 900px;
  margin: 0 auto;
  transform: translateX(-12px);
  background-color: rgba(33, 33, 50, 0.6);
}
.works-containers {
  background: #181a29;

  .work-list {
    width: 1680px;
    min-height: 800px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .work-container {
      width: 250px;
      height: 350px;
      // background: #0b1525;
      background: #0b1525;
      border-radius: 0px;
      overflow: hidden;
      margin-top: 30px;
      cursor: pointer;
      position: relative;
      .shadown1 {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
      }
      &:hover {
        .shadown1 {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      .start {
        position: absolute;
        right: 4px;
        top: 225px;
        z-index: 1;
        img {
          width: 16px;
          height: 21px;
          margin-left: 0px;
        }
      }
      .work-user {
        width: 250px;
        height: 35px;
        display: flex;
        align-items: center;

        .user-headImg {
          height: 22px;
          margin-left: 12px;
          display: flex;
          align-items: center;

          /deep/.el-image {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            object-fit: cover;
          }

          /deep/.el-icon-s-custom {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
						font-size: 18px;
          }

          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            object-fit: cover;
          }

          div {
            width: 120px;
            height: 30px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            line-height: 30px;
            color: #ffffff;
            opacity: 1;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      margin-left: 25px;

      &:last-child {
        margin-bottom: 15px;
      }

      .work-cover {
        width: 250px;
        height: 220px;
        margin: 0px auto;
        position: relative;
        overflow: hidden;
        .shadown {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(
            to top,
            rgba(0, 14, 14, 1) 0%,
            rgba(0, 14, 41, 0.3) 10%,
            rgba(0, 0, 0, 0)
          );
        }

        .imgContent {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .work-content {
        // background: #2D2E2C;
        opacity: 1;
        border-radius: 0px;

        .work-title {
          width: 90%;
          height: 35px;
          font-size: 16px;
          font-family: SimHei;
          font-weight: 500;
          line-height: 35px;
          color: rgb(0, 225, 225);
          letter-spacing: 2px;
          opacity: 1;
          margin-top: -5px;
          padding-left: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .work-type {
          width: 100%;
          height: 28px;
          padding-left: 12px;
          display: flex;
          align-items: center;
          img {
            width: 30px;
            margin-left: -5px;
          }
          span {
            font-size: 12px;
            margin-top: 1px;
            color: #8cadb0;
          }
        }
        .work-line {
          width: 100%;
          height: 2px;
          margin-top: 2px;
          margin-bottom: 2px;
          background: linear-gradient(
            to right,
            rgba(0, 14, 41, 1) 3%,
            rgba(75, 79, 112, 1) 35%,
            rgba(75, 79, 112, 1) 65%,
            rgba(0, 14, 41, 1) 97%
          );
        }
        .work-live {
          width: 100%;
          height: 25px;
          // height: 15px;
          display: flex;
          align-items: center;
          .work-look {
            display: flex;
            align-items: center;
            img {
              width: 26px;
              height: 22px;
            }

            div {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              line-height: 0px;
              color: #848e90d5;
              opacity: 1;
              margin-right: 6px;
              display: block;
            }

            &:first-child {
              margin-left: 35px;
              // margin-left: 5px;
            }

            &:nth-of-type(n + 2) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .kong {
    width: 100%;
    height: 300px;
    text-align: center;
    color: white;
    line-height: 300px;
  }

  .paginations {
    margin-top: 80px;
    margin-bottom: 100px;
    .el-pagination {
      text-align: center;
    }
    ::v-deep .el-pagination .btn-prev {
      // background: #353849;
      width: 65px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0px;
    }
    ::v-deep .el-pager li {
      background: #181a29;
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      background-color: #313345;
      margin-left: 10px;
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      text-align: center;
    }
    ::v-deep .el-pagination .btn-next {
      width: 65px;
      margin-left: 10px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0;
      // background: #353849;
    }
    ::v-deep .el-pager li.active {
      color: white !important;
      // background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
      background: #00b4d4;
    }
    ::v-deep .el-pagination__jump {
      display: inline-block;
      font-size: 16px;
      min-width: 35.5px;
      height: 35px;
      padding: 0 10px;
      line-height: 35px;
      vertical-align: top;
      box-sizing: border-box;
      border: 1px solid #414141;
      color: #ffffff;
      background-color: #313345;
    }
    ::v-deep .el-input {
      width: 70px;
      height: 33px;
    }
    ::v-deep .el-pagination__editor.el-input .el-input__inner {
      height: 24px;
      top: -2px;
      background: #001220 !important;
      color: white;
      border: none;
    }
    ::v-deep .el-pagination__editor {
      margin: 0 10px;
    }
  }
}
</style>
