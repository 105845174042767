<template>
    <!-- 合作伙伴 -->
    <div class="partners-container">
        <div class="partners-body-img">
            <div class="partner-name" v-for="(item, index) in row" :key="index" @click="handleClickPartner(index)">
                <div :style="item.selectFlag == true ? 'background: #2376D2;border-radius: 60px;' : 'background: rgba(255,255,255,1);border-radius: 60px;'">{{item.partnerName}}</div>
            </div>
        </div>
        <div class="partners-footer-img" v-if="proList.length > 0">
            <div class="partners-footer-left">
                <img v-if="coverImg.indexOf('.mp4') == -1" :src="coverImg" alt="">
                <video v-else id="my-player" class="video-player" controlslist="nodownload" :autoplay="false" controls="controls" :src="coverImg">
                </video>
            </div>
            <div class="partners-footer-right">
                <div class="partners">
                    {{partnerName}}
                </div>
                <div class="partners-content">
                    {{partnerIntro}}
                </div>
            </div>
            <div class="project"> 合作项目 </div>
        </div>
        <div class="mySwiper">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide  v-for="(item,index) in proList" :key="index" :style="index == navIndex ? 'border: 3px solid #2376D2;border-radius: 10px;':'border: 3px solid rgba(0,0,0,0);border-radius: 10px;'" @click.native="getSubLists(item, index)">
                    <div class="mySwiper-img">
                        <img v-if="item.cover.indexOf('.mp4') == -1" :src="item.cover" alt="">
                        <img v-else src="@/assets/img/content_icon_shipin_nor.png">
                        <div class="titlte">
                            <div>{{item.proType}}</div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" v-if="proList.length > 3" slot="button-prev" title="往前" @click="handleClickPrev"></div>
            <div class="swiper-button-next" v-if="proList.length > 3" slot="button-next" title="往后" @click="handleClickNext"></div>
        </div>
        <div class="click-btn" v-if="proList.length <= 3">
            <div class="prev-btn" @click="handleClickPrev" :style="navIndex == 0 ? 'cursor:inherit':''">
                <img class="prev" src="@/assets/newImg/prev.png">
            </div>
            <div class="next-btn" @click="handleClickNext" :style="navIndex == this.row.length-1 ? 'cursor:inherit;':''">
                <img class="next" src="@/assets/newImg/next.png">
            </div>
        </div>
    </div>
</template>

<script>
    import {getPartner} from '@/api/company.js';
    // 1. 存储swiper元素,避免重复获取
    // const c_swiper = document.getElementsByClassName("swiper")
    export default {
        data(){
            return{
                coverImg:'',
                partnerIntro:'',
                partnerName:'',
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    slidesPerView:3,
                    spaceBetween: 20    ,
                    slidesPerGroup: 3,
                    // loop: false, // 循环播放
                    // loopedSlides: 3,
                    loopFillGroupWithBlank: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    observe: true
                },
                row: '',
                proList: [],
                navIndex: 0,
            }
        },
        methods:{
            async getPartners(){
                let {code,rows} = await getPartner();
                if(code==200){
                    this.row = rows;
                    this.row.forEach((item,index) =>{
                        item.selectFlag = (index == 0);
                    })
                    this.coverImg = rows[0].proList[0].cover;
                    this.partnerIntro = rows[0].proList[0].proIntro;
                    this.partnerName = rows[0].proList[0].proName;
                    this.proList = rows[0].proList;
                    // this.proList1 = rows[0].proList.slice(0,2);
                }
            },
            // 切换合作项目
            getSubLists(item, index){
                console.log("index", index)
                this.coverImg = item.cover;
                var player= document.getElementsByClassName('video-player');
                player.src =  item.cover;
                this.partnerIntro = item.proIntro;
                this.partnerName = item.proName;
                this.navIndex = index;
            },
            
            // 切换合作伙伴
            handleClickPartner(index){
                this.row.forEach((item, indexs) => {
                    item.selectFlag = (index == indexs ? true : false);
                });
                this.proList = this.row[index].proList;
                if(this.proList.length == 0){
                    this.partnerName = this.row[index].partnerName;
                    this.partnerIntro = this.row[index].partnerIntro;
                    return ;
                }
                this.coverImg = this.row[index].proList[0].cover;
                this.partnerIntro = this.row[index].proList[0].proIntro;
                this.partnerName = this.row[index].proList[0].proName;
                this.navIndex = 0;
            },
            handleClickPrev(){
                if(this.navIndex >= 1){
                    if((this.navIndex - 3) >= 0){
                        this.navIndex -= 3;
                        this.coverImg = this.proList[this.navIndex].cover;
                        this.partnerIntro = this.proList[this.navIndex].proIntro;
                        this.partnerName = this.proList[this.navIndex].proName;
                        return ;
                    }
                    if((this.navIndex - 2) >= 0){
                        this.navIndex -= 2;
                        this.coverImg = this.proList[this.navIndex].cover;
                        this.partnerIntro = this.proList[this.navIndex].proIntro;
                        this.partnerName = this.proList[this.navIndex].proName;
                        return ;
                    }
                    this.navIndex -= 1;
                    this.coverImg = this.proList[this.navIndex].cover;
                    this.partnerIntro = this.proList[this.navIndex].proIntro;
                    this.partnerName = this.proList[this.navIndex].proName;
                }
                // else{
                //     this.swiperOptions.initialSlide = 0;
                //     console.log("this.swiperOptions.initialSlide", this.swiperOptions.initialSlide)
                // }
            },
            handleClickNext(){
                if(this.navIndex < this.proList.length-1){
                    if((this.navIndex+3) <= this.proList.length-1){
                        this.navIndex += 3;
                        this.coverImg = this.proList[this.navIndex].cover;
                        this.partnerIntro = this.proList[this.navIndex].proIntro;
                        this.partnerName = this.proList[this.navIndex].proName;
                        return ;
                    }
                    if((this.navIndex+2) < this.proList.length-1){
                        this.navIndex += 2;
                        this.coverImg = this.proList[this.navIndex].cover;
                        this.partnerIntro = this.proList[this.navIndex].proIntro;
                        this.partnerName = this.proList[this.navIndex].proName;
                        return ;
                    }
                    this.navIndex += 1;
                    this.coverImg = this.proList[this.navIndex].cover;
                    this.partnerIntro = this.proList[this.navIndex].proIntro;
                    this.partnerName = this.proList[this.navIndex].proName;
                }
                // else{
                //     this.swiperOptions.initialSlide = this.navIndex - 2;
                //     console.log("this.swiperOptions.initialSlide", this.swiperOptions.initialSlide)
                // }
            },
        },
        created(){
            this.getPartners()
        }
    }
</script>

<style lang="scss" scoped>
.partners-container{

    width: 1400px;
    margin: 80px auto 150px;
    //margin-bottom: 150px;
    display: flex;
    justify-content: flex-start;

    .partners-body-img{
        width: 150px;

        .partner-select{
            width: 150px;
            background: #2376D2;
            color: #000000;
            text-align: center;
            border-radius: 60px;
            height: 40px;
            line-height: 40px;
        }

        .partner-name{
            width: 150px;
            cursor: pointer;
            div{
                font-size: 22px;
                background: #FFFFFF;
                margin: 15px auto;
                text-align: center;
                border-radius: 60px;
                height: 40px;
                line-height: 40px;
                &:hover{
                    background: #2376D2;
                    color: #000000;
                }
            }
        }
    }

    .partners-footer-img{
        width: 1250px;
        height:350px;
        position: relative;
        margin-top:80px;
        margin-bottom: 20px;
        margin-left: 10px;
        .partners-bg{
            width: 100%;
            height: 400px;
            filter: blur(10px);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
        .partners-footer-left{
            width: 550px;
            height: 300px;
            object-fit: cover;
            background: #FFFFFF;
            box-shadow: -3px 0px 50px rgba(20, 20, 20, 0.5);
            opacity: 1;
            border-radius: 0px 10px 10px 0px;
            position: absolute;
            left:0;
            top:-80px;
            overflow: hidden;
            img{
                width:100%;
                height:100%;
                object-fit: cover;
            }
             .video-player{
                width: 100%;
                height: 100%;
                background: #000000;
            }
        }
        .partners-footer-right{
            height: 302px;
            width: 600px;
            position: absolute;
            left: 620px;
            top: -55px;
            .partners{
                height: 42px;
                font-size: 25px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 42px;
                color: #FFFFFF;
                text-shadow: 0px 2px 2px rgba(50, 50, 50, 0.25);
                opacity: 1;
            }
            .partners-content{
                height: 242px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 36px;
                color: rgba(255,255,255,0.6);
                margin-top: 10px;

                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow:ellipsis;

                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // word-break: break-all;
                // -webkit-line-clamp: 5;
                // overflow: hidden;
            }
        }
        .project{
            width: 200px;
            height: 70px;
            background: #2376D2;
            // background: rgba(158, 234, 106, 0.35);
            border-radius: 0px 10px 10px 0px;
            position: absolute;
            bottom: 35px;
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 70px;
            color: #FFFFFF;
            text-shadow: 0px 2px 2px rgba(50, 50, 50, 0.41);
            opacity: 1;
            text-align: center;
        }
    }
    /deep/ .swiper-slide{
        width: 320px !important;
        height: 200px !important;
        // margin-left: 10px !important;
    }
    /deep/ .swiper-slide:first-child{
        margin-left: 70px !important;
    }
    /deep/ .swiper-slide:last-child{
        margin-right: 50px !important;
    }
    .mySwiper{
        // margin: 0 auto;
        width: 1200px !important;
        margin-top: 420px;
        margin-right: 700px;
        position: relative;
    }
    /deep/.swiper-button-prev {
        left: 800px;
        right: 245px;
        top: 304px;
        width: 67px;
        height: 66px;
        background: #2D2E2C;
        opacity: 1;
        border-radius: 6px;
        cursor: pointer;
    }
    /deep/.swiper-button-prev:hover {
        background: #e2e2e2;
    }
    /deep/.swiper-button-next {
        left: 880px;
        right: 0px;
        right: 245px;
        top: 304px;
        width: 67px;
        height: 66px;
        background: #2D2E2C;
        opacity: 1;
        border-radius: 6px;
        cursor: pointer;
    }
    /deep/.swiper-button-next:hover {
        background: #e2e2e2;
        // background: #2376D2;
    }
    .mySwiper-img{
        width:100%;
        height:100%;
        object-fit: cover;
        position: relative;
        cursor: pointer;
        img{
            width:100%;
            height:100%;
            object-fit: cover;
            border-radius: 10px;
        }
        .titlte{
            width: 100%;
            height: 50px;
            background: #252525;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 0.7;
            border-radius: 0px 0px 10px 10px;
            position: absolute;
            bottom:0;
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                width: 100%;
                height: 31px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 31px;
                color: #FFFFFF;
                text-shadow: 0px 2px 2px rgba(50, 50, 50, 0.41);
                opacity: 1;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow:ellipsis;
            }
        }
    }

    .click-btn{
        width: 100%;
        color: #2376D2;
        display:flex;
        position: absolute;
        left: 73%;
        margin-top: 700px;
        margin-left: -178px;
        // margin-left: -96px;
        margin-bottom: 150px;
        .prev-btn{
            width: 67px;
            height: 66px;
            background: #2D2E2C;
            // background: #0c0c0c;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
            cursor: pointer;
            img{
                width: 44px;
                height: 45px;
            }
        }

        .next-btn{
            margin-left: 12px;
            margin-top: 2px;
            width: 67px;
            height: 66px;
            background: #2D2E2C;
            // background: #0c0c0c;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
                // width: 15px;
                // height: 19px;
                width: 44px;
                height: 45px;
            }
        }
        .prev, .next{
            display: inline-block;
        }
        .prev-high, .next-high{
            display: none;
        }
        // .prev-btn:hover .prev,
        // .next-btn:hover .next{
        //     display: none;
        // }
        // .prev-btn:hover .prev-high,
        // .next-btn:hover .next-high{
        //     display: inline-block;
        // }
        .prev-btn:hover{
            background: #e2e2e2;
            // background: #2376D2;
        }
        .next-btn:hover{
            background: #e2e2e2;
        }
    }
}
</style>