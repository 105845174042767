<template>
  <div class="header-container">
    <div class="users-content-header">
      <div class="users-content-img">
        <img
          :src="userInfo.headImg"
          title="点击可查看用户个人主页"
          @click="getUserPersonal(userInfo.id)"
        />
        <p class="users-content-level">Lv. {{levelNum}}</p>
      </div>
      <div class="users-content-user" v-if="true">
        <div class="users-content-username">{{userInfo.nickName}}</div>
        <div class="users-content-lv">
          <Lv :userId="userInfo.id" @getLevel="getLevel"></Lv>
        </div>
        <Medal :userId="userInfo.id"></Medal>
      </div>
    </div>
    <div class="users-content-setting">
      <div class="users-relation" @click="goAddFocus">
        <div class="AddFocus" v-show="!focusFlag">
          <span class="el-icon-plus"></span>关注
        </div>
        <div class="removeFocus" v-show="focusFlag">已关注</div>
      </div>
      <div class="users-relation" @click="getCollection">
        <div class="AddCollection" v-show="!collectFlag">
          <span class="el-icon-check"></span>收藏
        </div>
        <div class="removeCollection" v-show="collectFlag">已收藏</div>
      </div>
    </div>
    <!-- <div class="users-content-focus">粉丝数：{{focusNum}}</div> -->
  </div>
</template>

<script>
import {addFocus, cancelFocus, insertCollection, deleteCollection} from "@/api/work.js"
import Lv from "@/component/lv/newIndex.vue";
import Medal from "@/component/medal/index.vue";
export default {
  data() {
    return {
      levelNum: 1,
      focusFlag: false,
      collectFlag: false,
      focusNum: 0
    };
  },
  props: {
    userInfo: {
      type: Object,
      require: true
    }
  },
  computed: {
    // focusFlag() {
    //   return this.userInfo.fouseFlag;
    // }
  },
  watch: {
    'userInfo.fouseFlag': {
      handler(v) {
        this.focusFlag = v;
      },
      immediate: true
    },
    'userInfo.collectFlag': {
      handler(v) {
        this.collectFlag = v;
      },
      immediate: true
    },
    'userInfo.focus': {
      handler(v) {
        this.focusNum = v;
      },
      immediate: true
    }
  },
  components: {
    Lv,
    Medal,
  },
  created() {
    //this.focusFlag = this.userInfo.fouseFlag;
  },
  methods: {
    getUserPersonal(id) {
      this.$router.push(`/personalhome?isShowMsg=2&usersId=${id}`);
    },
    getLevel(e) {
      this.levelNum = e;
    },
    // 关注和取消关注
    async goAddFocus() {
      if (!this.focusFlag) {
        let data = {
          toUserId: this.userInfo.id,
          userId: this.$store.state.userInfo.id,
          headImg: this.$store.state.userInfo.headImg,
        };
        let { code } = await addFocus(data);
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "关注成功",
          });
          this.focusFlag = true;
          this.focusNum += 1;
        }
      } else {
        let data = {
          toUserId: this.userInfo.id,
          userId: this.$store.state.userInfo.id,
        };
        let { code } = await cancelFocus(data);
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "取消关注成功",
          });
          this.focusFlag = false;
          this.focusNum -= 1;
        }
      }
    },
    // 收藏和取消收藏
    async getCollection() {
      if (!this.collectFlag) {
        let data = {
          workId: Number(this.$route.query.id),
          userId: this.$store.state.userInfo.id,
          headImg: this.$store.state.userInfo.headImg,
          toUserid: this.userInfo.id,
          flag: 2
        };
        let { code, msg } = await insertCollection(data);
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
          this.collectFlag = true;
        }
      } else {
        let id =  Number(this.$route.query.id);
        let toId = this.$store.state.userInfo.id;
        let { code, msg } = await deleteCollection(id, toId);
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: msg,
          });
          this.collectFlag = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  .users-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    .users-content-img {
      cursor: pointer;
      width: 40%;
      height: 40%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 5px;

      img {
        width: 60%;
        height: 100%;
        border-radius: 50%;
      }

      .users-content-level {
        padding: 3px 10px;
        height: 14px;
        background-color: #234767;
        border-radius: 16px;
        font-size: 12px;
        color: #24c3d9;
        border: 1px solid #24c3d9;
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-30%, -70%);
      }
    }

    .users-content-user {
      width: 65%;
      //height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #ffffff;
      //margin-left: 15px;
      //justify-content: space-between;

      .users-content-username {
        width: 100%;
        font-size: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 5px;
        font-family: biaoti;
        letter-spacing: 4px;
      }

      .users-content-lv {
        width: 100%;
        margin-bottom: 10px;
      }

      .users-content-tag {
        width: 100%;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .medal {
        width: 100%;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .users-content-setting {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    img {
      width: 80px;
      cursor: pointer;
    }

    .users-relation {
      margin: 0 5px;

      div {
        width: 80px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 4px;
        font-weight: 500;
        cursor: pointer;
        border-radius: 18px;
        color: #1cebf4;
        // background-color: #234767;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);

        span {
          font-size: 18px;
        }
      }

      .removeFocus {
        color: #000000;
        font-weight: 600;
        font-family: SimHei;
        background-color: #54c9dc;
      }

      .removeCollection {
        color: #000000;
        font-weight: 600;
        font-family: SimHei;
        background-color: #54c9dc;
      }
    }
  }
  .users-content-focus{
    text-align: center;
    color: #1cebf4;
    margin-top: 10px;
  }
}
</style>