<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
  >
    <slot name="content"></slot>
    <div slot="footer">
      <slot name="footer-content"></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('changeShow', false)
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header{
  border-bottom: 1px solid #eeeeee;
}
/deep/.el-dialog__body{
  padding: 15px 20px;
}
</style>