<template>
  <div class="assignment-container">
    <!-- banner图 -->
    <div class="assignment-banner">
      <img
        src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/banner.png"
      />
      <div class="assignment-banner-box">
        <p class="assignment-banner-text">
          致力于打造最专业的<br />游戏美术平台
        </p>
        <p class="assignment-banner-line"></p>
        <p class="assignment-banner-subtitle">众多美术大神为你的项目提供助力</p>
      </div>
    </div>
    <div class="assignment-main">
      <!-- 数据栏 -->
      <ul class="content-width assignment-data-list">
        <li class="assignment-data-item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/user.png"
          />
          <div class="item-content">
            <p class="item-title">入驻用户</p>
            <p class="item-value">
              <count-to :startVal="0" :endVal="256" :duration="1500"></count-to>
              人
            </p>
          </div>
        </li>
        <li class="assignment-data-item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/encourage.png"
          />
          <div class="item-content">
            <p class="item-title">完成制作</p>
            <p class="item-value">
              <count-to
                :startVal="0"
                :endVal="87264"
                :duration="1500"
              ></count-to>
              件
            </p>
          </div>
        </li>
        <li class="assignment-data-item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst%2Fassignment%2Fbusiness.png"
          />
          <div class="item-content">
            <p class="item-title">累计交易</p>
            <p class="item-value">
              <count-to :startVal="0" :endVal="148" :duration="1500"></count-to>
              万元
            </p>
          </div>
        </li>
        <li class="assignment-data-item">
          <img
            src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/works.png"
          />
          <div class="item-content">
            <p class="item-title">作品展示</p>
            <p class="item-value">
              <count-to
                :startVal="0"
                :endVal="82513"
                :duration="1500"
              ></count-to>
              件
            </p>
          </div>
        </li>
      </ul>
      <!-- 任务筛选栏 -->
      <div class="content-width assignment-filter-box">
        <div class="assignment-filter-type">
          <p class="type-name">任务类型：</p>
          <ul>
            <li
              :class="typeof queryParams.cid === 'undefined' && 'active'"
              @click="eventChangeParams('cid')"
            >
              全部
            </li>
            <li
              :class="queryParams.cid === item.id && 'active'"
              v-for="item in projectTypeList"
              :key="item.id"
              @click="eventChangeParams('cid', item.id)"
            >
              {{ item.classifyName }}
            </li>
            <!-- <li>动作</li>
            <li>原画</li>
            <li>模型</li>
            <li>场景</li>
            <li>UI</li>
            <li>其它</li> -->
          </ul>
        </div>
        <div class="assignment-filter-type">
          <p class="type-name">测试类型：</p>
          <ul>
            <li
              :class="typeof queryParams.testType === 'undefined' && 'active'"
              @click="eventChangeParams('testType')"
            >
              全部
            </li>
            <li
              :class="queryParams.testType === item.value && 'active'"
              v-for="item in testTypeList"
              :key="item.value"
              @click="eventChangeParams('testType', item.value)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="assignment-filter-type">
          <p class="type-name">任务状态：</p>
          <ul>
            <li
              :class="typeof queryParams.status === 'undefined' && 'active'"
              @click="eventChangeParams('status')"
            >
              全部
            </li>
            <li
              :class="queryParams.status === item.value && 'active'"
              v-for="item in statusList"
              :key="item.value"
              @click="eventChangeParams('status', item.value)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 任务列表 -->
      <div class="content-width assignment-works-list">
        <div class="list-loading-status" v-if="loadingData">
          <list-loading></list-loading>
          <p>数据获取中...</p>
        </div>
        <template v-else>
          <template v-if="dataList.length">
            <el-row :gutter="20" style="display: flex; flex-wrap: wrap">
              <el-col :span="6" v-for="(item, index) in dataList" :key="index">
                <item :content="item" @changeShow="eventChangeDialog"></item>
              </el-col>
            </el-row>
            <div class="assignment-data-btn" v-if="isShowMoreBtn">
              <el-button
                type="primary"
                :disabled="isLoadMore"
                :loading="isLoadMore"
                @click="eventShowMore"
                >展示更多</el-button
              >
            </div>
          </template>
          <el-empty description="任务正在赶来的路上~" v-else></el-empty>
        </template>
      </div>
    </div>
    <!-- 弹幕 -->
    <barrage
      :textList="userList"
      @emitClear="emitShowUser"
      v-if="isShowBarrage"
      prefix="欢迎新用户["
      suffix="]加入"
    ></barrage>

    <!-- 输入密码弹窗 -->
    <dialogLock
      :dialogVisible="isShowLock"
      title="提示"
      width="400px"
      @changeShow="eventChangeDialog"
    >
      <div slot="content">
        <el-form :model="lockForm" :rules="lockRules" v-if="isShowLock">
          <el-form-item label="请输入密码" prop="password" required>
            <br />
            <el-input
              v-model="lockForm.password"
              type="password"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-button
        class="lock-footer-btn"
        @click="getDetail"
        type="primary"
        slot="footer-content"
        >提交</el-button
      >
    </dialogLock>
  </div>
</template>

<script>
import {
  getEpibolyList,
  getNewUser,
  getEpibolyDetail,
  getEpibolySpecificsDetail,
} from "@/api/ectocyst.js";
import { getClassifyList, getChildList } from "@/api/work.js";
import countTo from "vue-count-to";
import ectocystHeader from "../components/header.vue";
import listLoading from "../components/loading.vue";
import item from "../components/item.vue";
import ectocysFooter from "../components/footer.vue";
import barrage from "../components/barrage.vue";
import dialogLock from "../components/dialog.vue";
export default {
  data() {
    return {
      userNum: 3345,
      // 项目类型
      projectTypeList: [],
      // 测试类型
      testTypeList: [
        {
          value: 2,
          label: "付费测试",
        },
        {
          value: 1,
          label: "免费测试",
        },
        {
          value: 0,
          label: "无需测试",
        },
        {
          value: 3,
          label: "一对一试做",
        },
      ],
      // 任务状态
      statusList: [
        {
          value: 0,
          label: "报名中",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已结束",
        },
      ],
      // 数据列表
      dataList: [],
      // 参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        cid: undefined,
        status: undefined,
        testType: undefined,
      },
      // 总数
      total: 0,
      isLoadMore: false,
      loadingData: false,
      // 新用户数组
      userList: [],
      // 是否显示新用户欢迎
      isShowBarrage: false,
      lockForm: {
        id: undefined,
        password: undefined,
      },
      lockRules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isShowLock: false,
    };
  },
  computed: {
    // 是否显示 展示更多按钮
    isShowMoreBtn() {
      return (
        Math.ceil(this.total / this.queryParams.pageSize) >
        this.queryParams.pageNum
      );
    },
  },
  components: {
    countTo,
    listLoading,
    ectocystHeader,
    item,
    ectocysFooter,
    barrage,
    dialogLock,
  },
  created() {
    this.getInit();
    this.getNewUserList();
  },
  methods: {
    // 进界面时调用
    async getInit() {
      this.loadingData = true;
      this.dataList = await this.getList();
      this.getTypeList();
      this.loadingData = false;
    },
    // 获取类型列表
    async getTypeList() {
      let state = {
        queryType: 6,
      };
      let { code, data } = await getClassifyList(state);
      if (code === 200) {
        this.projectTypeList = data || [];
      }
    },
    // 获取列表数据
    getList() {
      return new Promise((reslove, reject) => {
        getEpibolyList({ ...this.queryParams })
          .then((res) => {
            let { code, rows, total } = { ...res };
            if (code === 200) {
              this.total = total;
              reslove(rows);
            } else {
              reject();
            }
          })
          .catch((e) => {
            reject();
          });
      });
    },
    // 点击展示更多
    async eventShowMore() {
      this.queryParams.pageNum += 1;
      this.isLoadMore = true;
      let data = await this.getList();
      this.dataList = this.dataList.concat(data);
      this.isLoadMore = false;
    },
    // 新用户加入弹幕
    async getNewUserList() {
      let { code, data } = await getNewUser();
      if (code === 200) {
        data ? (this.userList = data) : [];
        this.userList.length && this.emitShowUser(true);
      }
    },
    // 是否显示弹幕
    emitShowUser(bool) {
      this.isShowBarrage = bool;
    },
    // 修改参数
    async eventChangeParams(attributeName, id) {
      this.queryParams[attributeName] = id;
      this.loadingData = true;
      this.dataList = await this.getList();
      this.loadingData = false;
    },
    // 改变其显示状态
    eventChangeDialog(bool, id) {
      if (
        JSON.parse(this.$cookie.get("userInfo")).permission === "T-business" ||
        JSON.parse(this.$cookie.get("userInfo")).permission === "T-All"
      ) {
        this.getDetailByManage(id);
        return;
      }
      this.lockForm.id = id;
      this.lockForm.password = undefined;
      this.isShowLock = bool;
    },
    // 接包方调用详情接口
    async getDetail() {
      let { code, data } = await getEpibolyDetail({
        eid: this.lockForm.id,
        password: this.lockForm.password,
      });
      if (code === 200) {
        this.$router.push(
          "/ectocyst?page=assignmentDetail&content=" +
            encodeURI(JSON.stringify(data))
        );
      }
    },
    // 商务或者管理员查看，不需要密码的哟
    async getDetailByManage(eid) {
      let { code, data } = await getEpibolySpecificsDetail({
        eid: eid,
      });
      if (code === 200) {
        let routeUrl = this.$router.resolve({
          path:
            "/ectocyst?page=assignmentDetail&content=" +
            encodeURI(JSON.stringify(data)),
        });
        window.open(routeUrl.href, "_blank");
        // this.$router.push(
        //   "/ectocyst?page=assignmentDetail&content=" +
        //     encodeURI(JSON.stringify(data))
        // );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assignment-container {
  width: 100%;
  // 内容区域宽度
  .content-width {
    width: 1260px;
  }
  .assignment-banner {
    width: 100%;
    min-width: 1260px;
    //height: 558px;
    position: relative;
    //margin-top: 60px;
    color: #ffffff;
    > img {
      width: 100%;
    }
    .assignment-banner-box {
      //width: 900px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      .assignment-banner-text {
        //width: 900px;
        //height: 240px;
        font-size: 80px;
        font-size: clamp(40px, 4vw, 80px);
        letter-spacing: 5px;
        position: relative;
        display: inline-block;
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes fadeInAnimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: -10px;
          left: -28px;
          width: 36px;
          height: 36px;
          border-left: 5px solid #ffffff;
          border-top: 5px solid #ffffff;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -8px;
          right: -30px;
          width: 36px;
          height: 36px;
          border-right: 5px solid #ffffff;
          border-bottom: 5px solid #ffffff;
        }
      }
      .assignment-banner-line {
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        margin-top: 50px;
        position: relative;
        animation: bounceInLeft ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes bounceInLeft {
          0% {
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
          }
          100% {
            opacity: 1;
            transform: none;
          }
        }
        &::before {
          content: "";
          width: 60px;
          height: 8px;
          background-color: #ffffff;
          position: absolute;
          top: -8px;
          left: 0;
        }
      }
      .assignment-banner-subtitle {
        font-size: 20px;
        letter-spacing: 2px;
        margin-top: 10px;
        animation: bounceInRight ease 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        @keyframes bounceInRight {
          0% {
            opacity: 0;
            transform: translate3d(100%, 0, 0);
          }
          100% {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
  .assignment-main {
    width: 100%;
    //background-color: #ebf5ff;
    padding-bottom: 100px;
    // background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/content_bg.png");
    // background-repeat: no-repeat;
    // background-size: 1585px 1658px;
    // background-position: bottom right;

    .assignment-data-list {
      box-sizing: border-box;
      margin: -58px auto 0;
      background-color: #ffffff;
      position: relative;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      color: #737f97;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      .assignment-data-item {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          border-right: 3px solid #dfe4e9;
        }
        .item-content {
          margin-left: 10px;
          .item-title {
            margin-bottom: 5px;
          }
          .item-value {
            > span {
              color: #ebb92e;
              font-size: 46px;
              display: inline-block;
              //margin-right: 5px;
            }
          }
        }
      }
    }
    .assignment-filter-box {
      margin: 20px auto;
      box-sizing: border-box;
      background-color: #ffffff;
      padding: 5px 10px;
      border-radius: 2px;
      font-size: 14px;
      .assignment-filter-type {
        display: flex;
        align-items: center;
        padding: 5px 0;
        .type-name {
          font-weight: bold;
        }
        > ul {
          display: flex;
          align-items: flex-end;
          > li {
            padding: 5px 10px;
            cursor: pointer;
            &.active {
              background-color: #8eb7ef;
              color: #ffffff;
            }
          }
        }
      }
    }

    .assignment-works-list {
      margin: 0px auto;
      //padding: 20px 0;
      min-height: 500px;
      position: relative;
      .list-loading-status {
        width: 200px;
        position: absolute;
        left: calc(50% - 100px);
        top: 30%;
        text-align: center;
        font-size: 20px;
        color: #8b2970;
        p {
          opacity: 0.6;
        }
      }
      .assignment-data-btn {
        margin: 20px auto 0;
        text-align: center;
        p {
          padding: 0px 15px;
          background-color: #5078be;
          border-radius: 6px;
          display: inline-block;
          color: #ffffff;
          font-size: 24px;
          line-height: 50px;
          cursor: pointer;
        }
      }
    }
  }

  .lock-footer-btn {
    background-color: var(--default-ectocyst-color);
    border-color: var(--default-ectocyst-color);
    color: #ffffff;
    padding-left: 50px;
    padding-right: 50px;
    letter-spacing: 5px;
  }

  /deep/.el-empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/.el-empty__description {
    margin-top: 0;
    > p {
      font-size: 24px;
    }
  }
}
</style>