<template>
  <div class="detail-container">
    <div class="detail-header">
      <el-button type="text" icon="el-icon-arrow-left" @click="eventBack"
        >返回列表页</el-button
      >
    </div>
    <div class="detail-content">
      <el-form :model="content" ref="detailForm" :rules="rules">
        <div class="attestation-basic-info">
          <p class="info-title">任务信息</p>
          <el-form-item label="任务封面" prop="image" required>
            <br />
            <el-upload
              :action="uploadUrl"
              :show-file-list="false"
              list-type="picture-card"
              :limit="1"
              :on-success="
                (response) => {
                  return handleUploadSuccess(response, 'image');
                }
              "
            >
              <img
                v-if="content.image"
                :src="content.image"
                class="upload-img"
                style="width: 100%; height: 100%"
              />
              <i class="el-icon-plus" v-else></i>
            </el-upload>
          </el-form-item>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="任务名称" prop="title" required>
                <br />
                <el-input
                  v-model="content.title"
                  placeholder="请输入任务名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务类型" prop="cid" required>
                <br />
                <el-select
                  v-model="content.cid"
                  placeholder="请选择任务类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in projectTypeOptions"
                    :key="item.id"
                    :label="item.classifyName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资源名称" prop="projectName" required>
                <br />
                <el-input
                  v-model="content.projectName"
                  placeholder="请输入资源名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设置密码" prop="password" required>
                <br />
                <el-input
                  type="password"
                  v-model="content.password"
                  placeholder="请设置密码"
                  show-password
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预算" prop="money" required>
                <br />
                <el-input
                  type="password"
                  v-model="content.money"
                  placeholder="请输入预算"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务详情" prop="explain" required>
                <br />
                <el-input
                  v-model="content.explain"
                  placeholder="暂无"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="参考资料" prop="consult" required>
                <br />
                <el-input
                  v-model="content.consult"
                  placeholder="请输入参考资料"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名截止时间" prop="deadlineTime" required>
                <br />
                <el-date-picker
                  v-model="content.deadlineTime"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="请选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">测试信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="测试类型" prop="testType" required>
                <br />
                <el-select
                  v-model="content.testType"
                  placeholder="请选择测试类型"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in testTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="测试信息" prop="testInfo" required>
                <br />
                <el-input
                  v-model="content.testInfo"
                  placeholder="暂无"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="测试金额" prop="testMoney" required>
                <br />
                <el-input
                  v-model="content.testMoney"
                  placeholder="暂无"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="测试附件" prop="isAppendix" required>
                <br />
                <el-radio v-model="content.isAppendix" :label="true"
                  >有</el-radio
                >
                <el-radio v-model="content.isAppendix" :label="false"
                  >没有</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">报价</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="人天单价" prop="price" required>
                <br />
                <el-input
                  v-model="content.price"
                  placeholder="请输入人天单价"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="制作总人天" prop="day" required>
                <br />
                <el-input
                  v-model="content.day"
                  placeholder="请输入制作总人天"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <div class="detail-btn-box">
            <el-button class="detail-btn-item" @click="eventSubmit"
              >提交</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateEpiboly } from "@/api/ectocyst.js";
import { getClassifyList } from "@/api/work.js";
export default {
  data() {
    return {
      content: {},
      projectTypeOptions: [],
      // 测试类型
      testTypeList: [
        {
          value: "2",
          label: "付费测试",
        },
        {
          value: "1",
          label: "免费测试",
        },
        {
          value: "0",
          label: "无需测试",
        },
        {
          value: "3",
          label: "一对一试做",
        },
      ],
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      rules: {
        cid: [{ required: true, message: "请选择分类", trigger: "blur" }],
        image: [
          { required: true, message: "请上传任务封面", trigger: "change" },
        ],
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        projectName: [
          {
            required: true,
            message: "请输入资源名称",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请设置任务密码",
            trigger: ["blur", "change"],
          },
        ],
        money: [
          {
            required: true,
            message: "请输入预算",
            trigger: ["blur", "change"],
          },
        ],
        price: [
          {
            required: true,
            message: "请输入人天单价",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确的金额格式,可保留两位小数",
            trigger: ["blur", "change"],
          },
        ],
        day: [
          {
            required: true,
            message: "请输入制作总人天",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "格式不正确",
            trigger: ["blur", "change"],
          },
        ],
        deadlineTime: [
          {
            required: true,
            message: "请设置截止时间",
            trigger: ["blur", "change"],
          },
        ],
        consult: [
          {
            required: true,
            message: "请输入参考资料",
            trigger: ["blur", "change"],
          },
        ],
        explain: [
          {
            required: true,
            message: "请输入任务详情",
            trigger: ["blur", "change"],
          },
        ],
        testType: [
          { required: true, message: "请选择测试类型", trigger: "change" },
        ],
        testInfo: [
          {
            required: true,
            message: "请输入测试详情",
            trigger: ["blur", "change"],
          },
        ],
        testMoney: [
          {
            required: true,
            message: "请输入测试金额",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请输入正确的金额格式,可保留两位小数",
            trigger: ["blur", "change"],
          },
        ],
        isAppendix: [{ required: true, message: "请选择", trigger: "change" }],
      },
    };
  },
  props: {
    detailContent: {
      type: Object,
      require: true,
    },
  },
  created() {
    this.getTypeOptions();
    this.content = this.detailContent;
  },
  methods: {
    // 任务类型
    async getTypeOptions() {
      let { code, data } = await getClassifyList({
        queryType: 6,
      });
      if (code === 200) {
        this.projectTypeOptions = data || [];
      }
    },
    // 返回
    eventBack() {
      this.$emit("back");
    },
    // 上传成功后
    handleUploadSuccess(response, attributeName) {
      if (response.code === 200) {
        this.$set(this.content, attributeName, response.data.url);
      }
    },
    // 提交
    async eventSubmit() {
      let { code } = await updateEpiboly(this.content);
      if (code === 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "修改成功",
        });
        this.eventBack();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  //width: 100%;

  //padding: 20px 50px;
  .detail-header {
    width: 100%;
    //border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    padding: 20px 50px 10px;
    background-color: #ffffff;
  }
  .detail-content {
    margin-top: 10px;
    padding: 10px 50px 20px;
    background-color: #ffffff;
    .attestation-basic-info {
      margin-top: 20px;
      .info-title {
        position: relative;
        text-indent: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: var(--default-ectocyst-color);
        }
      }
    }
  }

  .detail-btn-box {
    text-align: center;
    .detail-btn-item {
      background-color: var(--default-ectocyst-color);
      color: #ffffff;
      padding: 10px 60px;
      font-size: 20px;
    }
  }
}
</style>