import instance from "./config.js";

// 查看作品列表
export function getWorkClassInfo(data){
    return instance.get("/api/work/list",{params:data});
}
//查询作品分类
export function getworkclass(id){
	return instance.get(`/api/work/query/${id}`,{params:id});
}

// 新增作品/api/work/insert
export function insertWork(data){
    return instance.post("/api/work/insert",data);
}

// 新增作品/api/work/insert
export function removeWork(id){
    return instance.get(`/api/work/remove/${id}`);
}

//获取作品详情/api/work/query
export function getWorksDetail(id){
    return instance.get(`/api/work/query/${id}`);
}

// 作品点赞  /api/like
export function getLiveLike(data){
    return instance.post("/api/like/addLike",data);
}

// 取消点赞
export function calcelLiveLike(data){
    return instance.post(`/api/like/cancelLike`,data);
}

// 关注   /api/focus/addFocus
export function addFocus(data){
    return instance.post("/api/focus/addFocus",data);
}

// 取消关注
export function cancelFocus(data){
    return instance.post("/api/focus/cancelFocus",data);
}

// 作品评论列表   /api/comment/list
export function commentList(data){
    return instance.get("/api/comment/list",{params:data});
}

export function hotComment(data){
    return instance.get("/api/comment/hot",{params:data});
}

// 新增作品评论  /api/comment/insert
export function insertComment(data){
    return instance.post("/api/comment/insert",data);
}

export function removeComment(data){
    return instance.post("/api/comment/delete",data);
}

//

// 新增收藏
export function insertCollection(data){
    return instance.post("/api/collect",data);
}

export function fileUpload(data){
    return instance.post("/api/file/upload",data);
}

export function deleteUpload(data) {
    return instance.get('/api/file/delete',{params: data});
}

export function bgUpload(data){
    return instance.post("/api/file/uploads",data);
}

// 删除收藏
export function deleteCollection(data){
    return instance.post(`/api/collect/delete`, data);
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return instance.get('/api/dict/data/type/' + dictType, {params: dictType})
}

// 获取资源分类列表
export function getClassifyList(data) {
    return instance.post('/api/classifies/getList', data)
}

// 获取资源子分类列表
export function getChildList(data) {
    return instance.get('/api/classifies/getChildList',{params: data})
}

// 网站留言
export function getBoard(data) {
	return instance.post('/api/comment/board',data)
}

// 添加工作岗位
export function addJobApi(data) {
	return instance.post('/api/job/add',data)
}

// 发布岗位时查询其相关信息
export function addJobBefo(data) {
	return instance.get('/api/job/befo',{params: data})
}