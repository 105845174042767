<template>
  <div class="index-page-container index-adviser-container">
    <div class="index-page-content index-adviser-content">
      <div class="page-title adviser-title">
        师资团队
        <span>排名不分先后</span>
      </div>
      <div class="adviser-box">
        <div class="adviser-top">
          <div class="adviser-top-img">
            <picture>
              <img v-lazy="require('@/assets/img/train/adviser.png')" />
            </picture>
          </div>
          <div class="adviser-top-user">
            <img v-lazy="showUserObj.url" />
            <div class="user-info">
              <p class="user-info-name">{{ showUserObj.name }}</p>
              <p class="user-info-position">{{ showUserObj.position }}</p>
              <p class="user-info-introduce">{{ showUserObj.introduce }}</p>
            </div>
          </div>
        </div>
        <div class="adviser-bottom">
          <ul>
            <li
              :class="activeIndex == item.id && 'active'"
              v-for="item in adviserList"
              :key="item.id"
              @click="eventChangeIndex(item)"
            >
              <img v-lazy="item.url" :alt="item.name" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adviserList: [
        {
          id: 1,
          name: "Jimmy",
          position: "教育部总监",
          introduce:
            "从事特效相关工作10余年，曾参与过影视、游戏、AR/VR等各种特效相关领域；从事游戏特效教育培训相关工作5年，教学经验丰富，管理经验多样。现任职于广州怪力视效网络科技有限公司，负责教育培训部的教学、管理、课程研发等相关工作。",
          url: require("@/assets/img/train/teacher/teacher-jxz-big.png"),
        },
        {
          id: 2,
          name: "Amon",
          position: "金牌讲师",
          introduce:
            "曾参与《决战平安京》、《漫威超级战争》、《王者荣耀》等多款网易，腾讯大厂的项目，熟练使用PS、3DsMax、Unity3D、UE4等工具，拥有丰富的项目实战经验。任职于广州怪力视效网络科技有限公司，从事Unity特效培训，培养0基础从入门到精通的游戏特效人才。",
          url: require("@/assets/img/train/teacher/teacher-wjx-big.png"),
        },
        {
          id: 3,
          name: "Jacob",
          position: "项目总监",
          introduce:
            "从事游戏行业8年，现任广州怪力视效特效团队总负责人，主要负责特效团队的项目统筹安排与品控。主要参与过的项目有《决战平安京》《天下3》《阴阳师》《七日之都》《镇魔曲手游》《百闻牌》《超级战争》《王者荣耀》《率土之滨》《机动都市阿尔法》《陈情令》《热血航线》《魂师对决》等百余款游戏",
          url: require("@/assets/img/train/teacher/teacher-ocy-big.png"),
        },
        {
          id: 4,
          name: "Ken",
          position: "资深游戏特效设计师",
          introduce:
            "游戏特效相关工作经验6年。现任职于广州怪力网络科技有限公司，负责审核把控团队制作的角色特效、场景特效、界面动效等品质质量。主要参与过的项目有《天下3》《暗黑破坏神不朽》《海外王者》《荒野行动》《明日之后》《航海王热血航线》等",
          url: require("@/assets/img/train/teacher/teacher-xzh-big.png"),
        },
        {
          id: 5,
          name: "Garson",
          position: "资深游戏特效设计师",
          introduce:
            "从事游戏特效行业5年，主要负责角色技能，展示和场景的特效制作。目前在广州怪力视效网络科技有限公司任特效组长一职。主要负责项目需求对接、品质把控以及团队管理工作，擅长高精度品质和风格的把控。主要参与过的项目有《阴阳师》《永远的7日之都》《明日之后》《第五人格》《哈利波特魔法觉醒》等",
          url: require("@/assets/img/train/teacher/teacher-yjx-big.png"),
        },
        {
          id: 6,
          name: "Gage",
          position: "资深游戏模型设计师",
          introduce:
            "从事游戏行业5年， 资深游戏模型设计师。现任职于广州怪力网络科技有限公司，主要负责手绘角色、游戏道具坐骑的建模与贴图制作。主要参与过的项目有《哈利波特》《天下3-端游》《 天下3-手游》 《哈利波特魔法觉醒》《阴阳师》等",
          url: require("@/assets/img/train/teacher/teacher-wzh-big.png"),
        },
        {
          id: 7,
          name: "Taylor",
          position: "资深游戏动作设计师",
          introduce:
            "从事游戏行业8年,参与过主机，手游  H5  页游 端游等各种游戏平台类型游戏制作，目前在广州怪力视效网络科技有限公司任动作部长一职。主要参与过的游戏项目有《黎明杀机》《荒野行动》《暗黑》《梦幻封神》《东京喰种》《王者荣耀》《天下》等",
          url: require("@/assets/img/train/teacher/teacher-hcz-big.png"),
        },
        {
          id: 8,
          name: "Anson",
          position: "资深游戏动作设计师",
          introduce:
            "从事游戏行业10年,主要负责游戏角色战斗动作、展示动画制作，目前就职于广州怪力视效网络科技有限公司任动作部。主要参与过的游戏项目有《火影忍者》《天龙八部》《剑侠情缘网络版3》《英雄联盟手游》等",
          url: require("@/assets/img/train/teacher/teacher-clr-big.png"),
        },
        {
          id: 9,
          name: "Justin Bieber",
          position: "资深游戏场景原画师",
          introduce:
            "从事游戏影视美术行业7年，主要负责游戏场景原画设计。曾在影视剧担任副美术；现任职于广州怪力视效网络科技有限公司，负责游戏场景、道具设计。主要参与过的游戏项目有《荒野行动》《天下》《摩尔庄园》等。",
          url: require("@/assets/img/train/teacher/teacher-lgz-big.png"),
        },
      ],
      activeIndex: 1,
      showUserObj: {},
    };
  },
  created() {
    this.showUserObj = this.adviserList[0];
  },
  methods: {
    // 点击切换展示的人物
    eventChangeIndex(data) {
      this.activeIndex = data.id;
      this.showUserObj = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.index-adviser-container {
  .index-adviser-content {
    .adviser-title {
      > span {
        position: absolute;
        left: calc(100% + 30px);
        bottom: 5px;
        white-space: nowrap;
        color: #9abfcc;
        font-size: 16px;
      }
    }
    .adviser-box {
      margin-top: -60px;
      width: 1125px;
      .adviser-top {
        width: 100%;
        height: 356px;
        position: relative;
        .adviser-top-img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 60px;
          left: 0;
          z-index: -1;
          img {
            width: 100%;
            height: auto;
          }
        }
        .adviser-top-user {
          height: 100%;
          //width: 100%;
          display: flex;
          margin: 0 0 0 210px;
          box-sizing: border-box;
          img {
            height: 100%;
            width: 390px;
            object-fit: contain;
          }
          .user-info {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 30px;
            max-width: 450px;
            padding: 110px 0 0;
            box-sizing: border-box;
            .user-info-name {
              color: #ffffff;
              font-size: 38px;
              font-weight: bold;
              margin-bottom: 8px;
            }
            .user-info-position {
              font-size: 14px;
              margin-bottom: 40px;
            }
            .user-info-introduce {
              font-size: 13px;
              line-height: 1.67;
            }
          }
        }
      }
      .adviser-bottom {
        position: relative;
        width: 100%;
        > ul {
          display: flex;
          justify-content: center;
          position: relative;
          padding: 0;
          margin: 0;
          overflow-y: hidden;
          width: 100%;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          > li {
            list-style: none;
            width: 140px;
            height: 186px;
            cursor: pointer;
            position: relative;
            background-color: #212121;
            &.active {
              background-image: linear-gradient(to top, #01a3b3, #01444b);
            }
            &:hover {
              background-image: linear-gradient(to top, #01a3b3, #01444b);
            }
            > img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          > li:nth-child(odd) {
            background-color: #171717;
          }
        }
      }
    }
  }
}
</style>