<template>
  <div class="index-page-container index-guidance-container">
    <div class="index-page-content index-guidance-content">
      <div class="page-title guidance-title">课程系列ㆍ毕业指导</div>
      <div class="guidance-box">
        <div class="guidance-item">
          <div class="guidance-item-title">就业课程</div>
          <div class="guidance-item-content">
            <ul>
              <li>1.学员个人创意作品集制作</li>
              <li>2.学员作品集毕业答辩</li>
              <li>3.面试指导与应聘测试题指导</li>
              <li>4.职业规划</li>
              <li>5.推荐岗位</li>
              <li>6.为了梦想，冲！冲！冲！</li>
            </ul>
          </div>
        </div>
        <div class="guidance-item guidance-item-sub">
          <div class="guidance-item-title">怪力大咖的私享会</div>
          <div class="guidance-item-content">
            <div class="guidance-item-sub-title">
              来自怪力视效的技术大咖，与学员面对面，想了解的一切都可以得到答案
            </div>
            <div class="guidance-item-img">
              <img v-lazy="require('@/assets/img/train/guidance_1.png')" />
              <img v-lazy="require('@/assets/img/train/guidance_2.png')" />
              <img v-lazy="require('@/assets/img/train/guidance_3.png')" />
              <img v-lazy="require('@/assets/img/train/guidance_4.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.index-guidance-container {
  .index-guidance-content {
    .guidance-box {
      display: flex;
      margin-top: 30px;
      .guidance-item {
        .guidance-item-title {
          color: #35edff;
          font-size: 32px;
        }
        .guidance-item-content {
          margin-top: 30px;
          width: 300px;
          background-color: rgba(40, 45, 60, 0.9);
          border: 1px solid #647a84;
          padding: 20px 30px;
          color: #9abfcc;
          font-size: 18px;
          > ul {
            > li {
              line-height: 40px;
              border-top: 1px solid rgba($color: #647a84, $alpha: 0.4);
              &:nth-child(1) {
                border-top: none;
              }
            }
          }
        }
      }
      .guidance-item.guidance-item-sub {
        margin-left: 50px;
        .guidance-item-title {
          padding-left: 120px;
        }
        .guidance-item-content {
          background-color: transparent;
          border: none;
          padding: 0;
          width: auto;
          .guidance-item-sub-title {
            width: 440px;
            font-size: 24px;
            color: #ffffff;
            line-height: 40px;
            padding-left: 120px;
          }
          .guidance-item-img {
            display: flex;
            flex-wrap: wrap;
            width: 800px;
            > img {
              width: 300px;
              height: 170px;
              margin-left: 80px;
              margin-top: 20px;
              &:nth-child(1) {
                margin-left: 0px;
              }
              &:nth-child(3) {
                margin-left: 120px;
              }
            }
          }
        }
      }
    }
  }
}
</style>