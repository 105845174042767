<template>
  <div class="input-style">
    <el-input
      v-model="inputValue"
      :placeholder="placeholderText"
      :type="type"
      :rows="rows"
      :maxlength="maxlength"
      clearable
      @change="onChange"
      :disabled="disabled"
      :show-word-limit="showWordLimit"
      :show-password="$attrs.showPassword"
    ></el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: undefined,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: "请填写",
    },
    type: {
      type: String,
      default: "text",
    },
    rows: {
      type: Number,
      default: 2,
    },
    maxlength: {
      type: Number,
      default: 10000,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value: {
        handler(v) {
            this.inputValue = v;
        }
    }
  },
  created() {
    this.inputValue = this.value;
  },
  methods: {
    onChange() {
        this.$emit('getValue', this.inputValue)
    }
  }
};
</script>

<style lang="scss" scoped>
.input-style {
  .el-input {
    width: auto;
    /deep/ .el-input__inner {
      background-color: #393d47;
      border: 0.0625rem solid #6f7693;
      border-radius: 0;
      color: #fff;
      &::placeholder {
        color: #ccc;
        opacity: 0.5;
      }
    }
  }

  /deep/ .el-textarea__inner{
    background: #393d47;
    border: 0.0625rem solid #6f7693;
    color: #fff;
    &:focus{
      outline: none;
      border-color: #6f7693;
    }
  }

  /deep/.el-textarea .el-input__count {
    background-color: transparent;
  }

  /deep/.el-input .el-input__count .el-input__count-inner{
    background-color: transparent;
  }
}
</style>