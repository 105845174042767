//dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。

function timeago(valueTime){  
    if(valueTime){
      valueTime = new Date(valueTime).getTime()
        var newData =  Date.parse(new Date());
        var diffTime = Math.abs(newData-valueTime);
        if (diffTime > 7 * 24 * 3600 * 1000) {
          var date = new Date(valueTime);
          var y = date.getFullYear();
          var m = date.getMonth() + 1;
          m = m < 10 ? ('0' + m) : m;
          var d = date.getDate();
          d = d < 10 ? ('0' + d) : d;
          var h = date.getHours();
          h = h < 10 ? ('0' + h) : h;
          var minute = date.getMinutes();
          var second = date.getSeconds();
          minute = minute < 10 ? ('1' + minute) : minute;
          second = second < 10 ? ('0' + second) : second;
          return  m + '-' + d+' '+h+':'+minute;
    
        } else if (diffTime < 7 * 24 * 3600 * 1000 && diffTime > 24 * 3600 * 1000) {
          // //注释("一周之内");
    
          // var time = newData - diffTime;
          var dayNum = Math.floor(diffTime / (24 * 60 * 60 * 1000));
          return dayNum + "天前";
    
        } else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) {
          // //注释("一天之内");
          // var time = newData - diffTime;
          var dayNum = Math.floor(diffTime / (60 * 60 * 1000));
          return dayNum + "小时前";
    
        } else if (diffTime < 3600 * 1000 && diffTime > 0) {
          // //注释("一小时之内");
          // var time = newData - diffTime;
          var dayNum = Math.floor(diffTime / (60 * 1000));
          return dayNum + "分钟前";
    
        }
      }
    
}
function getTime(date){
  // 开始时间
  let date1 = new Date();
  // 结束时间
  let d2 = date.replace(/\-/g, "/");
  let date2 = new Date(d2);
  // 时间相差秒数
  let dateDiff = date2.getTime() - date1.getTime();
  // 计算出相差天数
  let days = Math.floor(dateDiff / (24 * 3600 * 1000));
  // 计算出小时数
  let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  let hours = Math.floor(residue1 / (3600 * 1000));
  // 计算相差分钟数
  let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(residue2 / (60 * 1000));
  // 计算相差秒数
  let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(residue3 / 1000);

  let returnVal =
      ((days == 0) ? "" : days+"天") +
      ((hours == 0) ? "" : hours+"小时") +
      ((minutes == 0) ? "" : minutes+"分") +
      ((seconds == 0) ? "" : seconds+"秒");
  return returnVal;
}

function compare(date){
  let newDate = new Date();
  let oldDate = new Date(date);
  let result = newDate - oldDate;
  if(result==0){
    return ''
  }else if(result>0){
    return true
  }else{
    return false
  }
}

// 日期格式化
function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

function _isMobile() {
  // let flag = navigator.userAgent.match(
  //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  // );
  let flag = ((/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i).test(navigator.userAgent));
  return flag;
}


module.exports = {
    timeago,getTime,compare,parseTime,_isMobile
 }