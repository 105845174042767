<template>
  <div class="index-page-container index-environment-container">
    <div class="index-page-content index-environment-content">
      <div class="page-title environment-title">集训环境</div>
      <div class="environment-box">
        <el-image
          class="environment-img"
          :src="item"
          fit="fill"
          lazy
          :preview-src-list="srcList"
          v-for="(item, index) in srcList"
          :key="index"
        >
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: require("@/assets/img/1.jpg"),
      srcList: [
        require("@/assets/img/train/enviorment_1_new.jpg"),
        require("@/assets/img/train/enviorment_2.png"),
        require("@/assets/img/train/enviorment_3.png"),
        require("@/assets/img/train/enviorment_4.png"),
        require("@/assets/img/train/enviorment_5.png"),
        require("@/assets/img/train/enviorment_6.png"),
        require("@/assets/img/train/enviorment_7.png"),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.index-environment-container {
  .index-environment-content {
    .environment-box {
      margin-top: 100px;
      margin-left: -10vw;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 1550px;
      height: 520px;
      overflow: auto;
      .environment-img {
        width: 350px;
        height: 220px;
        margin-left: 50px;
        border-radius: 38px;
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(n + 5) {
          width: 200px;
          height: 250px;
          margin-top: 50px;
          margin-left: 0;
          margin-right: 100px;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>