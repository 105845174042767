<template>
  <div class="item-container" @click="eventShowLock">
    <div class="item-box">
      <div class="item-img">
        <div class="item-private" v-if="content.flag">
          <img src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/lock.png">
        </div>
        <img v-else
          :src="content.image"
          :alt="content.title"
        />
        <p class="item-number">NO.{{content.eid}}</p>
        <p
          class="item-status"
          :class="
            content.status === 0
              ? 'item-status-enroll'
              : content.status === 1
              ? 'item-status-action'
              : 'item-status-end '
          "
        >
          {{
            content.status === 0
              ? "报名中"
              : content.status === 1
              ? "进行中"
              : "已结束"
          }}
        </p>
      </div>
      <div class="item-title">{{content.classifyName + ' · ' + content.title}}</div>
      <div class="item-price">
        <!-- <el-statistic
          :precision="2"
          decimal-separator="."
          :value="content.minPrice"
          prefix="￥"
        ></el-statistic>
        <el-statistic
          :precision="2"
          decimal-separator="."
          :value="content.maxPrice"
          prefix="-"
          style="margin-left: 4px"
        ></el-statistic> -->
        {{ content.money }}
      </div>
      <div class="item-explain">
        <p class="item-enroll">
          {{ content.flag ? "限密码查看" : content.man || 0 + "人报名" }}
        </p>
        <p class="item-status-text" v-if="!content.isPrivate">
          {{ content.enrollState === 0 ? "报名已截止" : "正在报名中" }}
        </p>
      </div>
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      require: true,
    },
  },
  methods: {
    // 显示锁
    eventShowLock() {
      this.$emit('changeShow', true, this.content.eid);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-container {
  margin-top: 50px;
  border-radius: 10px;
  animation: showBox 1s ease;
  @keyframes showBox {
    0%{
      transform: scaleX(0);
      opacity: 0;
    }
    100%{
      transform: scaleX(1);
      opacity: 1;
    }
  }
  // 鼠标悬浮
  &:hover {
    .item-box {
      box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.1);

      .item-img {
        > img {
          //transform: scale(1.2);
        }
        &::after {
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.4);
        }
      }
    }
  }
  .item-box {
    background-color: #ffffff;
    border-radius: 5px;
    width: 100%;
    //height: 480px;
    margin-top: 30px;
    border: 1px solid rgba($color: #87929c, $alpha: 0.2);
    box-sizing: border-box;
    padding: 0 10px 10px;
    transition: all 0.5s;
    .item-img {
      width: 100%;
      //height: 255px;
      //width: 100%;
      height: 0;
      margin: 0 auto;
      margin-top: -30px;
      position: relative;
      padding-top: 90%;
      //overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.5s;
        border-radius: 5px;
      }
      .item-private{
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 1.0);
        background-image: url('https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/ectocyst/assignment/lock_bg.png');
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 5px;
        >img{
          width: 20%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      > img {
        object-fit: cover;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border: 1px solid #eeeeee;
      }
      >.item-number{
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 1;
        font-size: 14px;
        color: #ffffff;
        padding: 4px 12px;
        border-radius: 18px;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
      > .item-status {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        transform: translateY(50%);
        color: #ffffff;
        font-size: 14px;
        padding: 4px 12px;
        border-radius: 18px;

        &.item-status-end {
          background-color: #969aa5;
        }

        &.item-status-action {
          background-color: #68c675;
        }

        &.item-status-enroll {
          background-color: #1a51bafc;
        }
      }
    }
    .item-title {
      margin-top: 25px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #484848;
    }

    .item-price {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #000000;
      font-weight: bold;
      /deep/.el-statistic {
        width: auto;
        .con {
          color: #000000;
          .prefix {
            font-weight: bold;
            font-size: 20px;
            margin-left: -4px;
          }
        }
      }
      /deep/.el-statistic .con .number {
        padding: 0;
      }
    }

    .item-explain {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #484848;
      font-size: 12px;
      .item-enroll {
      }
      .item-status-text {
      }
    }
  }

  .item-lock-box{
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}
</style>