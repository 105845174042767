<template>
    <div class="lv-container">
        <el-progress :show-text="false" :percentage="percentageScale" color="#00d4ff"></el-progress>
        <div class="progress-text">{{percentageNum || 0}} / {{allExp}}</div>
    </div>
</template>

<script>
import { getexp } from '@/api/message.js';
export default {
    data() {
        return {
            // 当前经验值
            percentageNum: 100,
            // 完成当前等级所需经验值
            allExp: 500,
            lv: 1
        }
    },
    computed: {
        // 比例
        percentageScale() {
            return this.allExp == "MAX" ? 100 : (this.percentageNum / this.allExp) * 100;
        }
    },
    watch: {
        userId: {
            handler() {
                this.getexpValue();
            },
            immediate: true
        }
    },
    props: {
        userId: {
            type: Number,
            default: undefined
        }
    },
    created() {
        //this.getexpValue();
    },
    methods: {
        // 获取用户经验值
        getexpValue() {
            getexp({
                userId : this.userId
            }).then(res => {
                if (res.code === 200) {
                    this.percentageNum = parseInt(res.data);
                    if (res.data <= 500) {
						this.allExp = 500;
						this.lv = 1;
					} else if (res.data > 500 && res.data <= 1000) {
						this.allExp = 1000;
						this.lv = 2;
					} else if (res.data > 1000 && res.data <= 3000) {
						this.allExp = 3000;
						this.lv = 3;
					} else if (res.data > 3000 && res.data <= 8000) {
						this.allExp = 8000;
						this.lv = 4;
					} else if (res.data > 8000 && res.data < 20000) {
						this.allExp = 20000;
						this.lv = 5;
					} else if (res.data >= 20000) {
						this.allExp = "MAX";
						this.percentageNum = 'MAX';
						this.lv = 6;
					}
                    this.$emit('getLevel', this.lv);
                }
            }) 
        }
    }
}
</script>

<style lang="scss" scoped>
.lv-container{
    position: relative;
    /deep/.el-progress-bar__outer{
        border: 3px solid #1476ed;
        height: 26px !important;
        background-color: #0d1e36;
        box-shadow: 0px 0px 10px #1476ed;
        box-sizing: border-box;
    }

    /deep/.el-progress-bar__inner{
        width: 0%;
        box-sizing: border-box;
        height: 80%;
        position: absolute;
        left: 4px;
        top: 10%;
        max-width: calc(100% - 8px);
    }

    .progress-text{
        font-size: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        color: #ffffff;
    }
}

</style>