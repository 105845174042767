<template>
  <div class="attestation-container">
    <el-form :model="authForm" :rules="authRules" ref="authForm">
      <div class="attestation-top">
        <h1>{{ type === "person" ? "个人身份" : "企业身份" }}</h1>
        <p @click="eventToView(type === 'company' ? 'person' : 'company')">
          <i class="el-icon-sort"></i>切换到{{
            type === "company" ? "个人身份" : "企业身份"
          }}
        </p>
      </div>
      <div class="attestation-describe">
        出于你的资金安全和保密需要,请如实填写以下信息，怪力屋将对用户隐私信息进行严格保密。
      </div>
      <!-- 账户信息 -->
      <div class="attestation-basic-info">
        <p class="info-title">账户信息</p>
        <el-form-item
          :label="(type === 'person' ? '头像' : '企业logo') + '(大小2M以内哟!)'"
          prop="headImage"
          required
        >
          <br />
          <el-upload
            class="info-header-img"
            :action="uploadUrl"
            :show-file-list="false"
            list-type="picture-card"
            :limit="1"
            accept=".jpg,.png,.jpeg"
            :before-upload="(file) => { beforeUpload(file, 2) } "
            :on-success="
              (response) => {
                return handleUploadSuccess(response, 'headImage');
              }
            "
          >
            <img
              v-if="authForm.headImage"
              :src="authForm.headImage"
              class="upload-img"
            />
            <i class="el-icon-plus" v-else></i>
          </el-upload>
        </el-form-item>
        <el-row :gutter="86">
          <el-col :span="12">
            <el-form-item label="账号(手机号)" prop="phone" required>
              <br />
              <el-input
                v-model="authForm.phone"
                placeholder="请输入账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码" prop="password" required>
              <br />
              <el-input
                v-model="authForm.password"
                type="password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 个人信息 -->
      <div class="attestation-basic-info" v-if="type === 'person'">
        <p class="info-title">个人信息</p>
        <el-row :gutter="86">
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="name" required>
              <br />
              <el-input
                v-model="authForm.name"
                placeholder="请输入您的真实姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idCard" required>
              <br />
              <el-input
                v-model="authForm.idCard"
                placeholder="请输入您的身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex" required>
              <br />
              <el-select
                v-model="authForm.sex"
                clearable
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in sexOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系邮箱" prop="email" required>
              <br />
              <el-input
                v-model="authForm.email"
                placeholder="请输入你的邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系QQ" prop="qqNumber" required>
              <br />
              <el-input
                v-model="authForm.qqNumber"
                placeholder="请输入你的QQ"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="擅长方向" prop="forte" required>
              <br />
              <el-select
                v-model="authForm.forte"
                multiple
                filterable
                allow-create
                placeholder="请选择或者输入你的擅长方向"
                style="width: 100%"
              >
                <el-option
                  v-for="item in forteOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 企业/工作室信息 -->
      <div class="attestation-basic-info" v-if="type === 'company'">
        <p class="info-title">企业/工作室信息</p>
        <el-row :gutter="86">
          <el-col :span="12">
            <el-form-item label="公司名称" prop="name" required>
              <br />
              <el-input
                v-model="authForm.name"
                placeholder="请输入您的公司名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经营者姓名" prop="corporation" required>
              <br />
              <el-input
                v-model="authForm.corporation"
                placeholder="请输入您的公司法人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司地址" prop="companyAddress" required>
              <br />
              <el-input
                v-model="authForm.companyAddress"
                placeholder="请输入您的公司地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="营业执照注册号/统一社会信用代码"
              prop="creditCode"
              required
            >
              <br />
              <el-input
                v-model="authForm.creditCode"
                placeholder="请输入您的公司营业执照注册号/统一社会信用代码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="营业执照所在地" prop="licenseAddress" required>
              <br />
              <!-- <v-distpicker
                class="dist-picker"
                @selected="onSelected"
                :province="licenseAddress.province"
                :city="licenseAddress.city"
                :area="licenseAddress.area"
              ></v-distpicker> -->
              <el-cascader
                style="width: 100%"
                size="large"
                :options="licenseOptions"
                filterable
                @change="handleLicenseAddressChange"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经营年限" prop="businessTerm" required>
              <br />
              <el-input
                v-model="authForm.businessTerm"
                placeholder="若证件有效期为长期，请输入长期。如2020年1月1日-长期"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 银行信息 -->
      <div class="attestation-basic-info">
        <p class="info-title">银行信息</p>
        <el-row :gutter="86">
          <el-col :span="12">
            <el-form-item label="银行卡账号" prop="bankCard" required>
              <br />
              <el-input
                v-model="authForm.bankCard"
                placeholder="请输入您的银行卡账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户行" prop="openingBank" required>
              <br />
              <el-input
                v-model="authForm.openingBank"
                placeholder="请输入您的银行卡开户行"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户人" prop="bankHolders" required>
              <br />
              <el-input
                v-model="authForm.bankHolders"
                placeholder="请输入开户人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 项目信息 -->
      <div class="attestation-basic-info" v-if="type === 'company'">
        <p class="info-title">项目信息</p>
        <el-row :gutter="86">
          <el-col :span="12">
            <el-form-item label="项目联系人" prop="contacts" required>
              <br />
              <el-input
                v-model="authForm.contacts"
                placeholder="请输入项目联系人的姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="enterprisePhone" required>
              <br />
              <el-input
                v-model="authForm.enterprisePhone"
                placeholder="请输入项目联系人的电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系邮箱" prop="email" required>
              <br />
              <el-input
                v-model="authForm.email"
                placeholder="请输入项目联系人的邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 上传资料 -->
      <div class="attestation-basic-info">
        <p class="info-title">上传资料</p>
        <el-row :gutter="86" v-if="type === 'person'">
          <el-col :span="12">
            <el-form-item
              label="上传手持身份证人像面"
              prop="idcardFront"
              required
            >
              <br />
              <el-upload
                class="info-id-card"
                :action="uploadUrl"
                list-type="picture-card"
                :show-file-list="false"
                accept=".jpg,.png,.jpeg"
                :on-success="
                  (response) => {
                    return handleUploadSuccess(response, 'idcardFront');
                  }
                "
              >
                <img
                  :src="authForm.idcardFront"
                  class="upload-img"
                  v-if="authForm.idcardFront"
                />
                <i class="el-icon-plus" v-else></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="上传手持身份证国徽面"
              prop="idcardReversed"
              required
            >
              <br />
              <el-upload
                class="info-id-card"
                :action="uploadUrl"
                list-type="picture-card"
                :show-file-list="false"
                accept=".jpg,.png,.jpeg"
                :on-success="
                  (response) => {
                    return handleUploadSuccess(response, 'idcardReversed');
                  }
                "
              >
                <img
                  :src="authForm.idcardReversed"
                  class="upload-img"
                  v-if="authForm.idcardReversed"
                />
                <i class="el-icon-plus" v-else></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="86" v-if="type === 'company'">
          <el-col :span="12">
            <el-form-item
              label="盖企业章的营业执照照片"
              prop="businessIicense"
              required
            >
              <br />
              <el-upload
                class="info-id-card"
                :action="uploadUrl"
                list-type="picture-card"
                :show-file-list="false"
                :on-success="
                  (response) => {
                    return handleUploadSuccess(response, 'businessIicense');
                  }
                "
              >
                <img
                  :src="authForm.businessIicense"
                  class="upload-img"
                  v-if="authForm.businessIicense"
                />
                <i class="el-icon-plus" v-else></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 认证说明 -->
      <div class="attestation-basic-info">
        <p class="info-title">说明</p>
        <el-row :gutter="86">
          <el-col :span="20">
            <el-form-item label="认证说明" style="margin-bottom: 0">
              <br />
              <el-input
                type="textarea"
                autosize
                placeholder="请输入内容"
                v-model="describe"
                readonly="readonly"
              >
              </el-input>
              <!-- <br />
              <el-checkbox
                v-model="authForm.isChecked"
                class="info-describe-checkbox"
                name="isChecked"
                label="请确认您已仔细阅读以上内容，并保证上述信息真实有效"
              ></el-checkbox> -->
            </el-form-item>
            <el-form-item prop="isChecked" required>
              <el-checkbox
                v-model="authForm.isChecked"
                class="info-describe-checkbox"
                name="isChecked"
                label="请确认您已仔细阅读以上内容，并保证上述信息真实有效"
              ></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="form-submit">
        <el-button
          class="form-submit-btn"
          icon="el-icon-s-promotion"
          size="medium"
          @click="eventSubmit"
          >点击提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import { epibolyApplyPeople, epibolyApplyCorporation } from "@/api/ectocyst.js";
import VDistpicker from "v-distpicker";
import fileUpload from "@/component/form/fileUpload.vue";
export default {
  data() {
    const agreementValidator = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error("请勾选"));
      }
    };
    const idCardValidator = (rule, value, callback) => {
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let _IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
      // 校验身份证：
      if (_IDRe18.test(value) || _IDre15.test(value)) {
        callback();
      } else {
        callback(new Error("身份证号格式错误"));
      }
    };
    return {
      // 认证身份类型 person 个人 company 企业
      type: "person",
      authForm: {
        headImage: undefined,
        phone: undefined,
        password: undefined,
        contacts: undefined,
        idCard: undefined,
        sex: undefined,
        email: undefined,
        qqNumber: undefined,
        forte: undefined,
        name: undefined,
        corporation: undefined,
        companyAddress: undefined,
        creditCode: undefined,
        licenseAddress: undefined,
        businessTerm: undefined,
        bankCard: undefined,
        openingBank: undefined,
        bankHolders: undefined,
        enterprisePhone: undefined,
        idcardFront: undefined,
        idcardReversed: undefined,
        businessIicense: undefined,
        // 是否勾选同意协议
        isChecked: undefined,
      },
      authRules: {
        headImage: [
          {
            required: true,
            message:
              (this.type === "person" ? "头像" : "公司Logo") + "不能为空",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "账号不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式错误",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: ["blur", "change"],
          },
          {
            min: 8,
            max: 16,
            message: "密码应为8到16位字符",
            trigger: ["blur"],
          },
        ],
        name: [
          {
            required: true,
            message:
              (this.type === "person" ? "姓名" : "公司名称") + "不能为空",
            trigger: ["blur", "change"],
          },
        ],
        idCard: [
          {
            required: true,
            message: "身份证号不能为空",
            trigger: ["blur", "change"],
          },
          {
            validator: idCardValidator,
            trigger: ["blur"],
          },
        ],
        sex: [
          {
            required: true,
            message: "性别不能为空",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: ["blur", "change"],
          },
          { type: "email", message: "邮箱格式不正确", trigger: "blur" },
        ],
        qqNumber: [
          {
            required: true,
            message: "QQ不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[1-9]{1}[0-9]*$/,
            message: "QQ号格式不正确",
            trigger: ["blur"],
          }
        ],
        forte: [
          {
            required: true,
            message: "请选择或自行填写擅长的内容",
            trigger: ["blur", "change"]
          },
        ],
        corporation: [
          {
            required: true,
            message: "请填写经营者姓名",
            trigger: ["blur", "change"],
          },
        ],
        companyAddress: [
          {
            required: true,
            message: "请填写公司地址",
            trigger: ["blur", "change"],
          },
        ],
        creditCode: [
          {
            required: true,
            message: "请填写营业执照代码",
            trigger: ["blur", "change"],
          },
        ],
        licenseAddress: [
          {
            required: true,
            message: "请填写营业执照所在地",
            trigger: ["blur", "change"],
          },
        ],
        businessTerm: [
          {
            required: true,
            message: "请填写经营年限",
            trigger: ["blur", "change"],
          },
        ],
        bankCard: [
          {
            required: true,
            message: "银行卡账号不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[1-9](([0-9]{15,16})|([0-9]{18}))$/,
            message: "银行卡账号格式不正确",
            trigger: ["blur"]
          }
        ],
        openingBank: [
          {
            required: true,
            message: "开户行不能为空",
            trigger: ["blur", "change"],
          },
        ],
        bankHolders: [
          {
            required: true,
            message: "开户人不能为空",
            trigger: ["blur", "change"],
          },
        ],
        contacts: [
          {
            required: true,
            message: "项目联系人不能为空",
            trigger: ["blur", "change"],
          },
        ],
        enterprisePhone: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: ["blur", "change"],
          },
        ],
        idcardFront: [
          {
            required: true,
            message: "请按要求上传照片",
            trigger: "change",
          },
        ],
        idcardReversed: [
          {
            required: true,
            message: "请按要求上传照片",
            trigger: "change",
          },
        ],
        businessIicense: [
          {
            required: true,
            message: "请按要求上传照片",
            trigger: "change",
          },
        ],
        isChecked: [
          {
            validator: agreementValidator,
            trigger: "change",
          },
        ],
      },
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      licenseOptions: regionData,
      options: [
        {
          label: "男",
          value: "1",
        },
      ],
      sexOptions: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],
      // 特长
      forteOptions: [
        {
          label: "特效",
          value: "特效",
        },
        {
          label: "动作",
          value: "动作",
        },
        {
          label: "原画",
          value: "原画",
        },
        {
          label: "模型",
          value: "模型",
        },
        {
          label: "场景",
          value: "场景",
        },
        {
          label: "UI",
          value: "UI",
        },
      ],
      describe:
        "您好！\r\n" +
        "欢迎注册认证怪力屋！\r\n基于保障用户资金安全和项目保密的原则，怪力屋需要对涉及资金往来的用户进行身份认证。\r\n为使您更好地使用怪力屋，" +
        "用户应保证其提供给怪力屋的所有资料和信息的真实性、合法性、准确性和有效性；否则，怪力屋有权终止和取消用户通过怪力屋获取的服务和酬金。因用户提供的资料偏差给怪力屋或第三方造成损害的，该用户应依法承担相应的责任。\r\n" +
        "保护用户信息是怪力屋的一项基本原则，怪力屋会采取合理有效的措施确保用户信息的安全性，请您放心！除法律法规规定的情形外，未经用户许可怪力屋绝不会向任何第三方泄漏用户的资料和信息。",
    };
  },
  watch: {
    "$route.query.type": {
      handler() {
        this.eventChangeType(this.$route.query.type);
      },
    },
  },
  components: {
    VDistpicker,
    fileUpload,
  },
  created() {
    this.eventChangeType(this.$route.query.type || "person");
  },
  methods: {
    // 上传文件前调
    beforeUpload(file, maxSize) {
      let isLtMax = file.size / 1024 / 1024 < maxSize;
      if (!isLtMax) {
        this.$message.error("上传的图片大小不能超过 " + maxSize + "MB!");
      }
      return isLtMax;
    },
    eventChangeType(name) {
      this.type = name;
      this.$nextTick(() => {
        this.$refs.authForm.clearValidate();
      });
    },
    eventToView(name) {
      this.$router.push("/ectocyst/register?type=" + name);
    },
    onSelected(data) {
      // this.$set(
      //   this.authForm,
      //   "licenseAddress",
      //   data.province.value + "-" + data.city.value + "-" + data.area.value
      // );
      this.licenseAddress =
        data.province.value + "-" + data.city.value + "-" + data.area.value;
    },
    handleLicenseAddressChange(value) {
      console.log(value);
      let addressArr = [];
      value.forEach((element) => {
        addressArr.push(CodeToText[element]);
      });
      console.log(addressArr);
      this.authForm.licenseAddress = addressArr.join("-");
    },
    // 上传成功的回调
    handleUploadSuccess(response, attributeName) {
      if (response.code === 200) {
        this.$set(this.authForm, attributeName, response.data.url);
      } else {
      }
    },
    // 表单提交
    eventSubmit() {
      this.$refs.authForm.validate((valid) => {
        if (valid) {
          let {
            headImage,
            //iphone,
            phone,
            password,
            name,
            idCard,
            sex,
            email,
            qqNumber,
            forte,
            corporation,
            companyAddress,
            creditCode,
            licenseAddress,
            businessTerm,
            contacts,
            enterprisePhone,
            bankCard,
            openingBank,
            bankHolders,
            idcardFront,
            idcardReversed,
            businessIicense,
          } = this.authForm;
          if (this.type === "person") {
            let queryData = {
              headImage,
              // iphone,
              phone,
              password,
              name,
              idCard,
              sex,
              email,
              qqNumber,
              forte: forte.join(","),
              bankCard,
              openingBank,
              bankHolders,
              idcardFront,
              idcardReversed,
              userType: 1,
            };
            epibolyApplyPeople({ ...queryData }).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  offset: 80,
                  message: "提交成功，请耐心等待审核",
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else if (this.type === "company") {
            let queryData = {
              headImage,
              phone,
              password,
              name,
              corporation,
              companyAddress,
              creditCode,
              licenseAddress,
              businessTerm,
              contacts,
              enterprisePhone,
              email,
              bankCard,
              openingBank,
              bankHolders,
              businessIicense,
              userType: 2,
            };
            epibolyApplyCorporation({ ...queryData }).then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  offset: 80,
                  message: "提交成功，请耐心等待审核",
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.attestation-container {
  width: 1260px;
  margin: auto;
  padding: 50px 0;
  div[class^="attestation-"] {
    padding: 30px 50px;
    &:not(:first-child) {
      background-color: #ffffff;
      margin-top: 30px;
    }
  }
  .info-title {
    position: relative;
    text-indent: 20px;
    font-size: 20px;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: var(--default-ectocyst-color);
    }
  }

  /deep/.el-form-item__label {
    font-size: 16px;
    font-weight: bold;
  }

  .attestation-top {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    h1 {
    }
    > p {
      color: #5172fe;
      cursor: pointer;
      > i {
        transform: rotate(90deg);
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
  .attestation-describe {
    margin-top: 10px;
    font-size: 14px;
    color: #e43d30;
  }
  .attestation-basic-info {
    .info-header-img {
      //background-color: transparent;
      /deep/.el-upload,
      /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 120px;
        height: 120px;
        line-height: 128px;
        border-radius: 50%;
        overflow: hidden;
      }

      // /deep/.upload-text-tips{
      //   width: 100%;
      //   height: 100%;
      //   border-radius: 50%;
      //   margin: 0;
      //   background-color: #fbfdff;
      //   border: 0.0625rem dashed #c0ccda;
      // }
    }

    .upload-img {
      width: 100%;
      height: 100%;
    }

    .info-id-card {
      /deep/.el-upload,
      /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 300px;
        height: 300px;
        line-height: 300px;
      }
    }

    .info-describe-checkbox {
      /deep/.el-checkbox__inner {
        width: 16px;
        height: 16px;
      }
      /deep/.el-checkbox__label {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .form-submit {
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    .form-submit-btn {
      background-color: var(--default-ectocyst-color);
      border-color: var(--default-ectocyst-color);
      color: #ffffff;
      height: 50px;
    }
  }
}
</style>