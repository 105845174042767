<template>
    <div>
        <div class="recruitment-container">
            <router-link tag="a" class="invite-content" v-for="(item,index) in recruitList" :key="index" :to="`/home/recruitDetail?id=${item.id}&activeIndex=1&companyIndex=5`">
                <div class="invite-header">
                    <div class="invite-tag">
                        <div class="urgent" v-if="item.urgentFlag!==0">急</div>
                        <div class="hoticon" v-if="item.hotFlag!==0">
                            <img src="@/assets/img/bbs/file.png" alt="">
                            <div class="lines-hot"></div>
                        </div>
                        <div class="tag-title" :title="item.position">{{item.position}}</div>
                        <div class="note">
                            {{item.employCity}} | 经验 {{workExperiences[item.workExp]}} | {{item.eduBg}}
                        </div>
                    </div>
                    <div class="salary">{{item.emolument}}</div>
                </div>
                <div class="invite-duty">
                    <div style="width:124px;">福利关键字：</div>
                    <div class="duty-content" v-for="(key, indexs) in item.postWelFares" :key="indexs">{{key}}</div>
                </div>
                <!-- <div class="invite-duty">
                    <div style="width:124px;">工作职责：</div>
                    <div class="duty-content">{{item.postIntro ||''}}</div>
                </div> -->
                <!-- <div class="profession">{{item.postType}}</div> -->
                <div class="lines"></div>
                <div class="invite-footer">
                    <div class="firm-icons" v-if="item.sysUser">
                        <img class="imgs" :src="item.sysUser.avatar" >
                        <div class="firm-content">
                            <div class="firm-title">
                                {{item.sysUser.nickName}}
                            </div>
                            <div class="firm-tags">
                                互联网
                            </div>
                        </div>
                    </div>
                    <div class="invite-times">{{ item.createTime | Timeago }}</div>
                </div>
            </router-link>
        </div>
        <div class="paginations">
             <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pageSize"
				prev-text="上一页"
				next-text="下一页"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>
    
</template>

<script>
    import {gettalentRecruit} from '@/api/company.js';
    export default {
        data(){
            return{
                currentPage: 1,
                pageSize: 8,
                recruitList:'',
                total:0,
                workExperiences: [
                    "一年以内","1~3年","3~5年","5~10年","10年以上"
                ],
            }
        },
        created(){
            this.getRecruit()
        },
        methods:{
            handleSizeChange(val) {
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getRecruit();
            },
            async getRecruit(){
                let formData = {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                }
                let {code,rows,total} = await gettalentRecruit(formData);
                if(code===200){
                    this.total = total;
                    this.recruitList = rows;
                }
            },
            handleClick(item, index){
                const params = {id: item.id};
                let routeData =this.$router.resolve({
                    path:`/home/recruitDetail/${item.id}`,
                    query: params,
                })
                window.open(routeData.location.path, 's');
            }
        }
    }
</script>

<style lang="scss" scoped>
.recruitment-container{
    width:1400px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto;
    margin-top: 80px;
    box-sizing: border-box;
    border:0;
}
.invite-content{
    width:680px;
    height:280px;
    border-radius: 10px;
    padding: 0px 44px;
    overflow: hidden;
    background: #0B1328;
    // background: #2D2E2C;
    box-shadow: 0px 5px 10px rgba(17, 17, 17, 0.35);
    margin-right:40px;
    margin-top:40px;
    box-sizing: border-box;
    // border:1px solid rgba(0, 0, 0, 1);
    &:nth-child(2n){
        margin-right:0px;
    }
    &:nth-child(1){
        margin-top:0px;
    }
    &:nth-child(2){
        margin-top:0px;
    }
    &:hover{
        // box-shadow: 0px 0px 5px 1px (rgba(243, 243, 243, 0) 0%, #985ff5 25%, #C2CEE4 50%, #8569f5 75%, rgba(104, 172, 25, 0) 100%);
        box-shadow: 0px 0px 4px 1px #4144AB;
        // background-image: url('../../assets/newImg/13.png');
        // background-size: 99.5% 101%;
    }
    .invite-header{
        height:35px;
        display: flex;
        justify-content: space-between;
        margin-top:34px;
        .invite-tag{
            display: flex;
            align-items: center;
            .urgent{
                width: 30px;
                height: 30px;
                background: rgba(255, 85, 66, 0.36);
                border-radius: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                text-align: center;
                color: #FF5542;
                text-shadow: 0px 1px 1px rgba(28, 3, 0, 0.25);
                opacity: 1;
            }
            .hoticon{
                height:18px;
                margin-left:10px;
                margin-right:10px;
                img{
                    width:15px;
                    height:16px;
                    object-fit: cover;
                }
                .lines-hot{
                    width: 15px;
                    height: 1px;
                    background: #EC4D36;
                    opacity: 1;
                }
            }
            .tag-title{
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 35px;
                color: #FFFFFF;
                opacity: 1;
                max-width: 300px;
                overflow: hidden; /*文本超出隐藏*/
                text-overflow: ellipsis; /*文本超出显示省略号*/
                white-space: nowrap; /*超出的空白区域不换行*/
            }
            .note{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 300;
                line-height: 0px;
                color: #999999;
                opacity: 1;
                margin-left:20px;
            }
        }
        .salary{
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #F6A812;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
            opacity: 1;
        }
    }
    .invite-duty{
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #FFFFFF;
        opacity: 0.7;
        margin-top:21px;
        min-height: 40px;
        height: 76px;
        .duty-content{
            // border: 2px solid #5ED5C8;
            height: 28px;
            background-image: url('../../assets/newImg/27.png');
            background-size: 100% 100%;
            padding: 2px 10px;
            color: #ffffff;
            // color: #5ED5C8;
            margin: 5px 5px;
            // overflow: hidden; /*文本超出隐藏*/
            // text-overflow: ellipsis; /*文本超出显示省略号*/
            // white-space: nowrap; /*超出的空白区域不换行*/
        }
    }
    .profession{
        height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #FFFFFF;
        opacity: 0.35;
        margin-top:10px;
    }
    .lines{
        height: 2px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0) 0%, #985ff5 25%, #C2CEE4 50%, #8569f5 75%, rgba(104, 172, 25, 0) 100%);;
        opacity: 0.25;
        border-radius: 0px;
        margin-top:10px;
    }
    .invite-footer{
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        margin-top:20px;
        .firm-icons{
            display: flex;
            align-items: center;
            
            .imgs{
                width:55px;
                height:55px;
                border-radius: 5px;
                object-fit: cover;
            }
            .firm-content{
                margin-left:13px;
                margin-top:2px;
                .firm-title{
                    height:29px;
                    max-width:90px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 29px;
                    color: #FFFFFF;
                    overflow: hidden; /*文本超出隐藏*/
                    text-overflow: ellipsis; /*文本超出显示省略号*/
                    white-space: nowrap;
                }
                .firm-tags{
                    max-width:90px;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 24px;
                    color: rgba(255, 255, 255, 0.35);
                    overflow: hidden; /*文本超出隐藏*/
                    text-overflow: ellipsis; /*文本超出显示省略号*/
                    white-space: nowrap;
                }
            }
        }
        .invite-times{
            height: 20px;
            font-weight: 400;
            text-align: bottom;
            color: rgba(255, 255, 255, 0.63);
            margin-top: 35px;
        }
    }
}
.paginations {

				margin-top: 80px;
				margin-bottom: 100px;

				.el-pagination {
					text-align: center;
				}

				::v-deep .el-pagination .btn-prev {
					// background: #353849;
					width: 65px;
					height: 35px;
					background: #313345;
					color: #FFFFFF;
					padding: 0px;

				}

				::v-deep .el-pager li {
					background: #181A29;
					font-size: 16px;
					font-family: Arial;
					font-weight: 400;
					color: #ffffff;
					background-color: #313345;
					margin-left: 10px;
					min-width: 35px;
					height: 35px;
					line-height: 35px;
					box-sizing: border-box;
					text-align: center;
				}

				::v-deep .el-pagination .btn-next {
					width: 65px;
					margin-left: 10px;
					height: 35px;
					background: #313345;
					color: #FFFFFF;
					padding: 0;
					// background: #353849;
				}

				::v-deep .el-pager li.active {
					color: white !important;
					// background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
					background: #00b4d4;
				}

				::v-deep .el-pagination__jump {
					display: inline-block;
					font-size: 16px;
					min-width: 35.5px;
					height: 35px;
					padding: 0 10px;
					line-height: 35px;
					vertical-align: top;
					box-sizing: border-box;
					border: 1px solid #414141;
					color: #ffffff;
					background-color: #313345;
				}

				::v-deep .el-input {
					width: 70px;
					height: 33px;
				}

				::v-deep .el-pagination__editor.el-input .el-input__inner {
					height: 24px;
					top: -2px;
					background: #001220 !important;
					color: white;
					border: none;
				}

				::v-deep .el-pagination__editor {
					margin: 0 10px;
				}
			}
</style>