<template>
  <div class="comment-container">
    <div
      class="comment-main"
      :style="{ height: showTextarea ? 635 + 'px' : 745 + 'px' }"
    >
      <div class="comment-left">
        <comment-header :userInfo="userInfo"></comment-header>
      </div>
      <!-- <div class="comment-right">
        <comment-item
          :commentList="hotList"
          :workDetailList="workDetailList"
          @handleAddComment="addComment"
          @handleLiveLike="getCommentNums"
          title="热评"
          nothingText="暂时没有热评哦~~抢占第一个热评吧！"
        >
          <img src="@/assets/newImg/44.png" slot="icon" />
        </comment-item>
        <comment-item
          :commentList="allList"
          :workDetailList="workDetailList"
          title="全部评论"
          @handleAddComment="addComment"
          nothingText="暂时没有评论哦~~留下第一发评论吧！"
        >
          <img src="@/assets/newImg/45.png" slot="icon" />
        </comment-item>
        <div v-show="isshowinp" class="input-content2" id="input">
          <el-input v-model="comments2" :placeholder="placeholder"> </el-input>
          <div class="insertComment" @click="getInsertComment(2, 'comments2')">
            发送
          </div>
        </div>
        <div class="input-comment">
          <div
            class="input-release"
            v-show="!showTextarea"
            @click.stop="showTextarea = true"
          >
            <img src="@/assets/newImg/46.png" alt="" srcset="" />发表评论
          </div>
          <div class="input-textarea" v-show="showTextarea" id="textarea">
            <el-input
              type="textarea"
              placeholder="跟他聊聊~"
              v-model="comments"
              maxlength="30"
              show-word-limit
            >
            </el-input>
            <div class="input-release" @click="getInsertComment(1, 'comments')">
              <i class="el-icon-s-promotion"></i>发送
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import commentHeader from "./commentHeader";
import commentItem from "./commentItem";
import {
  commentList,
  getWorksDetail,
  insertComment,
  getLiveLike,
  calcelLiveLike,
} from "@/api/work.js";
export default {
  data() {
    return {
      // 热评
      hotList: [],
      // 全部评论
      allList: [],
      workDetailList: undefined,
      showTextarea: false,
      comments: undefined,
      isshowinp: false,
      comments2: undefined,
      placeholder: undefined,
      parent_id: undefined,
      parentNickname: undefined,
      tuUserId: undefined,
      toContent: undefined,
    };
  },
  props: {
    commentQuery: {
      type: Object,
      require: true,
    },
    userInfo: {
      type: Object,
      require: true,
    },
  },
  components: {
    commentHeader,
    commentItem,
  },
  created() {
    //this.getWorkDetail();
    this.workDetailList = { userInfo: { ...this.userInfo } };
  },
  mounted() {
    document.addEventListener("click", (e) => {
      const usercon = document.getElementById("input");
      if (
        usercon &&
        !usercon.contains(e.target) &&
        e.target.className !== "addComment"
      ) {
        this.comments2 = "";
        this.isshowinp = false;
      }
      const usercon2 = document.getElementById("textarea");
      if (
        usercon2 &&
        !usercon2.contains(e.target) &&
        e.target.className !== "sdsm"
      ) {
        this.comments = "";
        this.showTextarea = false;
      }
    });
  },
  methods: {
    // 获取评论列表
    async getCommentList(queryData) {
      console.log(queryData);
      let { code, data } = await commentList(queryData);
      if (code === 200) {
        this.hotList = data.hotComment ? [data.hotComment] : [];
        this.allList = data.commentList;
      }
    },
    // 获取详情信息 测试
    // async getWorkDetail() {
    //   let { code, data } = await getWorksDetail(478);
    //   if (code === 200) {
    //     this.workDetailList = data;
    //   }
    // },
    // 添加评论
    addComment({ nickname, id, tuUserId, toContent, e }) {
      this.showTextarea = false;
      this.isshowinp = true;
      this.comments2 = "";
      const input = document.getElementById("input");
      console.log(e);
      e.target.parentNode.appendChild(input);
      this.placeholder = "@" + nickname;
      this.parent_id = id;
      this.parentNickname = nickname;
      this.tuUserId = tuUserId;
      this.toContent = toContent;
    },
    // 评论
    async getInsertComment(type, contentName) {
      if (!this[contentName]) {
        this.$message.warning("评论内容不能为空！");
        return;
      }
      if (this[contentName].length > 30) {
        this.$message.warning("评论内容长度不能超过30个字符！");
        return;
      }
      let queryData = {
        modelId: this.commentQuery.modelId,
        userId: this.$store.state.userInfo.id,
        content: this[contentName],
        flag: 2,
      };
      if (type === 2) {
        queryData = {
          ...queryData,
          parentId: this.parent_id,
          parentNickname: this.parentNickname,
          tuUserId: this.tuUserId,
          toContent: this.toContent,
          headImg: this.$store.state.userInfo.headImg,
        };
      }
      let { code, msg } = await insertComment(queryData);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: msg,
        });
        this[contentName] = "";
        this.isshowinp = false;
        this.showTextarea = false;
        this.getCommentList(this.commentQuery);
      }
    },
    getCommentNums(ket) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getCommentNums2(ket);
      }, 500);
    },
    // 评论点赞
    async getCommentNums2(ket) {
      let data = {
        userId: this.userInfo.id,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      let calcelData = {
        userId: this.$store.state.userInfo.id,
        modelType: 2,
        modelId: ket.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      if (ket.likeFlag == 0) {
        let { code, msg } = await getLiveLike(data);
        if (code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "点赞成功",
          });
          this.getCommentList(this.commentQuery);
        }
      } else {
        this.calcelLikeNum(calcelData);
      }
    },
    async calcelLikeNum(data) {
      data.del = 1;
      let { code } = await calcelLiveLike(data);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "取消点赞成功",
        });
        this.getCommentList(this.commentQuery);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-container {
  width: 100%;
  height: 100%;
  position: relative;

  .comment-main {
    display: flex;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .comment-left,
  .comment-right {
    width: 100%;
    height: 400px;
    position: relative;
  }

  .input-comment {
    position: absolute;
    width: 100%;
    // background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
    bottom: 0px;
    left: 0;
    // transform: translateX(-50%);
    z-index: 999;
    border-radius: 10px;

    .input-release {
      width: 90%;
      margin: 0 auto 10px;
      height: 45px;
      background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
      line-height: 45px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 4px;
      cursor: pointer;
      border-radius: 10px;
      img {
        width: 25px;
        height: 25px;
        margin-right: 4px;
      }
      .el-icon-s-promotion {
        margin-bottom: 4px;
        margin-right: 4px;
      }
    }

    .input-textarea {
      width: 100%;
      height: 160px;

      textarea {
        width: 100%;
        height: calc(100% - 55px);
        // background-color: #234767;
        padding: 8px;
        border-color: #9ffff7;
        box-sizing: border-box;
        color: #ffffff;
        font-size: 14px;
      }

      .input-release {
        width: 100%;
        border-radius: 0;
      }

      .el-textarea {
        height: calc(100% - 50px);
        margin-bottom: 2px;
        box-sizing: border-box;

        /deep/.el-textarea__inner {
          height: 100%;
          outline: none;
          border: none;
          // background-color: #234767;
          background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
          color: #ffffff;
        }

        /deep/.el-input__count {
          background-color: transparent;
        }
      }
    }
  }

  .input-content2 {
    width: 100%;
    position: relative;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;

    /deep/.el-input__inner {
      width: 100%;
      height: 60px;
      background-color: #3b4252;
      border: none;
      border-radius: 12px;
      color: #ffffff;
    }

    .insertComment {
      width: 45px;
      height: 18px;
      position: absolute;
      right: 0;
      bottom: 0;
      //background-color: #2ca2e4;
      background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
      color: #ffffff;
      font-size: 12px;
      line-height: 18px;
      text-indent: 5px;
      text-align: center;
      letter-spacing: 3px;
      cursor: pointer;
      border-radius: 12px 0px 12px 0px;
    }
  }
}
</style>