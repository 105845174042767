<template>
  <div class="detail-container">
    <div class="detail-header">
      <el-button type="text" icon="el-icon-arrow-left" @click="eventBack"
        >返回列表页</el-button
      >
    </div>
    <div class="detail-content">
      <el-form>
        <!-- 接包人信息 -->
        <div class="attestation-basic-info">
          <p class="info-title">接包人信息</p>
          <el-form-item :label="type === 'person' ? '头像' : '企业logo'">
            <br />
            <el-image :src="content.headImage" style="width: 120px; height: 120px; border-radius: 50%;"></el-image>
          </el-form-item>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="账号(手机号)">
                <br />
                <el-input
                  v-model="content.phone"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称">
                <br />
                <el-input
                  v-model="content.name"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式">
                <br />
                <el-input
                  v-model="content.phone"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 任务 -->
        <div class="attestation-basic-info">
          <p class="info-title">任务</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="任务ID">
                <br />
                <el-input
                  v-model="content.eid"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务名称">
                <br />
                <el-input
                  v-model="content.title"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务类型">
                <br />
                <el-input
                  v-model="content.classifyName"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="截止时间">
                <br />
                <el-input
                  v-model="content.deadlineTime"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="任务详情">
                <br />
                <el-input
                  v-model="input"
                  type="textarea"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预算范围">
                <br />
                <el-input
                  v-model="content.money"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
        </div>
        <!-- 测试 -->
        <div class="attestation-basic-info">
          <p class="info-title">测试</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="测试类型">
                <br />
                <el-input
                  v-model="testTypeName"
                  placeholder="请输入任务ID"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="测试金额">
                <br />
                <el-input
                  v-model="content.testMoney"
                  placeholder="请输入任务名称"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="测试详情">
                <br />
                <el-input
                  v-model="content.testInfo"
                  type="textarea"
                  placeholder="请输入测试详情"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="测试附件">
                <br />
                <el-input
                  v-model="content.isAppendix"
                  placeholder="请输入任务名称"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 录屏 -->
        <div class="attestation-basic-info">
          <p class="info-title">录屏信息</p>
          <el-row :gutter="86">
            <el-col :span="24">
              <el-form-item label="录屏">
                <br />
                <video
                  controlslist="nodownload"
                  controls="controls"
                  class="info-video-item"
                >
                  <source
                    :src="content.testVideo"
                  />
                  您的浏览器不支持 video 标签。
                </video>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提交时间">
                <br />
                <el-input
                  v-model="content.createTime"
                  placeholder="请输入提交时间"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">技术评论</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="评论内容">
                <br />
                <el-input
                  v-model="content.appraise"
                  type="textarea"
                  :autosize="{ minRows: 8 }"
                  placeholder="请输入评论内容"
                  readonly
                  v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business' || JSON.parse($cookie.get('userInfo')).permission === 'T-All'"
                ></el-input>
                <el-input
                  v-model="appraise"
                  type="textarea"
                  :autosize="{ minRows: 8 }"
                  placeholder="请输入评论内容"
                  v-else
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 价格信息 -->
        <div
          class="attestation-basic-info"
          style="border-top: 1px solid #eeeeee; padding-top: 20px"
          v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business'"
        >
          <p class="info-title">价格信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="制作总人天" required>
                <br />
                <el-input
                  v-model="authForm.flnalDay"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  placeholder="请输入制作总人天"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="人天单价" required>
                <br />
                <el-input
                  v-model="authForm.finalOffer"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  placeholder="请输入人天单价"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="制作总价" required>
                <br />
                <el-input
                  v-model="input"
                  placeholder="请输入制作总价"
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
        </div>
        <!-- 审核按钮 -->
        <div
          class="attestation-basic-info"
          style="border-top: 1px solid #eeeeee; padding-top: 20px"
          v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business'"
        >
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="审核结果" required>
                <br />
                <el-radio-group v-model="authForm.flag" size="small">
                  <el-radio :label="1" border>审核通过</el-radio>
                  <el-radio :label="2" border>审核未通过</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info" v-if="JSON.parse($cookie.get('userInfo')).permission !== 'T-All'">
          <div class="detail-btn-box">
            <el-button class="detail-btn-item" @click="eventSubmit">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getEpibolyAddTechnologyEvaluate, epibolyTestVerify } from '@/api/ectocyst.js'
export default {
  data() {
    return {
      type: "person",
      // 测试类型
      testTypeList: [
        {
          value: 2,
          label: "付费测试",
        },
        {
          value: 1,
          label: "免费测试",
        },
        {
          value: 0,
          label: "无需测试",
        },
        {
          value: 3,
          label: "一对一试做",
        },
      ],
      testTypeName: undefined,
      // 评价内容
      appraise: undefined,
      authForm: {
        flnalDay: undefined,
        finalOffer: undefined,
        flag: undefined
      }
    };
  },
  props: {
    content: {
      type: Object,
      require: true
    }
  },
  created() {
    this.testTypeName = this.getDictByVal(this.testTypeList, this.content.testType);
    this.content.isAppendix = this.content.isAppendix ? '有' : '无';
  },
  methods: {
    // 点击返回
    eventBack() {
      this.testTypeName = undefined;
      this.$emit('back');
    },
    // 根据
    getDictByVal(option, val) {
      return option.find((el) => el.value == val).label;
    },
    // 提交
    eventSubmit() {
      if (JSON.parse(this.$cookie.get('userInfo')).permission === 'T-business') {
        epibolyTestVerify({
          ...this.authForm,
          mid: this.content.mid
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.eventBack();
          }
        })
      } else {
        getEpibolyAddTechnologyEvaluate({
          appraise: this.appraise,
          cid: this.content.cid,
          mid: this.content.mid
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '评价成功',
              type: 'success'
            });
            this.eventBack();
          }
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-container {
  //width: 100%;

  //padding: 20px 50px;
  .detail-header {
    width: 100%;
    //border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    padding: 20px 50px 10px;
    background-color: #ffffff;
  }
  .detail-content {
    margin-top: 10px;
    padding: 10px 50px 20px;
    background-color: #ffffff;
    .attestation-basic-info {
      margin-top: 20px;
      .info-title {
        position: relative;
        text-indent: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: var(--default-ectocyst-color);
        }
      }

      .info-video-item {
        width: 50%;
      }

      .detail-btn-box {
        text-align: center;
        .detail-btn-item {
          background-color: var(--default-ectocyst-color);
          color: #ffffff;
          padding: 10px 60px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>