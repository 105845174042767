<template>
  <div class="forum-detail-container">
    <div class="forum-detail-bg">
      <p></p>
      <p></p>
      <p></p>
    </div>
    <div class="box-left-comment">
      <comment
        :commentQuery="{ modelId: workDetailInfo.id }"
        :userInfo="{
          id: workDetailInfo.userInfo.id,
          nickName: workDetailInfo.userInfo.nickName,
          headImg: workDetailInfo.userInfo.headImg,
          fouseFlag: workDetailInfo.userInfo.fouseFlag,
          collectFlag: workDetailInfo.collectFlag,
          focus: 0,
        }"
        ref="child"
      >
      </comment>
    </div>
    <div class="box-right-play">
      <div class="play-info-box">
        <div class="play-info-like">
          <div class="like-box" @click="getLikeNum">
            <el-image
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/like_none.png"
              class="el-image"
              v-show="workDetailInfo.likeFlag == 0"
            />
            <el-image
              src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/like_show.png"
              class="el-image"
              v-show="workDetailInfo.likeFlag != 0"
            />
          </div>
        </div>
        <div class="play-info-time">
          发布于
          {{ workDetailInfo.createTime | dateformat("YYYY/MM/DD HH:mm") }}
        </div>
        <div class="play-info-video">
          <video
            controlslist="nodownload"
            autoplay="autoplay"
            controls="controls"
            class="video-item"
            v-if="activeWorkObj && activeWorkObj.type === 'video'"
            :key="videoKey"
          >
            <source :src="activeWorkObj && activeWorkObj.src" />
            您的浏览器不支持 video 标签。
          </video>
          <el-image
            class="work_img"
            :src="activeWorkObj && activeWorkObj.src"
            fit="contain"
            :preview-src-list="[activeWorkObj.src]"
            v-else
          >
          </el-image>
        </div>
        <div class="play-info-more">
          <div class="prev-btn" @click="getprev">
            <i class="el-icon-caret-left"></i>
          </div>
          <div class="moreImg">
            <div class="workImgs">
              <div
                class="workImg"
                v-for="(item, index) in workVideoImgList"
                :key="index"
                @click="showWorkItem(index)"
              >
                <img :src="item.cover" />
                <div class="thisTag" v-show="activeWorkIndex === index"></div>
              </div>
            </div>
          </div>
          <div class="next-btn" @click="getnext">
            <i class="el-icon-caret-right"></i>
          </div>
        </div>
        <div class="play-info-other">
          <p class="other-title">{{ workDetailInfo.title }}</p>
          <div class="other-info">
            <p class="other-tags" v-html="workclass"></p>
            <ul>
              <li>
                <el-image
                  src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/37.png"
                  title="浏览数"
                  style="width: 30px; height: 26px"
                >
                </el-image
                ><span>{{ workDetailInfo.browseNumber }}</span>
              </li>
              <li>
                <!-- <el-image
                  src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/38.png"
                  title="评论数"
                  style="width: 30px; height: 26px"
                >
                </el-image
                > -->
                <span>{{ workDetailInfo.commentNum }}</span>
              </li>
              <li>
                <el-image
                  src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/39.png"
                  title="关注数"
                  style="width: 30px; height: 26px"
                >
                </el-image
                ><span>{{ workDetailInfo.collectNum }}</span>
              </li>
              <li>
                <el-image
                  src="https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/40.png"
                  title="点赞数"
                  style="width: 30px; height: 26px"
                >
                </el-image
                ><span>{{ workDetailInfo.likeNum }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import comment from "./components/comment.vue";
import { getWorksDetail, getLiveLike, calcelLiveLike } from "@/api/work.js";
export default {
  data() {
    return {
      workDetailInfo: {},
      activeWorkObj: {},
      workVideoImgList: [],
      skillText: undefined,
      activeWorkIndex: 0,
      videoKey: Math.random(),
      translatax: 0,
      workclass: undefined,
    };
  },
  created() {
    if (this.$route.query.workDetailId) {
      this.getWorkDetail(this.$route.query.workDetailId);
    }
  },
  methods: {
    async getWorkDetail(id) {
      let { code, data } = await getWorksDetail(id);
      if (code === 200) {
        this.workDetailInfo = data;
        this.workclass =
          data.classifyList &&
          data.classifyList
            .map((v) => v.classify_name)
            .join("<i class='el-icon-arrow-right'></i>");
        if (
          this.workDetailInfo.classifyList &&
          this.workDetailInfo.classifyList[0].id === 41
        ) {
          this.skillText = this.escapeStringHTML(
            JSON.parse(this.workDetailInfo.description)
          );
        } else {
          this.handleVideoImg(data.videoUrl, data.files);
        }
        this.$nextTick(() => {
          this.$refs.child.getCommentList({ modelId: this.workDetailInfo.id });
        });
      }
    },
    // 把实体格式字符串转义成HTML格式的字符串
    escapeStringHTML(str) {
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      return str;
    },
    // 处理视频和图片的方法
    handleVideoImg(videoList, imgList) {
      this.workVideoImgList = [];
      videoList &&
        videoList.forEach((item) => {
          this.workVideoImgList.push({
            type: "video",
            src: item,
            cover: this.getVideoCover(item),
          });
        });
      imgList &&
        imgList.forEach((item) => {
          this.workVideoImgList.push({
            type: "img",
            src: item.url,
            cover: item.url,
          });
        });
      this.activeWorkObj = this.workVideoImgList[0];
    },
    getVideoCover(fileSrc) {
      let lastIndex_start = fileSrc.lastIndexOf("/");
      let name = fileSrc.substring(lastIndex_start + 1);
      let file_prefix = fileSrc.substring(
        0,
        fileSrc.lastIndexOf("/", fileSrc.lastIndexOf("/") - 1) + 1
      );
      return file_prefix + "images/" + name + "_0.jpg";
    },
    // 更换作品查看
    showWorkItem(index) {
      console.log(index);
      if (index >= this.workVideoImgList.length) return;
      this.activeWorkIndex = index;
      this.activeWorkObj = this.workVideoImgList[index];
      this.videoKey = Math.random();
    },
    getprev() {
      let moreimg = document.querySelector(".workImgs");
      let max = this.workVideoImgList.length * 156 - moreimg.offsetWidth;
      if (this.translatax < 0) {
        this.translatax += 156;
      }
      moreimg.style.transform = "translateX(" + this.translatax + "px)";
    },
    getnext() {
      let moreimg = document.querySelector(".workImgs");
      let max = this.workVideoImgList.length * 156 - moreimg.offsetWidth;
      if (this.translatax > -max) {
        this.translatax -= 156;
      }
      moreimg.style.transform = "translateX(" + this.translatax + "px)";
    },
    // 点赞和取消点赞
    async getLikeNum() {
      let data = {
        userId: this.opusUserId,
        modelType: 1,
        modelId: this.workDetailInfo.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      let cancleData = {
        userId: this.$store.state.userInfo.id,
        modelType: 1,
        modelId: this.workDetailInfo.id,
        headImg: this.$store.state.userInfo.headImg,
      };
      if (this.workDetailInfo.likeFlag == 0) {
        let { code, msg } = await getLiveLike(data);
        if (code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "点赞成功",
          });
          this.getWorkDetail(this.$route.query.workDetailId);
        }
      } else {
        this.calcelLikeNum(cancleData);
      }
    },
    async calcelLikeNum(data) {
      data.del = 1;
      let { code } = await calcelLiveLike(data);
      if (code == 200) {
        this.$message({
          type: "success",
          offset: 80,
          message: "取消点赞成功",
        });
        if (data.modelType == 1) {
          this.getWorkDetail(this.$route.query.workDetailId);
        } else {
          this.$refs.child.getCommentList({ modelId: this.workDetailInfo.id });
        }
      }
    },
  },
  components: {
    comment,
  },
};
</script>

<style lang="scss" scoped>
.forum-detail-container {
  width: 100%;
  height: calc(100vh - 60px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
  position: relative;
  .forum-detail-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 540px;
    height: 584px;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg_qiu.png");
    background-size: 100% 100%;
    > p {
      background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/crile.png");
      width: 47px;
      height: 49px;
      background-size: 100% 100%;
      position: absolute;
    }
    > p:first-child {
      left: 374.5px;
      top: 85px;
    }
    > p:nth-child(2) {
      left: 482px;
      top: 240px;
    }
    > p:nth-child(3) {
      left: 508px;
      top: 425px;
    }
  }

  .box-left-comment {
    position: fixed;
    left: 0;
    top: 80px;
    width: 400px;
    height: calc(100vh - 120px);
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/border.png");
    background-repeat: no-repeat;
    background-size: 410px 100%;
    background-position-x: -10px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }
  .box-right-play {
    float: right;
    width: calc(100% - 450px);
    max-height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    .play-info-box {
      margin-top: 50px;
      color: #ffffff;
      width: 80%;
      position: relative;
      .play-info-like {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        .like-box {
          position: relative;
          &::before,
          &:after {
            content: "";
            position: absolute;
            width: 16vw;
            height: 3px;
            background-image: linear-gradient(to right, transparent, #5f4eb2);
            right: 35px;
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            background-image: linear-gradient(to left, transparent, #5f4eb2);
            left: 35px;
          }
          .el-image {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
      .play-info-time {
        text-align: right;
        line-height: 40px;
        letter-spacing: 2px;
      }
      .play-info-video {
        max-width: 100%;
        height: 550px;
        background-color: #000000;
        display: flex;
        justify-content: center;
        .video-item {
          max-width: 100%;
          height: 100%;
        }
      }
      .play-info-more {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        background-color: #040608;
        border-top: 1px solid #364155;
        .prev-btn {
          background-color: #243166;
          color: #2dffff;
          font-size: 26px;
          height: 96px;
          display: flex;
          margin-left: 10px;
          align-items: center;
        }
        .next-btn {
          background-color: #243166;
          color: #2dffff;
          font-size: 26px;
          height: 96px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        .moreImg {
          overflow-x: hidden;
          overflow-y: hidden;
          width: 100%;

          .workImgs {
            display: flex;
            flex-direction: row;
            height: 96px;
            transition: all 0.3s linear;

            .workImg {
              position: relative;
              cursor: pointer;
              margin-left: 10px;

              img {
                width: 146px;
                height: 96px;
              }

              .thisTag {
                position: absolute;
                width: 144px;
                height: 94px;
                top: 0;
                left: 0;
                text-align: center;
                border: 1px solid #00dfff;
                background-color: rgba(255, 255, 255, 0.3);
                font-size: 12px;
              }
            }
          }
        }
      }
      .play-info-other {
        width: 100%;
        .other-title {
          font-size: 32px;
          color: #ffffff;
          font-family: biaoti;
          letter-spacing: 2px;
          line-height: 60px;
        }
        .other-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > ul {
            display: flex;
            align-items: center;
            > li {
              display: flex;
              align-items: center;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>