<template>
    <div class="sign-out-container">
        <el-dialog
            title="提示"
            :visible.sync="$store.state.showLoginDialog"
            width="300px"
            top="20vh"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="eventCancel"
            center>
            <p>登录后才能执行该操作哟！</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="eventCancel">取消</el-button>
                <el-button type="primary" @click="eventToLogin">去登录</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        centerDialogVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        // 取消
        eventCancel() {
            this.$store.state.showLoginDialog = false;
        },
        // 去登录
        eventToLogin() {
            this.$store.state.showLoginDialog = false;
            setTimeout(()=>{
                this.$router.push('/login')
            },500);
        }
    }
}
</script>

<style lang="scss" scoped>
    .sign-out-container{
        /deep/.el-dialog{
            background-color: #234767;
        }
        /deep/.el-dialog__title{
            font-family: 'biaoti';
            font-size: 26px;
            color: #FFFFFF;
            letter-spacing: 5px;
        }
        /deep/.el-dialog__close{
            color: #FFFFFF;
        }
        /deep/.el-dialog--center .el-dialog__body{
            text-align: center;
            color: #FFFFFF;
            font-size: 16px;
            letter-spacing: 2px;
        }
        /deep/.el-button{
            width: 85px;
            letter-spacing: 2px;
        }
        /deep/.el-button--primary{
            background-color: rgb(0, 212, 255);
            margin-left: 20px;
        }
    }

</style>