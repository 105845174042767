<template>
    <div class="file-upload-container">
        <div class="file-list-box">
            <div
                class="file-show-box"
                v-for="(item, index) in fileList_"
                :key="index"
            >
                <img :src="item.url" />
                <div class="file-show-btn">
                    <i
                        @click="changePrevImg(index)"
                        class="el-icon-back"
                        v-show="fileList_.length > 1"
                    ></i>
                    <i
                        @click="handlePictureCardPreview(item)"
                        class="el-icon-zoom-in"
                    ></i>
                    <i
                        @click="handleRemove(item.url, index)"
                        class="el-icon-delete"
                    ></i>
                    <i
                        @click="changeNextImg(index)"
                        class="el-icon-right"
                        v-show="fileList_.length > 1"
                    ></i>
                </div>
            </div>
            <el-upload
                v-show="!limit || fileList_.length < limit"
                :action="uploadUrl"
                :on-success="succesCoverPicture"
                :on-preview="handlePictureCardPreview"
                :before-upload="function(file){return beforeAvatarUpload(file);}"
                :show-file-list="false"
                :file-list="fileList_"
                :on-exceed="handleExceed"
                :limit="limit"
                :accept="accept"
                :multiple="multiple"
            >
                
                <div class="upload-text-tips">
                    <img src="@/assets/img/add.png" alt="" srcset="" />
                    <!-- <slot name="tips"></slot> -->
                    <p class="upload-tips-text" v-for="(item, index) in tipsList" :key="index">{{item}}</p>
                </div>
            </el-upload>
        </div>
        <el-image-viewer
            v-if="showViewer"
            :on-close="()=>{showViewer=false}"
            :url-list="dialogImageUrl" />
    </div>
</template>

<script>
import { compress, compressAccurately } from "image-conversion";
import { deleteUpload } from '@/api/work.js';
export default {
    data() {
        return {
            fileList_: [],
            dialogImageUrl: [],
            showViewer: false
        }
    },
    props: {
        fileList: {
            type: Array,
            default: []
        },
        fileType: {
            type: String,
            require: true
        },
        uploadUrl: {
            require: true
        },
        accept: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: false
        },
        // 最大允许上传个数
        limit: {
            type: Number
        },
        // 最大上传大小 单位:M
        maxSize: {
            type: Number
        },
        // 是否要压缩图片
        isCompress: {
            type: Boolean,
            default: false
        },
        // 压缩到多少M
        compressSize: {
            type: Number
        },
        // 是否是真的删除文件
        isRemove: {
            type: Boolean,
            default: true
        },
        tipsList: {
            type: Array,
            default: []
        }
    },
    components: {
        'el-image-viewer':()=>import('element-ui/packages/image/src/image-viewer')
    },
    watch: {
        fileList:{
            handler(v) {
                this.fileList_ = v;
            },
            immediate: true
        } 
    },
    methods: {
        // 点击删除
        async handleRemove(fileUrl, index) {
            if (this.isRemove) {
                let result = await deleteUpload({ fileName: fileUrl });
                if (result.code === 200) {
                    this.$message({
                        type: "success",
                        offset: 80,
                        message: "删除成功",
                    });
                    this.$delete(this.fileList_, index);
                    this.$emit('callbackFile', this.fileList_);
                }
                return;
            }
            this.$delete(this.fileList_, index);
            this.$emit('callbackFile', this.fileList_);
        },
        // 向左移
        changePrevImg(index) {
            if (index !== 0) {
                [this.fileList_[index], this.fileList_[index - 1]] = [
                    this.fileList_[index - 1],
                    this.fileList_[index],
                ];
            }
            this.fileList_ = this.fileList_.concat();
            this.$emit('callbackFile', this.fileList_);
        },
        // 向右移
        changeNextImg(index) {
            if (index !== this.fileList_.length - 1) {
                [this.fileList_[index], this.fileList_[index + 1]] = [
                    this.fileList_[index + 1],
                    this.fileList_[index],
                ];
            }
            this.fileList_ = this.fileList_.concat();
            this.$emit('callbackFile', this.fileList_);
        },
        // 点击预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = [];
            this.dialogImageUrl.push(file.url);
            this.showViewer = true;
        },
        // 检查上传的是否合格
        beforeAvatarUpload(file) {
            let isLtMax = true;
            if (this.maxSize) isLtMax = file.size / 1024 / 1024 < this.maxSize;
            if (!isLtMax) {
                this.$message.error("上传图片大小不能超过 " + this.maxSize + "MB!");
                return false;
            }
            if (this.fileType === 'image' && this.isCompress && this.compressSize && (file.size / 1024 / 1024 > this.compressSize)) {
                return new Promise((resolve) => {
                    compressAccurately(file, this.compressSize * 1024).then((res) => {
                        resolve(res);
                    });
                });
            }
            return true;
        },
        // 上传成功后的回调
        succesCoverPicture(response) {
            this.fileList_.push({
                url: response.data.url,
                fileName: response.data.fileName,
                fileType: 1,
            });
            this.$emit('callbackFile', this.fileList_);
        },
        handleExceed() {

        }
    }
}
</script>

<style lang="scss" scoped>
.file-upload-container{
    box-sizing: border-box;
    width: 100%;
    border-radius: 15px;
    background: rgba(195, 195, 195, 0.2);
    .file-list-box{
        display: flex;
        flex-wrap: wrap;
        .file-show-box{
            width: 200px !important;
            height: 200px !important;
            border-radius: 15px;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            overflow: hidden;
            background-color: #23272f;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            &:hover{
                .file-show-btn{
                    display: flex;
                }
            }
            .file-show-btn{
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                top: 50%;
                transform: translateY(-50%);
                justify-content: space-around;
                align-items: center;
                transition: all 0.2s linear;
                background-color: rgba($color: #000000, $alpha: 0.8);
                i {
                    cursor: pointer;
                    font-size: 25px;
                    transition: all 0.2s linear;
                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }
        }

        .upload-text-tips{
            width: 200px;
            height: 200px;
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
            border: 2px dashed #35edff;
            border-radius: 15px;
            background-color: #4b505e;

            img {
                margin: 30px auto;
            }
        }

        .upload-tips-text{
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
        }
    }
}
</style>