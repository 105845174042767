<template>
  <div class="list-container" v-if="!isShowDetail">
    <div class="list-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="任务类型" prop="cid">
              <el-select
                v-model="queryForm.cid"
                filterable
                clearable
                placeholder="项目类型"
                size="small"
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="测试类型" prop="tid">
              <el-select
                v-model="queryForm.tid"
                filterable
                clearable
                placeholder="测试类型"
                size="small"
              >
                <el-option
                  v-for="item in testOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发包时间">
              <el-date-picker
                v-model="taskTimeRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务名称" prop="title">
              <el-input
                placeholder="请输入任务名称"
                v-model="queryForm.title"
                size="small"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="header-bottom">
        <el-tabs v-model="tabsObject.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in tabsObject.list"
            :key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="list-right-list">
      <!-- <el-row>
        <el-col
          :span="4"
          :offset="index % 5 === 0 ? 0 : 1"
          v-for="(item, index) in 10"
          :key="index"
        >
          <item :content="testContent">
            <el-button class="list-btn">提交录屏</el-button>
          </item>
        </el-col>
      </el-row> -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="image" label="封面">
          <template slot-scope="scope">
            <img :src="scope.row.image" style="width: 100%" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="任务名称"> </el-table-column>
        <el-table-column prop="classifyName" label="任务类型(环节)">
        </el-table-column>
        <el-table-column prop="money" label="预算"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <p v-if="Number(scope.row.status) === 0">报名中</p>
            <p v-else-if="Number(scope.row.status) === 1">测试中</p>
            <p v-else-if="Number(scope.row.status) === 2">进行中</p>
            <p v-else-if="Number(scope.row.status) === 3">待结算</p>
            <p v-else-if="Number(scope.row.status) === 4">已结算</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" v-if="JSON.parse($cookie.get('userInfo')).permission !== 'T-All'">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="eventEdit(scope.row.eid)"
              v-if="Number(scope.row.status) === 0 || Number(scope.row.status) === 1"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="eventDel(scope.row.eid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>
  </div>
  <detail :detailContent="detailContent" @back="eventBack" v-else></detail>
</template>

<script>
import detail from "../detail/detail.vue";
import { parseTime } from "@/tools/timeCycle.js";
import { getEpibolyBusinessElist, deleteEpiboly, getEpibolySpecificsDetail } from "@/api/ectocyst.js";
import { getClassifyList } from "@/api/work.js";
export default {
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNum: 1,
        status: undefined,
        tid: undefined,
        cid: undefined,
        startTime: undefined,
        endTime: undefined,
        title: undefined,
      },
      taskTimeRange: undefined,
      tabsObject: {
        activeName: "all",
        list: [
          {
            label: "所有任务",
            name: "all",
          },
          {
            label: "报名中",
            name: "registering",
          },
          {
            label: "测试中",
            name: "testing",
          },
          {
            label: "制作中",
            name: "production",
          },
          {
            label: "待结算",
            name: "settled",
          },
          {
            label: "已结算",
            name: "completed",
          },
        ],
      },
      projectOptions: [],
      testOptions: [
        {
          value: 2,
          label: "付费测试",
        },
        {
          value: 1,
          label: "免费测试",
        },
        {
          value: 0,
          label: "无需测试",
        },
        {
          value: 3,
          label: "一对一试做",
        },
      ],
      tableData: [],
      total: 0,
      // 详情信息
      detailContent: undefined,
      isShowDetail: false
    };
  },
  watch: {
    taskTimeRange: {
      handler(v) {
        this.queryForm.startTime = v ? parseTime(v[0], '{y}-{m}-{d}') : undefined;
        this.queryForm.endTime = v ? parseTime(v[1], '{y}-{m}-{d}') : undefined;
      }
    }
  },
  components: {
    detail
  },
  created() {
    this.getTypeList();
    this.getList();
  },
  methods: {
    // 获取类型列表
    async getTypeList() {
      let state = {
        queryType: 6,
      };
      let { code, data } = await getClassifyList(state);
      if (code === 200) {
        this.projectOptions = data || [];
      }
    },
    // 调用列表接口
    async getList() {
      let { code, rows, total } = await getEpibolyBusinessElist(this.queryForm);
      if (code === 200) {
        this.tableData = rows;
        this.total = total;
      }
    },
    // 重置
    resetQuery() {
      this.taskTimeRange = undefined;
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      this.$refs['queryForm'].resetFields();
      this.getList();
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.getList();
    },
    // 修改页码
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getList();
    },
    handleClick() {
      if (this.tabsObject.activeName === "all") {
        this.queryForm.status = undefined;
      } else if (this.tabsObject.activeName === "registering") {
        this.queryForm.status = 0;
      } else if (this.tabsObject.activeName === "testing") {
        this.queryForm.status = 1;
      } else if (this.tabsObject.activeName === "production") {
        this.queryForm.status = 2;
      } else if (this.tabsObject.activeName === "settled") {
        this.queryForm.status = 3;
      } else if (this.tabsObject.activeName === "completed") {
        this.queryForm.status = 4;
      }
      this.getList();
    },
    // 详情返回
    eventBack() {
      this.isShowDetail = false
      this.detailContent = undefined;
      this.getList();
    },
    // 修改
    async eventEdit(eid) {
      let {code, data} = await getEpibolySpecificsDetail({eid});
      if(code === 200) {
        this.detailContent = data;
        this.isShowDetail = true;
      }
    },
    // 删除
    async eventDel(eid) {
      this.$confirm("是否确认删除该任务", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
        customClass: "ectocyst-message-box",
      })
        .then(() => {
          deleteEpiboly({eid: eid}).then(res => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                offset: 80,
                message: "删除成功",
              });
              this.getList();
            }
          })
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  .list-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      margin-top: 20px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .list-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  /deep/.el-pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>