<template>
  <div class="list-container">
    <div class="list-right-header">
      <div class="header-top">
        <div class="header-form">
          <el-form :inline="true" :model="queryForm" ref="queryForm">
            <el-form-item label="项目类型" prop="cid">
              <el-select
                v-model="queryForm.cid"
                filterable
                clearable
                placeholder="项目类型"
                size="small"
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="测试类型" prop="aid">
              <el-select
                v-model="queryForm.aid"
                filterable
                clearable
                placeholder="测试类型"
                size="small"
              >
                <el-option
                  v-for="item in testOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接包时间">
              <el-date-picker
                v-model="createTimeRange"
                type="daterange"
                value-format="yyyy-MM-dd hh:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="任务名称" prop="title">
              <el-input
                placeholder="请输入任务名称"
                v-model="queryForm.title"
                size="small"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getInit"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="header-bottom">
        <el-tabs v-model="tabsObject.activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.label"
            :name="item.name"
            v-for="item in tabsObject.list"
            :key="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="list-right-list">
      <!-- <el-row>
        <el-col
          :span="4"
          :offset="index % 5 === 0 ? 0 : 1"
          v-for="(item, index) in 10"
          :key="index"
        >
          <item :content="testContent">
            <el-button class="list-btn">提交录屏</el-button>
          </item>
        </el-col>
      </el-row> -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="cover" label="封面">
          <template slot-scope="scope">
            <img :src="scope.row.cover" style="width: 100%" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="任务名称"> </el-table-column>
        <el-table-column prop="classifyName" label="任务类型(环节)">
        </el-table-column>
        <el-table-column prop="money" label="预算"></el-table-column>
        <el-table-column prop="statusName" label="状态"> </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" v-if="tabsObject.activeName === 'production'">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-upload"
              @click="eventUploadVideo(scope.row.eid)"
              v-if="scope.row.scheduling"
              >提交录屏</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit-outline"
              @click="eventSetScheduling(scope.row.eid)"
              >{{ scope.row.scheduling ? "修改排期" : "填写排期" }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="sizes, total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-size="queryForm.pageSize"
      >
      </el-pagination>
    </div>

    <!-- 上传录屏 -->
    <el-dialog
      title="上传测试录屏"
      :visible.sync="uploadDialog"
      width="400px"
    >
      <el-upload
        class="upload-demo"
        drag
        :show-file-list="false"
        :action="uploadUrl"
        :on-success="uploadVideoSuccess"
        :before-upload="beforeUpload"
        accept=".mp4,.avi,.wmv"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>，最大100M
        </div>
      </el-upload>
    </el-dialog>

    <!-- 弹窗 -->
    <el-dialog title="排期" width="550px" :visible.sync="schedulingVisible">
      <el-form :model="schedulingForm" ref="schedulingForm" label-width="100px">
        <el-form-item label="计划开始时间" prop="expectedStartTime">
          <el-date-picker
            v-model="schedulingForm.expectedStartTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期时间"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="首次提交时间" prop="firstSubmissionTime">
          <el-date-picker
            v-model="schedulingForm.firstSubmissionTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            placeholder="首次提交时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="计划结束时间" prop="expectedEndTime">
          <el-date-picker
            v-model="schedulingForm.expectedEndTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            placeholder="计划结束时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="schedulingVisible = false">取 消</el-button>
        <el-button type="primary" @click="eventSubmitScheduling"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from "@/tools/timeCycle.js";
import {
  getMyEpibolyList,
  getMyEpibolyTestList,
  getEpibolyMaketime,
  getEpibolyAddCompletedVideo,
} from "@/api/ectocyst.js";
import { getClassifyList } from "@/api/work.js";
export default {
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNum: 1,
        status: undefined,
        cid: undefined,
        aid: undefined,
        startTime: undefined,
        endTime: undefined,
        title: undefined,
      },
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      uploadDialog: false,
      tabsObject: {
        activeName: "testing",
        list: [
          // {
          //   label: "所有任务",
          //   name: "all",
          // },
          // {
          //   label: "报名中",
          //   name: "registering",
          // },
          {
            label: "测试中",
            name: "testing",
          },
          {
            label: "测试未通过",
            name: "testout",
          },
          {
            label: "制作中",
            name: "production",
          },
          {
            label: "待结算",
            name: "settled",
          },
          {
            label: "已结算",
            name: "completed",
          },
        ],
      },
      projectOptions: [],
      testOptions: [
        {
          value: 2,
          label: "付费测试",
        },
        {
          value: 1,
          label: "免费测试",
        },
        {
          value: 0,
          label: "无需测试",
        },
        {
          value: 3,
          label: "一对一试做",
        },
      ],
      tableData: [],
      total: 0,
      // 时间区间
      createTimeRange: undefined,
      schedulingVisible: false,
      schedulingForm: {
        eid: undefined,
        expectedStartTime: undefined,
        firstSubmissionTime: undefined,
        expectedEndTime: undefined,
      },
      completedForm: {
        eid: undefined,
      },
    };
  },
  watch: {
    // 'tabsObject.activeName': {
    //   handler(v) {
    //     console.log(v);
    //     if (v === 'all') {
    //       this.queryForm.status = undefined;
    //     } else if (v === 'testing') {
    //       this.queryForm.status = 0;
    //       this.getTestList();
    //     } else if (v === 'production') {
    //       this.queryForm.status = 1;
    //       this.getTestList();
    //     } else if (v === 'testout') {
    //       this.queryForm.status = 2;
    //       this.getTestList();
    //     } else if (v === 'settled') {
    //       this.queryForm.status = 3;
    //       this.getList();
    //     } else if (v === 'completed') {
    //       this.queryForm.status = 4;
    //       this.getList();
    //     }
    //   },
    //   immediate: true
    // },
    createTimeRange: {
      handler(v) {
        this.queryForm.startTime = v
          ? parseTime(v[0], "{y}-{m}-{d}")
          : undefined;
        this.queryForm.endTime = v ? parseTime(v[1], "{y}-{m}-{d}") : undefined;
      },
    },
  },
  created() {
    this.getTypeList();
    this.getInit();
  },
  methods: {
    // 获取类型列表
    async getTypeList() {
      let state = {
        queryType: 6,
      };
      let { code, data } = await getClassifyList(state);
      if (code === 200) {
        this.projectOptions = data || [];
      }
    },
    getInit() {
      if (this.tabsObject.activeName === "testing") {
        this.queryForm.status = 0;
        this.getTestList();
      } else if (this.tabsObject.activeName === "production") {
        this.queryForm.status = undefined;
        this.getList();
      } else if (this.tabsObject.activeName === "testout") {
        this.queryForm.status = 2;
        this.getTestList();
      } else if (this.tabsObject.activeName === "settled") {
        this.queryForm.status = 3;
        this.getList();
      } else if (this.tabsObject.activeName === "completed") {
        this.queryForm.status = 4;
        this.getList();
      }
    },
    // 调用测试列表接口
    async getTestList() {
      let { code, rows, total } = await getMyEpibolyTestList(this.queryForm);
      if (code === 200) {
        this.tableData = rows;
        this.tableData.forEach((el) => {
          if (Number(el.status) === 0) {
            el.statusName = "待审核";
          } else if (Number(el.status) === 2) {
            el.statusName = "未通过";
          } else {
            el.statusName = "未知";
          }
        });
        this.total = total;
      }
    },
    // 调用列表接口
    async getList() {
      let { code, rows, total } = await getMyEpibolyList(this.queryForm);
      if (code === 200) {
        this.tableData = rows;
        this.tableData.forEach((el) => {
          el.cover = el.image;
          if (Number(el.status) === 0) {
            el.statusName = "报名中";
          } else if (Number(el.status) === 1) {
            el.statusName = "测试中";
          } else if (Number(el.status) === 2) {
            el.statusName = "制作中";
          } else if (Number(el.status) === 3) {
            el.statusName = "待结算";
          } else if (Number(el.status) === 4) {
            el.statusName = "已结算";
          }
        });
        this.total = total;
      }
    },
    // 修改页大小
    handleSizeChange(e) {
      this.queryForm.pageSize = e;
      this.getInit();
    },
    // 修改页码
    handleCurrentChange(e) {
      this.queryForm.pageNum = e;
      this.getInit();
    },
    // 切换tab栏
    handleClick() {
      this.getInit();
    },
    // 重置
    resetQuery() {
      this.createTimeRange = undefined;
      this.queryForm.startTime = undefined;
      this.queryForm.endTime = undefined;
      this.$refs["queryForm"].resetFields();
      this.getInit();
    },
    // 提交录屏
    eventUploadVideo(eid) {
      this.completedForm.eid = eid;
      this.uploadDialog = true;
    },
    // 上传录屏前调
    beforeUpload(file) {
      let isLtMax = file.size / 1024 / 1024 < 100;
      if (!isLtMax) {
        this.$message.error("上传的录屏大小不能超过 " + 100 + "MB!");
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: "正在上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    // 调用上传正式录屏接口
    async handleUpload(url) {
      let { code } = await getEpibolyAddCompletedVideo({
        eid: this.completedForm.eid,
        accomplishVedio: url,
      });
      if (code === 200) {
        this.loading.close();
        this.uploadDialog = false;
        this.$message({
          message: "提交成功,请等待审核",
          type: "success",
        });
        this.getInit();
      } else {
        this.loading.close();
      }
    },
    // 上传录屏成功
    uploadVideoSuccess(response) {
      if (response.code === 200) {
        this.handleUpload(response.data.url);
      }
    },
    // 填写排期
    eventSetScheduling(eid) {
      this.schedulingForm.eid = eid;
      this.schedulingVisible = true;
      this.$refs["schedulingForm"].resetFields();
    },
    async eventSubmitScheduling() {
      let { code } = await getEpibolyMaketime({
        ...this.schedulingForm,
      });
      if (code === 200) {
        this.$message({
          message: "提交成功",
          type: "success",
        });
        this.schedulingVisible = false;
        this.getInit();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  .list-right-header {
    width: 100%;
    //height: 120px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #ffffff;
    .header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .header-title {
        font-weight: bold;
      }
      .header-form {
        //margin-top: 15px;
        /deep/.el-form-item {
          margin-bottom: 0;
        }
      }
    }
    .header-bottom {
      margin-top: 20px;
      /deep/.el-tabs__header {
        margin-bottom: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
        background-color: #ffffff;
        bottom: -2px;
      }
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      /deep/.el-tabs__item:hover {
        color: var(--default-ectocyst-color);
      }
      /deep/.el-tabs__item.is-active {
        color: var(--default-ectocyst-color);
        font-weight: bold;
      }
      /deep/.el-tabs__active-bar {
        background-color: var(--default-ectocyst-color);
      }
    }
  }

  .list-right-list {
    margin-top: 10px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    // overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  /deep/.el-pagination {
    margin-top: 30px;
    text-align: right;
  }
}
</style>