<template>
  <div class="recruit-container">
    <div class="recruit-query-header">
      <div class="recruit-header-menu">
        <div><img src="@/assets/img/aboutUs/gg.png"> </div>
        <!-- <div> E:\glsx\code\glsx-qianduan\src\assets\img\aboutUs\guanggao.png
          <ul >src/assets/img/aboutUs/guanggao.png
             <li style="line-height: 30px; "><h3> 公司理念：</h3></li>
            <li  style="line-height: 30px; ">
               公司专注游戏方向的培养和学习，免费给每位真正想进入游戏行业的同学发展的平台，定期为人员免费提供专业技能和业务知识培训，提供更多的行业就业机会。
            </li>
            <li  style="line-height: 30px; ">
              <h3>特效班开展信息：</h3>
            </li>
            <li  style="line-height: 30px; ">
          计划3-4月开展一期20-30人规模的线下特效学习班，全程安排资深专业老师免费指导学习  
            </li>
            <li  style="line-height: 30px; ">
               <h3>学习条件：</h3>
            </li >

            <li  style="line-height: 30px; ">
              1、免费提供学习平台/场地，3个月内不带薪学习专业技能；
            </li>
              2、3个月后根据个人实际情况评估学习成果，达到考核标准的同学按照评估将给予1000元-3000元/月的补贴奖金（具体以负责人评估为准），学习超过6个月的学员评估考核通过后，可转为正式员工及待遇，提供就业机会/岗位； 
            
            <li style="line-height: 30px; ">
              3、综合评估学习不合格（学习成果、态度、技术水平等等）的同学将不能通过学习期，不通过的同学将进行淘汰或延长学习期；
            </li>

            <li  style="line-height: 30px; ">
              4、在校或自由职业的同学希望自己能有一技之长的，可以提前在我们“怪屋”官网http://glsxvfx.com，点击教育培训页面免费学习线上特效课程，学习打卡达到较高积分的，奖励实习就业名额。
            </li>

             投递简历邮箱：jyb@glsxvfx.com     
            <li  style="line-height: 30px; ">
              联系人：许老师、骆老师       
            </li>
            <li  style="line-height: 30px; ">
              电话：19120680543   
            </li>
          </ul>
           <div  style="margin-top: 20px;">
           <h2> 咨询二维码:</h2> <img src="@/assets/img/aboutUs/Wxzixun.png"/>
           </div> -->
          
    
      <!-- </div> -->
        
         
  
      </div>
    </div> 
   
  </div>
</template>

<script>
import item from "./components/item1.vue";

export default {
  data() {
    return {
      activeTypeIndex: 1,
      menuList: [],
      majorList: [],
      classifyName: undefined,
      queryForm: {
        pageNum: 1,
        pageSize: 4,
        title: undefined,
        authorName: undefined,
        // 方式
        cid: undefined,
        // 专业
        classify: undefined,
      },
      search_type_options: [
        {
          label: "岗位",
          value: 1,
        },
        {
          label: "用户",
          value: 2,
        },
      ],
      search_type: 1,
      searchtext: undefined,
      // 普通岗位
      jobList: [],
      total: 0,
    };
  },
  components: {
    item,
  },
  created() {
    this.$store.commit("setShowNav", false);

  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.recruit-container {
  width: 100vw;
  height: calc(100vh - 60px);
  background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/forcum/bg.jpg");
  background-size: 100% 100%;
  position: relative;
  padding-top: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/cursor/bg-qiu.png");
    background-position: 30px 50%;
    background-repeat: no-repeat;
  }
  .recruit-query-header {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 30px 20px 0px 140px;

    .recruit-header-menu {
      color: white;
      display: flex;
      align-items: flex-end;
      margin-right: 40px;
      .menu-list {
        display: flex;
        height: 48px;
        color: #ffffff;
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #929292;
          position: absolute;
          bottom: 0;
        }
        .menu-item {
          padding: 0 15px;
          margin: 0 5px;
          cursor: pointer;
          position: relative;
          line-height: 48px;
          > p {
            display: inline-block;
          }
          > .el-icon-close {
            margin-left: 5px;
          }
          .menu-children-box {
            position: absolute;
            top: 46px;
            left: 0;
           
            z-index: 2001;
            .menu-children-list {
              background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
              width: 100px;
              text-align: center;
              padding: 10px 0px;
             
              margin-right: 2px;
             
              .menu-children-item {
                height: 30px;
                line-height: 30px;
               
                &:hover {
                  background-color: #00a8b9;
                }
                &[active="true"] {
                  background-color: #00a8b9;
                }
              }
       
              &[type="show"] {
                display: block;
              }
            }
            .menu-children-list-son {
              position: relative;
              left: 100%;
              top: -40px;
              padding-left: 5px;
            }
          }
          &[type="show"] {
            .menu-children-list {
              display: block;
            }
          }
        }
        .menu-item.menu-item-active {
          &:after {
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 2px;
            background-color: #34e8fa;
            z-index: 999;
            left: 15px;
            bottom: 0;
          }
        }
      }
      .menu-search {
        display: flex;
        width: 290px;
        height: 36px;
        background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
        border-radius: 5px;
        margin-left: 40px;
        .search-center {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 160px;
            height: 2px;
            background-color: transparent;
            z-index: 999;
            left: 15px;
            bottom: 3px;
          }
          .search-input {
            position: relative;
            height: 2.25rem;
            width: 12.1875rem;
            border: none;
            color: #ffffff;
            font-size: 0.875rem;
            letter-spacing: 0.0625rem;
            text-indent: 0.9375rem;
            background-color: transparent;
            caret-color: #ffffff;
          }
        }
        .search-go {
          width: 36px;
          height: 36px;
          background-color: transparent;
          border: none;
          border-radius: 0 1px 1px 0;
          cursor: pointer;
          background-image: url("../../assets/newImg/search.png");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 26px 26px;
        }
      }
    }
  }
  .recruit-content {

    .recruit-hot-list{
      position: absolute;
      left: 160px;
      top: 250px;
      color: #FFFFFF;
      width: 300px;
      .hot-title{
        font-family: 'biaoti';
        font-size: 48px;
        letter-spacing: 5px;
        text-align: center;
      }
      .hot-list{
        width: 100%;
        height: 200px;
        .recruit-none-data{
          text-align: center;
          color: #87888d;
          .el-icon-postcard{
            font-size: 32px;
            margin-top: 50px;
          }
        }
      }
      .hot-text{
          >p{
            letter-spacing: 2px;
            line-height: 24px;
          }
          .text-flag{
            background-color: #1A4CA2;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 9px;
            display: inline-block;
            margin-top: 20px;
          }
      }
    }

    .recruit-list {
      position: relative;
      .recruit-item {
        position: absolute;
        &:nth-child(1) {
          left: 550px;
          top: 0px;
        }
        &:nth-child(2) {
          left: 650px;
          top: 180px;
        }
        &:nth-child(3) {
          left: 650px;
          top: 360px;
        }
        &:nth-child(4) {
          left: 550px;
          top: 540px;
        }
      }
    }

    .recruit-item-line {
      position: relative;
      .line-item {
        position: absolute;
        &:nth-child(1) {
          left: 370px;
          top: 70px;
          width: 180px;
          height: 80px;
        }
        &:nth-child(2) {
          left: 525px;
          top: 230px;
          width: 125px;
          height: 80px;
        }
        &:nth-child(3) {
          left: 520px;
          top: 410px;
          width: 130px;
          height: 80px;
        }
        &:nth-child(4) {
          left: 370px;
          top: 550px;
          width: 180px;
          height: 80px;
        }
      }
      .line-item svg {
        width: 100%;
        height: 100%;
        color: #ffffff;
        stroke: currentColor;
        stroke-width: 1;
        overflow: visible;
        transform: scaleY(-1);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .paginations {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<style lang="scss">
.search-left {
  .el-popper {
    position: absolute !important;
    left: 0;
  }
  .el-input__inner {
    width: 60px !important;
    color: #ffffff;
    background-color: transparent;
    padding-right: 0.875rem !important;
    padding-left: 0.375rem !important;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 1px 0 0 1px;
    letter-spacing: 3px;
    line-height: 2rem;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    // &:hover{
    // 	color: #00ceff !important;
    // }
  }

  .el-input__icon {
    width: 12px;
    margin-right: -3px;
    color: #ffffff;
    font-weight: bold;
  }

  .el-select__caret {
    color: #ffffff !important;
  }

  .el-select-dropdown {
    position: absolute;
    background-color: #232538;
    border: none;

    .popper__arrow {
      display: none;
    }

    .el-select-dropdown__item {
      color: #fff !important;
      height: 38px;
      &:hover {
        background-color: #3e4ea6;
      }
    }

    .selected {
      color: #ffffff;
      font-weight: 0;
    }

    .hover {
      font-weight: 600 !important;
      background-color: #3e4ea6;
    }
  }

  .el-input__icon {
    line-height: 2rem;
    color: #3e4ea6;
  }

  .el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
  .li{ 
   line-height: 30px; 
  }
}
</style>