<template>
  <div class="detail-container">
    <div class="detail-header">
      <el-button type="text" icon="el-icon-arrow-left" @click="eventBack"
        >返回列表页</el-button
      >
    </div>
    <div class="detail-content">
      <el-form>
        <div class="attestation-basic-info">
          <p class="info-title">接包人信息</p>
          <el-form-item :label="content.userType === 1 ? '头像' : '企业logo'">
            <br />
            <el-image
              :src="content.headImage"
              style="width: 120px; height: 120px; border-radius: 50%"
            ></el-image>
          </el-form-item>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="名称">
                <br />
                <el-input
                  v-model="content.name"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="账户类型">
                <br />
                <el-input
                  v-model="userTypeName"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">任务信息</p>
          <el-form-item label="任务封面">
            <br />
            <img
              :src="content.image"
              style="width: 240px; height: 240px; object-fit: cover"
            />
          </el-form-item>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="任务名称">
                <br />
                <el-input
                  v-model="content.title"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务类型（环节）">
                <br />
                <el-input
                  v-model="content.classifyName"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务预算">
                <br />
                <el-input
                  v-model="content.money"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">录屏信息</p>
          <el-row :gutter="86">
            <el-col :span="24">
              <el-form-item label="正式制作录屏">
                <br />
                <video
                  controlslist="nodownload"
                  controls="controls"
                  class="info-video-item"
                >
                  <source :src="content.accomplishVedio" />
                  您的浏览器不支持 video 标签。
                </video>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上传日期">
                <br />
                <el-input
                  v-model="content.uploadedTime"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <p class="info-title">报价信息</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="制作总人天">
                <br />
                <el-input
                  v-model="content.flnalDay"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="人天单价">
                <br />
                <el-input
                  v-model="content.flnalOffer"
                  placeholder="暂无"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-form ref="acceptForm" :model="acceptForm" :rules="acceptRules" v-if="JSON.parse($cookie.get('userInfo')).permission === 'T-business'">
        <div class="attestation-basic-info">
          <p class="info-title">验收操作</p>
          <el-row :gutter="86">
            <el-col :span="12">
              <el-form-item label="验收" prop="check" required>
                <br />
                <el-radio-group v-model="acceptForm.check" size="small">
                  <el-radio :label="1" border>验收通过</el-radio>
                  <el-radio :label="2" border>验收不通过</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结算比例" prop="proportion" required>
                <br />
                <el-input
                  v-model="acceptForm.proportion"
                  placeholder="请填写结算比例,最多输入两位小数"
                  oninput="value=value.replace(/[^\d]/g,'')"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="remar" label="备注">
                <br />
                <el-input
                  v-model="acceptForm.remar"
                  type="textarea"
                  :autosize="{ minRows: 8 }"
                  placeholder="请填写备注"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="attestation-basic-info">
          <div class="detail-btn-box">
            <el-button class="detail-btn-item" @click="eventSubmit"
              >提交</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { epibolyBusinessCheck } from "@/api/ectocyst.js";
export default {
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("百分比不能为空"));
      } else if (value > 100) {
        callback(new Error("值不能大于100%"));
      }
      callback();
    };
    return {
      acceptForm: {
        check: undefined,
        proportion: undefined,
        remar: undefined,
      },
      acceptRules: {
        check: [
          {
            required: true,
            message: "请选择验收结果",
            trigger: "change",
          },
        ],
        proportion: [
          {
            required: true,
            message: "请输入结算比例",
            trigger: ["blur", "change"],
          },
          {
            validator: validateMoney,
            trigger: "change",
          },
        ],
      },
      userTypeList: [{
        label: '企业',
        value: 2
      },{
        label: '个人',
        value: 1
      }],
      userTypeName: undefined
    };
  },
  props: {
    content: {
      type: Object,
      require: true,
    },
  },
  created() {
    this.userTypeName = this.getDictByVal(this.userTypeList, this.content.userType);
  },
  methods: {
    // 提交
    eventSubmit() {
      this.$refs['acceptForm'].validate((valid) => {
        if (valid) {
          epibolyBusinessCheck({
            check: this.acceptForm.check,
            proportion: (this.acceptForm.proportion / 100).toFixed(2),
            remar: this.acceptForm.remar,
            eid: this.content.eid
          }).then(res => {
            if (res.code === 200) {
              this.$message({
                showClose: true,
                message: "提交成功",
                type: "success",
              });
            }
            this.eventBack();
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击返回
    eventBack() {
      this.userTypeName = undefined;
      this.$emit("back");
    },
    // 根据
    getDictByVal(option, val) {
      return option.find((el) => el.value == val).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  .detail-header {
    width: 100%;
    //border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    padding: 20px 50px 10px;
    background-color: #ffffff;
  }

  .detail-content {
    margin-top: 10px;
    padding: 10px 50px 20px;
    background-color: #ffffff;
    .attestation-basic-info {
      margin-top: 20px;
      .info-title {
        position: relative;
        text-indent: 20px;
        font-size: 20px;
        margin-bottom: 20px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: var(--default-ectocyst-color);
        }
      }

      .info-video-item {
        width: 50%;
      }

      .detail-btn-box {
        text-align: center;
        .detail-btn-item {
          background-color: var(--default-ectocyst-color);
          color: #ffffff;
          padding: 10px 60px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>