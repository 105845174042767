<template>
  <div class="upload-work-container">
    <el-form ref="dataForm" :model="dataForm">
      <el-form-item label="作业标题">
        <glsx-input
          :maxlength="25"
          :value="dataForm.title"
          placeholderText="请输入，最长25个字"
          @getValue="changeInputValue($event, 'title')"
        ></glsx-input>
      </el-form-item>
      <el-form-item label="上传封面">
        <div class="uploadcov">
          <div class="work-cov">
            <div
              class="drag"
              v-for="(item, index) in docImgFileList"
              :key="index"
            >
              <img :src="item.url" />
              <div class="covbtn">
                <i
                  @click="handleRemoveDoc(item.fileName)"
                  class="el-icon-delete"
                ></i>
              </div>
            </div>
            <el-upload
              :action="uploadUrl"
              :on-success="succesCoverPicture"
              v-show="!docImgFileList.length"
              :on-preview="handlePictureCardPreview"
              :before-upload="
                (file) => {
                  return beforeAvatarUpload(file, 2);
                }
              "
              :show-file-list="false"
              :file-list="docImgFileList"
              :on-exceed="handleCoverExceed"
              :limit="1"
              accept=".jpg,.png,.gif"
              multiple
            >
              <div class="uploadcovs">
                <img src="@/assets/img/add.png" alt="" srcset="" />
                <p>支持jpg,gif,png格式</p>
                <p>封面大小不超过2M</p>
                <p>只能上传一张封面</p>
              </div>
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="上传视频">
        <div class="uploadvideo">
          <div class="work-video">
            <template>
              <div
                v-for="(item, index) in videoList"
                :key="item.fileName"
                class="drag"
              >
                <video :src="item.url" controls></video>
                <div class="vdobtn">
                  <!-- <i @click="changeprev(index)" class="el-icon-back"></i> -->
                  <i
                    @click="removeVideo(index, item.fileName)"
                    class="el-icon-delete"
                  ></i>
                  <!-- <i @click="changenext(index)" class="el-icon-right"></i> -->
                </div>
              </div>
            </template>
            <div
              class="drag"
              v-for="(item, index) in uploadList"
              :key="index"
              style="background-color: #4b505e; border: 0.125rem dashed #35edff"
              v-loading="true"
              element-loading-text="拼命上传中"
              element-loading-background="#4b505e"
            ></div>
            <el-upload
              v-show="!(uploadList.length || videoList.length)"
              class="upload-demo"
              :action="uploadUrl"
              :on-success="succesVideo"
              accept=".mp4,.avi"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeAvatarUploadVideo"
              :file-list="videoList"
            >
              <div class="uploadvideos">
                <img src="@/assets/img/add.png" alt="" srcset="" />
                <p>支持mp4,avi格式</p>
                <p>视频大小不超过30M</p>
                <p>最多上传1个视频</p>
              </div>
            </el-upload>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="作业说明">
        <glsx-input
          :value="dataForm.content"
          type="textarea"
          :rows="5"
          @getValue="changeInputValue($event, 'content')"
        ></glsx-input>
      </el-form-item>
      <el-form-item>
        <div class="form-footer-btn">
          <el-button @click="onSubmit" round>提交</el-button>
          <el-button round type="info" @click="reset">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { compressAccurately } from "image-conversion";
import glsxInput from "@/component/form/glsxInput.vue";
import { deleteUpload } from "@/api/work.js";
import { handleAddWork, getCourseWorkDetailById, handlEditWorkById } from "@/api/series.js";
export default {
  data() {
    return {
      dataForm: {
        title: undefined,
        content: undefined,
        cover: undefined,
        link: undefined,
      },
      docImgFileList: [],
      // 文件上传的地址
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/file/upload",
      // 上传中集合
      uploadList: [],
      // 已上传视频的集合
      videoList: [],
      videoUrl: undefined,
      // 图片集合
      imageList: [],
      // 所展示的图片地址
      coverImage: undefined,
      dialogImageUrl: undefined,
    };
  },
  props: {
    vid: {
      require: true,
    },
    workId: {},
  },
  components: {
    glsxInput,
  },
  created() {
    this.workId && this.getDetailById();
  },
  methods: {
    // 查询
    getDetailById() {
      getCourseWorkDetailById({ id: this.workId }).then((res) => {
        if (res.code === 200) {
          this.dataForm.title = res.data.title;
          this.dataForm.content = res.data.content;
          this.dataForm.cover = res.data.cover;
          this.dataForm.link = res.data.link;
          this.docImgFileList.push({
            url: res.data.cover,
            fileName: res.data.cover,
            fileType: 1,
          });
          this.videoList.push({
            url: res.data.link,
            fileName: res.data.link,
          });
        }
      });
    },
    // 关于图片上传的方法 start
    // 上传图片
    succesPicture(response, file, fileList) {
      //判断存储的数组长度
      this.imageList.push({
        url: response.data.url,
        fileType: 1,
        fileName: response.data.fileName,
      });
    },
    succesCoverPicture(response, file, fileList) {
      this.docImgFileList.push({
        url: response.data.url,
        fileName: response.data.fileName,
        fileType: 1,
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      //this.dialogVisible = true;
    },
    // 判断是否符合规定
    beforeAvatarUpload(file, maxSize = 30) {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";
      const isGif = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < maxSize;
      if (!isJPG && !isPng && !isGif) {
        this.$message.error("上传图片只能是 JPG、GIF或png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 " + maxSize + "MB!");
        return false;
      }
      if (maxSize == 2 && file.size / 1024 > 30) {
        return new Promise((resolve) => {
          // 压缩到30KB,可自定义
          compressAccurately(file, 30).then((res) => {
            resolve(res);
          });
        });
      }
      return true;
    },
    // 超出图片限制条件的回调函数
    handleExceed() {
      this.$message.warning(`请最多上传20个文件。`);
    },
    // 删除图片
    async handleRemove(index, fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.imageList.splice(index, 1);
      }
    },
    //删除封面图
    async handleRemoveDoc(fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.coverImage = "";
        this.docImgFileList = [];
      }
    },
    // 超出封面图限制条件的回调函数
    handleCoverExceed() {
      this.$message.warning(`请最多上传1张封面图。`);
    },
    // 关于图片上传的方法 end

    // 上传视频
    succesVideo(res) {
      this.videoUrl += res.data.url + ",";
      this.videoList.push({
        url: res.data.url,
        fileName: res.data.fileName,
      });
      console.log(this.uploadList);
      this.uploadList.length && this.uploadList.splice(0, 1);
    },
    // 删除视频
    async removeVideo(index, fileName) {
      let result = await this.eventRemoveFile(fileName);
      if (result.code === 200) {
        this.videoList.splice(index, 1);
        this.videoUrl = "";
        this.videoList.forEach((i, index) => {
          this.videoUrl += i.url + ",";
        });
      }
    },
    // 上传前的回调
    beforeAvatarUploadVideo(file) {
      //视频大小（MB）
      let fileSize = file.size / 1024 / 1024;
      // "video/wmv",
      //     "video/rmvb",
      //     "video/mov",
      //     "video/ogg",
      //     "video/flv",
      if (["video/mp4", "video/avi"].indexOf(file.type) == -1) {
        this.$message.error("请上传正确的视频格式！");
        return false;
      }
      if (fileSize > 30) {
        this.$message.error("上传的视频大小不可超过 30MB！");
        return false;
      }

      this.uploadList.push(file.uid);

      return true;
    },

    // 关于视频上传的方法 end

    // 调用删除文件接口
    eventRemoveFile(fileName) {
      return new Promise((resolve, reject) => {
        deleteUpload({ fileName }).then((res) => {
          resolve(res);
        });
      });
    },
    // 输入框组件发生变化
    changeInputValue(event, changeName) {
      this.dataForm[changeName] = event;
    },
    // 警告信息
    messageWarn(text) {
      this.$message({
        message: text,
        offset: 80,
        type: "warning",
      });
    },

    // 提交表单
    async onSubmit() {
      if (!this.dataForm.title) {
        this.messageWarn("请输入作业标题");
        return;
      }
      this.dataForm.cover = this.docImgFileList[0].url;
      if (!this.dataForm.cover) {
        this.messageWarn("请上传封面");
        return;
      }
      this.dataForm.link = this.videoList[0].url;
      if (!this.dataForm.cover) {
        this.messageWarn("请上传视频");
        return;
      }
      if (!this.dataForm.content) {
        this.messageWarn("请输入内容");
        return;
      }
      if (!this.workId) {
        let { code } = await handleAddWork({ ...this.dataForm, vid: this.vid });
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "提交成功",
          });
          this.reset();
          this.$emit("close");
        }
      } else {
        let { code } = await handlEditWorkById({ ...this.dataForm, id: this.workId });
        if (code === 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "修改成功",
          });
          this.reset();
          this.$emit("close");
        }
      }
    },

    // 重置
    reset() {
      this.dataForm = {
        title: undefined,
        content: undefined,
        cover: undefined,
        link: undefined,
      };
      this.docImgFileList = [];
      this.uploadList = [];
      this.videoList = [];
      this.videoUrl = undefined;
      this.imageList = [];
      this.coverImage = undefined;
      this.dialogImageUrl = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-work-container {
  color: #ffffff;
  /deep/ .el-form-item__label {
    color: #fff;
    letter-spacing: 3px;
    font-size: 16px;
  }
  /deep/ .el-input {
    width: calc(100% - 90px);
  }
  .uploadvideo {
    box-sizing: border-box;
    margin-top: 55px;
    width: 100%;
    border-radius: 15px;
    background: rgba(195, 195, 195, 0.2);

    .work-video {
      display: flex;
      flex-wrap: wrap;

      .drag {
        // flex: 200px;
        width: 200px !important;
        height: 200px !important;
        border-radius: 15px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #23272f;
        position: relative;

        video {
          width: 100%;
          height: 100%;
          margin-top: -20px;
        }

        .vdobtn {
          position: absolute;
          display: flex;
          width: 180px;
          padding: 10px;
          top: 70px;
          justify-content: center;
          align-items: center;

          i {
            cursor: pointer;
            font-size: 25px;
            transition: all 0.2s linear;

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        /deep/.el-loading-spinner {
          margin-top: 0;
          transform: translateY(-50%);
          .el-loading-text {
            line-height: 14px;
          }
        }
      }
    }

    .uploadvideos {
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
      border: 2px dashed #35edff;
      border-radius: 15px;
      background-color: #4b505e;
      img {
        margin: 30px auto;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: normal;
      }
    }
  }

  .uploadcov {
    box-sizing: border-box;
    margin-top: 55px;
    width: 100%;
    border-radius: 15px;
    background: rgba(195, 195, 195, 0.2);

    .work-cov {
      display: flex;
      flex-wrap: wrap;

      .drag {
        // flex: 200px;
        width: 200px !important;
        height: 200px !important;
        border-radius: 15px;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #23272f;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .covbtn {
          position: absolute;
          display: flex;
          width: 180px;
          padding: 10px;
          top: 70px;
          justify-content: center;
          align-items: center;

          i {
            cursor: pointer;
            font-size: 25px;
            transition: all 0.2s linear;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    .uploadcovs {
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
      border: 2px dashed #35edff;
      border-radius: 15px;
      background-color: #4b505e;

      img {
        margin: 30px auto;
      }

      p {
        font-size: 12px;
        letter-spacing: 1px;
        line-height: normal;
      }
    }
  }

  .form-footer-btn {
    display: flex;
    justify-content: space-between;
    button {
      width: 135px;
      height: 45px;
      font-size: 18px;
      color: #fff;
      text-align: center;
    }
    button:nth-child(1) {
      background-color: #00d0f4;
      border-color: #00d0f4;
    }
    button:nth-child(2) {
      background-color: rgba(68, 68, 68, 0.5);
      border-color: rgba(68, 68, 68, 0.5);
    }
  }
}
</style>