<template>
  <div>
    <div class="header">
      <div class="header-container" style="height: 60px">
        <div class="logo" @click="getguide">
          <img src="@/assets/newImg/GLlogo2.png" alt="" />
        </div>
        <div class="header-menu">
          <div
            @mouseover="showForum"
            @mouseleave="notshowForum"
            :class="
              activeIndex == 0 && $store.state.showNav
                ? 'menu-options actives'
                : 'menu-options'
            "
            @click="getForum"
          >
            <span>论坛</span>
            <div class="forumText" v-show="isShowForum">
              <div class="textLeft">
                <span>游戏美术设计师的交流平台</span>
                <span>与全国一线游戏美术设计师交流碰撞，激发灵感。</span>
              </div>
              <div class="textMain">
                <span>游戏特效</span>
                <span>原画插画</span>
                <span>场景、角色模型</span>
                <span>动作</span>
                <span>敬请期待</span>
              </div>
              <div class="textRight">
                <span
                  >这里有最全、最专业的游戏美术作品；3A游戏佳作展示；大神经验分享……总之你想要的这里都有。</span
                >
              </div>
            </div>
          </div>
          <div
            :class="
              activeIndex == 1 && $store.state.showNav
                ? 'menu-options possistion actives'
                : 'menu-options possistion'
            "
            @mouseover="showForum2"
            @mouseleave="notshowForum2"
            @click.stop="getCompany"
            v-if="isShowMenu"
          >
            公司简介
            <div class="forumText2" v-show="isShowForum2">
              <div class="textLeft">
                <span>怪力视效网络科技</span>
                <span>提供游戏美术全流程专业设计制作的团队</span>
              </div>
              <div class="textMain">
                <span>关于我们</span>
                <span>业务范围</span>
                <span>合作伙伴</span>
                <span>人才招聘</span>
                <span>联系我们</span>
              </div>
              <div class="textRight">
                <span>更多精彩作品请见【公司作品】</span>
              </div>
            </div>
            <div class="company-list" v-if="userModel && activeIndex == 1">
              <div
                :class="
                  companyIndex == 0
                    ? 'list-options list-active'
                    : 'list-options'
                "
                @click.stop="getCompany"
              >
                关于我们
              </div>
              <div
                :class="
                  companyIndex == 1
                    ? 'list-options list-active'
                    : 'list-options'
                "
                @click.stop="gobusiness"
              >
                业务范围
              </div>
              <div
                :class="
                  companyIndex == 2
                    ? 'list-options list-active'
                    : 'list-options'
                "
                @click.stop="gopartners"
              >
                合作伙伴
              </div>
              <div
                :class="
                  companyIndex == 3
                    ? 'list-options list-active'
                    : 'list-options'
                "
                @click.stop="gorecruitment"
              >
                人才招聘
              </div>
              <div
                :class="
                  companyIndex == 4
                    ? 'list-options list-active'
                    : 'list-options'
                "
                @click.stop="gocontnctus"
              >
                联系我们
              </div>
            </div>
            <div class="comText" v-show="isShowCom"></div>
          </div>
          <div
            :class="activeIndex == 2 ? 'menu-options actives' : 'menu-options'"
            @click="getMaterial"
            v-if="isShowMenu"
          >
            公司作品
          </div>
          <div
            :class="activeIndex == 4 ? 'menu-options actives' : 'menu-options'"
            @click="getRecruit"
          >
            招聘
          </div>
          <div
            :class="activeIndex == 3 ? 'menu-options actives' : 'menu-options'"
            @click="getTrain"
          >
            教育培训
          </div>
          <div
            :class="activeIndex == 5 ? 'menu-options actives' : 'menu-options'"
            @click="getLinkage"
          >
            校企联动
          </div>
          <div
            :class="activeIndex == 6 ? 'menu-options actives' : 'menu-options'"
            @click="getCourses"
          >
            在线课程
          </div>
          <div
            :class="activeIndex == 7 ? 'menu-options actives' : 'menu-options'"
            @click="getEctocyst"
            v-if="isShowCompWork"
          >
            外包平台
          </div>
        </div>
        <div class="header-right">
          <div
            :class="isShowMsg == 0 ? 'downed msgactive' : 'downed'"
            @click="godowned"
            v-show="getUserInfo"
          >
            <div class="icons-down">
              <img src="../../assets/img/header/update.png" alt="" />
            </div>
          </div>
          <div
            :class="
              isShowMsg == 1 ? 'message-icons msgactive ' : 'message-icons'
            "
            @click="goMessageDetail"
            v-if="getUserInfo"
          >
            <div class="icons-message">
              <img
                src="@/assets/newImg/2.png"
                v-show="this.$store.state.unReadNum == 0"
                alt=""
              />
              <img
                src="@/assets/newImg/2(2).png"
                v-show="this.$store.state.unReadNum > 0"
                alt=""
              />
              <div
                class="unread-message"
                v-show="this.$store.state.unReadNum > 0"
              >
                <span>{{
                  this.$store.state.unReadNum > 99
                    ? "99+"
                    : this.$store.state.unReadNum
                }}</span>
              </div>
            </div>
          </div>
          <div
            :class="isShowMsg == 3 ? 'logins ' : 'logins'"
            v-if="!getUserInfo"
            @click="getLogin"
          >
            <div class="icons-user">
              <img src="@/assets/newImg/3.png" alt="" />
            </div>
            <div class="loginorregister">登录</div>
          </div>
          <div
            :class="
              isShowMsg == 2 ? 'user-headerImg useractive' : 'user-headerImg'
            "
            @mouseover="isshowset = true"
            @mouseleave="isshowset = false"
            v-if="getUserInfo"
          >
            <div class="headerImg">
              <img
                @click="gopersonal(0)"
                :src="
                  JSON.parse(this.$cookie.get('userInfo')).headImg ||
                  require('@/assets/img/1.png')
                "
                alt=""
              />
              <div class="setpersonal" v-show="isshowset">
                <div @click="gopersonal('0')">个人中心</div>
                <div @click="gopersonal('1')">修改资料</div>
                <div @click="loginup">退出登录</div>
                <div @click="gettask">引导中心</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bigImg" @click="getForum">
      <video
        src="../../assets/home/12.mp4"
        autoplay
        muted
        loop
        width="100vw"
      ></video>
      <!-- <img src="../../assets/home/官网首页.jpg" alt=""> -->
      <span class="guide1">致力于打造最专业的</span>
      <span class="guide2">游戏美术平台</span>
    </div>
    <div class="page-footer">
      <p><img src="../../assets/newImg/GLlogo.png" /></p>
      <div class="text-box">
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >备案号：粤ICP备2021083385号</a
          >
        </p>
        <p>Copyright©2021 广州怪力视效网络科技有限公司 版权所有</p>
      </div>
    </div>
  </div>
</template>

<script>
import cookie from "js-cookie";
import { getBannerImgList } from "@/api/company.js";
import { loginUp } from "@/api/login.js";
export default {
  name: "VHeader",
  data() {
    return {
      activeIndex: 0,
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        },
      },
      item: 8,
      companyIndex: 0,
      isShowList: false,
      isShowMsg: -1,
      isShowLogin: false,
      isShowMenu: false,
      userModel: false,
      imgsList: [],
      isShowForum: false,
      isShowForum2: false,
      isShowCom: false,
      isshowset: false,
      isShowCompWork: false,
    };
  },
  created() {
    if (this.$route.query.companyIndex) {
      this.companyIndex = this.$route.query.companyIndex;
    }
    if (this.$route.query.activeIndex) {
      this.activeIndex = this.$route.query.activeIndex;
    }
    if (this.$route.query.isShowMsg) {
      this.isShowMsg = this.$route.query.isShowMsg;
    }
    if (this.$route.query.activeIndex == 1 && this.$route.query.companyIndex) {
      this.companyIndex = this.$route.query.companyIndex;
    }
    if (this.$cookie.get("userInfo")) {
      this.isShowLogin = true;
    }
    if (
      !this.$store.state.userInfo == "" &&
      this.$store.state.userInfo.roleType == 2
    ) {
      this.isShowMenu = true;
    }
    if (
      !this.$store.state.userInfo == "" &&
      this.$store.state.userInfo.roleType == 3
    ) {
      this.isShowMenu = true;
    }
    this.getBannerImgList(this.activeIndex);
  },
  computed: {
    //  获取登录信息
    getUserInfo() {
      if (
        this.$cookie.get("userInfo") &&
        this.$cookie.get("userInfo") != "undefined"
      ) {
        this.$store.commit(
          "setUserInfo",
          JSON.parse(this.$cookie.get("userInfo"))
        );
        return true;
      }
    },
  },
  watch: {
    "$route.query.activeIndex"(newVla, oldVla) {
      this.activeIndex = newVla;
      this.getBannerImgList(this.activeIndex);
    },
    "$route.query.open"(newVla, oldVla) {
      console.log(this.isShowMenu);
    },
    "$store.state.userInfo"(newVla) {
      if (!newVla == "" && newVla.roleType == 3) {
        this.isShowCompWork = true;
      }
    },
  },
  methods: {
    gettask() {
      this.$router.push("/task");
    },
    showForum() {
      this.isShowForum = true;
    },
    notshowForum() {
      this.isShowForum = false;
    },
    showForum2() {
      this.isShowForum2 = true;
      this.userModel = true;
    },
    notshowForum2() {
      this.isShowForum2 = false;
      this.userModel = false;
    },

    async loginup() {
      let data = {
        token: this.$cookie.get("accessToken"),
      };
      let { code } = await loginUp(data);
      if (code == 200) {
        this.$cookie.remove("accessToken");
        this.$cookie.remove("userInfo");
        this.$router.push("/login");
        // window.location.reload();
      }
    },

    getguide() {
      this.$router.push("/guide");
    },
    getForum() {
      this.activeIndex = 0;
      this.companyIndex = -1;
      this.isShowMsg = -1;
      this.$router.push("/home/newest?activeIndex=0&latest=1");
    },
    getMaterial() {
      this.activeIndex = 2;
      this.isShowMsg = -1;
      this.companyIndex = -1;
      console.log(12312312);
      this.$router.push("/home/materialList?activeIndex=2&latest=1");
    },
    getRecruit() {
      this.activeIndex = 4;
      this.companyIndex = -1;
      this.isShowMsg = -1;
      // if (process.env.NODE_ENV == "development") {
      // 	this.$router.push('/home/recruit?activeIndex=4');
      // 	return;
      // }
      // this.$router.push('/home/recruitment?activeIndex=4');
      this.$router.push("/home/recruit?activeIndex=4");
    },
    // 前往培训界面
    getTrain() {
      this.activeIndex = 3;
      this.companyIndex = -1;
      this.isShowMsg = -1;
      this.$router.push("/train?activeIndex=3");
    },
    getLinkage() {
      this.activeIndex = 5;
      this.companyIndex = -1;
      this.isShowMsg = -1;
      this.$router.push("/linkage?activeIndex=5");
    },
    // 前往在线课程界面
    getCourses() {
      this.activeIndex = 6;
      this.companyIndex = -1;
      this.isShowMsg = -1;
      this.$router.push("/courses?activeIndex=6");
    },
    // 前往外包平台界面
    getEctocyst() {
      // this.activeIndex = 7;
      // this.companyIndex = -1;
      // this.isShowMsg = -1;
      let routeUrl = this.$router.resolve({ path: "/ectocyst" });
      window.open(routeUrl.href, "_blank");
    },
    getCompany() {
      this.activeIndex = 1;
      this.isShowList = true;
      this.companyIndex = 0;
      this.isShowMsg = -1;
      this.$router.push("/home/profile?activeIndex=1&companyIndex=0");
    },
    gobusiness() {
      this.companyIndex = 1;
      this.$router.push("/home/business?activeIndex=1&companyIndex=1");
    },
    gopartners() {
      this.companyIndex = 2;
      this.$router.push("/partners?activeIndex=1&companyIndex=2");
    },
    gorecruitment() {
      this.companyIndex = 3;
      this.$router.push("/home/recruitment?activeIndex=1&companyIndex=3");
    },
    gocontnctus() {
      this.companyIndex = 4;
      this.$router.push("/home/contnctus?activeIndex=1&companyIndex=4");
    },
    godowned() {
      this.activeIndex = -1;
      this.isShowMsg = 0;
      this.$router.push("/release?isShowMsg=0");
    },
    goMessageDetail() {
      this.activeIndex = -1;
      this.isShowMsg = 1;
      this.$router.push("/usermessage?isShowMsg=1");
    },
    getLogin() {
      this.activeIndex = -1;
      this.isShowMsg = 3;
      this.$router.push("/login");
    },
    gopersonal(open) {
      this.activeIndex = -1;
      this.isShowMsg = 2;
      this.$router.push("/personalhome?isShowMsg=2" + "&open=" + open);
    },
    async getBannerImgList(activeIndex) {
      let type = activeIndex == 0 ? 1 : activeIndex == 1 ? 2 : 3;
      let formData = {
        type: type,
      };
      let { code, rows } = await getBannerImgList(formData);
      if (code === 200) {
        this.imgsList = rows;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/font/font.css");
.header {
  background: rgba(32, 34, 55, 0);
  :hover {
    background: #202237;
  }
}
.header-container {
  width: 100%;
  height: 60px;
  // background: #202237;
  opacity: 1;
  border-radius: 0px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;

  .logo {
    cursor: pointer;
    width: 72px;
    width: 130px;
    height: 37px;
    // box-shadow: 0px 2px 2px #1B1C1B;
    opacity: 1;
    border-radius: 0px;
    margin-left: 50px;
    margin-top: -3px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .header-menu {
    display: flex;
    margin-left: 75px;
    margin-top: -2px;
    .forumText {
      position: absolute;
      width: 100vw;
      height: 300px;
      left: -276px;
      top: 30px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      font-family: neirong;
      transform: scaleY(0.9);
      color: #fff;
      line-height: 40px;
      background: rgba(33, 35, 55, 1);
      padding: 0 10vw;
      box-sizing: border-box;
      .textLeft:hover {
        background: rgba(33, 35, 55, 0);
        span:hover {
          color: white;
          background: none;
        }
      }
      .textMain:hover {
        background: none;
        span:hover {
          color: #2376d2;
          background: none;
        }
      }
      .textRight:hover {
        background: none;
        span:hover {
          color: white;
          background: none;
        }
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .textLeft {
        width: 35%;
        font-size: 18px;
        //margin-left: 30px;
        :first-child {
          font-weight: 800;
          font-size: 40px;
          margin-bottom: 15px;
        }
      }
      .textMain {
        width: 20%;
        font-weight: 600;
      }
      .textRight {
        width: 35%;
        position: relative;
        font-size: 18px;
        :after {
          content: "";
          position: absolute;
          left: -50px;
          top: 30px;
          width: 2px;
          height: 245px;
          background-color: #888888;
        }
      }
    }
    .forumText2 {
      position: absolute;
      width: 100vw;
      height: 300px;
      left: -370px;
      top: 30px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      font-family: neirong;
      transform: scaleY(0.9);
      color: #fff;
      line-height: 40px;
      background: rgba(33, 35, 55, 1);
      padding: 0 10vw;
      box-sizing: border-box;
      .textLeft:hover {
        background: rgba(33, 35, 55, 0);
        span:hover {
          color: white;
          background: none;
        }
      }

      .textMain:hover {
        background: none;
        span:hover {
          color: #2376d2;
          background: none;
        }
      }
      .textRight:hover {
        background: none;
        span:hover {
          color: white;
          background: none;
        }
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .textLeft {
        width: 35%;
        font-size: 18px;
        //margin-left: 30px;
        :first-child {
          font-weight: 800;
          font-size: 40px;
          margin-bottom: 15px;
        }
      }
      .textMain {
        width: 20%;
        font-weight: 600;
      }
      .textRight {
        width: 35%;
        position: relative;
        font-size: 18px;
        :after {
          content: "";
          position: absolute;
          left: -50px;
          top: 30px;
          width: 2px;
          height: 245px;
          background-color: #888888;
        }
      }
    }
    .menu-options {
      // width: 69px;
      height: 45px;
      font-size: 20px;
      font-family: biaoti;
      font-weight: 400;
      line-height: 45px;
      margin: 0 20px;
      letter-spacing: 2px;
      transform: scaleY(1.15);
      color: #989898;
      // color: rgba(255, 255, 255, 0.8);
      // border-left: 1px #202237 solid;
      // border-right: 1px #202237 solid;
      text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }

    .possistion {
      position: relative;
      margin: 0 35px;
      .company-list {
        width: 172px;
        height: 250px;
        // background: red;
        position: absolute;
        left: 0px;
        overflow: hidden;
        background: #3e4155;
        z-index: 99999;

        .list-options {
          width: 172px;
          height: 50px;
          // background: #1c1f38;
          // background: rgba(45, 46, 44, 0.4);
          border-radius: 0px;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 50px;
          color: #ffffff;
          opacity: 1;

          &:hover {
            background: #3e41558e;
          }
        }

        .list-active {
          background: #3e41558e;
        }
      }
    }

    .actives {
      // color: #FFFFFF;
      color: #ffffff;
      // border-right:1px #174279 solid;
      border-bottom: 6px #85d2ec solid;
      // border-bottom:6px rgba(158, 234, 106, 1) solid;
    }
  }

  .header-right {
    height: 54px;
    width: 200px;
    position: absolute;
    right: 45px;
    padding-left: 2px;
    display: flex;
    align-items: flex-start;
    transform: translateY(-3px);

    .downed {
      width: 42px;
      height: 100%;
      // border-left: 1px #202237 solid;
      // border-right: 1px #202237 solid;
      // border-right:1px #202237 solid;
      // border-bottom: 6px #202237 solid;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .icons-down {
        width: 35px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .message-icons {
      width: 40px;
      height: 100%;
      margin-left: 21px;
      margin-right: 11px;
      // border-left: 1px #202237 solid;
      // border-right: 1px #202237 solid;
      // border-bottom: 6px #202237 solid;
      // margin-left:50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icons-message {
        width: 20px;
        border: none;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .unread-message {
          position: absolute;
          width: 20px;
          background-color: #ff0000;
          font-size: 10px;
          font-weight: 600;
          border-radius: 5px;
          text-align: center;
          color: #fff;
          top: 1px;
          right: -12px;
        }
      }
    }

    .msgactive {
      border-bottom: 6px #85d2ec solid;
      // border-bottom:6px rgba(158, 234, 106, 1) solid;
      // box-sizing: border-box;
      font-weight: bold;
    }

    .logins {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-left:50px;
      // border-left: 1px #202237 solid;
      // border-right: 1px #202237 solid;
      // border-bottom: 6px #202237 solid;
      cursor: pointer;
      padding-left: 20px;

      .icons-user {
        width: 46px;

        // background: rgb(156, 143, 145);
        img {
          width: 100%;
          margin-top: 5px;
          margin-left: 5px;
        }
      }

      .loginorregister {
        width: 135px;
        height: 31px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 31px;
        color: #56b5b5;
        text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
        opacity: 1;
        margin-left: 20px;
        &:hover {
          color: #16d5de;
        }
      }
    }

    .user-headerImg {
      width: 50px;
      height: 100%;
      // border-left: 1px #202237 solid;
      // border-right: 1px #202237 solid;
      // border-bottom: 6px #202237 solid;
      // margin-left:50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .headerImg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &:hover {
          border-radius: 50%;
          border: 1px solid #cbd3cb;
        }
      }
      .setpersonal {
        display: flex;
        position: absolute;
        flex-direction: column;
        right: -20px;
        top: 48px;
        width: 150px;
        height: 200px;
        justify-content: center;
        align-items: center;
        background-color: #202237;
        color: white;
        z-index: 99999;
        overflow: hidden;
        div {
          width: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background-color: #00a8b9;
          }
        }
      }
    }

    .useractive {
      // border-left: 1px #174279 solid;
      // border-right: 1px #174279 solid;
      // border-bottom: 6px #85D2EC solid;
      // box-sizing: border-box;
      font-weight: bold;
    }
  }
}
.bigImg {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .guide1 {
    position: absolute;
    cursor: pointer;
    font-size: 60px;
    text-shadow: 3px 3px 8px black;
    letter-spacing: 5px;
    color: white;
    left: 130px;
    bottom: 240px;
  }
  .guide2 {
    position: absolute;
    font-size: 60px;
    cursor: pointer;
    text-shadow: 3px 3px 8px black;
    letter-spacing: 5px;
    color: white;
    left: 130px;
    bottom: 175px;
  }
}

.page-footer {
  padding: 20px;
  color: #00d0f4;
  display: flex;
  align-items: center;
  img {
    width: 180px;
  }
  .text-box {
    margin-left: 20px;
    border-left: 1px solid #00d0f4;
    height: 50px;
    padding-left: 20px;
    p {
      font-size: 16px;
      a {
        color: #00d0f4;
      }
    }
    p:last-child {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>
