<template>
  <div class="home-container">
    <div :class="isbar == true ? 'home-contop bar' : 'home-contop'">
      <div class="top-left">
        <!-- 论坛首页
				<span>WORKS OF ALL ARTISTS</span> -->
      </div>
      <div class="top-right">
        <div
          :class="opindex == 1 ? 'options activecolor' : 'options'"
          @click="rewrite"
        >
          首页
        </div>
        <div
          :class="opindex == 2 ? 'options activecolor' : 'options'"
          @mouseenter.stop="
            getPageChildClassiftyList('showSelect', 'classify1', 40)
          "
          @mouseleave.stop="showSelect = false"
        >
          <div @click="changeClassify(40)">{{ changename }}</div>
          <i
            v-if="Original"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-arrow-down"
          ></i>
          <i
            v-if="!Original"
            @click="getOriginal"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-close"
          ></i>
          <div class="selectList1" v-if="showSelect">
            <div class="select-left">
              <div v-for="(item3, index3) in classify1" :key="item3.value">
                <div
                  class="hover1"
                  @click="change(item3.value, item3.label, 1, 1)"
                  @mouseover.stop="changeclass($event, item3.value, 1)"
                >
                  {{ item3.label }}
                </div>
                <div class="select-right" v-if="index3 == 1">
                  <div v-for="item4 in childClass" :key="item4.value">
                    <div
                      class="hover"
                      @click="change(item4.value, item4.label, 1)"
                    >
                      {{ item4.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="opindex == 3 ? 'options activecolor' : 'options'"
          id="select"
          @mouseenter.stop="
            getPageChildClassiftyList('showSelect2', 'classify2', 42)
          "
          @mouseleave.stop="showSelect2 = false"
        >
          <div @click="changeClassify(42)">{{ changename2 }}</div>
          <i
            v-if="Inspiration"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-arrow-down"
          ></i>
          <i
            v-if="!Inspiration"
            @click="getInspiration"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-close"
          ></i>
          <div class="selectList" v-show="showSelect2">
            <div class="select-left">
              <div v-for="(item, index) in classify2" :key="item.value">
                <div
                  class="hover1"
                  @click="change(item.value, item.label, 2, 1)"
                  @mouseover.stop="changeclass($event, item.value, 1)"
                >
                  {{ item.label }}
                </div>
                <div class="select-right" v-if="index == 1">
                  <div v-for="(item2, index2) in childClass" :key="item2.value">
                    <div
                      class="hover2"
                      @click="change(item2.value, item2.label, 2, 1)"
                      @mouseover.stop="changeclass($event, item2.value, 2)"
                    >
                      {{ item2.label }}
                    </div>
                    <div class="select-right2" v-if="index2 == 1">
                      <div v-for="item5 in childClass1" :key="item5.value">
                        <div
                          class="hover"
                          @click="change(item5.value, item5.label, 2)"
                        >
                          {{ item5.label }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="opindex == 4 ? 'options activecolor' : 'options'"
          @mouseenter.stop="
            getPageChildClassiftyList('showSelect3', 'classify3', 41)
          "
          @mouseleave="showSelect3 = false"
        >
          <div @click="changeClassify(41)">{{ changename3 }}</div>
          <i
            v-if="Share"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-arrow-down"
          ></i>
          <i
            v-if="!Share"
            @click="getShare"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-close"
          ></i>
          <div class="selectList3" v-show="showSelect3">
            <div class="select-left">
              <div v-for="item6 in classify3" :key="item6.value">
                <div class="hover" @click="change(item6.value, item6.label, 3)">
                  {{ item6.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="opindex == 5 ? 'options activecolor' : 'options'"
          @mouseenter.stop="showSelect4 = true"
          @mouseleave="showSelect4 = false"
        >
          <div>{{ changename4 || "排行榜" }}</div>
          <i
            v-if="ranking"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-arrow-down"
          ></i>
          <i
            v-if="!ranking"
            @click="getRanking"
            style="margin-left: 1px; margin-top: 3px"
            class="el-icon-close"
          ></i>
          <div class="selectList3" v-show="showSelect4">
            <div class="select-left">
              <div v-for="item6 in classify4" :key="item6.value">
                <div class="hover" @click="changeRank(item6.label, item6.value)">
                  {{ item6.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div :class="opindex==5 ? 'options activecolor':'options'" @click="getNews">
					最新
				</div> -->
        <div
          :class="opindex == 6 ? 'options activecolor' : 'options'"
          @click="getSelect"
        >
          推荐星标作品
        </div>
        <div class="search">
          <div class="search-left">
            <el-select
              v-model="type"
              placeholder="请选择"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-center">
            <input
              type="text"
              @keydown.enter="search"
              v-model="searchtext"
              placeholder="搜索作品名或用户昵称"
              class="search-input"
            />
          </div>
          <div class="search-right">
            <button @click="search" class="search-go"></button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 0 auto; scroll-behavior: smooth">
      <!-- <router-view></router-view> -->
      <newest :queryParams="{ ...newestObj }" ref="newest" :key="newestKey" />
    </div>
  </div>
</template>

<script>
//import Swiper from 'swiper';
import { getClassifyList, getChildList } from "@/api/work.js";
import newest from "@/views/home/newest.vue";
export default {
  name: "VHeader",
  data() {
    return {
      isbar: false,
      opindex: 1,
      showNews: true,
      options2: [
        {
          value: "username",
          label: "用户",
        },
        {
          value: "title",
          label: "作品",
        },
      ],
      type: "title",
      searchtext: undefined,
      latest: 1,
      classify2: [],
      classify3: [],
      classify4: [
        {
          value: "2",
          label: "浏览量",
        },
        {
          value: "3",
          label: "本月点赞排行",
        },
        {
          value: "4",
          label: "历史点赞排行",
        },
      ],
      childClass: [],
      childClass1: [],
      childClass2: [],
      showSelect: false,
      showSelect2: false,
      showSelect3: false,
      showSelect4: false,
      changename: "",
      changename2: "",
      changename3: "",
      changename4: "",
      classify1: [],
      Original: true,
      Inspiration: true,
      Share: true,
      ranking: true,
      newestKey: Math.random(),
      newestObj: {
        userName: undefined,
        title: undefined,
        classify: undefined,
        classifyId: undefined,
        type: undefined,
      },
    };
  },

  components: {
    newest,
  },
  watch: {
    "$route.query.latest"(newVla, oldVla) {
      this.latest = newVla;
    },
    searchtext(newVal) {
      if (newVal) {
        if (this.type === "username") {
          this.newestObj.userName = this.searchtext;
        }
        if (this.type === "title") {
          this.newestObj.title = this.searchtext;
        }
      } else {
        this.newestObj.userName = undefined;
        this.newestObj.title = undefined;
      }
    },
  },

  created() {
    this.getPageClassifyList();
    if (this.$route.query.latest) {
      this.latest = this.$route.query.latest;
    }
  },
  mounted() {
    // this.$router.push('/home/newest?activeIndex=0&latest=1');
    window.addEventListener("scroll", this.handleScroll);

  },
  methods: {
    changeClassify(id) {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      if (parseInt(id) === 40) {
        this.opindex = 2;
      }
      if (parseInt(id) === 42) {
        this.opindex = 3;
      }
      if (parseInt(id) === 41) {
        this.opindex = 4;
      }
      this.newestObj.classify = id;
      this.newestObj.type = 1;
      this.newestKey = Math.random();
      //this.$refs['newest'].getWorkClassInfos();
      // this.Original = parseInt(id) === 40;
      // this.Inspiration = parseInt(id) === 42;
      // this.Share = parseInt(id) === 41;
    },
    getOriginal() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.opindex = 2;
      //this.changeClassify(40);
      this.Original = true;
      this.newestObj.classifyId = undefined;
      this.newestObj.classify = 40;
      this.newestObj.type = 1;
      this.newestKey = Math.random();
    },
    getInspiration() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.opindex = 3;
      //this.changeClassify(42);
      this.Inspiration = true;
      this.newestObj.classifyId = undefined;
      this.newestObj.classify = 42;
      this.newestObj.type = 1;
      this.newestKey = Math.random();
    },
    getShare() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.opindex = 4;
      //this.changeClassify(41);
      this.Share = true;
      this.newestObj.classifyId = undefined;
      this.newestObj.classify = 41;
      this.newestObj.type = 1;
      this.newestKey = Math.random();
    },
    getRanking() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.changename4 = "";
      this.ranking = true;
      this.newestObj.classifyId = undefined;
      this.newestObj.type = undefined;
      this.opindex = 1;
      this.newestKey = Math.random();
    },
    // changeclass1(index, name) {
    // 	this.changename = name;
    // 	this.changename2 = '灵感';
    // 	this.changename3 = '技巧';
    // 	this.opindex = 2;
    // 	this.Original = false;
    // },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 320) {
        this.isbar = true;
      } else {
        this.isbar = false;
      }
    },
    change(id, label, type, type2) {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      let string = "";
      let latest = this.latest;
      if (type2 == 1) {
        string = id + ",";
        string = string.slice(0, -1);
        this.changename2 = label;
        // this.changename = '原创';
        this.changename = classifyName[0].classifyName;
        this.changename3 = classifyName[1].classifyName;
        // this.changename3 = '技巧';
        //this.$router.push('/home/newest?activeIndex=0&latest=' + latest + '&classify=' + string)
        this.newestObj.classify = string;
        this.newestObj.classifyId = undefined;
        this.newestObj.type = 1;
        this.newestKey = Math.random();
      } else {
        if (label == "不限") {
          string = id + ",";
          string = string.slice(0, -1);
          //this.$router.push('/home/newest?activeIndex=0&latest=' + latest + '&classify=' + string);
          this.newestObj.classify = string;
          this.newestObj.classifyId = undefined;
          this.newestObj.type = 1;
          this.newestKey = Math.random();
        } else if (label !== "不限") {
          string = id;
          //this.$router.push('/home/newest?activeIndex=0&latest=' + latest + '&classifyId=' + string);
          this.newestObj.classify = undefined;
          this.newestObj.classifyId = string;
          this.newestObj.type = undefined;
          this.newestKey = Math.random();
        }
      }
      if (type == 1) {
        this.opindex = 2;
        this.changename = label;
        this.changename2 = classifyName[1].classifyName;
        this.changename3 = classifyName[2].classifyName;
        this.Original = false;
        this.Inspiration = true;
        this.Share = true;
      } else if (type == 2) {
        this.opindex = 3;
        this.changename2 = label;
        this.changename = classifyName[0].classifyName;
        this.changename3 = classifyName[2].classifyName;
        this.Original = true;
        this.Share = true;
        this.Inspiration = false;
      } else if (type == 3) {
        this.opindex = 4;
        this.changename3 = label;
        this.changename = classifyName[0].classifyName;
        this.changename2 = classifyName[1].classifyName;
        this.Original = true;
        this.Inspiration = true;
        this.Share = false;
      }
    },
    // 点击修改排行耪
    changeRank(label, type) {
      this.opindex = 5;
			let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.changename4 = label;
      this.ranking = false;
			this.newestObj.type = type;
			this.newestObj.classifyId = undefined;
			this.newestObj.classify = undefined;
			this.newestKey = Math.random();
    },
    changeclass(e, id, type) {
      console.log(e);
      if (type == 1) {
        let hover1 = document.getElementsByClassName("hover1");
        hover1 = Array.from(hover1);
        hover1.forEach((i, index) => {
          i.classList.remove("ishover");
   
          if (index == hover1.length - 1) {
            e.target.classList.add("ishover");
          }
        });
      } else if (type == 2) {
        let hover2 = document.getElementsByClassName("hover2");
        hover2 = Array.from(hover2);
        hover2.forEach((i, index) => {
          i.classList.remove("ishover");
          if (index == hover2.length - 1) {
            e.target.classList.add("ishover");
          }
        });
      }

      let queryData = {
        queryType: 1,
        parentId: id,
      };
      if (type == 2) {
        let queryData = {
          queryType: 1,
          parentId: id,
        };
        getChildList(queryData).then((res) => {
          if (res.code == 200) {
            (this.childClass1 = [
              {
                label: "不限",
                value: id,
              },
            ]),
              res.data.forEach((i, index) => {
                this.childClass1.push({
                  label: i.classifyName,
                  value: i.id,
                });
              });
          }
        });
      } else if (type == 1) {
        getChildList(queryData).then((res) => {
          if (res.code == 200) {
            (this.childClass = []),
              (this.childClass1 = []),
              res.data.forEach((i, index) => {
                this.childClass.push({
                  label: i.classifyName,
                  value: i.id,
                });
              });
          }
        });
      }
    },
    rewrite() {
      this.newestObj.classify = undefined;
      this.newestObj.classifyId = undefined;
      this.newestObj.type = undefined;
      this.latest = 1;
      this.opindex = 1;
      this.newestKey = Math.random();
      //window.location.reload()
    },
    search() {
      // let latest = this.latest
      // let type = this.type
      // let search = this.searchtext
      //this.$router.push('/home/newest?activeIndex=0&latest=' + latest + '&type=' + type + '&search=' + search)
      if (this.type === "username") {
        this.newestObj.userName = this.searchtext;
        this.newestObj.title = undefined;
      }
      if (this.type === "title") {
        this.newestObj.title = this.searchtext;
        this.newestObj.userName = undefined;
      }
      this.newestKey = Math.random();
      //this.$refs['newest'].getWorkClassInfos();
    },
    getNews() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.latest = 1;
      this.opindex = 5;
      this.Original = true;
      this.Inspiration = true;
      this.Share = true;
      this.newestObj.type = 1;
      this.newestObj.classify = undefined;
      this.newestObj.classifyId = undefined;
      this.newestKey = Math.random();
      //this.$router.push('/home/newest?activeIndex=0&latest=1');
      // this.changename = '原创';
      // this.changename2 = '灵感';
      // this.changename3 = '技巧';
    },
    getSelect() {
      let classifyName = JSON.parse(localStorage.getItem("classifyName"));
      this.changename = classifyName[0].classifyName;
      this.changename2 = classifyName[1].classifyName;
      this.changename3 = classifyName[2].classifyName;
      this.latest = 2;
      this.opindex = 6;
      this.Original = true;
      this.Inspiration = true;
      this.Share = true;
      this.newestObj.type = 1;
      this.newestObj.classify = undefined;
      this.newestObj.classifyId = undefined;
      this.newestKey = Math.random();
      // this.$router.push('/home/newest?activeIndex=0&latest=2');
      // this.changename = '原创';
      // this.changename2 = '灵感';
      // this.changename3 = '技巧';
    },
    async getPageClassifyList() {
      let state = {
        queryType: 1,
      };
      let { code, data } = await getClassifyList(state);
      if (code == 200) {
     
        this.changename = data[0].classifyName;
        this.changename2 = data[1].classifyName;
        this.changename3 = data[2].classifyName;
        let classifyName = JSON.stringify(data);
        localStorage.setItem("classifyName", classifyName);
      }
    },
    // 鼠标移入菜单栏触发
    getPageChildClassiftyList(showName, ArrName, parentId, queryType = 1) {
      getChildList({ parentId, queryType }).then((res) => {
        if (res.code == 200) {
          this[ArrName] = [];
          this.childClass = [];
          this.childClass1 = [];
          res.data.forEach((i, index) => {
            this[ArrName].push({
              label: i.classifyName,
              value: i.id,
            });
            this[showName] = true;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-container {
  min-height: 600px;
  background: #181a29;

  .home-contop {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181a29;
    position: relative;
    .ishover {
      background-color: #00b3e2;
      width: 140px;
    }
    .hover {
      &:hover {
        background-color: #00b3e2;
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #181a29 10%, #323547, #181a29 90%);
      position: absolute;
      bottom: 0;
    }

    .top-left {
      color: white;
      //width: 380px;
      margin-left: 50px;
      font-family: biaoti;
      transform: scaleY(1.1);
      font-size: 36px;
      letter-spacing: 5px;

      span {
        margin-left: -20px;
        color: #434343;
        opacity: 0.6;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    .top-right {
      display: flex;

      .options {
        color: #fff;
        margin-top: 10px;
        min-width: 60px;
        height: 46px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0 5px;
        &:hover {
          background-color: #3a3c4b;
        }
      }
      .selectList3 {
        position: absolute;
        top: 46px;
        left: 0;
        background-color: #3a3c4b;
        width: 150px;
        height: 180px;
        z-index: 2001;
        display: flex;
        &::after {
          content: "";
          width: 150px;
          position: absolute;
          bottom: -5px;
          height: 5px;
          background-color: #3a3c4b;
        }
        .select-left {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 150px;
          height: 100%;

          div {
            height: 30px;
            font-size: 14px;
            text-indent: 15px;
            line-height: 30px;
            width: 140px;
          }
        }
      }
      .selectList1 {
        position: absolute;
        top: 46px;
        left: 0;
        background-color: #3a3c4b;
        width: 280px;
        height: 180px;
        z-index: 2001;
        display: flex;

        &::after {
          content: "";
          width: 280px;
          position: absolute;
          bottom: -5px;
          height: 5px;
          background-color: #3a3c4b;
        }

        .select-left {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 130px;
          height: 100%;

          div {
            height: 30px;
            font-size: 14px;
            text-indent: 15px;
            line-height: 30px;
            width: 140px;
          }

          .select-right {
            position: absolute;
            top: 0px;
            left: 150px;
            width: 130px;
            height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0px !important;
            }

            div {
              height: 30px;
              font-size: 14px;
              text-indent: 15px;
              line-height: 30px;
            }
          }
        }
      }

      #select {
        .selectList {
          position: absolute;
          top: 46px;
          left: 0;
          background-color: #3a3c4b;
          width: 400px;
          //width: 150px;
          height: 180px;
          z-index: 2001;
          display: flex;

          &::after {
            content: "";
            width: 400px;
            //width: 150px;
            position: absolute;
            bottom: -5px;
            height: 5px;
            background-color: #3a3c4b;
          }

          .select-left {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 130px;
            height: 100%;
            width: 100%;

            div {
              height: 30px;
              font-size: 14px;
              text-indent: 15px;
              line-height: 30px;
              width: 140px;
            }

            .select-right {
              position: absolute;
              top: 0px;
              left: 150px;
              width: 130px;
              height: 100%;

              div {
                height: 30px;
                font-size: 14px;
                text-indent: 15px;
                line-height: 30px;
              }

              .select-right2 {
                position: absolute;
                top: 0px;
                left: 150px;
                width: 100px;
                height: 100%;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                  width: 0px !important;
                }

                div {
                  height: 30px;
                  font-size: 14px;
                  text-indent: 15px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }

      .activecolor {
        &::after {
          content: "";
          position: absolute;
          top: 46px;
          width: calc(100% - 10px);
          height: 3px;
          background-color: #34e8fa;
        }
      }
    }

    .search {
      display: flex;
      width: 291px;
      height: 36px;
      margin-left: 40px;
      margin-top: 12px;
      margin-right: 142px;
      border: 1px solid #323232;

      .search-center {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 160px;
          height: 2px;
          background-color: #666;
          z-index: 999;
          left: 15px;
          bottom: 3px;
        }
      }

      .search-input {
        position: relative;
        height: 36px;
        width: 195px;
        border: none;
        color: white;
        font-size: 14px;
        letter-spacing: 1px;
        text-indent: 15px;
        background-color: #232538;
        caret-color: #666;
      }

      .search-go {
        width: 36px;
        height: 36px;
        background-color: #232538;
        border: none;
        border-radius: 0 1px 1px 0;
        cursor: pointer;
        background-image: url("../../assets/newImg/search.png");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 26px 26px;
      }
    }
  }

  .bar {
    position: fixed;
    z-index: 9999;
    top: 0;
  }
}
</style>
<style lang="scss">
.search-left {
  .el-popper {
    position: absolute !important;
    left: 0;
  }
  .el-input__inner {
    width: 60px !important;
    color: #ffffff;
    background-color: #232538;
    padding-right: 0.875rem !important;
    padding-left: 0.375rem !important;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 1px 0 0 1px;
    letter-spacing: 3px;
    line-height: 2rem;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    // &:hover{
    // 	color: #00ceff !important;
    // }
  }

  .el-input__icon {
    width: 12px;
    margin-right: -3px;
    color: #ffffff;
    font-weight: bold;
  }

  .el-select__caret {
    color: #ffffff !important;
  }

  .el-select-dropdown {
    position: absolute;
    background-color: #232538;
    border: none;
    left: 0 !important;

    .popper__arrow {
      display: none;
    }

    .el-select-dropdown__item {
      color: #fff !important;
      height: 38px;
    }

    .selected {
      color: #ffffff;
      font-weight: 0;
    }

    .hover {
      font-weight: 600 !important;
      background-color: #34e8fa;
    }
  }

  .el-input__icon {
    line-height: 2rem;
    color: rgb(107, 247, 240);
  }

  .el-popper[x-placement^="bottom"] {
    margin-top: 0;
  }
}
</style>
