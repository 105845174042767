<template>
    <div class="medal-container">
        <img :src="value.src" v-for="(value, key) in medalImgList" :key="key" />
    </div>
</template>

<script>
import { getMedalList } from '@/api/message.js'
export default {
    data() {
        return {
            medalImgList: []
        }
    },
    props: {
        userId: {
            type: Number,
            require: true,
            default: undefined
        }
    },
    watch: {
        userId() {
            this.getMedalInfo();
        }
    },
    created(){
        this.getMedalInfo();
    },
    methods: {
        getMedalInfo() {
            console.log(this.userId)
            getMedalList({userId: this.userId}).then(res => {
                if(res.code === 200) {
                    this.medalImgList = [];
                    for(let i in res.data) {
                        if (i != 'uid' && res.data[i]) { //
                            this.medalImgList.push({
                                src: require('@/assets/img/medal/'+i+'/'+res.data[i]+'.png'),
                                key: i
                            })
                        }
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .medal-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
       
        >img{
            width: 50px;
            height: 45px;
        }
            
    }
</style>