<template>
	<div class="personal-container">
		<div class="personal-content">
			<div class='personal-user'
				:style="`background:url('${homePic}') center center /cover no-repeat;width:100%`">
				<div class="users-content">
					<div class="header-img">
						<img :src="userInfo.headImg || require('@/assets/img/1.png')" alt="">
						<p class="users-info-level">Lv. {{levelNum}}</p>
					</div>
					<div class="user-info">
						<div class="user-info-name" :title="userInfo.nickName">{{userInfo.nickName}}</div>
						<Lv v-if="userInfo.id" :userId="userInfo.id" @getLevel="getLevel"></Lv>
					</div>
				</div>
				<Medal :userId="userInfo.id"></Medal>
				<div class="intro">{{userInfo.individualSign || '暂无个性签名'}}</div>
				<div class="data-num">
					<div style="margin-right: 20px;">
						<p>粉丝</p>{{userInfo.fansNum || 0}}
					</div>
					<div class="line"></div>
					<div style="margin-left: 20px;">
						<p>关注</p>{{userInfo.focusNum || 0}}
					</div>
				</div>
				<div class="data-integral" v-if="($cookie.get('userInfo') && userInfo.id === JSON.parse($cookie.get('userInfo')).id)">
					<p>在线课程观看积分：{{courseIntegral || 0}}</p>
				</div>
				<template v-if="!($cookie.get('userInfo') && userInfo.id === JSON.parse($cookie.get('userInfo')).id)">
					<div class="guanzhu"
						v-if="(!userInfo.focusFlag || userInfo.focusFlag == 0)"
						@click="getAddFocus(userInfo.id)"><i class="el-icon-plus"></i>关注</div>
					<div class="guanzhu yiguan"
						v-if="userInfo.focusFlag == 1 && $cookie.get('userInfo') && userInfo.id !== JSON.parse($cookie.get('userInfo')).id"
						@click="getCancelFocus(userInfo.id)">已关注</div>
				</template>
				
				<div class="upBgimg" @click="handleUpdate2" v-if="userInfo.id == $store.state.userInfo.id">
					<img src="../../assets/newImg/upBgimg.png" title="上传背景">
				</div>
			</div>
			<div :class="isbar==true ? 'personal-menu bar':'personal-menu'">
				<div class="menu-left">
					个人主页
					<span>MY WORK</span>
				</div>
				<div class="menu-right">
					<!-- <Menu :menuList="menuList"></Menu> -->
					<template v-if="userInfo.id == $store.state.userInfo.id">
						<div :class="consIndex==item.value ? 'menu-options menuactive' : 'menu-options'"
							v-for="item in menuList2" :key="item.value" @click="getdetail(item.value)">
							{{ item.cons }}
						</div>
					</template>
					
					<!-- <div v-if="userInfo.id !== $store.state.userInfo.id"
						:class="consIndex==index ? 'menu-options menuactive' : 'menu-options'"
						v-for="(item,index) in menuList2" :key="index" @click="getdetail(index)">
						{{ item.cons }}
					</div>  -->
				</div>
			</div>
			<div class="personal-data">
				<Worklist v-if="consIndex==0" />
				<Worklist v-if="consIndex==1" :classify="40"/>
				<Inspiration v-if="consIndex==2"/>
				<Skill v-if="consIndex == 3" />
				<my-recurit v-if="consIndex==4"></my-recurit>
				<Collection v-if="(consIndex==5 || consIndex==8)" :consIndex="consIndex" />
				<Fous v-if="consIndex==6" />
				<Fans v-if="consIndex==7" />
			</div>
			<el-dialog :title="title" :visible.sync="open" width="900px" :before-close="cancel" append-to-body :close-on-click-modal="false">
				<span style="position: absolute;top: 30px;left: 140px;letter-spacing: 2px;font-size: 12px;">Seller Information</span>
				<el-form ref="form" :model="form" :rules="rules" label-width="100px">
					<el-form-item class="coverimg" prop="headImg">
						<div class="uploadImg">
							<img class="headImg" style="object-fit: cover;" :src="form.headImg" alt="">
							<p style="cursor: pointer;color: aqua;margin-top: -10px;text-align: center;margin-left: -10px;" @click="close">更换头像</p>
						</div>
						<div class="head-info">
							<div class="layout" v-if='layoutBole'>
								<div class="posterCon">
									<div class="title">上传头像 <span @click='close'></span></div>
									<div>
										<div class="cutter">
											<ImgCutter ref="imgCutterModal" fileType="jpeg" :crossOrigin="true"
												crossOriginHeader="*" rate="" :tool="true" toolBgc="none"
												:isModal="false" :showChooseBtn="true" :lockScroll="true"
												:boxWidth="306" :boxHeight="306" :cutWidth="200" :cutHeight="200"
												:sizeChange="false" :moveAble="true" :imgMove="true"
												:originalGraph="true" :smallToUpload="false" :saveCutPosition="false"
												:scaleAble="true" @onPrintImg='selectPoster' :previewMode="true">
											</ImgCutter>
										</div>
										<div class="prev">
											<div class="avatar">
												<img :src="poster.dataURL" alt="" v-if='poster' class="posterImg">
											</div>
											<div class="avatarDisplay">头像预览</div>
											<div style="color: #FFFFFF;text-align: center;margin-top: 60px;">
												滚轮可以放大缩小哦</div>
										</div>
									</div>
									<div class="sure">
										<span @click='postImg'>确 认</span>
										<span @click='close'>取 消</span>
									</div>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="账号" prop="account">
						<el-input v-model="form.account" disabled placeholder="请输入账号" style="width: 70%" />
					</el-form-item>
					<el-form-item label="昵称" prop="nickName">
						<el-input v-model="form.nickName" :maxlength="10" show-word-limit placeholder="请输入昵称"
							style="width: 70%" />
					</el-form-item>
					<el-form-item label="性别">
						<el-radio-group v-model="form.gender">
							<el-radio v-for="dict in genderOptions" :key="dict.dictValue"
								:label="parseInt(dict.dictValue)">{{dict.dictLabel}}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="简介" prop="individualSign">
						<el-input v-model="form.individualSign" :maxlength="50" show-word-limit type="textarea"
							placeholder="请输入内容" style="width: 70%" />
					</el-form-item>
					<el-form-item class="intro-item" prop="city" label="城市">
						<v-distpicker class="distpicker" @selected="onSelected" :province="select.province"
							:city="select.city" :area="select.area"></v-distpicker>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<button class="btn1" @click="submitForm" :disabled="isDisabled">保 存</button>
					<button class="btn2" @click="cancel">取 消</button>
				</div>
			</el-dialog>
			<el-dialog :title="title" :visible.sync="open2" width="900px" append-to-body :close-on-click-modal="false">
				<el-form ref="form" :model="form" :rules="rules" label-width="100px">
					<el-form-item label="" prop="headImg">
						<div class="uploadImg">
							<img class="bgImg" style="object-fit: cover;" :src="form.backgroundImg" alt="">
							<img src="@/assets/img/homePage/upload.png" @click="close2"
								style="margin-left:30px;cursor: pointer" class="img-default">
							<img src="@/assets/img/homePage/upload1.png" @click="close2"
								style="margin-left:30px;cursor: pointer" class="img-high">
						</div>
						<div class="head-info">
							<div style="color: #FFFFFF">温馨提示：仅支持JPG、PNG，限10M以内</div>
							<div class="layout" v-if='layoutBole2'>
								<div class="posterCon">
									<div class="title">上传背景 <span @click='close2'></span></div>
									<div>
										<div class="cutter">
											<ImgCutter ref="imgCutterModal" fileType="jpeg" :crossOrigin="true"
												crossOriginHeader="*" rate="" :tool="true" toolBgc="none"
												:isModal="false" :showChooseBtn="true" :lockScroll="true"
												:boxWidth="306" :boxHeight="306" :cutWidth="240" :cutHeight="80"
												:sizeChange="false" :moveAble="true" :imgMove="true"
												:originalGraph="true" :smallToUpload="false" :saveCutPosition="false"
												:scaleAble="true" @onPrintImg='selectPoster' :previewMode="true">
											</ImgCutter>
										</div>
										<div class="prev">
											<div class="avatar2">
												<img :src="poster.dataURL" alt="" v-if='poster' class="posterImg">
											</div>
											<div class="avatarDisplay">背景预览</div>
											<div style="color: #FFFFFF;text-align: center;margin-top: 60px;">
												滚轮可以放大缩小哦</div>
										</div>
									</div>
									<div class="sure">
										<span @click='postImg2'>确 认</span>
										<span @click='close2'>取 消</span>
									</div>
								</div>
							</div>
						</div>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<button class="btn1" @click="submitForm2">保 存</button>
					<button class="btn2" @click="cancel">取 消</button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import Worklist from '@/component/personalHome/work.vue';
	import Collection from '@/component/personalHome/collection.vue';
	import Fous from '@/component/personalHome/fous.vue';
	import Fans from '@/component/personalHome/fans.vue';
	import Inspiration from '@/component/personalHome/inspiration.vue';
	import Skill from '@/component/personalHome/skill.vue'
	import recruitBox from './components/recruit.vue'
	import VDistpicker from 'v-distpicker';
	import ImgCutter from 'vue-img-cutter'
	import Lv from '@/component/lv/newIndex.vue'
	import Menu from '@/component/menu/index.vue'
	import Medal from "@/component/medal/index.vue";
	import MyRecurit from './components/recruit.vue'
	import {
		getuserInfos,
		getuserInfo_loginout,
		updateInfo,
		updateInfo2,
		getDicts,
		getCourseIntegral
	} from '@/api/message.js';
	import {
		fileUpload,
		addFocus,
		cancelFocus,
		bgUpload,
		getClassifyList,
    	getChildList
	} from '@/api/work.js';
	export default {
		data() {
			return {
				isbar: false,
				homePic: require('@/assets/img/4.png'),
				menuList: [],
				menuList2: [{
						cons: '全部',
						value: 0
					},{
						cons: '作品',
						value: 1
					},
					{
						cons: '灵感',
						value: 2
					},
					{
						cons: '技巧',
						value: 3
					},
					{
						cons: '招聘',
						value: 4
					},
					{
						cons: '收藏',
						value: 5
					},
					{
						cons: '心动岗位',
						value: 8
					},
					{
						cons: '关注',
						value: 6
					},
					{
						cons: '粉丝',
						value: 7
					},
					// {cons:'点赞'}
				],
				menupopList: [{
					name: '收藏', 
					id: 1
				},{
					name: '关注', 
					id: 2
				},{
					name: '粉丝', 
					id: 3
				}],
				consIndex: 0,
				userInfo: {},
				editInfoFlag: false,
				open: false,
				open2: false,
				title: '',
				layoutBole: false,
				layoutBole2: false,
				poster: '',
				postPosterUrl: '',
				postPosterUrl2: '',
				upBgimg: 0,
				// 角色类型字典
				roleTypeOptions: [],
				// 性别：1：男，2：女，3：保密
				genderOptions: [],
				// action:'http://www.glsxvfx'+"/api/file/upload",
				action: process.env.VUE_APP_BASE_API + "/api/file/upload",
				imageUrl: '',

				select: {
					province: '',
					city: '',
					area: ''
				},
				showIntroFlag: false,
				form: {
					id: null,
					account: null,
					userName: null,
					nickName: null,
					headImg: null,
					backgroundImg: null,
					intro: null,
					gender: null,
					birthday: null,
					city: null,
					individualSign: null,
					province: '',
					city: '',
					area: '',
				},
				testList: [],
				// 表单校验
				rules: {

				},
				levelNum: undefined,
				// 是否禁止按钮点击
				isDisabled: false,
				// 在线课程积分
				courseIntegral: 0
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			this.getCourseIntegral();
		},
		methods: {
			// 获取积分
			getCourseIntegral() {
				getCourseIntegral().then(res => {
					if (res.code === 200) {
						this.courseIntegral = res.data || 0
					}
				})
			},
			// 获取菜单内容
			getPageClassifyList() {
				getClassifyList({queryType: 1}).then(res => {
					if (res.code === 200) {
						res.data.forEach((item, index) => {
							let menu = {name: item.classifyName, id: item.id}
							this.menuList.push(menu);
							this.getPageChildList(item.id, index)
						})
						this.menuList = this.menuList.concat(this.menupopList);
					}
				})

			},
			// 获取菜单子内容
			async getPageChildList(id, index) {
				let childList = [];
				let data = await this.getChildAPI(id);
				if (!data.length) {
					return
				}
				let that = this;
				const fn = function(data) {
					data.forEach(async function(item) {
						let citem = item;
						let childData = await that.getChildAPI(citem.id);
						if(childData && childData.length) {
							that.$set(citem, 'childList', childData);
							fn(childData);
						}
					})
				}
				fn(data);
				if (data && data.length) {
					this.$set(this.menuList[index], 'childList', data);
				}
			},
			async getChildAPI(id) {
				return new Promise((resolve, reject) => {
					getChildList({
						queryType: 1,
						parentId: id
					}).then(res => {
						if (res.code === 200) {
							let rearr = [];
							res.data.forEach(item => {
								let citem = {name: item.classifyName, id: item.id};
								rearr.push(citem);
							})
							resolve(rearr);
						}
					})
				})
			},
			// 获取等级
			getLevel(event) {
				this.levelNum = event
			},
			handleScroll() {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop >= 380) {
					this.isbar = true;
				} else {
					this.isbar = false;
				}
			},
			getdetail(index) {
				this.consIndex = index;
			},
			async getUserInfo(id) {
				console.log(id);
				if(!this.$cookie.get('userInfo')) {
					let {
						code,
						data
					} = await getuserInfo_loginout(id);
					if (code == 200) {
						this.userInfo = data;
						this.form = this.userInfo;
						if (this.userInfo.backgroundImg == null) {
							this.homePic = require('@/assets/img/4.png')
						} else {
							this.homePic = this.userInfo.backgroundImg
						}
						this.$store.commit('setworkUserId', data)
					}
				} else {
					let {
						code,
						data
					} = await getuserInfos(id);
					if (code == 200) {
						this.userInfo = data;
						let cityArr = data.city ? data.city.split('-') : [];
						this.select.province = cityArr[0] || '';
						this.select.city = cityArr[1] || ''
						this.select.area = cityArr[2] || ''
						this.form = this.userInfo;
						if (this.userInfo.backgroundImg == null) {
							this.homePic = require('@/assets/img/4.png')
						} else {
							this.homePic = this.userInfo.backgroundImg
						}
						this.$store.commit('setworkUserId', data)
					}
				}
				if (this.userInfo.backgroundImg != null) {
					this.homePic = this.userInfo.backgroundImg
				}
			},
			async getGenderDicts(type) {
				let {
					code,
					data
				} = await getDicts(type);
				if (code == 200) {
					this.genderOptions = data;
				}
			},

			// 关注和取消关注
			async getAddFocus(userId) {
				if (!this.$cookie.get('userInfo')) {
					this.$store.commit('setShowLoginDialog', true);
        			return false;
				}
				let data = {
					toUserId: userId,
					userId: JSON.parse(this.$cookie.get('userInfo')).id,
					headImg: JSON.parse(this.$cookie.get('userInfo')).headImg
				}
				let {
					code
				} = await addFocus(data);
				if (code === 200) {
					this.$message({
						type: 'success',
						offset: 80,
						message: "关注成功"
					});
					this.getUserInfo(userId);
				}
			},
			async getCancelFocus(userId) {
				let data = {
					toUserId: userId,
					userId: JSON.parse(this.$cookie.get('userInfo')).id
				}
				let {
					code
				} = await cancelFocus(data);
				if (code === 200) {
					this.$message({
						type: 'success',
						offset: 80,
						message: "取消关注成功"
					});
					this.getUserInfo(userId);
				}
			},

			// 修改个人信息
			handleUpdate() {
				const id = this.userInfo.id;
				this.getUserInfo(id);
				// this.form = this.userInfo;
				this.imageUrl = this.userInfo.headImg;
				if (this.form.city) {
					const addr = this.form.city.split("-");
					if (addr) {
						if (addr.length == 1) {
							this.select = {
								province: addr[0],
							}
						}
						if (addr.length == 2) {
							this.select = {
								province: addr[0],
								city: addr[1],
							}
						}
						if (addr.length == 3) {
							this.select = {
								province: addr[0],
								city: addr[1],
								area: addr[2],
							}
						}
					}
				}
				this.title = "个人资料";
				this.open = true;
			},
			handleUpdate2() {
				const id = this.userInfo.id;
				this.getUserInfo(id);
				//this.form = this.userInfo;
				this.title = "修改个人背景";
				this.open2 = true;
			},
			// 表单提交
			submitForm() {
				this.$refs["form"].validate(valid => {
					if (valid) {
						if (this.form.id != null) {
							this.isDisabled = true;
							updateInfo(this.form).then(response => {
								if (response.code === 200) {
									this.$message.success(response.msg || "修改成功");
									this.open = false;
									setTimeout(() => {
										this.userInfo = response.data;
										this.$store.state.userInfo = response.data;
										this.$store.commit('setworkUserId', response.data)
										this.$cookie.set('userInfo', response.data);
										this.$store.commit('setUserInfo', JSON.parse(this.$cookie.get('userInfo')));
										this.isDisabled = false;
										this.$router.push('/personalhome?isShowMsg=2');
									}, 1000)
								} else {
									this.isDisabled = false;
								}
							});
						}
					}
				});
			},
			submitForm2() {
				this.$refs["form"].validate(valid => {
					if (valid) {
						if (this.form.id != null) {
							let data = {
								id: this.form.id,
								backgroundImg: this.form.backgroundImg
							}
							updateInfo2(data).then(response => {
								if (response.code === 200) {
									this.$message.success("修改成功");
									setTimeout(() => {
										this.open2 = false;
										window.location.reload();
									}, 1000)
								}
							});
						}
					}
				});
			},
			// 取消按钮
			cancel() {
				this.open = false;
				this.open2 = false;
				this.$router.push('/personalhome?isShowMsg=2');
				this.select = {
					province: '',
					city: '',
					area: ''
				};
				this.reset();
			},
			// 表单重置
			reset() {
				this.form = {
					id: null,
					account: null,
					headImg: null,
					backgroundImg: null,
					userName: null,
					nickName: null,
					roleType: null,
					intro: null,
					gender: null,
					birthday: null,
					city: null,
					individualSign: null,
				};
			},

			// 上传图片
			handleAvatarSuccess(response, file, fileList) {
				this.form.headImg = response.url;
				this.imageUrl = response.url;
			},
			beforeAvatarUpload(response, file, fileList) {

			},
			// 上传图片
			succesPicture(response, file, fileList) {
				//判断存储的数组长度
				this.imageList.push({
					url: response.url,
					fileType: 1,
					fileName: response.fileName
				});
			},

			// 地址选择
			onSelected(data) {
				this.form.city = data.province.value + "-" + data.city.value + "-" + data.area.value;
			},

			showIntro() {
				this.showIntroFlag = !this.showIntroFlag;
			},
			hiddenIntro() {
				this.showIntroFlag = false;
			},

			close() {
				this.layoutBole = !this.layoutBole;
			},
			close2() {
				this.layoutBole2 = !this.layoutBole2;
			},
			postImg() {
				let formdata = new FormData();
				const _that = this;
				if (this.poster.file) {
					const isJPG = this.poster.file.type === 'image/jpeg'
					const isPng = this.poster.file.type === 'image/png'
					const isLt10M = this.poster.file.size / 1024 / 1024 < 10
					if (!isJPG && !isPng) {
						_that.$message.error("只支持jpg/png格式");
						return;
					}
					if (!isLt10M) {
						_that.$message.error("图片不能超过10M！");
						return;
					}
					formdata.append('file', this.poster.file);
					fileUpload(formdata).then(res => {
						_that.postPosterUrl = res.data.url;
						_that.form.headImg = res.data.url;
					})
				}
				this.layoutBole = false
			},
			postImg2() {
				let formdata2 = new FormData();
				const _that = this;
				if (this.poster.file) {
					const isJPG2 = this.poster.file.type === 'image/jpeg'
					const isPng2 = this.poster.file.type === 'image/png'
					const isLt10M2 = this.poster.file.size / 1024 / 1024 < 10
					if (!isJPG2 && !isPng2) {
						_that.$message.error("只支持jpg/png格式");
						return;
					}
					if (!isLt10M2) {
						_that.$message.error("图片不能超过10M！");
						return;
					}
					formdata2.append('file', this.poster.file);
					fileUpload(formdata2).then(res => {
						_that.postPosterUrl = res.data.url;
						_that.form.backgroundImg = res.data.url;
					})
				}
				this.layoutBole2 = false
				
			},
			//cutDown
			selectPoster(res) {
				this.poster = res;
				this.form.coverImgUrl = res.dataURL;
			},



		},
		watch: {
			'$route.query.usersId'(newVla, oldVla) {
				if (newVla == undefined) {
					this.userInfo = this.$cookie.get('userInfo') ? JSON.parse(this.$cookie.get('userInfo')) : undefined;
					this.$store.commit('setworkUserId', this.userInfo)
				} else {
					this.consIndex = 0;
					this.getUserInfo(newVla);
				}
			},

		},
		created() {
			// this.getExp()
			if (this.$route.query.usersId) {
				this.consIndex = 0;
				this.getUserInfo(this.$route.query.usersId);
			} else {
				
				this.userInfo = this.$cookie.get('userInfo') && this.$cookie.get('userInfo') != 'undefined' ? JSON.parse(this.$cookie.get('userInfo')) : undefined;
				this.getUserInfo(this.userInfo.id);
			}
			if (this.$route.query.open == 1) {
				this.handleUpdate();
			}
			// this.getDicts("tb_role_type").then(response => {
			//     this.roleTypeOptions = response.data;
			// });
			this.getGenderDicts("sys_user_sex");
			//this.getPageClassifyList();
		},
		components: {
			Worklist,
			Collection,
			Skill,
			Fous,
			Fans,
			Inspiration,
			VDistpicker,
			ImgCutter,
			Lv,
			Menu,
			Medal,
			recruitBox,
			MyRecurit
		}
	}
</script>
<style lang="scss" scoped>
	.personal-container {
		width: 100%;
		overflow: hidden;
		min-height: 520px;

		.personal-content {
			width: 100%;
			margin: 0 auto;

			.personal-user {
				width: 100%;
				height: 380px;
				background: #444443;
				opacity: 0.94;
				overflow: hidden;

				.upBgimg {
					cursor: pointer;
					float: right;
					margin-right: 120px;

					img {
						width: 40px;
					}
				}

				.users-content {
					width: 350px;
					margin: 0 auto;
					margin-top: 50px;
					display: flex;
					justify-content: center;

					.header-img {
						width: 100px;
						height: 100px;
						background: #A8A8A8;
						border-radius: 50%;
						cursor: pointer;
						margin: 0 auto;
						position: relative;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							overflow: hidden;
							border-radius: 50%;
						}

						.users-info-level {
							padding: 3px 10px;
							height: 14px;
							background-color: #234767;
							border-radius: 16px;
							font-size: 12px;
							color: #24c3d9;
							border: 1px solid #24c3d9;
							font-weight: 600;
							width: 30px;
							position: absolute;
							top: -10px;
    						left: -45px;
						}
					}

					.user-info {
						width: 200px;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						padding: 10px 0;

						.user-info-name {
							width: 100%;
							height: 31px;
							font-size: 22px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							line-height: 25px;
							color: #FFFFFF;
							text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
							opacity: 1;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.users-info-level {
							padding: 3px 10px;
							height: 14px;
							background-color: #234767;
							border-radius: 16px;
							font-size: 12px;
							color: #24c3d9;
							border: 1px solid #24c3d9;
							font-weight: 600;
							width: 30px;
						}

						.yiguan {
							color: #666;
						}

						.username-lv {

							.names-top {
								display: flex;
								flex-direction: column;
								justify-content: space-around;
							}

							img {
								width: 68px;
								height: 28px;
							}
						}

						.level-v {
							margin-top: 10px;
							width: 100%;
							height: 31px;
							display: flex;

							img {
								width: 24px;
								height: 24px;
							}
						}
					}
				}

				.guanzhu {
					width: 120px;
					height: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					letter-spacing: 3px;
					font-weight: 600;
					cursor: pointer;
					border-radius: 18px;
					//border: 1px solid #ffb83e;
					//background-image: linear-gradient(-90deg, #ffb83e 40%, #fce108);
					color: #1cebf4;
    				background-color: #234767;
					margin: 0 auto;
					i {
						font-weight: bold;
					}
				}

				.intro {
					margin: 0 auto;
					width: 600px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					line-height: 25px;
					// color: rgba(255, 255, 255, 0.8);
					color: #FFFFFF;
					text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
					text-align: center;
					margin-top: 10px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
				}

				.data-num {
					margin: 20px auto;
					max-width: 251px;
					height: 25px;
					display: flex;
					align-items: center;
					justify-content: center;
					

					.line {
						width: 2px;
						height: 30px;
						background-color: #00a2f3;
					}

					div {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						color: #fff;
						line-height: 20px;

						p {
							color: #00c8ec;
							font-size: 12px;
						}
					}
				}

				.data-integral{
					
					color: #FFFFFF;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					font-weight: bold;
					>p{
						display: inline-block;
						padding: 10px;
						background-color: #00c8ec;
						border-radius: 5px;
					}
				}

			}

			.personal-menu {
				width: 100%;
				height: 65px;
				// background-image: linear-gradient(180deg, #1a769d, #132745);
				background-color: #131523;
				// background: #2A2A29;
				opacity: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				&::after {
					content: '';
					width: 100%;
					height: 2px;
					background: linear-gradient(to right, #181A29 10%, #323547, #181A29 90%);
					position: absolute;
					bottom: 0;
				}

				.menu-left {
					color: white;
					width: 380px;
					margin-left: 50px;
					font-family: biaoti;
					transform: scaleY(1.1);
					font-size: 36px;
					letter-spacing: 5px;

					span {
						margin-left: -20px;
						color: #434343;
						opacity: 0.6;
						font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
						font-size: 16px;
						letter-spacing: 1px;
					}
				}
				.menu-right{
					display: flex;
					margin-right: 150px;
				}

				.menu-options {
					box-sizing: border-box;
					height: 65px;
					text-align: center;
					line-height: 75px;
					font-size: 18px;
					font-family: SimHei;
					// font-weight: 800;
					letter-spacing: 4px;
					color: #fff;
					padding-left: 15px;
					padding-right: 15px;
					// color: rgba(255, 255, 255, 0.699);
					text-shadow: 0px 1px 1px rgba(17, 17, 17, 0.7);
					cursor: pointer;

					&:hover {
						color: #00d1ff;
					}
				}

				.menuactive {
					color: #00d1ff;
					opacity: 1;
					border-bottom: 4px solid #00d1ff;
				}
			}

			.personal-means {
				width: 333px;
				height: 882px;
				background: #353849;
				background: url('../../assets/newImg/border.png') center center / contain no-repeat;
				background-size: 100%;
				// background-color: #353849;
				background-position: 0px 0px;
				margin-left: 50px;
				// background: rgba(68, 68, 67, 0.6);
				// box-shadow: 2px 8px 15px rgba(17, 17, 17, 0.6);
				overflow: hidden;

				.means-brief {
					display: flex;
					align-items: center;
					margin-top: 30px;
					margin-left: 20px;

					.means-line {
						width: 6px;
						height: 22px;
						background: #2376D2;
						opacity: 1;
						border-radius: 0px;
					}

					.brief {
						width: 80px;
						height: 26px;
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						line-height: 26px;
						color: #FFFFFF;
						text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
						opacity: 1;
						margin-left: 5px;
					}
				}

				.general {
					width: 90%;
					margin: 0 auto;

					&:first-child {
						margin-top: 23px;
					}

					&:nth-of-type(n+2) {
						margin-top: 15px;
					}

					.means-otions {
						width: 273px;
						display: flex;
						margin-left: -3px;
						align-items: center;

						.means-icons {
							width: 25px;
							height: 25px;

							img {
								width: 17.5px;
								height: 20px;
								object-fit: cover !important;
							}
						}

						.nick {
							width: 270px;
							height: 24px;
							font-size: 16px;
							font-family: Arial;
							font-weight: 400;
							line-height: 24px;
							color: #FFFFFF;
							opacity: 1;
							margin-left: 8px;
							padding-right: 4px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.intro {
							width: 270px;
							min-height: 24px;
							font-family: Arial;
							font-weight: 400;
							line-height: 25px;
							color: #e2e2e2;
							margin-left: 8px;
							padding-right: 4px;
							// display: flex;
							// align-items: center;

							overflow: hidden; //超出隐藏
							text-overflow: ellipsis; //超出文本设置为...
							display: -webkit-box; //将div1转换为盒子模型
							-webkit-line-clamp: 2; //设置div1的文本为2行
							-webkit-box-orient: vertical; //从顶部向底部垂直布置子元素

							.title {
								width: 90px;
								font-size: 16px;
								color: #e2e2e2;
							}

							.content {
								width: 200px;
								font-size: 13px;
								// line-height: 20px;
								color: #e2e2e2;
								cursor: pointer;

								&:hover {
									color: #2D81D7;
									// color: rgba(154, 227, 103, 0.5);
								}
							}
						}

						.intro-show {
							width: 270px;
							min-height: 24px;
							font-family: Arial;
							font-weight: 400;
							line-height: 25px;
							color: #e2e2e2;
							margin-left: 8px;
							padding-right: 4px;

							.title {
								width: 90px;
								font-size: 16px;
								color: #e2e2e2;
							}

							.content {
								width: 200px;
								font-size: 13px;
								color: #e2e2e2;
								cursor: pointer;

								&:hover {
									color: #2D81D7;

									// color: rgba(154, 227, 103, 0.5);
								}
							}
						}
					}

					.dividing {
						width: 293px;
						height: 1px;
						background: rgba(255, 255, 255, 0.1);
						border-radius: 0px;
						margin-top: 14px;
					}
				}

			}

			.personal-data {
				width: 1670px;
				margin: 0 auto;
				//min-height: 851px;
				// background: #353849;
				// background: rgba(68, 68, 67, 0.6);
				// box-shadow: 2px 8px 15px rgba(17, 17, 17, 0.6);
				border-radius: 10px;
			}
		}

		.uploadpocture {
			margin-top: 20px;

			.upload-picture {
				width: 1416px;
				min-height: 350px;
				background: #373737;
				opacity: 1;
				border-radius: 0px;
				margin-top: 1px;
				display: flex;
				overflow: hidden;
				border-radius: 0 0 10px 10px;

				.yuan {
					margin-top: 25px;
				}

				.picture {
					margin-top: 20px;
					margin-left: 13px;

					.add-icon {
						width: 50px;
						height: 50px;
						margin-top: 92px;
					}

					/deep/ .el-upload--picture-card {
						background-color: #fbfdff;
						/* border: 1px dashed #c0ccda; */
						border-radius: 6px;
						box-sizing: border-box;
						/* width: 148px; */
						/* height: 148px; */
						line-height: 146px;
						vertical-align: top;
						width: 260px;
						min-height: 260px;
						background: #2A2A29;
						box-shadow: 0px 5px 6px rgba(17, 17, 17, 0.15);
						opacity: 1;
						border-radius: 5px;
						border: none;
						margin-bottom: 20px;
					}

					.el-upload__text {
						width: 176px;
						height: 63px;
						// font-size: 11px;
						font-family: SourceHanSansCN-Normal;
						line-height: 21px;
						color: #8697ac;
						margin: auto;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						text-shadow: 0px 2px 2px rgba(17, 17, 17, 0.7);
						opacity: 1;
						margin-top: -58px;
					}

					/deep/ .el-upload-list--picture-card .el-upload-list__item {
						width: 260px;
						height: 260px;
					}

					/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	// 上传头像
	.avatar-uploader .avatar {
		// border: 1px dashed #d9d9d9;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		// cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .avatar:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		background: #444443;
		width: 150px;
		height: 150px;
		line-height: 150px;
		text-align: center;
		border-radius: 10px;

		img {
			width: 100% !important;
			height: 100% !important;
		}
	}

	.avatar {
		width: 150px;
		height: 150px;
		display: block;
		border-radius: 10px;
		object-fit: cover;
	}

	.avatar {
		width: 150px;
		height: 150px;
		display: block;
		border-radius: 10px;
		object-fit: cover;
	}



	/deep/ .el-dialog {
		// background: rgba(68, 68, 67, 0.6) !important;
		background: #262736 !important;
		color: #FFFFFF !important;
		border-radius: 10px;
	}

	/deep/ .el-dialog__title {
		font-size: 26px;
		color: #FFFFFF;
	}

	/deep/ .el-dialog__header {
		background: #262736;
		font-family: biaoti;
		transform: scaleY(1.1);
		letter-spacing: 3px;
		font-size: 22px;
		
		// background: #1d1d1d;
		border-radius: 10px 10px 0 0;
	}

	/deep/ .el-dialog__body {
		background: #262736;
		padding-bottom: 0px;
		.coverimg{
			width: 240px;
			position: absolute;
			display: flex;
			justify-content: center;
			right: 0;
			z-index: 1;
			.el-form-item__content{
				margin-left: 0px !important;
			}
		}
	}

	/deep/ .el-dialog__footer {
		background: #262736;
		border-radius: 0 0 10px 10px;
	}

	/deep/ .el-form-item__label {
		color: #FFFFFF !important;
		font-size: 18px !important;
	}

	/deep/ .el-input__inner {
		background: #181a29 !important;
		color: #FFFFFF;
		border: 0;
		font-size: 16px !important;
	}

	/deep/ .el-textarea__inner {
		background: #181a29 !important;
		color: #FFFFFF;
		border: 0;
		// font-size: 16px !important;
		font-size: 15px !important;
		min-height: 75px !important;
	}

	/deep/ .el-radio__label {
		// background: #8b8b8b !important;
		color: #FFFFFF;
		/deep/ .el-radio__inner::after{
				display: none;
		}
		// border: 0;
	}

	/deep/ .dialog-footer {
		margin-bottom: 0px !important;
		display: flex;
		justify-content: space-between;
		padding: 0 38px;
		button{
			width: 85px;
			height: 35px;
			border: none;
			font-size: 16px;
			font-family: SimHei;
			border-radius: 35px;
			cursor: pointer;
		}
		.btn1{
			background-color: #00d0f4;
		}
		.btn2{
			background-color: #323543;
		}
	}

	/deep/.el-textarea .el-input__count {
		color: #FFFFFF !important;
		background: #1B2848 !important;
		height: 15px;
		line-height: 15px;
		margin-right: -9px;
	}

	/deep/ .el-input .el-input__count {
		color: #FFFFFF !important;
		background: #1B2848 !important;
		height: 15px;
		line-height: 15px;
		margin-right: -4px;
	}

	/deep/ .el-input__count-inner {
		color: #FFFFFF !important;
		background: rgba(0, 0, 0, 0) !important;
		// padding: 0 !important;
		height: 15px;
	}

	.head-box {
		display: flex;
		align-items: center;

		.head-info {
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #8697AC;
			margin-left: 26px;
			line-height: normal;

			img {
				cursor: pointer;
				width: 80px;
				height: 33px;
				margin-top: 23px;
			}
		}

		.background-info {
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #8697AC;
			margin-left: 26px;
			line-height: normal;

			img {
				cursor: pointer;
				width: 80px;
				height: 33px;
				margin-top: 23px;
			}
		}
	}

	.headImg {
		width: 120px;
		height: 120px;
		background: #6F758C;
		border-radius: 50%;
		overflow: hidden;
		object-fit: cover;
	}

	.bgImg {
		width: 600px;
		height: 200px;
		background: #6F758C;
		overflow: hidden;
		border: none;
		border-radius: 15px;
		object-fit: cover;
	}

	.img-default {
		display: inline-block;
	}

	.img-high {
		display: none;
	}

	.uploadImg:hover .img-default {
		display: none;
	}

	.uploadImg:hover .img-high {
		display: inline-block;
	}


	/deep/ .distpicker-address-wrapper {
		// background-color: #1B2848 !important;
		select {
			background-color: #181a29 !important;
			color: #FFFFFF;
			margin-left: 12px;
			&:first-child{
				margin-left: 0px;
			}
		}
	}

	.layout {
		background: rgba(0, 0, 0, 0.4);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		z-index: 9999999;
		left: 0;

		div {
			overflow: hidden;
		}

		.sure {
			width: 90%;
			text-align: center;
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 30px auto;
			color: #fff;

			span{
				display: block;
				width: 85px;
				height: 35px;
				text-align: center;
				line-height: 35px;
				border: none;
				font-size: 16px;
				font-family: SimHei;
				border-radius: 35px;
				background-color: #00d0f4;
				cursor: pointer;
				&:nth-child(2){
					background-color: #323543;
				}
			}
		}

		.cutter {
			float: left;
			margin-left: 10px;
			margin-right: 30px;
			padding-top: 15px;

			.h3 {
				font-size: 12px;
				line-height: 40px;
				color: #8697AC;
			}
		}

		.posterCon {
			width: 700px;
			height: 480px;
			// background: #222632;
			background: #2D3043;
			border-radius: 6px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: transLate(-50%, -50%);

			.title {
				line-height: 40px;
				border-bottom: 1px solid #6F758C;
				font-family: SimHei;
				text-align: center;
				letter-spacing: 6px;
				color: #fff;
				font-size: 20px;

				span {
					cursor: pointer;
					background: url(../../assets/img/homePage/nav_icon_close_nor.png) no-repeat center;
					display: inline-block;
					width: 13px;
					height: 13px;
					position: absolute;
					right: 20px;
					top: 12px;
				}
			}

			.prev {
				position: relative;
				margin-top: 35px;

				.avatarDisplay {
					width: 120px;
					height: 20px;
					font-size: 14px;
					text-align: center;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 24px;
					color: #8697AC;
					opacity: 1;
					margin: 8px auto;
				}

				.avatar {
					width: 120px;
					height: 120px;
					border-radius: 50%;
					background: #707070;
					margin: 0 auto;

					.posterImg {
						width: 100%;
						height: 100%;
						margin: 0;
						// margin-top: 8px;
						// margin-left: 7px;
					}
				}

				.avatar2 {
					width: 300px;
					height: 100px;
					border-radius: 12px;
					background: #707070;
					margin: 0 auto;

					.posterImg {
						width: 100%;
						height: 100%;
						margin: 0;
						// margin-top: 8px;
						// margin-left: 7px;
					}
				}

				.audit {
					width: 120px;
					height: 19px;
					font-size: 12px;
					text-align: center;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 24px;
					color: #EC6736;
					opacity: 1;
					margin-top: 8px;
				}

				div {
					font-size: 12px;
					line-height: 24px;
					color: #fff;
				}

				p {
					line-height: 24px;
					color: #8697AC;
					font-size: 12px;
					width: 164px;
				}
			}
		}

		/deep/ .i-dialog-footer {
			display: none !important;
		}

		/deep/ .copyright {
			display: none !important;
		}
	}
	.bar {
		position: fixed !important;
		z-index: 9999;
		top: 0;
	}
</style>
