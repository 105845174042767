<template>
  <div class="index-page-container index-teacher-container">
    <div class="index-page-content index-teacher-content">
      <div class="page-title teacher-title">讲师团队</div>
      <div class="teacher-box">
        <div class="teacher-item" v-for="item in teacherList" :key="item.id">
          <div class="item-header">
            <img v-lazy="item.headerImg" />
          </div>
          <div class="item-info">
            <p class="item-info-name">{{ item.name }}</p>
            <p class="item-info-position">{{ item.position }}</p>
          </div>
          <div class="item-introduce">
            {{ item.introduce }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teacherList: [
        {
          id: 1,
          headerImg: require("@/assets/img/train/teacher/teacher-jxz.png"),
          name: "Jimmy",
          position: "教育部总监",
          introduce:
            "从事特效相关工作10余年，曾参与过影视、游戏、AR/VR等各种特效相关领域；从事游戏特效教育培训相关工作5年，教学经验丰富，管理经验多样。现任职于广州怪力视效网络科技有限公司，负责教育培训部的教学、管理、课程研发等相关工作。",
        },
        {
          id: 2,
          headerImg: require("@/assets/img/train/teacher/teacher-wjx.png"),
          name: "Amon",
          position: "金牌讲师",
          introduce:
            "曾参与《决战平安京》、《漫威超级战争》、《王者荣耀》等多款网易，腾讯大厂的项目，熟练使用PS、3DsMax、Unity3D、UE4等工具，拥有丰富的项目实战经验。任职于广州怪力视效网络科技有限公司，从事Unity特效培训，培养0基础从入门到精通的游戏特效人才。",
        },
        {
          id: 3,
          headerImg: require("@/assets/img/train/teacher/teacher-ocy.png"),
          name: "Jacob",
          position: "项目总监",
          introduce:
            "从事游戏行业8年，现任广州怪力视效特效团队总负责人，主要负责特效团队的项目统筹安排与品控。主要参与过的项目有《决战平安京》《天下3》《阴阳师》《七日之都》《镇魔曲手游》《百闻牌》《超级战争》《王者荣耀》《率土之滨》《机动都市阿尔法》《陈情令》《热血航线》《魂师对决》等百余款游戏",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.index-teacher-container {
  .index-teacher-content {
    .teacher-box {
      margin-top: 155px;
      min-height: 310px;
      display: flex;
      justify-content: space-between;
      width: 1100px;
      .teacher-item {
        width: 240px;
        height: 100%;
        transform: skewY(-5deg);
        border: 1px solid #647a84;
        box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.43);
        background-color: rgba(40, 45, 60, 0.9);
        //position: relative;
        .item-header {
          width: 110px;
          height: 100px;
          border: 1px solid #647a84;
          box-shadow: 0px 12px 10px 0px rgba(0, 0, 0, 0.75);
          background-color: #181c28;
          // position: absolute;
          // top: -55px;
          // left: 50%;
          margin-top: -55px;
          margin-left: 65px;
          overflow: hidden;
          img {
            width: 100%;
            transform: skewY(5deg);
          }
        }
        .item-info {
          //margin-top: -5px;
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          background-image: linear-gradient(to right, #171b26, #343e52);
          //border-width: 5px 0 0 0;
          //border-style: solid;
          //border-image: linear-gradient(to right, #d73860, #6c85e5) 1;
          border-bottom: 1px solid #647a84;
          text-align: center;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background-image: linear-gradient(to right, #d73860, #6c85e5);
          }
          .item-info-name {
            font-size: 24px;
            transform: skewY(5deg);
          }
          .item-info-position {
            font-size: 14px;
            transform: skewY(5deg);
          }
        }
        .item-introduce {
          transform: skewY(5deg);
          padding: 30px 20px;
          font-size: 14px;
          color: #9abfcc;
          height: 260px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>