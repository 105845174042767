<template>
  <div class="index-page-container index-interpolation-container">
    <div class="index-page-content index-interpolation-content">
      <div class="page-title">就业机会ㆍ大厂内推</div>
      <div class="interpolation-box">
        <ul>
          <li><img v-lazy="require('@/assets/img/train/y5.jpg')" /></li>
          <li><img v-lazy="require('@/assets/img/train/y3.jpg')" /></li>
          <li><img v-lazy="require('@/assets/img/train/y8.jpg')" /></li>
          <li><img v-lazy="require('@/assets/img/train/y4.jpg')" /></li>
        </ul>
        <ul>
          <li><img v-lazy="require('@/assets/img/train/y2.jpg')" /></li>
          <li><img v-lazy="require('@/assets/img/train/y6.jpg')" /></li>
          <li><img v-lazy="require('@/assets/img/train/y1.jpg')" /></li>
        </ul>
        <ul>
          <li class="circular-box">
            <span v-for="item in 6" :key="item">●</span>
          </li>
          <!-- style="width: 300px;" -->
          <li><img v-lazy="require('@/assets/img/train/y7.jpg')" /></li>
          <li
            class="circular-box"
            style="
              background-image: linear-gradient(to right, #8db4d4, #6e9ae8);
            "
          >
            <span v-for="item in 6" :key="item">●</span>
          </li>
        </ul>
        <ul>
          <li class="more-company">更多企业</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.index-interpolation-container {
  .index-interpolation-content {
    .interpolation-box {
      margin-top: 100px;
      > ul {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        li {
          width: 250px;
          height: 100px;
          overflow: hidden;
          margin-left: 100px;
          &:nth-child(1) {
            margin-left: 0;
          }
          img {
            width: 100%;
            height: 100%;
            transform: scaleY(1.1);
          }
          span {
            // display: block;
            // width: 10px;
            // height: 10px;
            // border-radius: 50%;
            // // background-color: aqua;
            // line-height: 120px;
          }
        }
        .circular-box {
          display: flex;
          align-items: center;
          width: 220px;
          justify-content: space-between;
          color: transparent;
          background-image: linear-gradient(to right, #c86f8f, #9dbac8);
          -webkit-background-clip: text;
          font-size: 30px;
        }
        .more-company {
          font-size: 32px;
          color: #9abfcc;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}
</style>