<template>
  <div class="message-container">
    <div class="message-content">
      <ul class="message-header">
        <li
          class="header-item"
          :class="messageType === 0 && 'header-item-active'"
          @click="eventChangeType(0)"
        >
          <p>全部通知</p>
        </li>
        <li
          class="header-item"
          :class="messageType === 1 && 'header-item-active'"
          @click="eventChangeType(1)"
        >
          <p>已读通知</p>
        </li>
        <li
          class="header-item"
          :class="messageType === 2 && 'header-item-active'"
          @click="eventChangeType(2)"
        >
          <p>未读通知</p>
        </li>
      </ul>
      <div class="message-list">
        <item
          :info="item"
          v-for="item in messageList"
          :key="item.id"
          @readMessage="readMessage"
          @remove="remove"
        ></item>
      </div>
      <div class="message-btn-box">
        <el-button
          class="btn-item"
          :class="!messageList.length && 'btn-disabled'"
          round
          @click="remove(idList)"
          >一键删除</el-button
        >
        <el-button
          class="btn-item"
          :class="!isRead && 'btn-disabled'"
          round
          @click="readMessage(idList)"
          >一键已读</el-button
        >
      </div>
    </div>
    <div class="paginations">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageNum"
        :page-size="queryForm.pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import item from "./components/item.vue";
import { getMessageDetails, removeMessage } from "@/api/message.js";
export default {
  data() {
    return {
      messageType: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 5,
      },
      isShowkong: false,
      messageList: [],
      total: 0,
    };
  },
  computed: {
    isRead() {
      return this.messageList.filter((v) => {
        return !v.readFlag;
      }).length;
    },
    idList() {
      return this.messageList.map((item) => {
        return item.id;
      });
    },
  },
  components: {
    item,
  },
  created() {
    this.getMessageList();
  },
  methods: {
    eventChangeType(t) {
      this.messageType = t;
      this.queryForm.pageNum = 1;
      this.getMessageList();
    },
    async getMessageList() {
      let readFlag;
      if (this.messageType == 1) {
        readFlag = 1;
      } else if (this.messageType == 2) {
        readFlag = 0;
      }
      let queryData = {
        userId: JSON.parse(this.$cookie.get("userInfo")).id,
        readFlag: readFlag,
        ...this.queryForm,
      };
      let { code, rows, total } = await getMessageDetails(queryData);
      if (code == 200) {
        this.isShowkong = false;
        this.messageList = rows;
        this.total = total;
      }
      // 查询未读邮件数量，有更好方法请修改
      let unreadInfo = await getMessageDetails({
        userId: JSON.parse(this.$cookie.get("userInfo")).id,
        readFlag: 0,
        ...this.queryForm,
      });
      this.$store.commit("setunReadNum", unreadInfo.total);
    },
    readMessage({ ids, flag }) {
      if (!this.isRead) {
        return;
      }
      if (flag) {
        return;
      }
      let queryData = {
        list: ids,
        flag: 1,
      };
      if (flag !== 0) {
        this.$confirm("确认标记为已读？", "", {
          type: "warning",
          center: true,
        })
          .then((_) => {
            this.handleReadFun(queryData);
          })
          .catch((_) => {});
      } else {
        this.handleReadFun(queryData);
      }
    },
    handleReadFun(queryData) {
      removeMessage(queryData).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            offset: 80,
            message: "操作成功",
          });
          this.getMessageList();
        }
      });
    },
    remove(ids = []) {
      if (!this.messageList.length) return;
      this.$confirm("确认删除？", "", {
        type: "warning",
        center: true,
      })
        .then((_) => {
          let queryData = {
            list: ids,
            flag: 2,
          };
          removeMessage(queryData).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                offset: 80,
                message: "操作成功",
              });
              this.getMessageList();
            }
          });
        })
        .catch((_) => {});
    },
    handleCurrentChange(event) {
      this.queryForm.pageNum = event;
      this.getMessageList();
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  width: 100%;
  height: 100vh;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/message/bg.png");
  }
  .message-content {
    width: 700px;
    height: 650px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/login/new/border.png");
    background-size: 100% 100%;
    border-radius: 10px;
    &::after {
      content: "";
      width: 750px;
      height: 750px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("https://glw-1314030635.cos.ap-guangzhou.myqcloud.com/message/bg-round.png");
      background-size: 100% 100%;
      z-index: -1;
    }

    .message-header {
      display: flex;
      width: 100%;
      height: 80px;
      background-image: linear-gradient(105deg, #3a66a5, #6b4fbd);
      color: #ffffff;
      font-size: 18px;
      letter-spacing: 2px;
      border-radius: 10px 10px 0 0;
      padding: 0 20px;
      box-sizing: border-box;
      .header-item {
        flex: 1;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
        font-size: 24px;

        &.header-item-active {
          > p {
            display: inline-block;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 5px;
              width: 100%;
              height: 5px;
              background-color: #35edff;
              border-radius: 2.5px;
            }
          }
        }
      }
    }

    .message-list {
      width: 100%;
      height: calc(100% - 135px);
      padding: 0 5px;
      box-sizing: border-box;
    }
    .message-btn-box {
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;
      .btn-item {
        background-color: #313346;
        border: none;
        color: #89898b;
        &:hover {
          color: #35edff;
          transform: scale(1.05);
        }
        &.btn-disabled {
          background-color: #313346;
          color: #3f4955;
          &:hover {
            transform: scale(1);
          }
        }
      }
    }
  }

  .paginations{
    position: absolute;
    top: 850px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
  }
}
</style>