<template>
  <div class="item-container" @click="eventToDetail">
    <div class="item-left-image">
      <img :src="info.cover" />
    </div>
    <div class="item-right-content">
      <div class="content-header">
        <div class="content-title">
          {{ info.title }}
        </div>
        <!-- <div class="content-time">发布于：{{ recruitInfo.createTime }}</div> -->
      </div>
      <div class="content-status">
        状态：{{ Number(info.flag) === 0 ? "待评价" : "已评价" }} &nbsp; 评分: {{info.scoring}}
      </div>
      <!-- <div class="content-duty">{{getTagText(recruitInfo.postIntro)}}</div> -->
      <div class="content-user">
        <div class="content-user-left">
          <img :src="info.image" />
          <p>{{ info.userName }}</p>
        </div>
        <div class="content-user-right" v-if="isOperate">
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click.stop="eventEdit"
            v-if="Number(info.flag) === 0"
            >修改</el-button
          >
          <el-button type="danger" icon="el-icon-delete" @click.stop="eventDel"
            >删除</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleDeleteWorkById } from "@/api/series.js";
export default {
  props: {
    info: {
      type: Object,
      require: true,
    },
    isOperate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 修改
    eventEdit() {
      this.$emit("edit", this.info.id);
    },
    // 删除
    async eventDel() {
      this.$confirm("是否确认删除该作业?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { code } = await handleDeleteWorkById({
            id: this.info.id,
          });
          if (code === 200) {
            this.$emit('list');
            this.$message({
              type: "success",
              offset: 80,
              message: "删除成功",
            });
          }
        })
        .catch(function () {});
    },
    // 详情界面
    eventToDetail() {
      this.$emit("clickTo", this.info.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #323547, #181a29) 1;
  .item-left-image {
    width: 200px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .item-right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 220px);
    color: #ffffff;
    margin-left: 20px;
    .content-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .content-title {
        font-size: 24px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // width: calc(100% - 300px);
        width: 100%;

        span {
          color: #ff5916;
          margin-left: 20px;
        }
      }
      .content-time {
        width: 250px;
        font-size: 14px;
        //color: #016985;
        text-align: right;
      }
    }
    .content-duty {
      font-size: 14px;
      color: #016985;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .content-label {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      span {
        padding: 0 10px;
        line-height: 20px;
        letter-spacing: 2px;
        border: 1px solid #35edff;
        border-radius: 10px;
        display: inline-block;
        margin-right: 15px;
      }
    }
    .content-user {
      display: flex;
      align-items: center;
      font-size: 14px;
      justify-content: space-between;
      // padding-left: 30px;
      .content-user-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .content-user-auth {
          display: inline-block;
          padding: 2px 5px;
          border: 1px solid #35edff;
          font-size: 12px;
          background-color: #424a61;
          margin-left: 20px;
        }
      }
      .content-user-right {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          margin-right: 10px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>