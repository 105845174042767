<template>
    <div class="item-container">
        <div class="item-left-image">
            <img :src="recruitInfo.cover" />
        </div>
        <div class="item-right-content">
            <div class="content-header">
                <div class="content-title">
                    {{recruitInfo.position}}
                    <span>{{recruitInfo.emolUment}}</span>
                </div>
                <div class="content-time">发布于：{{recruitInfo.createTime}}</div>
            </div>
            <div class="content-duty">{{getTagText(recruitInfo.postIntro)}}</div>
            <div class="content-label">
                <span v-for="(item, index) in getPostWelfareList(recruitInfo.postWelfare)" :key="index">{{item}}</span>
            </div>
            <div class="content-user">
                <div class="content-user-left">
                    <img :src="recruitInfo.authorHead" />{{recruitInfo.authorName}}
                    <!-- <span class="content-user-auth">已认证</span> -->
                </div>
                <div class="content-user-right">
                    <span>
                        <img
                            src="@/assets/newImg/37.png"
                            alt=""
                            title="浏览数"
                            style="width: 27px; height: 22px"
                        />
                        <div>{{recruitInfo.browseNumber}}</div>
                    </span>
                    <span>
                        <img
                            src="@/assets/newImg/38.png"
                            alt=""
                            title="评论数"
                            style="width: 27px; height: 22px"
                        />
                        <div>{{recruitInfo.commentNum}}</div>
                    </span>
                    <span>
                        <img
                            src="@/assets/newImg/39.png"
                            alt=""
                            title="收藏数"
                            style="width: 27px; height: 22px"
                        />
                        <div>{{recruitInfo.colleNum}}</div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        recruitInfo: {
            type: Object,
            require: true
        }
    },
    created() {
        
    },
    methods: {
        // 提取标签中的文本，用于岗位描述
        getTagText(tag) {
            let oDiv = document.createElement('div');
            oDiv.innerHTML = this.escapeStringHTML(JSON.parse(tag));
            let text = oDiv.innerText;
            return text;
        },
        // 福利拆分
        getPostWelfareList(text) {
            return text.split(',').filter(Boolean);
        },
        escapeStringHTML(str) {
            str = str.replace(/&lt;/g, "<");
            str = str.replace(/&gt;/g, ">");
            return str;
        },
    }
}
</script>

<style lang="scss" scoped>
.item-container{
    display: flex;
    padding: 40px 0 20px;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #323547, #181A29) 1;
    .item-left-image{
        width: 200px;
        height: 150px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .item-right-content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 220px);
        color: #FFFFFF;
        margin-left: 20px;
        .content-header{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .content-title{
                font-size: 24px;
                cursor: pointer;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: calc(100% - 300px);

                span{
                    color: #ff5916;
                    margin-left: 20px;
                }
            }
            .content-time{
                width: 250px;
                font-size: 14px;
                //color: #016985;
                text-align: right;
            }
        }
        .content-duty{
            font-size: 14px;
            color: #016985;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .content-label{
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            span{
                padding: 0 10px;
                line-height: 20px;
                letter-spacing: 2px;
                border: 1px solid #35edff;
                border-radius: 10px;
                display: inline-block;
                margin-right: 15px;
            }
        }
        .content-user{
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-between;
            .content-user-left{
                display: flex;
                align-items: center;
                cursor: pointer;
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .content-user-auth{
                    display: inline-block;
                    padding: 2px 5px;
                    border: 1px solid #35edff;
                    font-size: 12px;
                    background-color: #424a61;
                    margin-left: 20px;
                }
            }
            .content-user-right{
                display: flex;
                align-items: center;
                span{
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>