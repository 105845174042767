<template>
  <div class="works-containers">
    <div class="classify-header" :class="isBar && 'classify-header-bar'">
      <div class="classify-list">
        <!-- <div :class="firstSelected ? 'classify-label-all selected' : 'classify-label'" @click="handleClick(null, -1)">全部</div> -->
        <div
          :class="
            item.selectFlag ? 'classify-label selected' : 'classify-label'
          "
          v-for="(item, index) in classifyList"
          :key="index"
          @click="handleClick(item, index)"
        >
          {{ item.classifyName }}
        </div>
      </div>
      <div class="classify-Child" style="margin-top: 20px">
        <div
          :class="
            child.selectFlag ? 'classify-label selected' : 'classify-label'
          "
          v-for="(child, childIndex) in childList"
          :key="childIndex"
          @click="handleChildClick(child, childIndex)"
        >
          {{ child.classifyName }}
        </div>
      </div>
    </div>
    <div
      class="work-list"
      v-if="workList"
      :style="isBar && 'margin-top: 120px'"
    >
      <router-link
        class="work-container"
        v-for="(item, index) in workList"
        :key="index"
        tag="a"
        target="_blank"
        :to="`/workDetail?activeIndex=0&workDetailId=${item.id}`"
      >
        <div class="work-cover">
          <img :src="item.cover" alt="" />
          <div class="hoticon" v-if="item.hotFlag >= 1">
            <img src="@/assets/img/bbs/file.png" alt="" />
            <div class="lines"></div>
          </div>
        </div>
        <div class="work-content">
          <div class="work-title">
            {{ item.title }}
          </div>
          <div class="work-live">
            <div class="work-look">
              <img
                src="@/assets/newImg/37.png"
                alt=""
                style="width: 15px; height: 13px"
              />
              <div>{{ item.browseNumber }}</div>
            </div>
            <div class="work-look">
              <img
                src="@/assets/newImg/38.png"
                alt=""
                style="width: 15px; height: 14px"
              />
              <div>{{ item.commentNum }}</div>
            </div>
            <div class="work-look">
              <img
                src="@/assets/newImg/39.png"
                alt=""
                style="width: 15px; height: 14px"
              />
              <div>{{ item.collectNum }}</div>
            </div>
            <div class="work-hot" v-if="item.hotFlag >= 1">
              <img
                src="@/assets/newImg/42.png"
                alt=""
                style="width: 15px; height: 14px"
              />
            </div>
          </div>
          <div class="work-user">
            <!-- <div class="user-headImg">
                            <img :src="item.headImg | 'http://www.glsxvfx.com/prod-api/profile/upload/2021/11/10/default_headImg.jpg'" alt="">
                            <div>{{ item.nickName }}</div>
                        </div> -->
            <div class="lasttime">
              <!-- {{item.createTime | Timeago}} -->
              发布时间：{{ item.createTime }}
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="kong" v-if="isShowshuju">暂无数据！</div>
    <div class="paginations">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getWorkClassInfo,
  getDicts,
  getClassifyList,
  getChildList,
} from "@/api/work.js";
import Cookie from "js-cookie";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 15,
      workList: "",
      isShowshuju: false,
      isShowshujus: false,
      classifyList: [],
      childList: [],
      total: 0,
      firstSelected: true,
      selectFlag: false,
      classifyLabel: null,
      childLabel: null,
      isBar: false,
    };
  },
  components: {},
  watch: {
    "$route.query.latest"(newVla, oldVla) {
      this.getWorkClassInfos(newVla);
    },
  },
  created() {
    this.$parent.optionIndex = 0;
    this.getClassifyList();
    this.getWorkClassInfos(null);
  },
  mounted() {
    window.addEventListener("scroll", this.eventHandleScroll);
  },
  methods: {
    // 滚动
    eventHandleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 360) {
        this.isBar = true;
      } else {
        this.isBar = false;
      }
    },
    handleSizeChange(val) {},
    // 换页数
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getWorkClassInfos(this.classifyLabel ? this.classifyLabel : null);
    },
    // 切换分类
    async handleClick(item, index) {
      let label = undefined;
      this.currentPage = 1;
      if (index == 0) {
        label = undefined;
        this.classifyLabel = undefined;
        // this.firstSelected = true;
        this.classifyList.forEach((item1, index) => {
          if (index == 0) {
            item1.selectFlag = true;
            this.childList = [];
          } else {
            item1.selectFlag = false;
          }
        });
      } else {
        label = item.classifyName;
        this.classifyLabel = item.classifyName;
        // this.firstSelected = false;
        this.classifyList.forEach((item1, indexs) => {
          if (indexs == index) {
            item1.selectFlag = true;
          } else {
            item1.selectFlag = false;
          }
        });
        let queryData = {
          parentId: item.id,
        };
        getChildList(queryData).then((res) => {
          if (res.code == 200) {
            this.childList = res.data;
            // this.childList.forEach((child, childIndex)=>{
            //     if(childIndex == 0){
            //         child.selectFlag = true
            //     }
            //     label = item.classifyName + this.childList[0].classifyName;
            // })
          }
        });
      }
      this.getWorkClassInfos(label);
    },
    // 切换子分类
    handleChildClick(item, index) {
      let label = undefined;
      label = this.classifyLabel + "," + item.classifyName;
      this.childList.forEach((item1, indexs) => {
        if (indexs == index) {
          item1.selectFlag = true;
        } else {
          item1.selectFlag = false;
        }
      });
      this.getWorkClassInfos(label);
    },
    getWorkDetail(item) {
      this.$router.push(`/workDetail?activeIndex=0&workDetailId=${item.id}`);
    },
    async getWorkClassInfos(classifyLabel) {
      this.isShowshuju = false;
      if (Cookie.get("userInfo")) {
        this.$store.commit("setUserInfo", JSON.parse(Cookie.get("userInfo")));
      }
      let data = {
        workType: 2,
        type: 1,
        classify: classifyLabel,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      let { code, rows, total } = await getWorkClassInfo(data);
      if (code == 200) {
        this.workList = rows;
        this.total = total;
        if (rows.length == 0) {
          this.isShowshuju = true;
        }
      }
    },
    async getClassifyList() {
      let state = {
        queryType: 0,
      };
      let { code, data } = await getClassifyList(state);
      if (code == 200) {
        this.classifyList = data;
        this.classifyList.forEach((item, index) => {
          if (index == 0) {
            item.selectFlag = true;
          } else {
            item.selectFlag = false;
          }
        });
        let queryData = {};
        getChildList(queryData).then((res) => {
          if (res.code == 200) {
            this.childList = res.data | res.rows;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.works-containers {
  .work-list {
    width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    // background: #4e4e4e;
    .work-container {
      width: 258.5px;
      height: 270px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 18px;
      margin-top: 20px;
      // background: #0C0D12;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0);
      &:hover {
        box-shadow: 0px 0px 5px 1px rgba(0, 119, 255, 0.88);
      }
      &:nth-child(1n) {
        margin-left: 5px;
      }
      &:nth-child(5n) {
        margin: 0;
        margin-top: 20px;
      }
      &:last-child {
        margin-bottom: 5px;
      }
      .work-cover {
        width: 260px;
        height: 160px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 1s all;
          &:hover {
            transform: scale(1.3);
          }
        }
        .hoticon {
          position: absolute;
          top: 14px;
          left: 15px;
          img {
            width: 15px;
            height: 16px;
            object-fit: cover;
          }
          .lines {
            width: 15px;
            height: 1px;
            background: #ec4d36;
            opacity: 1;
          }
        }
      }
      .work-content {
        height: 120px;
        // background: #2D2E2C;
        background: #0c0d12;
        opacity: 1;
        border-radius: 10px;
        .work-title {
          width: 251px;
          height: 50px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 500;
          line-height: 35px;
          color: #ffffff;
          opacity: 1;
          padding-left: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .work-live {
          width: 260px;
          // height: 15px;
          display: flex;
          .work-look {
            height: 1px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            div {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              line-height: 0px;
              color: #ffffff;
              opacity: 1;
              margin-left: 6px;
              display: block;
            }
            &:first-child {
              margin-left: 12px;
            }
            &:nth-of-type(n + 2) {
              margin-left: 20px;
            }
          }
          .work-hot {
            height: 1px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            // div{
            //     font-size: 14px;
            //     font-family: Microsoft YaHei;
            //     font-weight: 300;
            //     line-height: 0px;
            //     color: #9EEA6A;
            //     opacity: 1;
            //     margin-left:6px;
            //     display: block;
            // }
            &:nth-of-type(n + 2) {
              margin-left: 85px;
            }
          }
        }
        .work-user {
          width: 100%;
          height: 45px;
          display: flex;
          justify-content: space-between;
          padding: 0px 12px;
          align-items: center;
          margin-top: 15px;
          border-top: 1px #4398fa6c solid;
          .user-headImg {
            height: 30px;
            display: flex;
            align-items: center;
            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
            }
            div {
              width: 120px;
              height: 30px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              line-height: 30px;
              color: #ffffff;
              opacity: 1;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .lasttime {
            //width: 150px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            line-height: 30px;
            color: #cfcccc;
            opacity: 0.65;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .classify-header {
    background: #181a29;
    padding: 5px;
    width: 100%;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 0.125rem;
      background: linear-gradient(to right, #181a29 10%, #323547, #181a29 90%);
      position: absolute;
      bottom: 0;
    }
  }
  .classify-header.classify-header-bar {
    position: fixed;
    top: 0;
    z-index: 9999;
  }
  .classify-list {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 10px;
    overflow: hidden;
    //background: #353849;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    .classify-label-all {
      margin-right: 20px;
      margin-left: 20px;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
      padding: 3px 15px;
      &:hover {
        // background: #2D2E2D;
        color: #2d2e2d;
        border-radius: 10px;
        background: #c8f398;
      }
    }
    .classify-label {
      margin-right: 10px;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
      padding: 2px 10px;
      &:hover {
        // background: #2D2E2D;
        // color: #2D2E2D;
        color: #86d2ea;
        // background: #c8f398;
      }
    }
    .selected {
      //color: #86D2EA;
      // color: #2D2E2D;
      // border-radius: 10px;
      //padding: 2px 10px;
      //border-bottom: 2px #86D2EA solid;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 0.125rem;
        background: #86d2ea;
        position: absolute;
        bottom: 0px;
        left: -50%;
        transform: translateX(50%);
      }
    }
  }
  .classify-Child {
    width: 1390px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 10px;
    overflow: hidden;
    //background: #353849;
    // background: #555454;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    .classify-label-all {
      margin-right: 20px;
      margin-left: 20px;
      color: #ffffff;
      font-size: 16px;
      cursor: pointer;
      padding: 3px 15px;
      &:hover {
        // background: #2D2E2D;
        color: #2d2e2d;
        border-radius: 10px;
        background: #c8f398;
      }
    }
    .classify-label {
      margin-right: 10px;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      padding: 2px 10px;
      &:hover {
        // background: #2D2E2D;
        // color: #2D2E2D;
        color: #86d2ea;
        // background: #c8f398;
      }
    }
    .selected {
      //color: #86D2EA;
      // color: #2D2E2D;
      // border-radius: 10px;
      //padding: 2px 10px;
      //border-bottom: 2px #86D2EA solid;
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 0.125rem;
        background: #86d2ea;
        position: absolute;
        bottom: 0px;
        left: -50%;
        transform: translateX(50%);
      }
    }
  }
  .kong {
    width: 100%;
    height: 300px;
    text-align: center;
    color: white;
    line-height: 300px;
  }
  .paginations {
    margin-top: 80px;
    margin-bottom: 100px;

    .el-pagination {
      text-align: center;
    }

    ::v-deep .el-pagination .btn-prev {
      // background: #353849;
      width: 65px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0px;
    }

    ::v-deep .el-pager li {
      background: #181a29;
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      background-color: #313345;
      margin-left: 10px;
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      box-sizing: border-box;
      text-align: center;
    }

    ::v-deep .el-pagination .btn-next {
      width: 65px;
      margin-left: 10px;
      height: 35px;
      background: #313345;
      color: #ffffff;
      padding: 0;
      // background: #353849;
    }

    ::v-deep .el-pager li.active {
      color: white !important;
      // background-image: linear-gradient(135deg,#54c1ef,#10a5ff);
      background: #00b4d4;
    }

    ::v-deep .el-pagination__jump {
      display: inline-block;
      font-size: 16px;
      min-width: 35.5px;
      height: 35px;
      padding: 0 10px;
      line-height: 35px;
      vertical-align: top;
      box-sizing: border-box;
      border: 1px solid #414141;
      color: #ffffff;
      background-color: #313345;
    }

    ::v-deep .el-input {
      width: 70px;
      height: 33px;
    }

    ::v-deep .el-pagination__editor.el-input .el-input__inner {
      height: 24px;
      top: -2px;
      background: #001220 !important;
      color: white;
      border: none;
    }

    ::v-deep .el-pagination__editor {
      margin: 0 10px;
    }
  }
}
</style>