<template>
  <div class="index-page-container index-curriculum-container">
    <div class="index-page-content index-curriculum-content">
        <div class="curriculum-title">
          <div class="curriculum-title-left">{{content.title}}</div>
          <el-button class="curriculum-title-right" @click="eventToPage(12)">留言咨询</el-button>
        </div>
        <div class="curriculum-box">
          <div class="curriculum-box-type" v-for="(item,index) in content.typeList" :key="index">
            <div class="type-title">{{item.title || ''}}</div>
            <div class="type-content">
              <ul>
                <li v-for="(citem, cindex) in item.contentList" :key="cindex">{{(cindex + 1) + '.' + citem}}</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      require: true
    }
  },
  methods: {
    eventToPage(n) {
      this.$emit('eventToPage', n);
    }
  }
}
</script>

<style lang="scss" scoped>
.index-curriculum-container{
  .index-curriculum-content{
    .curriculum-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-width: 1158px;
      .curriculum-title-left{
        font-size: 40px;
        position: relative;
        //width: 800px;
        display: inline-block;
        &::after{
          content: '';
          width: 80px;
          height: 5px;
          background-image: linear-gradient(to right, #d73860, #6c85e5);
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
      .curriculum-title-right{
        height: 40px;
        border-radius: 10px;
        background-image: linear-gradient(to right, #d73860, #6c85e5);
        color: #FFFFFF;
        border: none;
      }
    }
    .curriculum-box{
      margin-top: 25px;
      display: flex;
      .curriculum-box-type{
        //width: 500px;
        color: #9ABFCC;
        font-size: 18px;
        .type-title{
          height: 35px;
          border-bottom: 3px solid #9ABFCC;
          text-indent: 50px;
        }
        .type-content{
          margin-top: 35px;
          width: 500px;
          border: 1px solid #647A84;
          background-color: rgba(40,45,60,0.9);
          padding: 26px;
          margin-left: 50px;
          height: calc(100% - 125px);
          >ul{
            >li{
              width: 100%;
              line-height: 40px;
              border-top: 1px solid rgba($color: #647A84, $alpha: 0.4);
              &:nth-child(1) {
                border-top: none;
              }
            }
          }
        }
        &:nth-child(1) {
          .type-title{
            text-indent: 0;
          }
          .type-content{
            margin-left: 0px;
          }
        }
      }
    }
  }
}
</style>