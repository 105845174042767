<template>
    <div class="menu-container">
        <el-menu :default-active="activeIndex" 
            class="el-menu-demo" 
            mode="horizontal" 
            background-color="#131522" 
            text-color="#fff"
            active-text-color="#00d1ff"
            >
            <customElMenu :list="menuList" @handleSelect="handleSelect" @deleteSelect="deleteSelect" :activeIndex="activeIndex" @getChildList="getChildList"></customElMenu>
            <!-- <div v-for="item in menuList" :key="item.id">
                <el-submenu :index="item.id" 
                    popper-class="children-menu" 
                    :popper-append-to-body="false"
                    v-if="item.childList && item.childList.length">
                    <template slot="title">{{item.name}}</template>
                    <el-submenu v-show="false" index="0" popper-class="children-menu"></el-submenu>
                </el-submenu>
                <el-menu-item :index="item.id" @click.native="handleSelect(1,2)" v-else>{{item.name}}</el-menu-item>
            </div> -->
            <!-- <el-menu-item index="1" @click.native="handleSelect(1,2)">作品</el-menu-item> -->
            <!-- <el-submenu index="1" popper-class="children-menu" :popper-append-to-body="false">
                <template slot="title">作品</template>
                <el-submenu v-show="false" index="0" popper-class="children-menu"></el-submenu>
            </el-submenu>
            <el-submenu index="2" popper-class="children-menu" :popper-append-to-body="false" @click.native="handleSelect(1,2)">
                <template slot="title"><span>{{activeLabel || '灵感'}}</span><i class="el-icon-close" @click.stop="deleteSelect(1,'选项x')" v-show="activeLabel" ref="testref"></i></template>
                <el-submenu index="2-1" popper-class="children-menu" :popper-append-to-body="false" @click.native="handleSelect(1,'选项1')">
                    <template slot="title">选项1</template>
                    <el-menu-item index="2-1-1" @click.native="handleSelect(1,'选项1-1')">选项1-1</el-menu-item>
                    <el-menu-item index="2-1-2" @click.native="handleSelect(1,'选项1-2')">选项1-2</el-menu-item>
                    <el-menu-item index="2-1-3" @click.native="handleSelect(1,'选项1-3')">选项1-3</el-menu-item>
                </el-submenu>
                <el-menu-item index="2-2">选项2</el-menu-item>
                <el-menu-item index="2-3">选项3</el-menu-item>
                <el-submenu index="2-4" popper-class="children-menu" :popper-append-to-body="false">
                    <template slot="title">选项4</template>
                    <el-menu-item index="2-4-1">选项4-1</el-menu-item>
                    <el-menu-item index="2-4-2">选项4-2</el-menu-item>
                    <el-menu-item index="2-4-3">选项4-3</el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-menu-item index="3">技巧</el-menu-item> -->
        </el-menu>
    </div>
</template>

<script>
let downDom = '';
import customElMenu from './components/customElMenu.vue'
import {
    getClassifyList,
    getChildList
} from '@/api/work.js'
export default {
    data() {
        return {
            activeIndex: '1',
            activeLabel: undefined,
        }
    },
    props: {
        menuList: {
            type: Array,
            default: () => []
        }
    },
    components: {
        customElMenu
    },
    methods: {
        getChildList(id) {
            this.$emit('eventChildList', id)
        },
        // 选择
        handleSelect(id, name) {
            console.log(id, name);
            this.activeLabel = name;
            this.activeIndex = id.toString();

            // console.log(this.$refs['testref'].parentNode.childNodes);
            // if(downDom) downDom.style.display="";
            // for(let i = 0; i < this.$refs['testref'].parentNode.childNodes.length; i++) {
            //     let item = this.$refs['testref'].parentNode.childNodes[i];
            //     if (Object.values(item.classList).indexOf('el-icon-arrow-down') != -1) {
            //         downDom = item;
            //         break;
            //     }
            // }
            // downDom.style.display="none";
        },
        // 删除所选
        deleteSelect(key, keyPath) {
            console.log(key, keyPath);
            this.activeLabel = undefined;
            console.log(downDom);
            downDom.style.display="";
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-container{
    /deep/.el-menu.el-menu--horizontal{
        border-bottom: 0;
        height: 65px;
    }
    /deep/.el-menu--horizontal>.custom-container{
        display: flex;
        height: 100%;
    }
    /deep/.el-menu.el-menu--horizontal>.custom-container>li{
        font-size: 18px;
        line-height: 75px;
        height: 100%;
        letter-spacing: 4px;
        font-family: SimHei;
        position: relative;
    }

    /deep/.el-menu.el-menu--horizontal>.custom-container>li:hover{
        background-color: #3a3c4b !important;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-submenu .el-submenu__title{
        width: 100%;
        height: 100%;
        line-height: 75px;
        font-size: 18px;
        letter-spacing: 4px;
        font-family: SimHei;
        padding: 0;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-submenu .el-submenu__title>.sub-title-box{
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-submenu .el-submenu__title>.sub-title-box.is-active{
        border-bottom: 4px solid #00d1ff;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-submenu .el-submenu__title:hover{
        background-color: #3a3c4b !important;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-menu-item.is-active{
        box-sizing: border-box;
        border-bottom: 4px solid #00d1ff;
    }

    /deep/.el-menu--horizontal>.custom-container>.el-submenu.is-active{
        box-sizing: border-box;
        border-bottom: 4px solid #00d1ff;
    }

    /deep/.el-menu--horizontal {
        //background-color: #3a3c4b;
    }

    /deep/.children-menu {
        position: absolute !important;
        //top: 60px !important;
        top: 0 !important;
    }

    /deep/.children-menu ul{
        background-color: #3a3c4b !important;
    }

    /deep/.children-menu li{
        font-size: 16px !important;
    }

    /deep/.children-menu li .el-submenu__title{
        font-size: 16px !important;
        background-color: #3a3c4b !important;
    }

    /deep/.children-menu .el-submenu__title {
        height: 36px !important;
        line-height: 36px !important;
        color: #ffffff !important;
    }

    /deep/.children-menu .el-menu-item {
        color: #ffffff !important;
        background-color: #3a3c4b !important;
    }

    /deep/.el-menu--horizontal .custom-container .el-menu .custom-container .el-menu-item:hover,  /deep/.el-menu--horizontal .custom-container .el-menu .el-submenu__title:hover{
        background-color: #00d1ff !important;
    }

    /deep/.el-icon-arrow-down{
        //display: none;
    }

    /deep/.custom-container[tabindex = "0"] >li .el-icon-arrow-down{
        right: 5px;
        top: 60%;
    }

    /deep/.custom-container[tabindex = "0"] >li >.children-menu{
        top: 65px !important;
    }

}

</style>