<template>
  <div class="index-page-container index-series-container">
    <div class="index-page-content index-series-content">
      <div class="page-title series-title">课程系列</div>
      <div class="series-curriculum-box">
        <div class="series-curriculum-item">
          <div class="item-top">
            <div class="item-top-text">
              <p>Unity引擎</p>
              <p>特效课程</p>
            </div>
            <div class="item-top-img">
              <img v-lazy="require('@/assets/img/train/Unity.png')" />
            </div>
          </div>
          <div class="item-content-text">
            在学习过程中将经历大量项目实战；和怪力技术人员面对面，让你快速掌握Unity游戏特效核心技术，并具备相应的工作能力。
          </div>
          <div class="item-content-btn">
            <span @click="eventToPage(12)"
              >立即报名<i class="el-icon-right"></i
            ></span>
          </div>
        </div>
        <div class="series-curriculum-item">
          <div class="item-top">
            <div class="item-top-text">
              <p>3dsMax</p>
              <p>动作课程</p>
            </div>
            <div class="item-top-img">
              <img v-lazy="require('@/assets/img/train/3dsmax.png')" />
            </div>
          </div>
          <div class="item-content-text">
            从关键Pose到连击，通过代表性案例让学员快速掌握游戏动作要点，提高项目实战能力，完成小白到动画师的蜕变。
          </div>
          <div class="item-content-btn">
            <span @click="eventToPage(12)"
              >立即报名<i class="el-icon-right"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    eventToPage(n) {
      this.$emit("eventToPage", n);
    },
  },
};
</script>

<style lang="scss" scoped>
.index-series-container {
  .index-series-content {
    .series-curriculum-box {
      margin-top: 60px;
      display: flex;
      .series-curriculum-item {
        display: flex;
        flex-direction: column;
        width: 520px;
        height: 340px;
        padding: 60px 20px 20px 50px;
        box-sizing: border-box;
        border-width: 5px;
        border-style: solid;
        border-image-source: linear-gradient(to top, #6f9ee3, #c07d99);
        border-image-slice: 1;
        color: #9abfcc;
        background-color: rgba(40, 45, 60, 0.9);
        .item-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 50px;
          box-sizing: border-box;
          .item-top-text {
            font-size: 34px;
          }
          .item-top-img {
            width: 84px;
            > img {
              width: 100%;
              height: auto;
            }
          }
        }
        .item-content-text {
          margin-top: 40px;
          font-size: 16px;
          letter-spacing: 2px;
          height: 80px;
        }
        .item-content-btn {
          font-size: 20px;
          margin-top: 20px;
          color: #35edff;
          font-weight: bold;
          span {
            cursor: pointer;
          }
        }
        &:nth-child(2) {
          margin-left: 60px;
          border-image: linear-gradient(to top, #6f9ee3, #9778d6) 1;
        }
      }
    }
  }
}
</style>