<template>
  <div class="recruit-form-left-container">
    <el-form-item label="职位名称" label-width="120px">
      <glsx-input
        :maxlength="15"
        :value="dataForm.position"
        placeholderText="请输入职位名称,最多15个字"
        @getValue="changeInputValue($event, 'position')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="公司名称" label-width="120px">
      <glsx-input
        :maxlength="25"
        :value="dataForm.company"
        :disabled="true"
        placeholderText="请输入公司名称,最多25个字"
        @getValue="changeInputValue($event, 'company')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="投递邮箱" label-width="120px">
      <glsx-input
        :value="dataForm.email"
        type="email"
        placeholderText="请输入投递邮箱"
        @getValue="changeInputValue($event, 'email')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="公司地址" label-width="120px">
      <glsx-input
        :value="dataForm.address"
        type="text"
        :disabled="true"
        placeholderText="请输入公司地址"
        @getValue="changeInputValue($event, 'address')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="招聘分类" label-width="120px">
      <div class="work-type">
        <el-row :gutter="20" style="width: 100%">
          <el-col :span="8">
            <glsxSelect
              :options="classOptionsLevel1"
              :optionValue="dataForm.cid"
              @getValue="getClassifyId($event, 0, 'classOptionsLevel2')"
            ></glsxSelect>
          </el-col>
          <el-col :span="8">
            <glsxSelect
              :options="classOptionsLevel2"
              :optionValue="dataForm.classify"
              v-if="classOptionsLevel2.length"
              ref="OptionsLevel2"
              @getValue="changeInputValue($event, 'classify')"
            ></glsxSelect>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
    <el-form-item label="薪水/报酬" label-width="120px">
      <glsx-input
        :maxlength="25"
        :value="dataForm.emolUment"
        placeholderText="请输入薪酬如8-10K"
        @getValue="changeInputValue($event, 'emolUment')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="就业城市" label-width="120px">
      <glsx-input
        :value="dataForm.employCity"
        placeholderText="请输入就业城市"
        @getValue="changeInputValue($event, 'employCity')"
      ></glsx-input>
    </el-form-item>
    <el-form-item label="学历要求" label-width="120px">
      <div class="work-type">
        <el-row :gutter="10" style="width: 100%">
          <el-col :span="24">
            <glsxSelect
              :options="educationOptions"
              :optionValue="dataForm.eduBg"
              @getValue="changeInputValue($event, 'eduBg')"
              placeholder="请选择学历要求"
            ></glsxSelect>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
    <el-form-item label="工作经验" label-width="120px">
      <div class="work-type">
        <el-row :gutter="10" style="width: 100%">
          <el-col :span="24">
            <glsxSelect
              :options="experOptions"
              :optionValue="dataForm.workExp"
              @getValue="changeInputValue($event, 'workExp')"
              :allowCreate="true"
              placeholder="请选择工作经验要求"
            ></glsxSelect>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
    <el-form-item label="福利关键词" label-width="120px">
      <div class="work-type work-welfare">
        <el-row :gutter="10" style="width: 100%">
          <el-col :span="24">
            <glsxSelect
              :options="welfareOptions"
              :optionValue="postWelfareArr"
              @getValue="changePostWelfare"
              :allowCreate="true"
              :multiple="true"
              placeholder="请选择或自行输入新增福利关键词"
            ></glsxSelect>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import {addJobBefo, getClassifyList} from "@/api/work.js";
import glsxInput from "@/component/form/glsxInput.vue";
import glsxSelect from "@/component/form/glsxSelect.vue";
export default {
  data() {
    return {
      dataForm: {},
      classOptionsLevel1: [],
      classOptionsLevel2: [],
      educationOptions: [
        {
          value: "不限",
          label: "不限",
        },
        {
          value: "中专",
          label: "中专",
        },
        {
          value: "高中",
          label: "高中",
        },
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          value: "硕士",
          label: "硕士",
        },
        {
          value: "博士",
          label: "博士",
        },
      ],
      welfareOptions: [
        {
          value: "五险一金",
          label: "五险一金",
        },
        {
          value: "周末双休",
          label: "周末双休",
        },
        {
          value: "下午茶",
          label: "下午茶",
        },
        {
          value: "免费体检",
          label: "免费体检",
        },
      ],
      experOptions: [
        { label: "1年以内", value: "1年以内" },
        { label: "1~3年", value: "1~3年" },
        { label: "3~5年", value: "3~5年" },
        { label: "5~10年", value: "5~10年" },
        { label: "10年以上", value: "10年以上" },
      ],
    }
  },
  components: {
    glsxInput,
    glsxSelect
  },
  created() {
    this.getAddJobBefore();
    this.getParentClassifyList();
  },
  methods: {
    changeInputValue(event, attributeName) {
      this.$set(this.dataForm, attributeName, event);
    },
    async getAddJobBefore() {
      let { code, data } = await addJobBefo();
      if (code === 200) {
        this.dataForm.company = data.company;
        this.dataForm.address = data.address;
      }
    },
    async getParentClassifyList() {
      let { code, data } = await getClassifyList({ queryType: 2 });
      if (code === 200) {
        data.forEach((item, index) => {
          this.classOptionsLevel1.push({
            label: item.classifyName,
            value: item.id,
          });
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.recruit-form-left-container{

}
</style>